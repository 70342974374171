import {RESET_APP, RESET_STORE} from '@actions/global.actions';
import {
  NOTIFICATIONS_ADD,
  NOTIFICATIONS_HIDE,
  NOTIFICATIONS_SHOW,
} from '@actions/notifications.actions';
import {emptyArr} from '@utils/constants';

const initialState = {
  shown: emptyArr,
  hidden: emptyArr,
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
  case RESET_APP:
  case RESET_STORE: {
    return initialState;
  }
  case NOTIFICATIONS_ADD: {
    const {notification} = action.payload;

    return {
      ...state,
      shown: [...state.shown, notification],
    };
  }
  case NOTIFICATIONS_SHOW: {
    const {notification} = action.payload;

    const newHidden = state.hidden.filter(({id}) => id !== notification.id);

    return {
      ...state,
      shown: [...state.shown, notification],
      hidden: newHidden.length ? newHidden : emptyArr,
    };
  }
  case NOTIFICATIONS_HIDE: {
    const {notification} = action.payload;

    const newShown = state.shown.filter(({id}) => id !== notification.id);

    return {
      ...state,
      shown: newShown.length ? newShown : emptyArr,
      hidden: [...state.hidden, notification],
    };
  }
  default: {
    return state;
  }
  }
};

export default notifications;
