import * as T from '@types/load.types';
import {mapDataToArray} from '@utils/misc.utils';
import {normalizeCompetence, normalizePersonCompetence} from '@utils/normalize.utils';

export const upsertCompetences = (state, action) => {
  const {pid, data} = action?.payload ?? {};

  const dataArr = mapDataToArray(data);

  for (const item of dataArr) {
    const competence = normalizeCompetence(item);

    const cid = competence?.competence_id;

    if (!cid) continue;

    if (!state.competences.hasOwnProperty(cid)) {
      state.competences[cid] = {};
    }

    state.competences[cid].last_update = Date.now();

    state.competences[cid].data = {
      ...state.competences[cid]?.data,
      ...competence,
      competence_id: cid,
    };

    if (!pid) continue;

    const personCompetence = normalizePersonCompetence(item);

    if (personCompetence?.passed == null && personCompetence?.checklist == null) continue;

    // update person competence, since competence can return "passed" value
    if (!state.personCompetences.hasOwnProperty(pid)) {
      state.personCompetences[pid] = {};
    }

    state.personCompetences[pid][cid] = {
      ...state.personCompetences[pid][cid],
      data: {
        ...state.personCompetences[pid][cid]?.data,
        ...personCompetence,
        person_id: pid,
        competence_id: cid,
      },
    };
  }
};

export const upsertPersonCompetences = (state, action) => {
  const {pid, data = {}} = action?.payload ?? {};

  if (!pid) return;

  if (!state.personCompetences.hasOwnProperty(pid)) {
    state.personCompetences[pid] = {};
  }

  if (!data) return;

  const dataArr = mapDataToArray(data);

  for (const item of dataArr) {
    const personCompetence = normalizePersonCompetence(item);
    const cid = personCompetence?.competence_id;

    if (!cid) continue;

    if (!state.personCompetences[pid].hasOwnProperty(cid)) {
      state.personCompetences[pid][cid] = {};
    }

    state.personCompetences[pid][cid].status = T.LoadStatuses.LOADED;
    state.personCompetences[pid][cid].last_update = Date.now();

    if (!personCompetence) continue;

    state.personCompetences[pid][cid] = {
      ...state.personCompetences[pid][cid],
      data: {
        ...state.personCompetences[pid][cid]?.data,
        ...personCompetence,
        person_id: pid,
        competence_id: cid,
      },
    };
  }
};

export const upsertChildrenCompetences = (state, action) => {
  const {
    cid: cidPayload,
    pid,
    data: children = [],
    batchData = [], // [{cid, data}]
  } = action?.payload ?? {};

  if (!cidPayload && !batchData?.length) return;

  const data = batchData?.length
    ? batchData
    : [{
      cid: cidPayload,
      data: children,
    }];

  for (const item of data) {
    if (!item?.cid) continue;

    const {cid: parentCid, data: childrenData = []} = item;

    if (!parentCid) continue;

    if (!state.competences.hasOwnProperty(parentCid)) {
      state.competences[parentCid] = {};
    }

    state.competences[parentCid].children_status = T.LoadStatuses.LOADED;
    state.competences[parentCid].children_last_update = Date.now();

    if (!childrenData?.length) continue;

    // prepare parent competence
    if (!state.competences[parentCid]?.data?.children_ids?.length) {
      state.competences[parentCid] = {
        ...state.competences[parentCid],
        data: {
          ...state.competences[parentCid]?.data,
          children_ids: action?.payload?.children_ids ?? [],
        },
      };
    }

    // prepare parent person competence
    if (pid && !state.personCompetences.hasOwnProperty(pid)) {
      state.personCompetences[pid] = {};
    }

    for (const child of childrenData) {
      const childCompetence = normalizeCompetence(child) ?? {};

      const {competence_id} = childCompetence;

      if (!competence_id) continue;

      if (!state.competences[parentCid].data.children_ids.includes(competence_id)) {
        state.competences[parentCid].data.children_ids.push(competence_id);
      }

      state.competences[competence_id] = {
        ...state.competences[competence_id],
        data: {
          ...state.competences[competence_id]?.data,
          // data that can't be updated from learning paths
          ...childCompetence,
          competence_id,
        },
      };

      if (!state.competences[competence_id].data.parent_ids) {
        state.competences[competence_id].data.parent_ids = [];
      }

      if (!state.competences[competence_id].data.parent_ids.includes(parentCid)) {
        state.competences[competence_id].data.parent_ids.push(parentCid);
      }

      if (!pid) continue;

      const childPersonCompetence = normalizePersonCompetence(child);

      if (!childPersonCompetence) continue;

      if (!state.personCompetences.hasOwnProperty(pid)) {
        state.personCompetences[pid] = {};
      }

      state.personCompetences[pid][competence_id] = {
        ...state.personCompetences[pid][competence_id],
        data: {
          ...state.personCompetences[pid][competence_id]?.data,
          ...childPersonCompetence,
          person_id: pid,
          competence_id,
        },
      };
    }
  }
};
