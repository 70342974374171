import memoize from 'micro-memoize';
import {createSelector} from 'reselect';
import {getImageUrl} from '@utils/data.utils';
import {filterNullishProps, isObjectWithKeys} from '@utils/misc.utils';
import {isStringWithLength} from '@utils/string.utils';
import {selectAlert} from './auth.selectors';
import {getConfigObject} from './config.selectors';

export const stringifyQSSimple = obj => isObjectWithKeys(obj)
  ? Object.entries(obj)
    .map(([key, value]) => value === undefined
      ? null
      : `${encodeURIComponent(key)}=${
        value === null
          ? ''
          : encodeURIComponent(value)}`)
    .filter(Boolean)
    .join('&')
  : typeof obj === 'string'
    ? obj
    : '';

export const NanoThemeIds = {
  nanoDefaultBgColor: 'nanoDefaultBgColor',
  nanoDefaultBgGradient: 'nanoDefaultBgGradient',
  nanoDefaultTxtColor: 'nanoDefaultTxtColor',
  nanoDefaultFontTitle: 'nanoDefaultFontTitle',
  nanoDefaultFontTxt: 'nanoDefaultFontTxt',
};

export const NANO_THEME_KEYS = Object.keys(NanoThemeIds);

export const getIsMobile = ({browser: {is: {extraSmall, small} = {}} = {}}) => extraSmall || small;
export const getIsTablet = ({browser: {lessThan: {large: lessThanLarge} = {}} = {}}) => lessThanLarge;

export const getIsMobileCourseCatalog = ({
  browser: {
    is: {mobileCourseCatalog: isMobileCourseCatalog} = {},
    lessThan: {mobileCourseCatalog: lessThanMobileCourseCatalog} = {},
  } = {},
}) => isMobileCourseCatalog || lessThanMobileCourseCatalog;

export const getWindowSize = ({browser: {lessThan}}) => lessThan;

export const selectCustomTheme = ({theme: {customTheme}}) => customTheme;

export const selectThemeOrgId = ({theme: {orgId}}) => orgId;

export const selectLogoUrl = createSelector(
  getConfigObject,
  selectCustomTheme,
  (configObject, customTheme) => {
    const customThemeLogo = customTheme?.logoUrl;

    if (customThemeLogo) return getImageUrl(customThemeLogo);

    return configObject.getProperty('params.menu.logo') || '/images/snapper.svg';
  },
);

export const selectAppLoading = createSelector(
  selectAlert,
  getConfigObject,
  (alert = {}, configObject = {}) => {
    const isReloading = alert?.type === 'alert-reloading';

    if (!isReloading) return null;

    return {isReloading};
  },
);

export const selectCurrentNanoTheme = createSelector(
  selectCustomTheme,
  theme => {
    const nanoTheme = {};

    NANO_THEME_KEYS.forEach(key => {
      nanoTheme[key] = theme[key];
    });

    return nanoTheme;
  },
);

export const createNanoThemeParamsSelector = memoize(localTheme => createSelector(
  selectCurrentNanoTheme,
  theme => {
    // If local theme is set, use it
    if (localTheme) {
      const {
        color,
        colorTo,
        bgGradient,
        txtColor,
        defaultFont,
        defaultFontTitle,
      } = localTheme || {};

      if (!color && !bgGradient) return null;

      const [colorFrom, _colorTo] = isStringWithLength(bgGradient)
        ? bgGradient.split(',')
        : [color, colorTo];

      return {
        color: colorFrom,
        colorTo: _colorTo,
        txtColor,
        defaultFont,
        defaultFontTitle,
      };
    }

    const {
      nanoDefaultBgColor: color,
      nanoDefaultBgGradient: gradient,
      nanoDefaultTxtColor: txtColor,
      nanoDefaultFontTxt: defaultFont,
      nanoDefaultFontTitle: defaultFontTitle,
    } = theme || {};

    if (!color && !gradient) return null;

    const [colorFrom, colorTo] = gradient
      ? gradient.split(',')
      : [color, null];

    return {
      color: colorFrom,
      colorTo,
      txtColor,
      defaultFont,
      defaultFontTitle,
    };
  },
));

/** Creates a selector that returns the nano theme as a query string.
 *  If localTheme is supplied, it will override the global theme. */
export const createNanoThemeUrlParamsSelector = memoize(localTheme => createSelector(
  createNanoThemeParamsSelector(localTheme),
  theme => !!theme && stringifyQSSimple(filterNullishProps(theme)),
));
