import dayjs from 'dayjs';
import {produce} from 'immer';
import memoize from 'micro-memoize';
import {shallowEqual} from 'react-redux';
import {createSelector} from 'reselect';
import {selectProfileRolesCompetenceIds, selectProfileRolesTrackIds} from '@actions/profile.actions';
import {courseCatalogSelectLoadStatus} from '@context/course-catalog/selectors';
import {sortRoles} from '@routes/my-education/utils/utils';
import {
  _selectShowRoleData,
  _selectShowRoleStatus,
  _selectSummaryRequirementData,
  selectIsKioskMode,
  selectPassedCompetencesMap,
  selectPassedIdsSet,
  selectPendingItemsMap,
  selectProfileExpiringCompetences,
  selectProfileId,
} from '@selectors/profile.selectors';
import {i18ndayjs} from '@src/i18n';
import {
  findCompetenceId,
  findCompetencePassedDate,
  getIsCompetenceExpired,
} from '@src/store/util/competence-identity';
import {LoadStatuses} from '@types/load.types';
import {buildCompetencesTreeV2} from '@utils/competence.utils';
import {getImageUrl, verifyPassed} from '@utils/data.utils';
import {filterNullishProps, isObjectWithKeys} from '@utils/misc.utils';
import {validIntOrNull} from '@utils/number.utils';
import {
  createDeepEqualSelector,
  createIdsSelector,
  createJSONEqualSelector,
  createObjectNumberIdsSelector,
} from '@utils/selectors.utils';
import {emptyArr, emptyObj} from '../../common/utils/constants';
import {getCompetenceCoverImageSrc} from '../util/courses.util';
import {
  selectAPICompetences,
  selectAPICoursegroups,
  selectAPIPersonCompetences,
  selectAPITracks,
} from './api.selectors';
import {selectAuthStartUrl} from './auth.selectors';
import {getConfigObject, getDefaultImage} from './config.selectors';
import {
  createCompetenceDetailsByIdSelector,
  getNormalizedSelectedCompetences,
  selectCourseCatalogNormalizedData as selectCourseCatalogData,
} from './courses.selectors';

const selectPassedOrInProgressCompetenceIds = createSelector(
  selectPassedIdsSet,
  selectPassedCompetencesMap,
  (passedIdsSet, passedCompetencesMap) => {
    const passedOrInProgressIds = Object.keys(passedCompetencesMap || {});

    const unique = new Set(passedIdsSet);

    for (const id of passedOrInProgressIds) {
      const num = validIntOrNull(id);

      if (num != null) unique.add(num);
    }

    if (!unique.size) return emptyArr;

    return [...unique];
  },
);

const selectProfilePersoncompetences = createSelector(
  selectAPIPersonCompetences,
  selectProfileId,
  (personCompetences, profileId) => personCompetences?.[profileId] || emptyObj,
);

const selectProfilePersoncompetenceIds = createObjectNumberIdsSelector(selectProfilePersoncompetences);
const selectCompetenceIds = createObjectNumberIdsSelector(selectAPICompetences);
const selectCourseCatalogIds = createObjectNumberIdsSelector(selectCourseCatalogData);
const selectProfilePendingCompetenceIds = createObjectNumberIdsSelector(selectPendingItemsMap);

const selectAllCompetencesIds = createIdsSelector(
  selectPassedOrInProgressCompetenceIds,
  selectCompetenceIds,
  selectProfilePendingCompetenceIds,
  selectProfilePersoncompetenceIds,
  selectCourseCatalogIds,
  (passedOrInProgressIds, competenceIds, pendingIds, personCompetenceIds, courseCatalogIds) => {
    const res = [...new Set([
      ...passedOrInProgressIds,
      ...competenceIds,
      ...pendingIds,
      ...personCompetenceIds,
      ...courseCatalogIds,
    ])];

    if (!res.length) return emptyArr;

    return res;
  },
);

const _createAllCompetencesDataSelector = memoize((personId, roleId, trackId, mapId) => createSelector(
  selectAPITracks,
  selectProfilePersoncompetences,
  selectAPICompetences,
  selectProfilePendingCompetenceIds,
  selectPassedCompetencesMap,
  selectCourseCatalogData,
  selectAllCompetencesIds,
  (tracks, personCompetences, competences, pendingItems, passedCompetencesMap, catalogData, allIds) => {
    const data = {};

    for (const id of allIds) {
      data[id] = {
        track: tracks[id]?.data,
        competence: competences[id]?.data,
        personCompetence: personCompetences[id]?.data,
        passedCompetence: filterNullishProps(passedCompetencesMap?.[id]),
        pendingItem: filterNullishProps(pendingItems?.[id]),
        courseCatalogCourse: filterNullishProps(catalogData[id]),
        ...!!(personId || roleId || trackId || mapId) && {
          extraAttributes: {
            personId,
            roleId,
            trackId,
            mapId,
          },
        },
      };

      data[id] = filterNullishProps(data[id]);
    }

    return data;
  },
));

const _createMergedCompetencesSelectorV2 = memoize((personId, roleId, trackId, mapId) => createDeepEqualSelector(
  _createAllCompetencesDataSelector(personId, roleId, trackId, mapId),
  selectPassedIdsSet,
  (allCompetences, passedCidsSet) => {
    const joined = {};

    for (const cid of Object.keys(allCompetences)) {
      const {
        track,
        competence,
        personCompetence,
        passedCompetence,
        pendingItem,
        courseCatalogCourse,
        extraAttributes,
      } = allCompetences[cid] || {};

      const mergedPersonCompetence = {
        ...passedCompetence,
        ...personCompetence,
      };

      const merged = {
        ...courseCatalogCourse,
        ...track,
        ...competence,
        ...mergedPersonCompetence,
        ...extraAttributes,
      };

      merged.passed = merged?.passed !== 100 && passedCidsSet.has(cid)
        ? 100
        : merged.passed;

      const passed_date = findCompetencePassedDate(merged);
      const waiting_for_approval = merged?.passed === 100
        ? false
        : merged?.waiting_for_approval ?? !!pendingItem;

      if (passed_date) merged.passed_date = passed_date;
      if (waiting_for_approval != null) merged.waiting_for_approval = waiting_for_approval;

      if (!merged.cover && merged.track_image) {
        merged.cover = {url: merged.track_image};
      }

      joined[cid] = merged;
    }

    return joined;
  },
));

const _createCompetencesWithChildrenSelector = memoize((personId, roleId, trackId, mapId) => createSelector(
  _createMergedCompetencesSelectorV2(personId, roleId, trackId, mapId),
  selectPassedIdsSet,
  (joined, passedCidsSet) => buildCompetencesTreeV2(joined, passedCidsSet),
));

export const selectAllMergedCompetencesData = _createCompetencesWithChildrenSelector();

export const createMergedCompetencesSelectorWithExtraAttributes = memoize(args => {
  const nonNullishArgs = filterNullishProps(args);

  // if no args are passed, return the default selector
  if (!isObjectWithKeys(nonNullishArgs)) return selectAllMergedCompetencesData;

  const {personId, roleId, trackId, mapId} = nonNullishArgs;

  return _createCompetencesWithChildrenSelector(personId, roleId, trackId, mapId);
}, {
  // check for equality of the args object
  isMatchingKey: (a, b) => {
    const object1 = a?.[0] || {};
    const object2 = b?.[0] || {};

    const nonNullish1 = filterNullishProps(object1);
    const nonNullish2 = filterNullishProps(object2);

    return shallowEqual(nonNullish1, nonNullish2);
  },
});

export const selectExpiredProfileCompetencesMap = createJSONEqualSelector(
  selectProfileExpiringCompetences,
  selectAllMergedCompetencesData,
  (expiring, competences) => expiring?.data?.length
    ? expiring?.data?.reduce((acc, expiringItem) => {
      const cid = findCompetenceId(expiringItem);
      const competence = competences[cid];

      if (!cid || !getIsCompetenceExpired(expiringItem) || competence && !getIsCompetenceExpired(competence)) return acc;

      acc[cid] = expiringItem;

      return acc;
    }, {})
    : emptyObj,
);

export const selectShowRoleWithoutStatus = createSelector(
  _selectShowRoleData,
  selectPassedIdsSet,
  selectAllMergedCompetencesData,
  (roleData = emptyObj, passedIdsSet = null, competences = {}) => {
    const {requirements = []} = roleData || {};

    if (!requirements?.length || !passedIdsSet?.size && !isObjectWithKeys(competences)) return {data: roleData};

    const newData = produce(requirements, draft => {
      for (const competence of draft) {
        const competence_id = findCompetenceId(competence);

        if (!competence_id) continue;

        const joinedData = competences[competence_id] || {};

        Object.assign(competence, joinedData);
      }
    });

    return {
      data: {
        ...roleData,
        requirements: newData,
      },
    };
  },
);

export const selectShowRole = createSelector(
  _selectShowRoleStatus,
  selectShowRoleWithoutStatus,
  (status, {data: roleData}) => ({
    ...status,
    data: roleData,
  }),
);

const graceKeys = [-7, 7, 30, 90, 120]; // 0 is also an option, but maps to 120 days

const getNearestGraceKeyDown = days => {
  if (!days) return 120;

  const nearestKey = graceKeys.reduce((acc, key) => {
    if (days >= key) return key;

    return acc;
  }, -7);

  return nearestKey;
};

export const isOnboardingRole = role => role?.roletype_name === 'Onboarding';
export const isMandatoryRequirement = competence => competence?.requirement_type === 'Mandatory';

export const selectOnboardingRole = createSelector(
  selectShowRole,
  selectPassedCompetencesMap,
  (role, passedCompetences) => {
    const {status, data: roleData} = role || {};

    if (!roleData?.id || !isOnboardingRole(roleData)) return {
      role: emptyObj,
      stages: emptyArr,
      nonEmptyStages: emptyArr,
      competenceById: emptyObj,
      isEmpty: true,
      status: status || LoadStatuses.NOT_LOADED,
    };

    const {
      id,
      title,
      description,
      startdate,
      files,
      requirements = [],
      optional_competences = [],
      required_competences = [],
    } = roleData || {};

    const competenceSortKeys = [...required_competences, ...optional_competences]
      .reduce((acc, {sortkey, lock_period, id}) => {
        acc[id] = sortkey + (lock_period ? 1000 : 0);

        return acc;
      }, {});

    const lockableCompetenceIds = new Set(required_competences
      .filter(({lock_period}) => !!lock_period)
      .map(({id}) => id));

    const competenceById = {};

    const stages = graceKeys.reduce((acc, key) => {
      acc[key] = {
        dateEnd: dayjs(startdate).add(key, 'day')
          .format('YYYY-MM-DD'),
        competences: {
          mandatory: [],
          optional: [],
          allIds: [],
        },
        completedCompetences: {
          mandatory: [],
          optional: [],
          allIds: [],
        },
        allCompleted: false,
        key,
      };

      return acc;
    }, {});

    let hasNoDefinedPeriods = true;

    requirements.forEach((req, idx) => {
      const {
        competence = {},
        grace_period,
      } = req || {};

      if (grace_period) hasNoDefinedPeriods = false;

      const {
        competence_id,
        competence_type,
      } = competence || {};

      if (!competence_id) return;

      const graceKey = getNearestGraceKeyDown(grace_period);

      stages[graceKey].competences.allIds.push(competence_id);
      const isMandatory = isMandatoryRequirement(req);

      if (isMandatory) {
        stages[graceKey].competences.mandatory.push(competence_id);
      } else {
        stages[graceKey].competences.optional.push(competence_id);
      }

      competenceById[competence_id] = {
        ...passedCompetences[competence_id],
        ...req,
        ...competence,
        type: competence_type?.competence_type,
        key: graceKey,
        isMandatory,
        isLockable: isMandatory && lockableCompetenceIds.has(competence_id),
      };

      const isPassed = verifyPassed(competenceById[competence_id]);

      competenceById[competence_id].isPassed = isPassed;

      if (isPassed) {
        stages[graceKey].completedCompetences.allIds.push(competence_id);

        if (isMandatory) {
          stages[graceKey].completedCompetences.mandatory.push(competence_id);
        } else {
          stages[graceKey].completedCompetences.optional.push(competence_id);
        }
      }
    });

    const nonEmptyStageIds = [];
    const sortedIds = {};

    const stagesWithInfo = Object.values(stages)
      .filter(stage => !!stage?.competences?.allIds?.length)
      .sort((a, b) => Number(a.key) - Number(b.key))
      .map((stage, idx, arr) => ({
        ...stage,
        prevStage: idx > 0 ? arr[idx - 1] : null,
      }))
      .reduce((acc, stage, idx) => {
        if (!stage?.competences?.allIds) return acc;

        const {
          competences: {
            allIds,
            mandatory: mandatoryCompetenceIds,
          },
          completedCompetences: {
            allIds: completedIds,
            mandatory: completedMandatoryCompetenceIds,
          },
          key,
          prevStage,
        } = stage;

        const competencesLength = allIds.length;
        const isNotEmpty = competencesLength > 0;

        if (isNotEmpty) nonEmptyStageIds.push(key);

        const passedLength = completedIds.length;
        const allCompleted = competencesLength === passedLength;
        const allMandatoryCompleted = mandatoryCompetenceIds.length === completedMandatoryCompetenceIds.length;

        sortedIds[key] = {
          mandatory: {
            completed: [],
            incomplete: [],
            all: [],
          },
          optional: {
            completed: [],
            incomplete: [],
            all: [],
          },
          all: {
            completed: [],
            incomplete: [],
            all: [],
          },
        };

        const mandatorySorted = mandatoryCompetenceIds
          .sort((a, b) => {
            const aKey = competenceSortKeys[a];
            const bKey = competenceSortKeys[b];

            return aKey - bKey;
          });

        const stageLockIndex = allMandatoryCompleted
          ? -1
          : mandatorySorted.findIndex(id => lockableCompetenceIds.has(id) && !completedMandatoryCompetenceIds.includes(id));

        allIds.sort((a, b) => {
          const aKey = competenceSortKeys[a];
          const bKey = competenceSortKeys[b];

          return aKey - bKey;
        }).forEach((id, idx) => {
          const competence = competenceById[id];
          const {
            isMandatory,
            isLockable,
            isPassed: competencePassed,
          } = competence;

          const isLocked = stageLockIndex !== -1 && isMandatory && isLockable && mandatorySorted.indexOf(id) > Math.max(stageLockIndex, 0);

          const isPassed = !isLocked && competencePassed;
          const status = isLocked
            ? 'LOCKED'
            : isPassed
              ? 'PASSED'
              : 'OPEN';

          let openedAfterId = null;

          if (isMandatory) {
            if (isLocked && idx > 0) {
              openedAfterId = sortedIds[key].mandatory.incomplete.slice(-1)?.[0];
            }
            sortedIds[key].mandatory.all.push(id);
            if (isPassed) {
              sortedIds[key].mandatory.completed.push(id);
            } else {
              sortedIds[key].mandatory.incomplete.push(id);
            }
          } else {
            sortedIds[key].optional.all.push(id);
            if (isPassed) {
              sortedIds[key].optional.completed.push(id);
            } else {
              sortedIds[key].optional.incomplete.push(id);
            }
          }
          sortedIds[key].all.all.push(id);
          if (isPassed) {
            sortedIds[key].all.completed.push(id);
          } else {
            sortedIds[key].all.incomplete.push(id);
          }

          competenceById[id].isPassed = isPassed;
          competenceById[id].isLocked = isLocked;
          competenceById[id].isOpened = !isLocked;
          competenceById[id].status = status;
          competenceById[id].openedAfterId = openedAfterId;
        });

        const prevStageDateEnd = prevStage?.dateEnd;
        const openedDate = prevStageDateEnd || null;
        const today = dayjs();

        acc[key] = stage;
        acc[key].allCompleted = allCompleted;
        acc[key].allMandatoryCompleted = allMandatoryCompleted;
        acc[key].dateOpen = i18ndayjs(openedDate).format('DD. MMM YYYY');
        acc[key].isOpen = openedDate
          ? dayjs(openedDate).isSame(today, 'day') || dayjs(openedDate).isBefore(today, 'day')
          : true;

        return acc;
      }, {});

    const cover = files?.find(file => file.title === 'cover');
    const header = files?.find(file => file.title === 'header');

    const nonEmptyStages = nonEmptyStageIds.map(key => stagesWithInfo[key]);

    return {
      role: {
        description,
        id,
        title,
        startdate,
        coverImage: cover?.url ? getImageUrl(cover.url) : null,
        headerImage: header?.url ? getImageUrl(header.url) : null,
      },
      competenceById,
      stages: stagesWithInfo,
      nonEmptyStages,
      firstIncompleteStage: nonEmptyStages.find(({isOpen, allMandatoryCompleted}) => isOpen && !allMandatoryCompleted),
      hasOnlyOneStage: nonEmptyStages.length === 1,
      status: LoadStatuses.LOADED,
      sortedIds,
      hasNoDefinedPeriods,
    };
  },
);

const rolesNotReady = Object.freeze({status: LoadStatuses.IS_LOADING});

const rolesEmpty = Object.freeze({
  status: LoadStatuses.LOADED,
  complete: emptyArr,
  hidden: emptyArr,
  inProgress: emptyArr,
});

const _selectMyRolesWithCompetenceIds = createSelector(
  _selectSummaryRequirementData,
  selectProfileRolesCompetenceIds,
  selectProfileRolesTrackIds,
  (rolesData, rolesCompetenceIds, rolesTrackIds) => {
    if (!rolesData?.length) return emptyArr;

    return rolesData.map(role => ({
      ...role,
      competence_ids: rolesCompetenceIds[role?.role_id || role?.id] || emptyArr,
      track_id: rolesTrackIds[role?.role_id || role?.id] || null,
    }));
  },
);

export const selectMyPageRoles = createJSONEqualSelector(
  _selectMyRolesWithCompetenceIds,
  selectAllMergedCompetencesData,
  (rolesData, joinedData) => {
    if (!rolesData) return rolesNotReady;
    if (!rolesData?.length) return rolesEmpty;

    const {roles, hidden, complete} = sortRoles(rolesData, joinedData);

    return {
      inProgress: roles,
      hidden,
      complete,
      status: LoadStatuses.LOADED,
    };
  },
);

export const selectMyPageRolesById = createJSONEqualSelector(
  selectMyPageRoles,
  ({
    inProgress = [],
    hidden = [],
    complete = [],
  }) => {
    const roles = [...inProgress, ...hidden, ...complete].filter(r => !!r?.id);

    if (!roles.length) return emptyObj;

    return roles.reduce((acc, role) => {
      acc[role.id] = role;

      return acc;
    }, {});
  },
);

export const selectTrackIdByRoleId = createJSONEqualSelector(
  selectMyPageRolesById,
  myRoles => {
    const trackIdByRoleId = {};

    for (const roleId of Object.keys(myRoles)) {
      const trackId = myRoles[roleId]?.trackData?.competence_id;

      if (!trackId) continue;

      trackIdByRoleId[roleId] = trackId;
    }

    return isObjectWithKeys(trackIdByRoleId)
      ? trackIdByRoleId
      : emptyObj;
  },
);

export const selectKioskModeAllowedStartIds = createJSONEqualSelector(
  selectIsKioskMode,
  selectMyPageRolesById,
  (isKioskMode, myRoles) => {
    const {
      '-2': personalCompetences,
      ...roles
    } = myRoles;

    const roleIds = [];
    const trackIds = [];

    for (const roleId of Object.keys(roles)) {
      if (!['role', 'onboarding'].includes(roles[roleId]?.type)) continue;

      roleIds.push(Number(roleId));

      const trackId = roles[roleId]?.trackData?.competence_id;

      if (!trackId) continue;

      trackIds.push(Number(trackId));
    }

    return {
      roleIds,
      trackIds,
    };
  },
);

export const selectKioskConfigStartUrl = createSelector(
  selectIsKioskMode,
  getConfigObject,
  (isKioskMode, configObject) => isKioskMode
    ? configObject?.getProperty?.('params.start-route')
    : null,
);

const notKioskMode = Object.freeze({
  status: false,
  role: null,
  meta: 'not_kiosk_mode',
});

const kioskMultiple = Object.freeze({
  status: false,
  role: null,
  meta: 'multiple_roles',
});

const kioskLoading = Object.freeze({
  status: LoadStatuses.IS_LOADING,
  role: null,
  meta: 'no_roles',
});

export const selectKioskSingleRole = createSelector(
  selectIsKioskMode,
  selectMyPageRolesById,
  (isKioskMode, myPageRoles) => {
    if (!isKioskMode) return notKioskMode;

    const {
      '1': _, // default employee role
      ...myRoles
    } = myPageRoles || {};

    if (!isObjectWithKeys(myRoles)) return kioskLoading;

    if (Object.keys(myRoles).length > 1) return kioskMultiple;

    const role = myRoles[Object.keys(myRoles)[0]];
    const isCompleted = role?.missing_count === 0 && role?.passed_count > 0;

    return {
      status: LoadStatuses.LOADED,
      role,
      meta: 'single_role',
      isCompleted,
    };
  },
);

export const selectIsKioskSingleRoleNotLoaded = createSelector(
  selectIsKioskMode,
  selectKioskSingleRole,
  (isKioskMode, role) => {
    if (!isKioskMode) return false;

    const {
      status,
      meta,
    } = role || {};

    return meta === 'no_roles' || status === LoadStatuses.IS_LOADING;
  },
);

const START_URL_MY_EDUCATION = ['/my-education'];

export const selectKioskModeStartUrlAlt = createSelector(
  selectIsKioskMode,
  selectKioskConfigStartUrl,
  selectMyPageRolesById,
  selectKioskSingleRole,
  selectAuthStartUrl,
  (isKioskMode, configStartUrl, myRoles, kioskSingleRole, authStartUrl) => {
    if (!isKioskMode) return emptyArr;

    const {
      roleIds = [],
      trackIds = [],
    } = selectKioskModeAllowedStartIds.resultFunc(isKioskMode, myRoles);

    if (roleIds.length > 1) return START_URL_MY_EDUCATION;

    const urls = [
      authStartUrl,
      !!trackIds[0] && `/learning-path/${trackIds[0]}`,
      !!roleIds[0] && `/my-education/role/${roleIds[0]}`,
    ].filter(Boolean);

    if (urls.length) return urls;

    return configStartUrl
      ? [configStartUrl]
      : emptyArr;
  },
);

export const selectShouldAllowMyPage = createSelector(
  selectIsKioskMode,
  selectKioskModeAllowedStartIds,
  (isKioskMode, {roleIds}) => !isKioskMode || (roleIds?.length || 0) > 1,
);

const emptyCoursegroups = Object.freeze({
  data: emptyObj,
  topLevelIds: emptyArr,
});

export const selectCoursegroupsMap = createSelector(
  selectAPICoursegroups,
  ({data}) => {
    if (!data?.length) return emptyCoursegroups;

    const order = data.filter(({parent_id}) => !parent_id).map(({id}) => id);

    const flat = {};

    const recursiveFlat = group => {
      const {id, children = [], ...rest} = group || {};

      flat[id] = {
        id,
        children_ids: children.length
          ? children.map(({id}) => id)
          : null,
        ...rest,
      };

      if (!children.length) return;

      children.forEach(child => recursiveFlat(child));
    };

    data.forEach(group => recursiveFlat(group));

    return {
      data: flat,
      topLevelIds: order,
    };
  },
);

export const selectCoursgroupsTreeData = createSelector(
  selectCoursegroupsMap,
  ({data: flat, topLevelIds: order}) => {
    if (!order?.length) return emptyArr;

    const ids = [];

    const addIds = id => {
      ids.push(id);

      const children = flat[id]?.children_ids || [];

      children.forEach(childId => addIds(childId));
    };

    order.forEach(id => addIds(id));

    const first = {
      id: 0,
      children: order,
      parent: null,
      name: '',
    };

    const rest = ids.map(id => ({
      id,
      parent: flat[id]?.parent_id || 0,
      children: flat[id]?.children_ids || [],
      name: flat[id]?.name || '',
    }));

    return [first, ...rest];
  },
);

export const selectCoursgroupsBreadpaths = createSelector(
  selectCoursegroupsMap,
  ({data: flat, topLevelIds: order}) => {
    if (!order?.length) return emptyArr;

    const ids = [];

    const addIds = id => {
      ids.push(id);

      const children = flat[id]?.children_ids || [];

      children.forEach(childId => addIds(childId));
    };

    order.forEach(id => addIds(id));

    const getBreadpath = id => {
      const {parent_id, name} = flat[id] || {};

      const path = [[id], name || ''];

      if (!parent_id) return path;

      let currentId = parent_id;

      while (currentId) {
        path[0].push(currentId);
        path[1] = `${flat[currentId]?.name || ''} > ${path[1]}`;
        currentId = flat[currentId]?.parent_id;
      }

      return [path[0].reverse(), path[1]];
    };

    return ids.reduce((acc, id) => {
      acc[id] = getBreadpath(id);

      return acc;
    }, {});
  },
);

export const createCompetenceGroupsSelectorBySearch = memoize(search => createSelector(
  selectCoursegroupsMap,
  selectCoursgroupsBreadpaths,
  ({data}, breadpaths) => {
    const searchLower = search?.toLowerCase?.() || '';

    const filtered = Object.entries(breadpaths)
      .filter(([, [, name]]) => name?.toLowerCase?.()?.includes?.(searchLower))
      .map(([id, [path]]) => ({
        id,
        path,
        name: data[id]?.name,
      }));

    return filtered;
  },
));

export const selectCoursegroupsData = createSelector(
  selectAPICoursegroups,
  coursegroups => {
    const {data, status} = coursegroups || {};

    if (!data?.length) return {
      data: emptyArr,
      status,
    };

    return {
      data,
      status,
    };
  },
);

export const createSingleCompetenceSelector = memoize(competenceId => createJSONEqualSelector(
  selectAllMergedCompetencesData,
  competences => {
    if (!competenceId || !competences?.[competenceId]) return emptyObj;

    return competences[competenceId];
  },
));

export const selectKioskRedirectUrl = createSelector(
  selectIsKioskMode,
  selectAuthStartUrl,
  selectKioskConfigStartUrl,
  selectKioskModeStartUrlAlt,
  (isKioskMode, authStartUrl, configStartUrl, kioskModeStartUrl) => {
    if (!isKioskMode) return null;

    const startUrl = kioskModeStartUrl?.[0] || authStartUrl || configStartUrl;

    return isKioskMode && !!startUrl && ['/my-education', '/'].includes(startUrl)
      ? '/my-education'
      : startUrl;
  },
);

export const createCourseCatalogCompetenceDetailsSelector = memoize(competenceId => createSelector(
  getDefaultImage,
  createCompetenceDetailsByIdSelector(competenceId),
  createSingleCompetenceSelector(competenceId),
  (defaultImage, competenceDetails, competence) => {
    const {events = emptyArr, calendarItems} = competenceDetails || {};
    const {content = {}, files = [], isFetching: competenceDetailsFetching} = competenceDetails || {};

    const isFetching = competenceDetailsFetching || !isObjectWithKeys(competenceDetails);
    const selfSignedUpForAnyEvent = !!events?.some?.(event => !!event?.participantsData?.selfConfirmed);

    const coverUrl = files?.length || isObjectWithKeys(content)
      ? getCompetenceCoverImageSrc({
        files,
        content,
      }, defaultImage)
      : null;

    const nonNullish = filterNullishProps(competenceDetails);

    return {
      competenceDetails: {
        ...competence,
        ...nonNullish,
        cover: {url: coverUrl || competenceDetails?.cover?.url || competence?.cover?.url || competence?.track_image},
        isFetching,
      },
      events,
      calendarItems,
      selfSignedUpForAnyEvent,
    };
  },
));

export const courseCatalogSelectActiveCompetencesWithProps = createSelector(
  courseCatalogSelectLoadStatus,
  getNormalizedSelectedCompetences,
  (loadStatus, normalizedSelectedCompetences) => {
    const {data: competencesData} = normalizedSelectedCompetences || {};
    const {selectedCompetencesIsFetching} = loadStatus || {};

    return {
      data: competencesData?.length
        ? competencesData
        : emptyArr,
      isFetching: selectedCompetencesIsFetching,
    };
  },
);
