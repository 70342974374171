import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Cookies from 'js-cookie';
import curry from 'lodash/curry';
import {allowedLanguages} from '@config';
import {getTwoletterLangCodeFromNavigatorLanguage} from '@utils/data.utils';
import {validIntOrNull} from '@utils/number.utils';

export const setLanguage = () => {
  const langCodeNavigator = getTwoletterLangCodeFromNavigatorLanguage();
  let locale = localStorage.getItem('language');

  if (langCodeNavigator && !locale) {
    locale = langCodeNavigator;
  }

  if (!allowedLanguages.includes(locale)) {
    locale = allowedLanguages[0];
  }

  localStorage.setItem('language', locale);

  const dayjsLocale = {
    no: 'nb',
    en: 'en-us',
  }[locale];

  dayjs.extend(customParseFormat);
  dayjs.locale(dayjsLocale);
};

export const handleToggleParams = configObject => {
  const pathname = window.location?.pathname || '';

  const isSetMapLocation = pathname.includes('/set-atlas');
  const isLoadConfig = pathname.includes('/load-config');

  if (!isSetMapLocation && !isLoadConfig) {
    return;
  }

  if (isLoadConfig) {
    localStorage.removeItem('learningportalConfig');
  }

  let langId;
  let updatedLang = false;
  let updatedTrack = false;

  try {
    const params = new URL(document.location)?.searchParams;

    langId = params.get('lang');

    if (langId) {
      updatedLang = true;
    }
  } catch {
    langId = null;
  }

  if (!langId) {
    langId = localStorage.getItem('lang') || configObject?.getProperty?.('params.default-language') || 'no';
  }

  localStorage.setItem('language', langId);

  if (isSetMapLocation) {
    const trackAlias = pathname.split('/')?.[2];

    Object.entries(localStorage).forEach(([k, v]) => {
      if (/^track-data\/\d+$/.test(k)) {
        localStorage.removeItem(k);
      }
    });

    // WE NEED TO MAKE BACKWARDS COMPATIBILITY FOR TRENERATTEST.
    if (trackAlias && configObject) {
      const trackAliasMap = configObject.getProperty('routes.atlas.trackAlias');

      if (trackAliasMap && typeof trackAliasMap === 'object') {
        const trackId = validIntOrNull(trackAliasMap[`${trackAlias}/${langId}`]);

        if (trackId) {
          localStorage.setItem('track', trackId);
          Cookies.set('trackId', trackId);

          updatedTrack = true;
        }
      }
    }
  }

  if (updatedTrack) {
    window.location = '/atlas';
  } else if (!isSetMapLocation && (isLoadConfig || updatedLang)) {
    window.location = '/';
  }
};

export const wrap = curry((wrappers, Component) => wrappers?.length
  ? wrappers.reduce((acc, wrapper) => typeof wrapper === 'function'
    ? wrapper(acc)
    : wrapper[0](acc, ...wrapper.slice(1)), Component)
  : Component);
