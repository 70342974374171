import dayjs from 'dayjs';
import {createSelector} from 'reselect';
import {LoadStatuses} from '@types/load.types';
import {eventHasStarted, getBartId} from '@utils/data.utils';
import {normalizePersonCompetence} from '@utils/normalize.utils';
import {validIntOrNull} from '@utils/number.utils';
import {createIdsSelector, createJSONEqualSelector} from '@utils/selectors.utils';
import {emptyArr, emptyObj, emptySet} from '../../common/utils/constants';
import {findCompetenceId} from '../util/competence-identity';
import {getConfigObject} from './config.selectors';
import {getSelectedOrganisation} from './employees.selectors';
import {_selectRolesData, selectRolesOrganisations} from './roles.selectors';
import {selectUserMeData} from './user.selectors';

export const selectProfile = ({profile}) => profile || emptyObj;
export const selectActiveOrgId = ({profile: {activeOrg}}) => activeOrg?.orgId || null;

export const selectOrgTreeParents = ({profile: {orgTreeParents}}) => orgTreeParents;

export const selectProfilePersonRoles = ({profile}) => profile?.person?.data?.roles || emptyArr;

export const getProfile = ({profile: {person}}) => person;
export const getRoles = ({profile: {roles}}) => roles;
export const _selectProfileRolesData = ({profile: {person: {data}} = {}}) => data?.roles || emptyArr;
export const _selectProfileCompetences = ({profile: {competences}}) => competences;
export const selectProfileSelfSign = ({profile: {selfSign}}) => selfSign;
const _selectSelfSignData = ({profile: {selfSign: {data} = {}} = {}}) => data;

export const _selectProfileEvents = ({profile: {events}}) => events;
export const selectCourseEventsById = ({courses: {normalizedData: {courseEvents: {eventById}}}}) => eventById;

const _selectProfileIsManager = ({profile: {person: {storeManager}}}) => storeManager;
const _selectUserGroupNames = ({user: {data: {group_names}}}) => group_names;

export const getSpecialroles = ({profile: {specialroles: {data}}}) => data;
export const getIsSuperuser = ({profile: {specialroles: {superuser}}}) => superuser;
export const getIsDriftsjef = ({profile: {specialroles: {driftsjef}}}) => driftsjef;
export const _getPassed = ({profile: {passed}}) => passed;
const _getPassedData = ({profile: {passed: {data}}}) => data;

const _selectShowRole = ({profile: {show_role} = {}}) => show_role;

export const _selectShowRoleStatus = createSelector(
  _selectShowRole,
  ({data: _, ...status}) => status,
);

export const _selectShowRoleData = ({profile: {show_role: {data}} = {}}) => data;

export const selectIsSubFetchingRole = ({profile: {competences: {isSubFetching}}}) => isSubFetching;
export const getSection = ({profile: {section}}) => section;
export const getTab = ({profile: {tab}}) => tab;
export const getCompetences = ({profile: {competences}}) => competences.data;
export const shouldUpdate = ({profile: {updated}}) => updated === null;
export const selectProfilePersonData = ({profile: {person} = {}}) => person?.data;
export const getPendingChecklists = ({profile: {pendingChecklists}}) => pendingChecklists;
export const selectProfileRolesSummary = ({profile: {summary}}) => summary;
export const selectSummaryTrackData = ({profile: {summary: {trackData: {data}}}}) => data;
export const _selectSummaryRequirementData = ({profile: {summary: {data} = {}} = {}}) => data?.requirement;
export const selectProfileExpiringCompetences = ({profile: {expiring}}) => expiring;
export const getProfileCompetenceLevel = ({profile: {person: {competencelevel}}}) => competencelevel;

export const selectShowRoleDataWithoutCompetences = createSelector(
  _selectShowRoleData,
  ({
    optional_competences: _,
    required_competences: __,
    requirements: ___,
    ...roleData
  }) => ({...roleData}),
);

export const selectIsManager = createSelector(
  _selectProfileIsManager,
  _selectUserGroupNames,
  (isManager, groupNames) => isManager || groupNames?.some(val => ['manager', 'superuser'].includes(val?.toLowerCase())),
);

export const selectProfileEvents = createSelector(
  _selectProfileEvents,
  selectCourseEventsById,
  (profileEvents, eventById) => {
    const data = profileEvents?.data?.map?.(event => {
      const {id} = event;

      if (!id) return event;

      const eventObject = eventById?.[id] || {};

      return {
        ...event,
        files: event.files || eventObject.files,
        event_files: event.event_files || eventObject.event_files,
        user_files: event.user_files || eventObject.user_files,
        fileUploadEnabled: event.user_file_upload_allowed ?? event.fileUploadEnabled ?? eventObject.user_file_upload_allowed ?? eventObject.fileUploadEnabled,
        fileUploadDeadline: event.user_file_upload_date_limit ?? event.fileUploadDeadline ?? eventObject.user_file_upload_date_limit ?? eventObject.fileUploadDeadline,
      };
    }) || emptyArr;

    return {
      ...profileEvents,
      data,
    };
  },
);

export const selectUpcomingProfileEventsData = createSelector(
  selectProfileEvents,
  selectIsManager,
  (events, isManager) => {
    if (!events?.data?.length) return emptyArr;

    // if (isManager) return events.data;

    const filtered = events.data.filter(event => !eventHasStarted(event));

    return filtered.length
      ? filtered
      : emptyArr;
  },
);

export const selectIsProfileTasksEmpty = createSelector(
  selectUpcomingProfileEventsData,
  selectProfileExpiringCompetences,
  selectProfileSelfSign,
  (events, expiring, selfSign) => !events?.length && !expiring?.data?.length && !selfSign?.data?.length,
);

export const selectProfileRoles = createSelector(
  _selectProfileRolesData,
  _selectRolesData,
  (profileRoles, rolesData) => {
    if (!Array.isArray(rolesData) || !Array.isArray(profileRoles) || !rolesData.length || !profileRoles.length) return emptyArr;

    return rolesData.filter(role => profileRoles
      .some(profileRole => profileRole.id === role.id));
  },
);

export const selectSelfSignIds = createSelector(
  _selectSelfSignData,
  selfSignData => selfSignData?.map(ss => ss.course_id) || emptyArr,
);

export const selectIsKioskMode = createSelector(
  getConfigObject,
  configObject => Number(configObject?.getProperty?.('params.kiosk') || 0) > 0,
);

export const selectIsMyPageDisabled = createSelector(
  selectIsKioskMode,
  selectProfilePersonRoles,
  (isKioskMode, personRoles) => isKioskMode && personRoles?.length === 1,
);

export const getIsFetchingProfile = createSelector(
  getProfile,
  ({status}) => status === LoadStatuses.IS_LOADING,
);

export const getPassed = createSelector(
  _getPassed,
  passed => {
    if (!passed?.data?.length) return {
      ...passed,
      allIds: emptyArr,
      byId: emptyObj,
    };

    const {
      allIds = [],
      byId = {},
    } = passed?.data?.reduce?.((acc, item) => {
      acc.byId[item.competence_id] = item;
      acc.allIds.push(item.competence_id);

      return acc;
    }, {
      allIds: [],
      byId: {},
    }) || {};

    return {
      ...passed,
      allIds,
      byId,
    };
  },
);

export const getPassedIds = createSelector(
  _getPassedData,
  passed => {
    if (!passed?.length) return emptyArr;

    const ids = [];

    for (const competence of passed) {
      if (competence?.passed !== 100) continue;

      if (competence.valid_until) {
        if (dayjs().isBefore(dayjs(competence.valid_until))) ids.push(competence.competence_id);
      } else {
        ids.push(competence.competence_id);
      }
    }

    return ids;
  },
);

export const selectPassedIdsSet = createIdsSelector(
  getPassedIds,
  passedIds => passedIds?.length
    ? new Set(passedIds)
    : emptySet,
);

export const selectPassedCompetencesMap = createJSONEqualSelector(
  _getPassedData,
  passedCompetences => passedCompetences?.length
    ? passedCompetences.reduce((acc, competence) => {
      const cid = findCompetenceId(competence);

      if (cid) acc[cid] = normalizePersonCompetence(competence);

      return acc;
    }, {})
    : emptyObj,
);

export const selectProfileId = createSelector(
  selectProfilePersonData,
  selectUserMeData,
  (profileData, userMeData) => getBartId() ?? profileData?.person_id ?? userMeData?.person_id ?? profileData?.id,
);

export const selectProfileUserName = createSelector(
  selectProfilePersonData,
  selectUserMeData,
  (profileData, userMeData) => getBartId()
    ? profileData?.user_name
    : userMeData?.username || profileData?.user_name,
);

export const selectProfileFullname = createSelector(
  selectProfilePersonData,
  selectUserMeData,
  (profileData, userMeData) => profileData?.fullname ?? userMeData?.fullname,
);

export const selectProfileFirstname = createSelector(
  selectProfilePersonData,
  selectUserMeData,
  (profileData, userMeData) => profileData?.firstname || userMeData?.firstname,
);

export const selectOnboardingDataNotCompleted = createSelector(
  _selectSummaryRequirementData,
  rolesData => {
    const onboardingData = rolesData?.find?.(req => req.type === 'onboarding');

    if (!onboardingData || !onboardingData?.missing_count) return null;

    return onboardingData;
  },
);

export const getIsFullPersonFetched = createSelector(
  selectProfilePersonData,
  data => !!data,
);

export const selectPersonOrganisations = createSelector(
  selectProfilePersonData,
  personData => personData?.organisations || emptyArr,
);

export const getMainOrganisations = createSelector(
  selectPersonOrganisations,
  selectActiveOrgId,
  (organisations, activeOrgId) => {
    if (!organisations?.length) return null;

    const orgId = validIntOrNull(activeOrgId || localStorage.getItem('orgId'));

    if (orgId) {
      const orgMatch = organisations.find(o => o.id === orgId);

      if (orgMatch) {
        return {
          title: orgMatch.title,
          brand_id: organisations[0]?.brand_id,
          id: orgMatch.id,
          organisation_id: orgMatch.id,
        };
      }
    }

    return organisations[0];
  },
);

export const getOrganisation = createSelector(
  selectPersonOrganisations,
  selectActiveOrgId,
  getSelectedOrganisation,
  (organisations, activeOrgId, selectedOrg) => {
    if (!organisations) return null;

    const orgId = activeOrgId || validIntOrNull(localStorage.getItem('orgId'));

    if (!orgId) return organisations?.[0];

    const orgMatch = organisations.find(o => o.id === orgId);

    if (orgMatch) return orgMatch;

    if (selectedOrg?.data?.organisation_id === orgId) {
      return {
        ...selectedOrg.data,
        id: orgId,
      };
    }

    return organisations?.[0] || null;
  },
);

export const getOrganisationId = createSelector(
  selectIsManager,
  getMainOrganisations,
  selectActiveOrgId,
  (manager, mainOrg, activeOrgId) => {
    if (activeOrgId) return activeOrgId;

    if (mainOrg?.organisation_id) return mainOrg.organisation_id;

    return mainOrg;
  },
);

export const getBrandId = createSelector(
  getOrganisation,
  getSelectedOrganisation,
  getMainOrganisations,
  (org, selectedOrg, mainOrg) => org?.brand_id || selectedOrg?.brand_id || mainOrg?.brand_id,
);

export const getSelectedRoleId = createSelector(
  selectProfile,
  ({show_role}) => show_role?.data?.id || null,
);

export const getHelptexts = ({profile: {helptexts}}) => helptexts;

export const getProfileOrganisations = ({profile: {person: {data: {organisations}}}}) => organisations;

export const getPassedIdsNew = createIdsSelector(
  _getPassedData,
  passed => {
    if (!passed?.length) return emptyArr;

    const ids = [];

    for (const competence of passed) {
      if (competence?.passed === 100) {
        ids.push(competence.competence_id);
      }
    }

    return ids;
  },
);

export const getPassedCompetencesIds = competences => {
  if (!competences.data?.length) {
    return emptyArr;
  }

  const ids = [];

  for (const competence of competences.data) {
    if (competence?.passed === 100) {
      ids.push(competence.competence_id);
    }
  }

  return ids;
};

const getPassedCompetencesCheck = competences => {
  if (!Array.isArray(competences?.data) || !competences?.data?.length) return emptyArr;

  return competences.data.filter(competence => competence.passed === 100);
};

export const getMessageUnreadCount = ({messages: {unread: {cnt}}}) => cnt;

export const getPassedCompetences = createSelector(
  _selectProfileCompetences,
  competences => ({
    passed: getPassedCompetencesCheck(competences),
    keys: getPassedCompetencesIds(competences),
  }),
);

export const getRecommendedCompetences = ({profile: {recommendedCompetences}}) => recommendedCompetences;

export const getCVs = ({profile: {cvs}}) => cvs;
export const getCVsFull = ({profile: {cvs_full}}) => cvs_full;

export const getReport = ({profile: {report}}) => report;

export const getNormalizedProfileEvents = ({profile: {normalizedData: {events} = {}} = {}}) => events;

export const getProfileEventsLoadStatus = ({profile: {normalizedData: {events: {status} = {}} = {}} = {}}) => status;

export const selectProfileOrganisations = createSelector(
  getProfile,
  selectRolesOrganisations,
  (profile, organisations) => {
    const orgsData = organisations?.data;
    const profileOrgs = profile?.data?.organisations;

    if (!Array.isArray(orgsData) || !Array.isArray(profileOrgs) || !profileOrgs?.length || !orgsData?.length) return emptyArr;

    return orgsData.filter(organisation => profileOrgs
      .some(profileOrg => profileOrg.id === organisation.id));
  },
);

export const selectProfileCompetences = createSelector(
  _selectProfileCompetences,
  competences => {
    if (!competences?.data?.length) return {
      ...competences,
      allIds: emptyArr,
      byId: emptyObj,
    };

    const {
      allIds = emptyArr,
      byId = emptyObj,
    } = competences?.data?.reduce?.((acc, item) => {
      acc.byId[item.id] = item;
      acc.allIds.push(item.id);

      return acc;
    }, {
      allIds: [],
      byId: {},
    }) || {};

    return {
      ...competences,
      byId,
      allIds,
    };
  },
);

export const selectPendingItemsMap = createJSONEqualSelector(
  selectProfileSelfSign,
  pendingItems => pendingItems?.length
    ? pendingItems.reduce((acc, pendingItem) => {
      const cid = findCompetenceId(pendingItem);

      if (cid) acc[cid] = pendingItem;

      return acc;
    }, {})
    : emptyObj,
);
