import {RESET_APP, RESET_STORE} from '@actions/global.actions';
import {USER_ERROR, USER_LOADING, USER_SET_PARTIAL, USER_SUCCESS} from '@actions/user.actions';
import {LoadStatuses} from '@types/load.types';
import {emptyArr} from '@utils/constants';

const initialState = {
  status: LoadStatuses.NOT_LOADED,
  data: {
    access_level: null,
    active_org: null,
    admin_orgs: emptyArr,
    email: null,
    fullname: null,
    group_names: emptyArr,
    id: null,
    orgs: emptyArr,
    person_id: null,
    username: null,
  },
  error: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
  case RESET_STORE:
  case RESET_APP: {
    return initialState;
  }
  case USER_LOADING: {
    return {
      ...state,
      status: LoadStatuses.IS_LOADING,
      error: null,
    };
  }
  case USER_SUCCESS: {
    return {
      ...state,
      status: LoadStatuses.LOADED,
      data: action.payload,
      error: null,
    };
  }
  case USER_ERROR: {
    return {
      ...state,
      status: LoadStatuses.FAILED,
      error: action.payload,
    };
  }
  case USER_SET_PARTIAL: {
    const {username, person_id} = action.payload || {};

    const newData = {};

    if (username) newData.username = username;
    if (person_id) newData.person_id = person_id;

    return {
      ...state,
      data: {
        ...state.data,
        ...newData,
      },
      status: [LoadStatuses.IS_LOADING, LoadStatuses.LOADED, LoadStatuses.LOADED_PARTIAL].includes(state.status)
        ? state.status
        : LoadStatuses.LOADED_PARTIAL,
    };
  }
  default: {
    return state;
  }
  }
};
