import dayjs from 'dayjs';
import {daysDifference} from '@utils/time.utils';

export const verifyDeadlineDates = (signOnDeadlineDate, signOffDeadlineDate) => {
  const now = dayjs();

  const enableSignOn = dayjs(signOnDeadlineDate).isAfter(now);
  const enableSignOff = dayjs(signOffDeadlineDate).isAfter(now);

  const daysToSignOn = enableSignOn ? daysDifference(signOnDeadlineDate, now) : 0;
  const daysToSignOff = enableSignOff ? daysDifference(signOffDeadlineDate, now) : 0;

  return {
    enableSignOn,
    enableSignOff,
    daysToSignOn,
    daysToSignOff,
  };
};

export const parseEvent = (event, type) => {
  const {
    id,
    title,
    startdate,
    enddate,
    competence_id,
    duration,
    durations,
    location,
    max_participants,
    participants_count,
    sign_on_deadline,
    sign_off_deadline,
    short_description,
    user_file_upload_allowed,
    user_file_upload_date_limit,

    children_ids,
    children,

    files,
    event_files,
    user_files,
    employee_files,
    // certificates_url,
    // competence_groups,
    // joinable,
    parent_id,
    __isChildEvent,
  } = event;

  if (!event?.startdate) return null;

  const startDate = dayjs(startdate);

  if (startDate.isBefore(dayjs())) return null;

  const {title: locationStr} = location || {};

  const startsToday = startDate.isSame(dayjs());

  const durationUnits = {};

  durations?.forEach?.(({duration, type}) => {
    durationUnits[type] = duration;
  });

  const {
    daysToSignOn,
    daysToSignOff,
    enableSignOn,
    enableSignOff,
  } = verifyDeadlineDates(dayjs(sign_on_deadline), dayjs(sign_off_deadline));

  const childrenIds = children_ids || children?.map?.(({id}) => id);

  return {
    id,
    event_id: id,
    title,
    startdate,
    enddate,
    sign_on_deadline,
    sign_off_deadline,
    isToday: startsToday,
    location: locationStr,
    locationTitle: locationStr,
    participants_count,
    confirmedCount: participants_count,
    maxParticipants: max_participants,
    shortDescription: short_description,

    duration: {
      formatted: duration,
      units: durationUnits,
    },

    deadlines: {
      sign_on_deadline,
      sign_off_deadline,
      daysToSignOn,
      daysToSignOff,
      signOnPassed: !enableSignOn,
      signOffPassed: !enableSignOff,
    },

    files: files?.length ? files : undefined,
    event_files: event_files?.length ? event_files : undefined,
    user_files: user_files?.length ? user_files : undefined,
    employee_files: employee_files?.length ? employee_files : undefined,

    user_file_upload_allowed,
    user_file_upload_date_limit,
    fileUploadEnabled: user_file_upload_allowed,
    fileUploadDeadline: user_file_upload_date_limit,

    // certificateUrl: certificates_url,
    courseId: competence_id,
    competence_id,
    children_ids: childrenIds?.length ? childrenIds : undefined,
    children: children?.length ? children : undefined,
    // courseTypeTitle,
    // joinable,
    __isChildEvent: __isChildEvent || !!parent_id,
    __isParsedEvent: true,
    __isFullView: participants_count != null && max_participants != null,
  };
};
