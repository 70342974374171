import {emptyArr, emptyObj} from '@utils/constants';

export const emptySummary = Object.freeze({
  requirement: emptyArr,
  optional: emptyArr,
  other: emptyArr,
});
export const summaryKeys = Object.keys(emptySummary);
export const emptySummaryMap = Object.freeze(Object.fromEntries(summaryKeys.map(key => [key, emptyObj])));

export const selectedPersonV2InitialState = {
  orgId: null,
  personId: null,
  username: null,
  initialized: false,

  componentState: {
    tab: 'role',

    summary: {
      expanded: emptyObj, // {[`${section}.${roleId}?.${competenceIds[]...}`]}
      loading: emptyObj, // {[`${section}.${roleId}?.${competenceIds[]...}`]}
    },
  },

  apiData: { // pure data from api calls
    person: emptyObj,
    summary: emptySummary,
    competencelevel: null,
    loginCount: null,
    roles: emptyArr,
    events: emptyArr,
    report: emptyObj,
  },

  // normalized data
  competences: emptyObj, // {[competence_id]: {...data, children_ids: []}},
  personCompetences: emptyObj, // {[competence_id]: {...data (phce), }}
  roleCompetenceIds: emptyObj, // {[roleId]: {requirement: [], optional: [], all: []}}
};
