import {isStringWithLength} from './string.utils';

/** Checks if a value is an integer.
 * @param {any} value
 * @returns {number|null} */
const _intOrNull = value => Number.isInteger(value)
  ? value
  : null;

/**
 * Checks if a value is either an integer,
 * or a string that can be parsed to an integer without removing any extra characters.
 * Trailing and leading whitespace is ignored.
 * (e.g. 1, '1', ' 1 ', '1.0' is valid, but '1.1' or '1 1' is not)
 * @param {any} value
 * @param {number|null} defaultValue - value to return if value is not valid, defaults to null if not provided or not an integer
 * @returns {number|null} */
export const validIntOrNull = (value, defaultValue = null) => {
  const _defaultValue = _intOrNull(defaultValue);

  if (typeof value === 'number') {
    if (Number.isInteger(value)) return value;
  } else if (isStringWithLength(value)) {
    const parsed = Number(value);

    if (Number.isInteger(parsed)) return parsed;
  }

  return _defaultValue;
};

export const clamp = (value, min, max) => Math.min(Math.max(value, min), max);
export const randInt = (min = 0, max = 1) => Math.floor(Math.random() * (max - min + 1) + min);
