import {parseEvent} from '@src/hooks/store/course/util';
import {getImageUrl} from '@utils/data.utils';
import {getDurationText, getYearMonthString} from '@utils/time.utils';
import {findCompetenceId, findCompetenceType, getCompetenceCoverUrl} from './competence-identity';

export const insertSingleEvent = (acc, parsedEvent) => {
  const {
    allEventIdsSet,
    eventById,
    eventIdsByCourseId,
    eventIdsByYearMonth,
  } = acc || {};

  const {competence_id: courseId, id: eventId} = parsedEvent || {};

  if (eventId == null || courseId == null) return acc;

  const [year, month] = getYearMonthString(parsedEvent.startdate).split('-');

  // no other events in prev state, we can return early
  if (!allEventIdsSet?.size) {
    return {
      allEventIdsSet: new Set([eventId]),
      eventById: {[eventId]: parsedEvent},
      eventIdsByCourseId: {[courseId]: [eventId]},
      eventIdsByYearMonth: {[year]: {[month]: [eventId]}},
    };
  }

  allEventIdsSet.add(eventId);
  eventById[eventId] = parsedEvent;

  if (!eventIdsByCourseId[courseId]) {
    eventIdsByCourseId[courseId] = [eventId];
  } else if (!eventIdsByCourseId[courseId].includes(eventId)) {
    eventIdsByCourseId[courseId].push(eventId);
  }

  const prevByYearMonth = eventIdsByYearMonth?.[year]?.[month] || [];

  if (prevByYearMonth.includes(eventId)) {
    return acc;
  }

  if (!eventIdsByYearMonth[year]) {
    eventIdsByYearMonth[year] = {[month]: [eventId]};
  } else if (eventIdsByYearMonth[year][month]) {
    eventIdsByYearMonth[year][month].push(eventId);
  } else {
    eventIdsByYearMonth[year][month] = [eventId];
  }

  return acc;
};

export const reduceEventsFullUpdate = (acc, event) => {
  const {
    id: eventId,
    competence_id: courseId,
  } = event || {};

  if (eventId == null || courseId == null) return acc;

  const {allEventIdsSet = new Set()} = acc || {};

  if (!eventId || !courseId || allEventIdsSet.has(eventId)) return acc;

  const parsedEvent = event?.__isParsedEvent
    ? event
    : parseEvent(event);

  if (!parsedEvent) return acc;

  return insertSingleEvent(acc, parsedEvent);
};

export const getCompentenceMetaData = ({competence, passedIdsSet, passedCompetencesMap}) => {
  const {durations} = competence || {};

  const competence_id = findCompetenceId(competence);
  const competence_type = findCompetenceType(competence);

  if (!competence_id || !competence_type || !durations) return null;

  const durationText = getDurationText(durations);
  const metaData = {
    durationText,
    ...competence_type === 'Nano Speed Course' && passedIdsSet?.has(competence_id)
      ? {
        passed: true,
        points: passedCompetencesMap[competence_id]?.point || 0,
      }
      : {},
  };

  return metaData;
};

export const getCompetenceCoverImageSrc = (competence, placeholderUrl) => {
  const coverImageUrl = getCompetenceCoverUrl(competence);

  if (coverImageUrl) return coverImageUrl;

  return competence?.content?.url
    ? getImageUrl(competence.content.url)
    : !!placeholderUrl && getImageUrl(placeholderUrl) || null;
};

export const getCompetenceCoverImage = (competence, defaultImage) => {
  const {files, content, cover} = competence || {};

  if (!files && !content && !cover) return defaultImage;

  return getCompetenceCoverImageSrc({
    files,
    content,
    cover,
  }, defaultImage);
};
