import memoize from 'micro-memoize';
import {emptyArr, emptyObj} from './constants';
import {validIntOrNull} from './number.utils';
import {isStringWithLength} from './string.utils';

export const NOOP = () => {};
export const NullFn = () => null;

export const isObject = obj => !!obj && typeof obj === 'object' && !Array.isArray(obj);
export const isObjectWithKeys = obj => isObject(obj) && Object.keys(obj).length > 0;

export const filterNullishProps = (obj, includeEmptyStrings = false) => {
  if (!isObjectWithKeys(obj)) return null;

  const result = {};

  for (const key of Object.keys(obj)) {
    if (obj[key] == null || obj[key] === '' && !includeEmptyStrings) continue;
    if (obj[key] === '' && result[key]) continue;

    result[key] = obj[key];
  }

  return result;
};

export const addifNotExistsUsingKey = (arr, elem, key) => {
  if (!arr.some(e => e[key] === elem[key])) {
    return [...arr, elem];
  }

  return arr;
};

export const addifNotExists = (arr, elem) => {
  if (!arr.includes(elem)) {
    return [...arr, elem];
  }

  return arr;
};

export const removeDuplicatesUsingKey = (arr, key) => {
  const res = [];

  for (const elem of arr) {
    if (!res.some(r => r[key] === elem[key])) {
      res.push(elem);
    }
  }

  return res;
};

export function getChildrenDataRecurse(r, a) {
  var b = {};

  Object.keys(a).forEach(function (k) {
    if (k !== 'children') {
      b[k] = a[k];
    }
  });

  r.push(b);

  if (Array.isArray(a.children)) {
    b.children = a.children.map(function (a) {
      return a.id;
    });

    return a.children.reduce(getChildrenDataRecurse, r);
  }

  return r;
}

export const reorderList = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

export const filterUndefined = obj => Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== undefined));

export const pickRandom = arr => arr[Math.floor(Math.random() * arr.length)];

/** Takes a string (pathname) and tries to parse route, role_id, competence_id and parent_competence_id from it
 * @example
 * parseIdsFromPath('/role/1/2') // {route: 'role', role_id: 1, competence_id: 2, parent_competence_id: null}
 * @example
 * parseIdsFromPath('/role/1') // {route: 'role', role_id: 1, competence_id: null, parent_competence_id: null}
 * @example
 * parseIdsFromPath('/learning-path/123/456') // {route: 'learning-path', role_id: null, competence_id: 456, parent_competence_id: 123}
 * @param {string} pathname
 * @returns {object} {route: string|null, role_id: number|null, competence_id: number|null, parent_competence_id: number|null}
*/
export const parsePathname = (pathname, search) => {
  if (!isStringWithLength(pathname)) return emptyObj;

  const _pathname = search
    ? `${pathname}${search}`
    : pathname;

  const urlRegex = new RegExp(/\/atlas\/(?<atlas_id>\d+)$/i.source // atlas
    + '|'
    + /\/my-education\/role\/(?<role_id>-?\d+)(\/(?<role_competence_id>\d+))?$/i.source // role
    + '|'
    + /\/learning-path\/(?<track_id>(\d+))(\/(?<track_child_id>(\d+)))?$/i.source // learning-path
    + '|'
    + /\/(learning-path\/(?<track_resources_track_id>(\d+))\/resources)(\/(?<track_resource_id>(\d+))(\?type=(?<track_resource_type>(\w+)))?$)?/i.source // learning-path resources
    + '|'
    + /\/(?<course_catalog>(course-catalog))($|\/(?<course_catalog_course_id>(\d+))$)/i.source); // course-catalog

  const matches = _pathname.match(urlRegex);

  const {
    atlas_id,
    role_id,
    role_competence_id,
    track_id,
    track_child_id,
    track_resources_track_id,
    track_resource_id,
    track_resource_type,
    course_catalog,
    course_catalog_course_id,
  } = matches?.groups ?? {};

  const trackId = validIntOrNull(track_child_id ?? track_id ?? track_resources_track_id ?? atlas_id);

  const routeName
    = (!!trackId || !!track_resources_track_id) && 'learning-path'
    || role_id != null && 'role'
    || !!course_catalog && 'course-catalog';

  if (!routeName) return emptyObj;

  const parentTrackId = validIntOrNull(track_child_id ? track_id : null);
  const cmsId = track_resource_type === 'cms' ? validIntOrNull(track_resource_id) : null;
  const competenceId = validIntOrNull(role_competence_id ?? (cmsId ? null : track_resource_id));

  return {
    route: routeName,
    role_id: validIntOrNull(role_id),
    competence_id: competenceId,

    track_id: trackId,
    parent_track_id: parentTrackId,

    is_track_resources_route: !!track_resources_track_id,
    track_resource_id: track_resource_id ? competenceId : null,
    track_cms_id: cmsId,

    is_course_catalog_route: !!course_catalog,
    course_catalog_id: validIntOrNull(course_catalog_course_id),
  };
};

export const objectKeysOrEmptyArray = (obj = {}) => isObjectWithKeys(obj)
  ? Object.keys(obj)
  : [];

export const objectValuesOrEmptyArray = (obj = {}) => isObjectWithKeys(obj)
  ? Object.values(obj)
  : [];

export const mergeCompetencesNonNullish = (...objects) => objects
  .reduce((acc, obj) => {
    if (!isObjectWithKeys(obj)) return acc;

    Object.keys(obj).forEach(key => {
      if (!obj[key]) return;

      if (!acc[key]) {
        acc[key] = obj[key];
      } else if (Array.isArray(obj[key])) {
        acc[key] = [...acc[key] || [], ...obj[key] || []].filter((item, index, arr) => {
          if (isObjectWithKeys(item)) {
            const foundIndex = arr.findIndex(i => {
              if (i.competence_id && item.competence_id) {
                return i.competence_id === item.competence_id;
              }

              return i.id === item.id;
            });

            return foundIndex === index;
          }

          return arr.indexOf(item) === index;
        });
      } else if (isObjectWithKeys(obj[key])) {
        acc[key] = mergeCompetencesNonNullish(acc[key], obj[key]);
      } else if (typeof obj[key] === 'string' || typeof obj[key] === 'number') {
        acc[key] = obj[key];
      }
    });

    return acc;
  }, {});

export const mapDataToArray = data => {
  if (!data) return emptyArr;

  if (Array.isArray(data)) return data?.length
    ? data
    : emptyArr;

  /** if data is non-empty object:
  * if number keys, we can assume that the keys are id's and the values are objects
  * if not number keys, we can assume that it is only one object */
  if (isObjectWithKeys(data)) return validIntOrNull(Object.keys(data)[0])
    ? Object.values(data)
    : [data];

  return emptyArr;
};

export const findObjectByKeyAndValue = (obj, key, value, currentDotPath = '') => {
  if (!obj || typeof obj !== 'object') return null;

  for (const currentKey of Object.keys(obj)) {
    const currentValue = obj[currentKey];

    const dotPath = currentDotPath
      ? `${currentDotPath}.${currentKey}`
      : currentKey;

    if (currentKey === key && currentValue === value) {
      return {
        obj,
        key,
        value,
        path: dotPath.split('.').slice(0, -1),
      };
    }

    if (typeof currentValue === 'object') {
      const result = findObjectByKeyAndValue(currentValue, key, value, dotPath);

      if (result) return result;
    }
  }

  return null;
};

export const findObjectByKeyAndValueAll = (obj = null, key = '', value = '', currentDotPath = '', currentRes = []) => {
  if (!obj || typeof obj !== 'object') return null;

  for (const currentKey of Object.keys(obj)) {
    const currentValue = obj[currentKey];

    const dotPath = currentDotPath
      ? `${currentDotPath}.${currentKey}`
      : currentKey;

    if (currentKey === key && currentValue === value) {
      currentRes.push({
        obj,
        key,
        value,
        path: dotPath.split('.').slice(0, -1),
      });
    }

    if (typeof currentValue === 'object') {
      findObjectByKeyAndValueAll(currentValue, key, value, dotPath, currentRes);
    }
  }

  return currentRes;
};

export const findObjectByKey = (obj, key, currentDotPath = '') => {
  if (!obj || typeof obj !== 'object') return null;

  for (const currentKey of Object.keys(obj)) {
    const currentValue = obj[currentKey];

    const dotPath = currentDotPath
      ? `${currentDotPath}.${currentKey}`
      : currentKey;

    if (currentKey === key) {
      return {
        obj: currentValue,
        key,
        path: dotPath.split('.'),
      };
    }

    if (typeof currentValue === 'object') {
      const result = findObjectByKey(currentValue, key, dotPath);

      if (result) return result;
    }
  }

  return null;
};

export function getByPath(obj, path, defaultValue) {
  if (!obj || typeof obj !== 'object' || path == null) return defaultValue ?? null;
  if (obj?.hasOwnProperty?.(path) || typeof path === 'number') return obj?.[path] ?? defaultValue;

  if (!path?.length) return defaultValue ?? null;

  const parts = path?.split?.('.') || path;

  let current = obj;

  for (let i = 0; i < parts?.length; i++) {
    if (!current?.hasOwnProperty?.(parts[i])) return defaultValue;
    current = current[parts[i]];
  }

  return current;
}

export const memoedGetByPath = memoize(getByPath);

export const getAllParentIds = (cid, competencesMap) => {
  if (cid == null || !competencesMap) return [];

  const parentIds = new Set();

  const getParentIdsRecurse = competenceId => {
    const competence = competencesMap?.[competenceId] || {};

    if (!competence) return;

    competence?.parent_ids?.forEach?.(id => {
      parentIds.add(id);

      getParentIdsRecurse(id);
    });
  };

  getParentIdsRecurse(cid);

  return [...parentIds.values()];
};

/** Use this instead of `Array.prototype.at` or `String.prototype.at` for better browser support (older Safari).
 * Babel doesn't transpile `Array.prototype.at` and `String.prototype.at` correctly for some reason. */
export const atIndex = (strOrArr, index) => {
  if (!strOrArr?.length) return undefined;

  const idx = index < 0
    ? strOrArr.length + index
    : index;

  if (idx < 0 || idx >= strOrArr.length) return undefined;

  return strOrArr[idx];
};

/* Gets the last element of array or last character of string */
export const last = strOrArr => atIndex(strOrArr, -1);
