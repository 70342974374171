import dayjs from 'dayjs';
import {canHaveChildrenCompetences, isChecklistItemCompetence} from '@src/store/util/competence-identity';
import {validIntOrNull} from './number.utils';
import {filterFutureDate} from './time.utils';

export const getRoleId = roleOrId =>
  validIntOrNull(roleOrId) ?? validIntOrNull(roleOrId?.roleId ?? roleOrId?.role_id ?? roleOrId?.id);

export const getRoleTitle = role =>
  role?.display_name || role?.title || role?.name || role?.role_name || role?.role_title;

export const getRoleType = role =>
  role?.type ?? role?.role_type ?? (role?.is_position ? 'crew' : 'role');

export const getIsFutureRole = role =>
  role?.future ?? (!!role?.startdate && dayjs(role.startdate).isAfter(dayjs()));

export const getRoleEndDate = role => filterFutureDate(role?.enddate);

export const sortRoleByType = (a, b) => {
  if (!a || !b) return 0;

  let modifier = 0;

  modifier += b.is_position || b.type === 'crew' ? 10 : 0;
  modifier -= a.is_position || a.type === 'crew' ? 10 : 0;

  modifier -= (b?.id ?? a?.role_id ?? 0) < 0 ? 15 : 0;
  modifier += (a?.id ?? b?.role_id ?? 0) < 0 ? 15 : 0;

  modifier -= getIsFutureRole(b) ? 5 : 0;
  modifier += getIsFutureRole(a) ? 5 : 0;

  return (a.title || '').localeCompare(b.title || '') + modifier;
};

export const getCanEditEmployeeRoleCompetence = competence => {
  if (isChecklistItemCompetence(competence)) {
    return !!competence?.passed;
  }

  return !canHaveChildrenCompetences(competence);
};

export const roleIsPosition = role => {
  if (role?.is_position !== undefined) {
    return role.is_position;
  }

  const metatype = role?.role_meta_type_name ?? role?.rolemetatype;

  return metatype === 'position';
};
