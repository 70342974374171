/* eslint-disable unicorn/prefer-spread */
import {responsiveStoreEnhancer} from 'redux-responsive';
import createSagaMiddleware from 'redux-saga';
import {configureStore} from '@reduxjs/toolkit';
import {injectModuleFactory, injectReducerFactory, injectSagaFactory} from './lazy/core';
import {createRootReducer} from './root-reducer';
import {createRootSaga} from './root-saga';

const sagaMiddleware = createSagaMiddleware({
  onError: error => {
    console.error(error);
    // store.dispatch({
    //   type: 'ERROR',
    //   payload: error,
    // });
  },
});

export const createStore = initialState => {
  const store = configureStore({
    reducer: createRootReducer(),
    preloadedState: initialState,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sagaMiddleware),
    enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(responsiveStoreEnhancer),
  });

  // Extensions
  store.runSaga = sagaMiddleware.run;

  store.injectSaga = injectSagaFactory(store, true);
  store.injectReducer = injectReducerFactory(store, true);
  store.injectModule = injectModuleFactory(store);

  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry
  store.injectingCurrent = {}; // Module registry (boolean)

  sagaMiddleware.run(createRootSaga({
    injectedSagas: store.injectedSagas,
    injectingCurrent: store.injectingCurrent,
  }));

  return store;
};

export const store = createStore();
