import * as React from 'react';
import {emptyArr} from '@utils/constants';

/** percentage = 100: Height from bottom of main menu to bottom of window */
export const containerVh = (percentage = 100) => `calc(var(--container-vh, 1vh) * ${percentage})`;
/** percentage = 100: Height of window */
export const windowVh = (percentage = 100) => `calc(var(--window-vh, 1vh) * ${percentage})`;

/**
 * Creates global css variables that can be used directly or by utility functions
  ** height: calc(var(--container-vh, 1vh) * 100);
  ** height: calc(var(--window-vh, 1vh) * 100);
  ** height: ${containerVh(100)};
  ** height: ${windowVh(100)};
*/
export const useAppHeight = () => {
  React.useEffect(() => {
    const initViewHeight = () => {
      const setVH = () => {
        const windowVh = window.innerHeight * 0.01;
        const menuEl = document.querySelector('.app-wrapper__menu');

        if (menuEl) {
          const containerVh = (window.innerHeight - menuEl.clientHeight) * 0.01 - .05;

          document.documentElement.style.setProperty('--container-vh', `${containerVh}px`);
        }

        document.documentElement.style.setProperty('--window-vh', `${windowVh}px`);
      };

      window.addEventListener('resize', setVH);
      setVH();
    };

    initViewHeight();

    return () => {
      window.removeEventListener('resize', initViewHeight);
    };
  }, []);

  return null;
};

export const useOnClickOutside = (refs, callback) => {
  const listener = React.useCallback(event => {
    const forAny = fn => Array.isArray(refs)
      ? refs.reduce((bool, ref) => !!(bool || ref?.current && fn?.(ref?.current)), false)
      : !!refs?.current && fn?.(refs.current);

    if (!forAny(current => current?.contains?.(event?.target))) {
      callback?.(event);
    }
  }, [callback, refs]);

  React.useEffect(() => {
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [listener]);
};

const getWindowDimensions = ({useOuterHeight, useOuterWidth, useMaxInnerOuterHeight}) => {
  let height = useOuterHeight ? window.outerHeight : window.innerHeight;

  if (useMaxInnerOuterHeight) {
    height = Math.max(window.outerHeight, window.innerHeight);
  }
  const width = useOuterWidth ? window.outerWidth : window.innerWidth;

  return {
    width,
    height,
  };
};

// Usage: const { width, height } = useWindowDimensions();
export const useWindowDimensions = ({useOuterHeight, useOuterWidth, useMaxInnerOuterHeight}) => {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions({
    useOuterHeight,
    useOuterWidth,
    useMaxInnerOuterHeight,
  }));

  React.useLayoutEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions({
        useOuterHeight,
        useOuterWidth,
        useMaxInnerOuterHeight,
      }));
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return windowDimensions;
};

export const useScrollToTop = (containerSelector = '.app-wrapper__content__main', deps = emptyArr) => {
  React.useEffect(() => {
    let container = document.querySelector(containerSelector);

    if (!container) return undefined;

    container.scrollTop = 0;

    return () => {
      container = null;
    };
  }, [containerSelector, deps]);
};
