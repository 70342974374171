import memoize from 'micro-memoize';
import {createSelector} from 'reselect';
import {
  getCourseCatalogComponentState,
  getCourseCatalogLayoutConfig,
} from '@selectors/components.selectors';
import {
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
} from '@selectors/config.selectors';
import {
  getNormalizedCompetencegroups,
  getNormalizedSelectedCompetenceGroups,
  getNormalizedSelectedCompetences,
  getSelectedCompetenceTypes,
  selectCompetenceGroupsBaseGroupId,
} from '@selectors/courses.selectors';
import {getIsMobile} from '@selectors/global.selectors';
import {i18n} from '@src/i18n';
import * as T from '@types/load.types';
import {emptyArr, emptySet} from '@utils/constants';
import {isFailed, isLoadedOrLoading} from '@utils/loadstatus.utils';
import {isObjectWithKeys} from '@utils/misc.utils';

const emptyActiveGroupIds = Object.freeze({
  ids: emptyArr,
  idsSet: emptySet,
});

export const courseCatalogSelectActiveView = ({components: {courseCatalog: {state: {activeView}}}}) => activeView;
export const courseCatalogSelectActiveCompetenceGroupId = ({components: {courseCatalog: {state: {selectedTopLevelCompetencegroup}}}}) => selectedTopLevelCompetencegroup;

export const courseCatalogSelectCompetencegroupsData = createSelector(
  getNormalizedCompetencegroups,
  competencegroups => {
    const {ids, data} = competencegroups || {};

    if (!ids?.length || !isObjectWithKeys(data)) return emptyArr;

    return ids.map(id => data[id]).filter(Boolean);
  },
);

export const courseCatalogSelectIsSimpleMobileView = createSelector(
  getCourseCatalogLayoutConfig,
  getIsMobile,
  (layoutConfig, isMobile) => {
    const {config: {options: catalogOptions = {}} = {}} = layoutConfig || {};
    const {simpleMobileView} = catalogOptions || {};

    return simpleMobileView && isMobile;
  },
);

export const courseCatalogSelectActiveGroupIds = createSelector(
  getNormalizedSelectedCompetenceGroups,
  selectedGroups => {
    if (!selectedGroups?.ids?.length) return emptyActiveGroupIds;

    return {
      ids: selectedGroups.ids,
      idsSet: new Set(selectedGroups.ids),
    };
  },
);

export const courseCatalogSelectActiveParentGroupId = createSelector(
  courseCatalogSelectActiveGroupIds,
  ({ids}) => ids?.[0],
);

export const courseCatalogSelectLoadStatus = createSelector(
  getNormalizedSelectedCompetences,
  getNormalizedCompetencegroups,
  getNormalizedSelectedCompetenceGroups,
  getSelectedCompetenceTypes,
  courseCatalogSelectCompetencegroupsData,
  (selectedCompetences, competencegroups, selectedCompetencegroups, competenceTypes, competencegroupsData) => {
    const {isFetching: competencesIsFetching} = selectedCompetences || {};
    const {status: competenceGroupsLoadStatus} = competencegroups || {};

    const selectedCompetencesLoadStatus = selectedCompetencegroups?.status;
    const selectedCompetencesIsFetching = selectedCompetencesLoadStatus === T.LoadStatuses.IS_LOADING;
    const selectedCompetencesIsLoaded = selectedCompetencesLoadStatus === T.LoadStatuses.LOADED;

    const competencegroupsIsFetching = competenceGroupsLoadStatus === T.LoadStatuses.IS_LOADING;
    const competencegroupsIsLoaded = competenceGroupsLoadStatus === T.LoadStatuses.LOADED;
    const competencegroupsInitialized = competenceGroupsLoadStatus !== T.LoadStatuses.NOT_LOADED;

    const isReady = !!competencegroupsData && competencegroupsIsLoaded;

    return {
      competencesIsFetching: selectedCompetencesIsFetching,
      selectedCompetencesIsFetching,
      selectedCompetencesIsLoaded,
      competencesIsLoaded: selectedCompetencesIsLoaded,
      competencegroupsIsFetching,
      competencegroupsIsLoaded,
      competencegroupsInitialized,
      isReady,
      isInitialized: competencegroupsInitialized,
      isLoaded: competencegroupsIsLoaded,
      isFetching: competencesIsFetching,
    };
  },
);

export const courseCatalogSelectDefaultBaseGroupId = createSelector(
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
  (configObject, configForCurrLangAndTrack) => {
    const configStartAtGroupId = configForCurrLangAndTrack?.courseCatalog?.startAtGroupId;

    return configObject?.isMapActivated
      ? !!configStartAtGroupId && configStartAtGroupId
      : configObject?.getProperty?.('routes.course-catalog.startAtGroupId') || configStartAtGroupId || null;
  },
);

export const courseCatalogCreateBaseGroupLoadStatusSelector = memoize(baseGroupId => createSelector(
  selectCompetenceGroupsBaseGroupId,
  courseCatalogSelectDefaultBaseGroupId,
  getNormalizedCompetencegroups,
  (currentBaseGroupId, defaultBaseGroupId, {status = T.LoadStatuses.NOT_LOADED}) => {
    const checkGroupId = baseGroupId || defaultBaseGroupId || null;

    if (checkGroupId == null || currentBaseGroupId == null) return status;
    if ((currentBaseGroupId || null) !== checkGroupId && isLoadedOrLoading(status) || isFailed(status)) return T.LoadStatuses.NOT_LOADED;

    return status;
  },
));

export const courseCatalogSelectIsReady = createSelector(
  courseCatalogSelectLoadStatus,
  ({isReady}) => isReady,
);

export const courseCatalogSelectActiveCompetenceGroupTitle = createSelector(
  courseCatalogSelectCompetencegroupsData,
  getCourseCatalogComponentState,
  courseCatalogSelectActiveView,
  courseCatalogSelectActiveCompetenceGroupId,
  courseCatalogSelectIsReady,
  (competencegroupsData, componentState, activeView, selectedTopLevelCompetencegroup, isReady) => {
    if (!isReady) return undefined;

    if (activeView === 'upcoming-events') return i18n('course-catalog.course-section-classroom');

    if (selectedTopLevelCompetencegroup != null) return competencegroupsData
      ?.find?.(c => c?.id === selectedTopLevelCompetencegroup)?.title || undefined;

    return undefined;
  },
);
