import * as React from 'react';
import {useClearCacheCtx} from 'react-clear-cache';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {authLogoutRequest} from '@actions/auth.actions';
import CourseLoader from '@components/loading/courseLoader';
import {LoadingLine} from '@components/loading/loading-line';
import {ENABLE_CYPRESS_HISTORY, SENTRY_ERROR_REPORT_DIALOG} from '@config';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {selectAppLoading} from '@selectors/global.selectors';
import {selectProfileFullname} from '@selectors/profile.selectors';
import * as Sentry from '@sentry/react';
import {i18n} from '@src/i18n';
import {IdentityWarning} from '@styles/mixins';
import {loadableRetry} from '@utils/loadable-retry';

export const LoginContainer = loadableRetry(() => {
  console.log('load login.container');

  return import(/* webpackChunkName: "login.container" */ './routes/auth/containers/login-container');
}, {
  name: 'login.container',
  fallback: <LoadingLine />,
});

export const HelpContainer = loadableRetry(() => {
  console.log('load help.container');

  return import(/* webpackChunkName: "help.container" */ './routes/help/containers/help-container/help-container');
}, {name: 'help.container'});

export const MyEducationSwitch = loadableRetry(() => {
  console.log('load myEducation.switch');

  return import(/* webpackChunkName: "my.education" */ './routes/my-education/my-education-switch');
}, {
  name: 'my.education',
  fallback: <LoadingLine />,
});

export const Atlas = loadableRetry(() => {
  console.log('load Atlas');

  return import(/* webpackChunkName: "atlas" */ './routes/atlas/index');
}, {
  name: 'atlas',
  fallback: <LoadingLine />,
});

export const AppLoading = React.memo(() => {
  const loading = useSelector(selectAppLoading);

  if (!loading) return null;

  return <CourseLoader />;
});

// makes history from react-router visible from outside
// this can example be used to change url without reloading the page
const CypressHistorySupport = () => {
  const history = useHistory();

  React.useEffect(() => {
    window.cyHistory = history;
  }, [history]);

  return null;
};

const enableCyHistory = process.env.NODE_ENV !== 'production' && ENABLE_CYPRESS_HISTORY;

export const CyHistory = enableCyHistory
  ? <CypressHistorySupport />
  : null;

export const CvContainer = loadableRetry(() => {
  console.log('load cv.container');

  return import(/* webpackChunkName: "cv.container" */ './routes/my-education/containers/my-education-cv/cv-container');
}, {name: 'cv.container'});

export const DebugPanel = loadableRetry(() => {
  console.log('load debug.panel');

  return import(/* webpackChunkName: "debug.panel" */ './features/debug');
}, {name: 'debug.panel'});

export const MessageCheck = loadableRetry(() => {
  console.log('load message.check');

  return import(/* webpackChunkName: "message.check" */ './common/components/message-check/message-check');
}, {name: 'message.check'});

export const NanoLearningPlayerContainer = loadableRetry(() => {
  console.log('load nano.player');

  return import(/* webpackChunkName: "nano.player" */ './routes/nano-learning/containers/nano-learning-player-container');
}, {name: 'nano.player'});

export const CoursePlayer = loadableRetry(() => {
  console.log('load course.player');

  return import(/* webpackChunkName: "course.player" */ './routes/course-catalog/components/course-player/course-player');
}, {name: 'course.player'});

export const CacheBuster = React.memo(({children}) => {
  const {isLatestVersion, emptyCacheStorage} = useClearCacheCtx();

  React.useEffect(() => {
    if (!isLatestVersion) {
      localStorage.removeItem('learningportalConfig');
      emptyCacheStorage();
      window.location.reload(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLatestVersion]);

  return isLatestVersion && children
    ? children
    : null;
});

export const BartPanel = () => {
  const dispatch = useDispatch();
  const fullname = useSelector(selectProfileFullname);

  const logout = () => {
    dispatch(authLogoutRequest());
  };

  return (
    <IdentityWarning>
      <span>
        {i18n('person.you-have-now')}
        <strong css={css({marginLeft: '.4em'})}>{fullname}</strong>
      </span>
      <span>
        <button
          type="button"
          onClick={logout}
          css={css`
            background: #2c2c2c;
            padding: .5rem 1rem;
            color: white;
            margin-left: 1rem;
            display: flex;
            flex-wrap: nowrap;
            min-width: max-content;
            margin-right: 1rem;
            cursor: pointer;

            &:hover {
              background: black;
            }
          `}
        >
          Logg ut
        </button>
      </span>
    </IdentityWarning>
  );
};

const ErrorFallbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    button {
      margin-bottom: 1rem;
      color: #fff;
      background: #2d2d2d;
      border: none;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      cursor: pointer;
      transition: background 0.2s ease-in-out;

      &:hover {
        background: #3d3d3d;
      }
    }

    button:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ErrorFallback = () => (
  <ErrorFallbackWrapper>
    <h1>En ukjent feil har oppstått</h1>
    <p>Vennligst prøv igjen senere</p>

    <div className="buttons">
      {SENTRY_ERROR_REPORT_DIALOG && (
        <button onClick={() => Sentry.showReportDialog()}>
          Rapporter feil
        </button>
      )}

      <button onClick={() => window.location.reload()}>
        Last siden på nytt
      </button>

      {/* <button onClick={() => window.history.back()}>
        Gå tilbake
      </button> */}
    </div>
  </ErrorFallbackWrapper>
);
