/* eslint-disable unicorn/prefer-spread */
import {useEffect, useRef} from 'react';
import {useSelector, useStore} from 'react-redux';
import {selectReduxModuleLoadStatusById} from '@actions/app.actions';
import {LoadStatuses} from '@types/load.types';

export const useIsModuleLoaded = moduleId => {
  const loadStatus = useSelector(selectReduxModuleLoadStatusById(moduleId));

  return loadStatus === LoadStatuses.LOADED;
};

export const useInjectModule = (moduleId, disabled) => {
  const {injectModule} = useStore();

  const moduleLoadStatus = useSelector(selectReduxModuleLoadStatusById(moduleId));
  const isLoaded = moduleLoadStatus === LoadStatuses.LOADED;
  const isInjecting = useRef(moduleLoadStatus === LoadStatuses.IS_LOADING);
  const shouldInject = !disabled && moduleLoadStatus === LoadStatuses.NOT_LOADED && !isInjecting.current;

  useEffect(() => {
    if (!shouldInject) return;

    isInjecting.current = true;
    injectModule(moduleId, () => {
      isInjecting.current = false;
    });
  }, [injectModule, moduleId, shouldInject]);

  return isLoaded;
};
