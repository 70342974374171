import {darken} from 'polished';
import {baseTheme} from './base';

const primary = '#0A5CD5';
const surface = '#CFE1FC';

const courseCatalog = {
  articleCards: {subTitleColor: '#456ca5'},
  coursegroups: {
    buttons: {
      active: {
        color: '#fff',
        backgroundColor: primary,
      },
      inactive: {
        color: '#0c0d13',
        backgroundColor: '#c7d7f0',
      },
      hover: {
        color: '#191927',
        backgroundColor: darken(.025, '#c7d7f0'),
      },
    },
  },
  toggleSwitch: {
    active: {
      color: '#324b74',
      backgroundColor: '#ededed',
    },
    inactive: {
      color: '#667085',
      backgroundColor: 'transparent',
    },
    hover: {
      color: '#667085',
      backgroundColor: 'transparent',
    },
  },
};

const blue = {
  chartPrimaryColor: primary,
  chartSecondaryColor: '#EDEDED',
  borderHoverColor: primary,
  chartBadge: primary,
  progress: {
    ...baseTheme.progress,
    'default': {
      ...baseTheme.progress.default,
      backgroundColor: primary,
    },
  },
  scheme: {
    ...baseTheme.scheme,
    primary: {
      ...baseTheme.scheme.primary,
      color25: '#F5FAFF',
      color50: '#EFF8FF',
      color100: '#D1E9FF',
      color200: '#B2DDFF',
      color300: '#84CAFF',
      color400: '#53B1FD',
      color500: '#2E90FA',
      color600: '#1570EF',
      color700: '#175CD3',
      color800: '#1849A9',
      color900: '#194185',
    },
  },
  buttons: {
    ...baseTheme.buttons,
    primaryTheme: {
      ...baseTheme.buttons.primaryTheme,
      backgroundColor: primary,
      backgroundColorHover: darken(0.1, primary),
    },
    oversize: {
      ...baseTheme.buttons.oversize,
      backgroundColor: primary,
    },
    defaultTheme: {
      ...baseTheme.buttons.defaultTheme,
      border: '1px solid ' + primary,
      color: primary,
      colorHover: darken(0.1, primary),
      borderHover: '1px solid ' + darken(0.1, primary),
    },
    toggle: {
      ...baseTheme.buttons.toggle,
      color: '#000',
      backgroundColorSelected: surface,
      colorSelected: primary,
      borderColorSelected: primary,
    },
  },
  colors: {
    ...baseTheme.colors,
    primary,
    surface,
    dropdown: baseTheme.colors.dropdown,
    header: '#D1EB2E',
    menu: {header: primary},
    reports: {
      ...baseTheme.colors.reports,
      selectReport: {
        ...baseTheme.colors.reports.selectReport,
        colorSelected: primary,
        color: '#000',
      },
    },
  },
  tiles: {
    color1: primary,
    color2: primary,
  },
  courseCatalog,
};

export default blue; // testTheme;//
