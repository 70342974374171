import {appInjectReduxModule} from '@actions/app.actions';
import {LoadStatuses} from '@types/load.types';
import {createRootReducer} from '../root-reducer';
import {getReduxModule} from './modules';

export function injectModuleFactory(store) {
  return async function injectModule(key, callback) {
    if (!key || store.injectingCurrent[key]) {
      return;
    }
    const {app: {reduxModules = {}} = {}} = store.getState() || {};
    const currentStatus = reduxModules[key];

    if (!currentStatus || currentStatus !== LoadStatuses.NOT_LOADED) return;

    store.injectingCurrent[key] = true;
    store.dispatch(appInjectReduxModule.request(key));
    const _module = getReduxModule(key);

    if (!_module) return;

    const [reducer, saga] = await Promise.all(_module);
    const awaitInject = [];

    if (saga) {
      awaitInject.push(store.injectSaga(key, {
        saga,
        // mode: 'DAEMON',
      }));
    }

    if (reducer) {
      awaitInject.push(store.injectReducer(key, reducer));
    }

    Promise.all(awaitInject).then(() => {
      store.dispatch(appInjectReduxModule.success(key));
      if (callback) callback();
    })
      .catch(error => {
        store.injectingCurrent[key] = false;
      })
      .finally(() => {
        store.injectingCurrent[key] = false;
      });
  };
};

export function injectSagaFactory(store, isValid = true) {
  return async function injectSaga(key, descriptor = {}, args = {}) {
    const newDescriptor = {
      ...descriptor,
      mode: descriptor.mode,
    };

    const {saga} = newDescriptor;

    const hasSaga = Reflect.has(store.injectedSagas, key);

    if (!hasSaga) {
      const task = await store.runSaga(saga, args);

      store.injectedSagas[key] = {
        ...newDescriptor,
        task,
      };
    }
  };
}

export function injectReducerFactory(store, isValid) {
  return async function injectReducer(key, reducer) {
    if (
      Reflect.has(store.injectedReducers, key)
      && store.injectedReducers[key] === reducer
    )
      return;

    store.injectedReducers[key] = reducer;

    try {
      await store.replaceReducer(createRootReducer(store.injectedReducers));
    } catch (error) {
      console.error('injectReducer error', {
        key,
        error,
      });
    }
  };
}
