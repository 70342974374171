import {createSelector} from 'reselect';
import {getConfigObject} from '@selectors/config.selectors';
import {emptyArr, emptyObj} from '../../common/utils/constants';

export const getSelectedMapCourse = state => state.map.selectedMapCourse.data;

export const getMapCourses = state => state.map.mapCourses;

export const selectMap = ({map}) => map || emptyObj;

export const reselectMapTracks = createSelector(
  getMapCourses,
  mapCourses => mapCourses?.data?.tracks || emptyArr,
);

export const reselectMapFirstTrack = createSelector(
  reselectMapTracks,
  mapTracks => mapTracks?.[0] || emptyObj,
);

export const reselectDotts = createSelector(
  reselectMapFirstTrack,
  mapFirstTrack => mapFirstTrack?.dotts || emptyArr,
);

// given a course-title like this This is a title :param1: :param2:
// return a list of the params like this ["param1","param2"]
export const getCourseTitleParams = courseTitle => {
  const eatFirstAndLastChar = str => str.slice(1, -1);

  if (courseTitle === undefined) {
    return emptyArr;
  }
  const params = courseTitle.match(/:[^:]+?:/g);

  if (params) {
    return params.map(p => eatFirstAndLastChar(p).toLowerCase());
  }

  return emptyArr;
};

// get all courses in map, with outro and verification
export const getAllMapDotts = createSelector(
  reselectDotts,
  dotts => {
    if (!dotts?.length) return emptyArr;

    // if the course title contains any of the marker parameters verification or outro, we dont want to show them in the map
    return dotts;
  },
);

// get courses in map, with outro and verification filtered out if exists
export const getMapDotts = createSelector(
  reselectDotts,
  dotts => {
    if (!dotts?.length) return emptyArr;

    // if the course title contains any of the marker parameters verification or outro, we dont want to show them in the map
    return dotts.filter(d => !['verification', 'outro'].some(hideCourseWithParam => getCourseTitleParams(d.title).includes(hideCourseWithParam)));
  },
);

export const getMapOutro = createSelector(
  getAllMapDotts,
  dotts => {
    if (!dotts?.length) {
      return null;
    }

    return dotts.find(t => getCourseTitleParams(t.title).includes('outro'));
  },
);

export const getMapVerification = createSelector(
  getAllMapDotts,
  dotts => {
    if (!dotts?.length) {
      return null;
    }

    return dotts.find(t => getCourseTitleParams(t.title).includes('verification'));
  },
);

export const getIsAllMapDotsCompleted = createSelector(
  getMapDotts,
  dotsShownInMap => {
    if (!dotsShownInMap?.length) {
      return null;
    }

    return dotsShownInMap.every(dott => dott.status === 'DONE');
  },
);

export const getIsMapVerified = state => state.map.mapCourses.mapIsVerified;

export const getIsFirstMapDotCompleted = createSelector(
  getMapDotts,
  dotts => dotts?.[0]?.status === 'DONE',
);
export const getLayout = state => state.map?.track?.data?.layout === 'track' ? 'hide-menu' : '';

export const getOutroIsCompleted = state => state.map.mapCourses.outroIsDone;

// return value:
// true if the entire map is completed(including possible outro and/or verification)
// false if the map is not completed
// null if we dont know yet(we are still waiting for the answer)
export const getIsMapCompleted = createSelector(
  getConfigObject,
  getMapVerification,
  getMapOutro,
  getIsAllMapDotsCompleted,
  getOutroIsCompleted,
  getIsMapVerified,
  (configObject, mapVerification, mapOutro, allDotsInMapCompleted, outroIsDone, verifyIsDone) => {
    const useAtlas = configObject.isMapActivated;

    if ([undefined, false].includes(useAtlas)) {
      return true;
    }

    const waiting = mapVerification === null || mapOutro === null;

    if (waiting) {
      return null;
    }

    // uncomment the following to disable the map
    // return true;

    if (!mapVerification && !mapOutro) {
      return allDotsInMapCompleted === null ? null : allDotsInMapCompleted;
    } else if (mapVerification && mapOutro) {
      return outroIsDone === null ? null : outroIsDone;
    } else if (mapVerification) {
      return verifyIsDone === null ? null : verifyIsDone;
    } else if (mapOutro) {
      return outroIsDone === null ? null : outroIsDone;
    } else {
      return null;
    }
  },
);

const selectMapTrackData = ({map: {track: {data} = {}} = {}} = {}) => data || emptyObj;

export const selectMapLayout = createSelector(
  selectMapTrackData,
  trackData => trackData?.layout || '',
);

export const getLayoutClassname = createSelector(
  selectMapLayout,
  layout => layout === 'track' ? 'hide-menu' : '',
);

export const a = 123;

export const getInitializeMyCoursesView = state => state.map.initializeMyCoursesView;

export const getMapVerificationCompetence = state => state.map.mapVerificationCompetence;
