export const MODALS_PUT_MODAL = 'modals/putModal';
export const MODALS_UPDATE_MODAL_STATE = 'modals/setModalState';

export const modalsPutModal = (...payload) => ({
  type: MODALS_PUT_MODAL,
  payload, // either: { modalId, payload: {} } or modalId, payload: {}
});

export const modalsCloseModal = modalId => ({
  type: MODALS_PUT_MODAL,
  payload: {
    modalId,
    isOpen: false,
  },
});

export const modalsUpdateModalState = (modalId, payload) => ({
  type: MODALS_UPDATE_MODAL_STATE,
  payload: {
    modalId,
    payload,
  },
});
