import {format} from 'date-fns/format';
import {isValid} from 'date-fns/isValid';
import {enUS, nb} from 'date-fns/locale';
import {parse} from 'date-fns/parse';
import {getCurrLang, i18n} from '@src/i18n';

export const langToLocaleMap = {
  no: nb,
  en: enUS,
};

export const dateFormatPlaceholdderByLang = {
  no: 'dd.mm.åååå',
  en: 'dd.mm.yyyy',
};

/* The validation logic resides in the component, but if we got passed false,
we know that the date is invalid
*/
export const validateFormDatePicker = date => {
  if (date === false) {
    return i18n('globals.invalid-date');
  }

  return undefined;
};

export const validateDateObj = dateObj => isValid(dateObj) && dateObj.getFullYear() > 1000;

export const dateObjToSubmitFormat = dateObj => format(dateObj, 'dd.MM.yyyy');
export const submitFormatToDateObj = submitFormat => parse(submitFormat, 'dd.MM.yyyy', new Date());

export const dateStrToDateObj = (dateStr, lang) => {
  const format = {
    no: 'dd.MM.yyyy',
    en: 'dd.MM.yyyy',
  }[lang];

  return parse(dateStr, format, new Date());
};

export const dateObjToDateStr = (dateObj, lang) => {
  const formatForLang = {
    no: 'dd.MM.yyyy',
    en: 'dd.MM.yyyy',
  }[lang];

  return format(dateObj, formatForLang);
};

export const getLocale = () => langToLocaleMap[getCurrLang()];
