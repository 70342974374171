const employeesModule = () => {
  console.log('load employees.redux');
  const reducer = import(/* webpackChunkName: 'employees-reducer' */ '@actions/employees.actions').then(module => module.default);
  const saga = import(/* webpackChunkName: 'employees-sagas' */ '@sagas/employees.sagas').then(module => module.default);

  return [reducer, saga];
};

const adminTracksModule = () => {
  console.log('load admin.redux');
  const reducer = import(/* webpackChunkName: 'admin-reducer' */ '@reducers/admin.reducers').then(module => module.default);
  const saga = import(/* webpackChunkName: 'admin-sagas' */ '@admin/store/sagas').then(module => module.default);

  return [reducer, saga];
};

const paneletModule = () => {
  console.log('load dashboard.panelet.redux');
  const reducer = import(/* webpackChunkName: 'panelet-reducer' */ '@actions/panelet.actions').then(module => module.default);
  const saga = import(/* webpackChunkName: 'panelet-sagas' */ '@sagas/panelet.sagas').then(module => module.default);

  return [reducer, saga];
};

const __asyncModules = {
  employees: employeesModule,
  admin: adminTracksModule,
  panelet: paneletModule,
};

export const getReduxModule = moduleId => {
  const module = __asyncModules[moduleId];

  return module ? module() : null;
};
