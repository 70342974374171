import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router';
import {selectReduxModuleLoadStatuses} from '@actions/app.actions';
import {LoadingLine} from '@components/loading/loading-line';
import {getIsSuperuser, selectIsManager, selectProfileId} from '@selectors/profile.selectors';
import {ReduxModuleIds} from '@src/redux/lazy/constants';
import {LoadStatuses} from '@types/load.types';
import {ReduxModuleInjector} from './module-injector';

// const createCombinedStatusSelector = memoize((
//   baseSelector,
//   ...stateKeys
// ) => createSelector(
//   ...stateKeys.map(id => baseSelector(id)),
//   (...states) => {
//     if (states.includes(LoadStatuses.FAILED)) return LoadStatuses.FAILED;
//     if (states.includes(LoadStatuses.NOT_LOADED)) return LoadStatuses.NOT_LOADED;
//     if (states.includes(LoadStatuses.IS_LOADING)) return LoadStatuses.IS_LOADING;

//     return LoadStatuses.LOADED;
//   },
// ));

// const combinedSelector = createCombinedStatusSelector(selectReduxModuleLoadStatusById, ReduxModuleIds.employees, ReduxModuleIds.panelet);

const WithPaneletModule = React.memo(({children}) => {
  const profileId = useSelector(selectProfileId);
  const isManager = useSelector(selectIsManager);
  const isSuperUser = useSelector(getIsSuperuser);

  const {panelet, employees} = useSelector(selectReduxModuleLoadStatuses);

  if (panelet === LoadStatuses.LOADED && employees === LoadStatuses.LOADED) return children || null;

  const isLoading = !profileId
    || panelet === LoadStatuses.IS_LOADING
    || employees === LoadStatuses.IS_LOADING;

  if (isLoading) return <LoadingLine />;
  if (profileId && !(isManager || isSuperUser)) return <Redirect to="/my-education" />;

  return (
    <ReduxModuleInjector moduleId={ReduxModuleIds.employees}>
      <ReduxModuleInjector moduleId={ReduxModuleIds.panelet}>
        {children}
      </ReduxModuleInjector>
    </ReduxModuleInjector>
  );
});

export default WithPaneletModule;
