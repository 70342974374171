import dayjs from 'dayjs';
import {backendUrl} from '@config';
import {i18n} from '@src/i18n';
import {CourseTypes} from '@types/competence';
import {getImageUrl} from '@utils/data.utils';
import {isObjectWithKeys, parsePathname} from '@utils/misc.utils';
import {validIntOrNull} from '@utils/number.utils';
import {isStringWithLength} from '@utils/string.utils';

const courseTypeKeys = new Set(Object.keys(CourseTypes));

export const findCompetenceType = competenceOrType => {
  if (isStringWithLength(competenceOrType)) return competenceOrType.toLowerCase();

  if (!isObjectWithKeys(competenceOrType)) return null;

  let competenceType = competenceOrType?.competence_type?.competence_type_key
    || competenceOrType?.course_type?.course_type
    || competenceOrType?.competence_type_key
    || competenceOrType?.competence_type?.competence_type
    || competenceOrType?.competence_type?.name
    || competenceOrType?.competence_type;

  if (!competenceType || isObjectWithKeys(competenceType)) {
    competenceType = competenceType?.competence_type_key
      || competenceType?.competence_type
      || competenceType?.type;

    if (!isStringWithLength(competenceType)) {
      competenceType = competenceOrType?.competence?.competence_type?.competence_type
        || competenceOrType?.competence?.competence_type
        || competenceOrType?.competence_type?.competence_type_key
        || competenceOrType?.competence_type
        || competenceOrType?.competence?.competence_type_key
        || competenceOrType?.competence?.competence_type?.competence_type_key
        || competenceOrType?.competenceType
        || competenceOrType?.competence?.competenceType
        || competenceOrType?.course_type
        || competenceOrType?.type;
    }
  }

  if (isObjectWithKeys(competenceType)) return findCompetenceType(competenceType);

  return isStringWithLength(competenceType)
    ? competenceType.toLowerCase()
    : findCompetenceType(competenceOrType?.course || (isObjectWithKeys(competenceOrType?.data) ? competenceOrType.data : null));
};

export const i18nCourseGetCompetenceType = course => {
  const competenceType = findCompetenceType(course);

  if (!competenceType) return '';

  return i18n(`globals.course-types.${competenceType}`, null, course?.competence_type?.title || competenceType);
};

const getCompetenceTypes = typesOrTypeKeys => {
  const types = typesOrTypeKeys.flatMap(competenceType => {
    if (courseTypeKeys.has(competenceType)) {
      return [competenceType, ...CourseTypes[competenceType]];
    }

    return [competenceType];
  });

  if (!types.length) return [];

  for (const competenceType of types) {
    if (courseTypeKeys.has(competenceType) && !types.includes(competenceType)) {
      types.push(competenceType);
    }
  }

  return types;
};

export const findCompetenceFiles = competence => {
  if (!isObjectWithKeys(competence)) return null;

  const files = competence?.files
    || competence?.competence?.files
    || competence?.course?.files
    || competence?.data?.files
    || competence?.competence_files
    || competence?.competence?.competence_files
    || competence?.competence?.competence?.files
    || null;

  if (!files?.length) return null;

  return files.filter(file => isObjectWithKeys(file));
};

export const getCompetenceCoverUrl = (competence, defaultImage = null) => {
  if (!isObjectWithKeys(competence)) return null;

  const coverUrl = competence?.cover?.url
    || competence?.competence?.cover?.url
    || competence?.course?.cover?.url
    || competence?.data?.cover?.url
    || competence?.cover_url
    || competence?.competence?.cover_url
    || competence?.course?.cover_url
    || competence?.data?.cover_urls
    || null;

  if (coverUrl) return getImageUrl(coverUrl);

  const files = findCompetenceFiles(competence);

  if (!files?.length) return null;

  const coverFile = files.find(file => file?.title === 'cover' || file?.type === 'cover');

  if (coverFile?.url) return getImageUrl(coverFile.url);

  return coverFile?.file_id || coverFile?.id
    ? getImageUrl(`${backendUrl}/files/image/${coverFile?.file_id || coverFile?.id}`)
    : !!defaultImage && getImageUrl(defaultImage) || null;
};

export const findCompetenceTitle = competence => {
  if (!isObjectWithKeys(competence)) return null;

  const title = competence?.title
    || competence?.competence?.title
    || competence?.course?.title
    || competence?.data?.title
    || competence?.competence_title
    || competence?.competence?.competence_title
    || competence?.course?.competence_title
    || competence?.data?.competence_title
    || competence?.name
    || competence?.competence?.name
    || competence?.course?.name
    || competence?.data?.name
    || null;

  return isStringWithLength(title)
    ? title
    : null;
};

export const findCompetenceShortDescription = (competence, returnRealValue) => {
  if (!isObjectWithKeys(competence)) return null;

  const description = competence?.short_description
    ?? competence?.competence?.short_description
    ?? competence?.course?.short_description
    ?? competence?.data?.short_description

    ?? competence?.shortDescription
    ?? competence?.competence?.shortDescription
    ?? competence?.course?.shortDescription
    ?? competence?.data?.shortDescription

    ?? competence?.abstract
    ?? competence?.competence?.abstract
    ?? competence?.course?.abstract
    ?? competence?.data?.abstract

    ?? competence?.teaser
    ?? competence?.content?.teaser
    ?? competence?.competence?.content?.teaser
    ?? competence?.course?.content?.teaser
    ?? competence?.data?.content?.teaser;

  // ?? competence?.description
  // ?? competence?.competence?.description
  // ?? competence?.course?.description
  // ?? competence?.data?.description
  // ?? competence?.competence_description
  // ?? competence?.competence?.competence_description
  // ?? competence?.course?.competence_description
  // ?? competence?.data?.competence_description;

  if (typeof description === 'string') return description;

  if (competence?.data && returnRealValue) {
    const values = [
      competence?.data?.data,
      competence?.data,
    ].map(data => findCompetenceShortDescription(data, true));

    const str = values.find(v => typeof v === 'string');

    if (str) return str;

    const notUndefined = values.find(v => v !== undefined);

    if (notUndefined !== undefined) return notUndefined;
  }

  return returnRealValue
    ? description
    : null;
};

export const findCompetenceDescription = (competence, returnRealValue) => {
  if (!isObjectWithKeys(competence)) return null;

  const description = competence?.description
    || competence?.competence?.description
    || competence?.course?.description
    || competence?.data?.description
    || competence?.competence_description
    || competence?.competence?.competence_description
    || competence?.course?.competence_description
    || competence?.data?.competence_description;

  if (description) return description;

  if (returnRealValue) {
    return competence?.description
      ?? competence?.competence?.description
      ?? competence?.course?.description
      ?? competence?.data?.description
      ?? competence?.competence_description
      ?? competence?.competence?.competence_description
      ?? competence?.course?.competence_description
      ?? competence?.data?.competence_description;
  }

  return findCompetenceShortDescription(competence, returnRealValue);
};

const createTypeCheckFunction = typeKeyOrKeys => {
  if (!typeKeyOrKeys) return () => false;

  const types = Array.isArray(typeKeyOrKeys)
    ? typeKeyOrKeys
    : [typeKeyOrKeys];

  const typesToCheck = new Set(getCompetenceTypes(types));

  return typeOrCompetence => {
    const type = findCompetenceType(typeOrCompetence);

    return !!type && typesToCheck.has(type);
  };
};

export const isCertificateCompetence = createTypeCheckFunction('certificate');
export const isFormalCompetence = createTypeCheckFunction('formalcompetence');
export const isComplexCompetence = createTypeCheckFunction('complex');
export const isECourseCompetence = createTypeCheckFunction('ecourse');
export const isEquivalentsCompetence = createTypeCheckFunction('equivalents');
export const isClassroomCourseCompetence = createTypeCheckFunction('course');
export const isTrackCompetence = createTypeCheckFunction('track');
export const isDigitalSignatureCompetence = createTypeCheckFunction('digitalsignature');
export const isChecklistItemCompetence = createTypeCheckFunction('checklist_item');
export const isVerificationCompetence = createTypeCheckFunction('verification');
export const isNanoCompetence = createTypeCheckFunction('nano');
export const isGroupCompetence = createTypeCheckFunction('group');
export const isClassRoomCourseOrGroupCompetence = createTypeCheckFunction(['course', 'group']);

// grouped types

export const isSelfSignCompetence = createTypeCheckFunction('selfsign');
export const isChecklistCompetence = createTypeCheckFunction('checklist');
export const isChecklistOrChecklistItemCompetence = createTypeCheckFunction(['checklist', 'checklist_item']);
export const isNanoOrEcourseCompetence = createTypeCheckFunction(['nano', 'ecourse']);
export const isSelfSignOrChecklistItemCompetence = createTypeCheckFunction(['selfsign', 'checklist_item']);
export const isSelfSignOrSignatureCompetence = createTypeCheckFunction(['selfsign', 'digitalsignature']);
export const isChecklistSelfSignOrSignatureCompetence = createTypeCheckFunction(['selfsign', 'checklist', 'checklist_item', 'digitalsignature']);
export const isSelfSignChecklistOrChecklistItemCompetence = createTypeCheckFunction(['selfsign', 'checklist', 'checklist_item']);
export const isSelfSignOrChecklistCompetence = createTypeCheckFunction(['selfsign', 'checklist', 'checklist_item']);
export const isChecklistItemSelfSignOrSignatureCompetence = createTypeCheckFunction(['selfsign', /* 'checklist', */'checklist_item', 'digitalsignature']);

// types with special properties
export const canHaveChildrenCompetences = createTypeCheckFunction([
  'complex',
  'equivalents',
  'checklist',
  'track',
  'group',
]);

export const canBeStatusPending = createTypeCheckFunction([
  'formalcompetence',
  'verification',
  'certificate',
  'checklist_item',
]);

export const findCompetenceId = idOrCompetence => {
  if (!idOrCompetence) return null;

  const id = typeof idOrCompetence === 'number' || typeof idOrCompetence === 'string'
    ? idOrCompetence
    : idOrCompetence?.competence_id
      ?? idOrCompetence?.competence?.competence_id
      ?? idOrCompetence?.course_id
      ?? idOrCompetence?.competence?.course_id
      ?? idOrCompetence?.courseId
      ?? idOrCompetence?.competence?.courseId
      ?? idOrCompetence?.competenceId
      ?? idOrCompetence?.competence?.competenceId
      ?? idOrCompetence?.course?.competence_id
      ?? idOrCompetence?.course?.cid
      ?? idOrCompetence?.course?.course_id
      ?? idOrCompetence?.course?.courseId
      ?? idOrCompetence?.competence?.id
      ?? idOrCompetence?.competence?.cid
      ?? idOrCompetence?.course?.id
      ?? idOrCompetence?.pending_checklists?.competence_id
      ?? idOrCompetence?.pending_checklists?.id
      ?? idOrCompetence?.cid
      ?? idOrCompetence?.id;

  if (id != null) return validIntOrNull(id);
  if (isObjectWithKeys(idOrCompetence?.data)) return findCompetenceId(idOrCompetence.data);

  return null;
};

export const findCompetenceChecklist = item => {
  if (!isObjectWithKeys(item)) return null;

  return item?.checklist
    || item?.competence?.checklist
    || item?.course?.checklist
    || item?.data?.checklist
    || item?.competence_checklist
    || null;
};

export const findCompetenceChildrenIds = item => {
  if (!isObjectWithKeys(item)) return null;

  const childrenIds = item?.children_ids
    || item?.competence?.children_ids
    || item?.children?.map(child => findCompetenceId(child))
    || item?.competence?.children?.map(child => findCompetenceId(child))
    || null;

  if (childrenIds != null) return childrenIds.filter(id => !!id);
  if (isObjectWithKeys(item?.data)) return findCompetenceChildrenIds(item.data);
  if (isObjectWithKeys(item?.course)) return findCompetenceChildrenIds(item.course);

  return Array.isArray(childrenIds)
    ? childrenIds
    : null;
};

export const findCompetencePassed = item => {
  if (!isObjectWithKeys(item)) return null;

  return validIntOrNull(item?.competence?.passed
    ?? item?.course?.passed
    ?? item?.passed
    ?? item?.data?.passed
    ?? item?.passedInfo?.passed);
};

export const findCompetenceCheckedBy = item => {
  if (!isObjectWithKeys(item)) return null;

  const checkedBy = item.competence?.checked_by
    ?? item.course?.checked_by
    ?? item.data?.checked_by
    ?? item.checked_by
    ?? item.checklist?.check_raw
    ?? item.check_raw
    ?? null;

  if (checkedBy) return checkedBy;
  if (checkedBy === '' && isSelfSignOrChecklistItemCompetence(item)) return 'manager';

  return null;
};

export const findPersonId = item => {
  if (!isObjectWithKeys(item)) return null;

  const personId = validIntOrNull(item?.person_id
    ?? item?.personId
    ?? item?.pid
    ?? item?.person?.person_id
    ?? item?.person?.id
    ?? item?.competence?.person_id
    ?? item?.competence?.personId
    ?? item?.competence?.person?.person_id
    ?? item?.competence?.person?.id);

  if (personId != null) return personId;
  if (isObjectWithKeys(item?.data)) return findPersonId(item.data);
  if (isObjectWithKeys(item?.course)) return findPersonId(item.course);

  return null;
};

export const findUsername = item => {
  if (!isObjectWithKeys(item)) return null;

  const username = item?.username || item?.userName || item?.user_name
    || item?.person?.username || item?.person?.userName || item?.person?.user_name
    || item?.competence?.username || item?.competence?.userName || item?.competence?.user_name
    || item?.competence?.person?.username || item?.competence?.person?.userName || item?.competence?.person?.user_name
    || null;

  if (isStringWithLength(username)) return username;
  if (isObjectWithKeys(item?.data)) return findUsername(item.data);

  return null;
};

export const findOrgId = item => {
  if (!isObjectWithKeys(item)) return null;

  const orgId = validIntOrNull(item?.organisation_id
    || item?.orgId
    || item?.org_id
    || item?.organisationId
    || item?.organisation?.id
    || item?.organisation?.organisation_id
    || item?.organisation?.orgId);

  if (orgId != null) return orgId;
  if (isObjectWithKeys(item?.data)) return findOrgId(item.data);

  return null;
};

export const findTrackId = payload => {
  if (!isObjectWithKeys(payload)) return null;

  const id = validIntOrNull(payload?.track_id
    || payload?.trackId
    || payload?.track?.id
    || payload?.track?.track_id
    || payload?.track?.trackId
    || payload?.competence?.track_id
    || payload?.competence?.trackId);

  if (id != null) return id;

  if (isTrackCompetence(payload)) return findCompetenceId(payload);
  if (isObjectWithKeys(payload?.data)) return findTrackId(payload.data);
  if (isObjectWithKeys(payload?.course)) return findTrackId(payload.course);

  return null;
};

export const findMapId = payload => {
  if (!isObjectWithKeys(payload)) return null;

  const id = validIntOrNull(payload?.map_id
    || payload?.mapId
    || payload?.competence?.track_id
    || payload?.competence?.trackId);

  if (id != null) return id;

  if (isTrackCompetence(payload)) return findCompetenceId(payload);

  if (isObjectWithKeys(payload?.data)) return findMapId(payload.data);
  if (isObjectWithKeys(payload?.course)) return findMapId(payload.course);

  return null;
};

export const findRoleId = payload => {
  if (typeof payload === 'number' || typeof payload === 'string') return validIntOrNull(payload);

  if (!isObjectWithKeys(payload)) return null;

  const id = validIntOrNull(payload?.role_id
    ?? payload?.roleId
    ?? payload?.role?.role_id
    ?? payload?.role?.roleId
    ?? payload?.role?.id
    ?? payload?.competence?.role_id
    ?? payload?.competence?.roleId
    ?? payload?.competence?.role?.id);

  if (id != null) return id;

  if (isObjectWithKeys(payload?.data)) return findRoleId(payload.data);
  if (isObjectWithKeys(payload?.course)) return findRoleId(payload.course);

  return null;
};

export const findCompetenceUserFiles = item => {
  if (!isObjectWithKeys(item)) return null;

  const userFiles = item?.user_files
    || item?.userFiles
    || item?.competence?.user_files
    || item?.competence?.userFiles
    || item?.competence_user_files
    || item?.data?.user_files
    || item?.data?.userFiles
    || item?.person?.user_files
    || item?.person?.userFiles
    || item?.person?.files
    || item?.person_competence?.user_files
    || item?.person_competence?.userFiles
    || item?.person_competence?.files
    || null;

  if (userFiles != null) return userFiles;
  if (isObjectWithKeys(item?.data)) return findCompetenceUserFiles(item.data);
  if (isObjectWithKeys(item?.course)) return findCompetenceUserFiles(item.course);

  return null;
};

export const findCompetencePassedDate = item => {
  if (!isObjectWithKeys(item)) return null;

  const passedDate = item?.passed_date
    || item?.competence?.passed_date
    || item?.competence_passed_date
    || item?.person_competence?.passed_date
    || item?.person_competence?.passedDate
    || null;

  if (passedDate != null) return passedDate;

  const passed = findCompetencePassed(item);

  const date = passed === 100
    ? item?.person_competence?.date
      || item?.date
      || item?.competence?.date
      || item?.competence_date
      || item?.joined
      || item?.data?.joined
      || null
    : null;

  if (date != null) return date;

  const checklist = findCompetenceChecklist(item);

  if (checklist) {
    try {
      const checkedAt = [
        checklist.is_check_manager,
        checklist.is_check_user,
      ]
        .filter(d => !!d)
        .sort((a, b) => dayjs(b, 'DD.MM.YYYY HH:mm').diff(dayjs(a, 'DD.MM.YYYY HH:mm')))[0];

      if (checkedAt) return checkedAt;
    } catch (error) {
      console.error(error);
    }
  }

  if (isObjectWithKeys(item?.data)) return findCompetencePassedDate(item.data);
  if (isObjectWithKeys(item?.course)) return findCompetencePassedDate(item.course);

  return null;
};

// todo: rewrite and test this for all types
export const getCompetenceIsPending = item => {
  if (!isObjectWithKeys(item)) return null;

  const pending = item?.pending
    || item?.competence?.pending
    || item?.competence_pending
    || item?.person_competence?.pending
    || item?.person_competence?.is_pending
    || item?.is_pending
    || item?.competence?.is_pending
    || null;

  if (pending != null) return pending;

  const passed = findCompetencePassed(item);

  if (passed === 100) return false;

  const checklist = findCompetenceChecklist(item);

  if (checklist?.check_raw != null) {
    const {check_raw, is_check_manager, is_check_user} = checklist;

    if (!is_check_user && !is_check_manager) return false;
    if (check_raw === 'both' && is_check_user) return true;
    // if (check_raw === 'manager') return true; // will only be true if person is not manager
  }

  const children_ids = findCompetenceChildrenIds(item);

  return passed === 50 && !children_ids?.length;
};

// dont use this?
export const getPassedTasksInfo = ({
  competence,
  passedIdsSet,
  pendingSelfSignIds = [],
}) => {
  const cid = findCompetenceId(competence);

  if (!cid) return null;

  const competencePassed = findCompetencePassed(competence);
  const childrenIds = findCompetenceChildrenIds(competence);

  const tasksCount = childrenIds?.length || 1;

  const childrenPassedCount = childrenIds?.length
    ? childrenIds.filter(id => !!passedIdsSet?.has?.(id)).length
    : +!!passedIdsSet?.has?.(cid);

  const isPassed = competencePassed == null
    ? childrenPassedCount === tasksCount
    : competencePassed === 100;

  const passedPercent = competencePassed == null
    ? Math.round(childrenPassedCount / tasksCount * 100)
    : competencePassed;

  const completedTasksCount = isPassed
    ? tasksCount
    : childrenPassedCount ?? passedPercent
      ? Math.round(passedPercent / 100 * tasksCount)
      : 0;

  const checkedBy = findCompetenceCheckedBy(competence);
  const waitingForApproval = !!checkedBy && passedPercent === 50
    && ['both', 'manager'].includes(checkedBy)
    || !!pendingSelfSignIds?.includes?.(cid)
    || getCompetenceIsPending(competence);

  return {
    total: tasksCount,
    completed: completedTasksCount,
    isPassed,
    passedPercent,
    waitingForApproval,
    checkedBy,
    status: isPassed && !waitingForApproval
      ? 'passed'
      : waitingForApproval
        ? 'waiting_for_approval'
        : completedTasksCount > 0
          ? 'in_progress'
          : 'not_started',
  };
};

export const findPhceId = item => {
  if (!isObjectWithKeys(item)) return null;

  const phceId = validIntOrNull(item?.phceId
    ?? item?.phce_id
    ?? item?.competence?.phce_id
    ?? item?.competence?.phceId
    ?? item?.person_competence_id
    ?? item?.person_competence?.id
    ?? item?.person_competence?.person_competence_id
    ?? item?.person_competence?.phce_id
    ?? item?.competence?.person_competence_id
    ?? item?.person_competence_id);

  if (phceId != null) return phceId;

  if (item?.id) {
    const competence_id = findCompetenceId(item);

    if (competence_id != null && competence_id !== item.id) return item.id;
  }

  if (isObjectWithKeys(item?.data)) return findPhceId(item.data);
  if (isObjectWithKeys(item?.course)) return findPhceId(item.course);

  return null;
};

// remove this?
export const getIsPassedOrWaitingForApproval = (competence, passedCompetenceIdsSet) => {
  const cid = findCompetenceId(competence);

  if (!cid) return false;

  if (passedCompetenceIdsSet?.has?.(cid)) return true;

  const passed = findCompetencePassed(competence);

  if (passed != null) return passed === 100;

  const isPending = getCompetenceIsPending(competence);

  if (isPending) return true;

  const passedInfo = competence?.passedInfo
    || !!passedCompetenceIdsSet && getPassedTasksInfo({
      competence,
      passedIdsSet: passedCompetenceIdsSet,
    });

  if (passedInfo?.isPassed || passedInfo?.waitingForApproval) return true;

  return !!findPhceId(competence); // this return-value is not correct, since we can have a phceId and not be completed or waiting for approval, but this is how it is in the old code
};

// remove this?
export const getIsCompletedCompetence = (competence, passedCompetenceIdsSet) => {
  if (!isObjectWithKeys(competence)) return false;

  const {passedInfo = {}} = competence;
  const passed = findCompetencePassed(competence);

  return passed === 100
    || !!passedInfo?.isPassed
    || !!competence?.isPassed
    || !!passedCompetenceIdsSet?.length && !!getPassedTasksInfo({
      competence,
      passedIdsSet: passedCompetenceIdsSet,
    })?.isPassed;
};

export const hasDescriptionField = item => {
  const hasField = item?.__hasDescriptionField || findCompetenceDescription(item, true) !== undefined;

  if (!hasField && typeof item?.data === 'string') {
    try {
      const jsonData = JSON.parse(item.data);

      return hasDescriptionField(jsonData);
    } catch {
      return false;
    }
  }

  return hasField;
};

export const checkedByManagerValues = new Set(['', 'manager']);

export const getShouldHideSelfSignButton = competence => {
  if (!competence) return false;
  if (competence?.passed === 100 || competence?.waiting_for_approval) return false;
  if (!isSelfSignOrChecklistItemCompetence(competence)) return false;

  return checkedByManagerValues.has(findCompetenceCheckedBy(competence));
};

export const getRoleType = role => role?.role_type_role_type || role?.role_type || role?.type;

export const isTypeRoleOrOnboarding = role =>
  (getRoleType(role) === 'role' || getRoleType(role) === 'onboarding')
  && (role?.id ?? role?.role_id) !== -2;

export const findLearningpathResourcesId = track => {
  if (!isObjectWithKeys(track)) return null;

  let resourcesId = null;

  try {
    const track_json_data = track?.competence_json_data ?? JSON.parse(track.data);

    resourcesId = track_json_data?.data?.data?.data?.resources_coursegroup_id
      ?? track_json_data?.data?.data?.resources_coursegroup_id
      ?? track_json_data?.data?.resources_coursegroup_id
      ?? track_json_data?.resources_coursegroup_id;
  } catch (error) {
    console.error(error);
  }

  return validIntOrNull(resourcesId);
};

export const getKioskStartUrl = (roles, trackData, configStartUrl) => {
  if (!roles?.length) return configStartUrl;
  if (roles.length > 1) return '/my-education';

  if (!trackData) return null;

  const {
    id: roleId,
    missing_count,
    passed_count,
  } = roles?.[0] || {};

  const track = roleId
    ? trackData?.[roleId]
    : null;

  if (!track?.id) {
    const {role_id} = parsePathname(configStartUrl);

    return role_id
      ? `/my-education/role/${role_id}`
      : configStartUrl;
  }

  const isCompleted = (missing_count ?? 0) === 0 && (passed_count ?? 0) > 0;
  const resourcesId = isCompleted ? findLearningpathResourcesId(track) : null;

  return resourcesId == null
    ? `/learning-path/${track.id}`
    : `/learning-path/${track.id}/resources`;
};

export const getIsCompetenceExpired = competence => {
  if (!isObjectWithKeys(competence)) return false;

  const {valid_until} = competence || {};

  return valid_until && new Date(valid_until) < new Date();
};
