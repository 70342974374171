import * as React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Loading from '@components/loading/loading';
import styled from '@emotion/styled';
import {i18n} from '@src/i18n';

const Wrapper = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0) linear-gradient(rgb(0, 3, 54) 0%, rgb(0, 62, 126) 100%) no-repeat scroll center center / cover;
  top: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  justify-content: center;
  justify-items: center;
  display: flex;
  align-items: center;
  align-content: center;
  color: white;
  z-index: 999999;

  .course-loader-logo {
    margin-top: 30px;
    min-width: 200px;
    justify-content: center;
    display: flex;
  }

  .course-loader-text {
    &.wrapper {
      position: fixed;
      bottom: 10px;
      left: 10px;
    }

    &.time {
      font-size: 16px;
      float: left;
      font-weight: 900;
    }

    &.info-text {
      font-size: 14px;
      float: left;
      margin-left: 10px;
      padding-top: 2px;
      color: rgba(255, 255, 255, .4);
      font-weight: 300;
    }
  }
`;

/* Displays a skeleton for an article */
const CourseLoader = React.memo(({
  startup,
  appload,
}) => (
  <Wrapper className="course-loader">
    <div className="course-loader-content">
      <div className="course-loader-logo" />
      <Loading />
      <div className="course-loader-text wrapper">
        <div className="course-loader-text time">
          {dayjs().format('HH:mm')}
        </div>
        <div className="course-loader-text info-text">
          {appload
            && <>{i18n('globals.loading')}</>
            || startup && <>{i18n('globals.loading-nano')}</>
            || <>{i18n('globals.register')}</>}
        </div>
      </div>
    </div>
  </Wrapper>
));

CourseLoader.propTypes = {
  isMobile: PropTypes.bool,
  startup: PropTypes.bool,
};

CourseLoader.defaultProps = {
  isMobile: false,
  startup: false,
};

export default CourseLoader;
