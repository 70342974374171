import axios from 'axios';
import {apiFetchPersonRoles, apiFetchRoleCompetences, apiFetchRoles} from '@actions/api.actions';
import {backendUrl, backendUrlV2} from '@config';
import {axiosRetry} from '@utils/async.utils';
import {emptyArr} from '@utils/constants';
import {filterNullishProps} from '@utils/misc.utils';
import {createFetchSaga} from '@utils/redux-sagas.utils';

export const fetchRoleCompetencesPromise = ({roleId, personId}) => axiosRetry({
  url: `${backendUrl}/persons/requiredcourses`,
  params: {
    rids: roleId,
    pid: personId,
  },
}).then(({data}) => data);

const fetchAllRolesPromise = () => axiosRetry({
  url: `${backendUrl}/api/roles`,
  params: {
    limit: 1000,
    fields: 'title,id,rolemetatype,role_type_id,kiosk',
    role_meta_types: 'position,role,specialrole',
  },
}).then(({data}) => data.roles.map(role => ({
  ...role,
  role_id: role.id,
  kiosk: !!role.kiosk,
  is_position: role.rolemetatype === 'position',
  is_onboarding: role.role_type_id === 11,
})))
  .catch(console.error);

const fetchPersonRolesPromise = ({
  personId,
  username,
  apiV1 = true,
  apiV2 = true,
  future = false,
}) => axios.all([
  ...apiV2 && personId
    ? [
      axiosRetry({
        url: `${backendUrlV2}/persons/${personId}/roles`,
        params: {future},
      })
        .then(({data}) => data)
        .catch(console.error),
    ]
    : [undefined],
  ...apiV1 && username
    ? [
      axiosRetry({
        url: `${backendUrl}/api/roles`,
        params: {
          user_name: username,
          fields: 'rolemetatype,role_id,title,organisation_id,role_type_id,kiosk',
          role_meta_types: 'role',
          limit: 1000,
        },
      })
        .then(({data}) => data?.roles)
        .catch(console.error),
    ]
    : [undefined],
]).then(axios.spread((apiV2Roles = [], apiV1Roles = []) => {
  if (!apiV2Roles?.length && !apiV1Roles?.length) return emptyArr;

  const byId = {};
  const ids = new Set();

  if (Array.isArray(apiV2Roles)) {
    for (const role of apiV2Roles) {
      const rid = role?.role_id || role?.id;

      if (!rid) continue;
      ids.add(rid);
      byId[rid] = role;
    }
  }

  if (Array.isArray(apiV1Roles)) {
    for (const role of apiV1Roles) {
      const rid = role?.id || role?.role_id;

      if (!rid) continue;
      ids.add(rid);
      const nonNullish = filterNullishProps(role);

      byId[rid] = {
        ...byId[rid],
        ...nonNullish,
      };
    }
  }

  return [...ids].map(id => byId[id]);
}));

export const fetchRolesAPI = createFetchSaga(apiFetchRoles, fetchAllRolesPromise);
export const fetchPersonRolesAPI = createFetchSaga(apiFetchPersonRoles, fetchPersonRolesPromise);
export const fetchRoleCompetencesAPI = createFetchSaga(apiFetchRoleCompetences, fetchRoleCompetencesPromise);
