import {LoadingLine} from '@components/loading/loading-line';
import {loadableRetry} from '@utils/loadable-retry';

const LoadableCourseCatalogSwitch = loadableRetry(() => {
  console.log('load course.catalog.switch');

  return import(/* webpackChunkName: "course.catalog.switch" */ './course-catalog-switch');
}, {
  fallback: <LoadingLine />,
  name: 'course.catalog.switch',
});

export default LoadableCourseCatalogSwitch;
