import {isStringWithLength} from '@utils/string.utils';
import {ModalIds} from '../modal-ids';

const isProd = process.env.NODE_ENV === 'production';

/**
 * (Dev only) Helper function to validate the modal id.
 * Used internally by the {modals} slice of the store.
 * @param {string} modalId - The modal id.
 * @throws {Error} - If the modal id is invalid.
 */
export const validateModalId = isProd
  ? () => {}
  : modalId => {
    if (
      !isStringWithLength(modalId)
      || !Object.values(ModalIds).includes(modalId)
    ) {
      throw new Error(`Invalid modalId: ${modalId}`);
    }
  };

/**
 * (Dev only) Helper function to validate the modal payload.
 * Used internally by the {modals} slice of the store.
 * @param {string} modalId - The modal id. (See {@link ModalIds} for the list of modal ids.)
 * @param {object} payload - The modal payload.
 * @throws {Error} - If the modal payload is invalid.
 *
 * See {@link modalsPayload} for the list of valid payload keys/types.
 */

// export const validateModalPayload = isProd
//   ? () => {}
//   : (modalId, payload) => {
//     const modalPayloadKey = modalsPayload[modalId];

//     if (!modalPayloadKey) return;

//     const {not_null = [], optional = []} = modalPayloadKey || {};

//     const keys = new Set();

//     not_null.forEach(key => {
//       if (typeof key === 'object') {
//         const {type, key: payloadKey} = key;

//         if (!payloadKey || typeof payloadKey !== 'string') throw new Error(`[${modalId}] Invalid key: ${payloadKey}`);
//         if (payload[payloadKey] == null) throw new Error(`[${modalId}] Missing required key: ${payloadKey}`);
//         if (type && typeof payload[payloadKey] !== type) throw new TypeError(`[${modalId}] Invalid type for key: ${payloadKey}`);

//         keys.add(payloadKey);

//         return;
//       }

//       if (typeof key !== 'string') throw new Error(`[${modalId}] Invalid key: ${key}`);
//       if (payload[key] == null) throw new Error(`[${modalId}] Missing required key: ${key}`);
//       if (typeof payload[key] !== 'string' && payload[key].trim() === '') throw new Error(`[${modalId}] Invalid value for key: ${key}`);

//       keys.add(key);
//     });

//     optional.forEach(key => {
//       if (typeof key === 'object') {
//         const {type, key: payloadKey} = key;

//         if (!payloadKey || typeof payloadKey !== 'string') throw new Error(`[${modalId}] Invalid key: ${payloadKey}`);
//         if (payloadKey && payload[payloadKey] == null) return;
//         if (type && payload[payloadKey] && typeof payload[payloadKey] !== type) throw new TypeError(`[${modalId}] Invalid type for key: ${payloadKey}`);

//         keys.add(payloadKey);

//         return;
//       }

//       if (typeof key !== 'string') throw new TypeError(`[${modalId}] Invalid key: ${key}`);

//       keys.add(key);
//     });

//     Object.keys(payload).forEach(key => {
//       if (!keys.has(key)) throw new Error(`[${modalId}] Invalid key: ${key}`);
//     });
//   };
