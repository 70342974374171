import axios from 'axios';
import {freeze} from 'immer';
import {all, call, put} from 'redux-saga/effects';
import {apiFetchChildrenCompetences, apiFetchCompetence} from '@actions/api.actions';
import {backendUrl, backendUrlV2} from '@config';
import {waitForOrgId, waitForProfileId} from '@sagas/app.sagas';
import {retry} from '@utils/sagas.utils';
import {findCompetenceId, findOrgId, findPersonId} from '../util/competence-identity';

const DEFAULT_FIELDS_CHILDREN_COMPETENCES = 'certificate_url,passed,competence_id,'
  + 'competence(files,checked_by,title,id,short_description,description,person_competence_id,competence_type_id),'
  + 'competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade';

export function* fetchCompetenceAPI(action) {
  try {
    const {payload} = action || {};

    const {successAction = apiFetchCompetence.success} = payload || {};

    const cid = findCompetenceId(payload);

    const batch = Array.isArray(payload?.batch)
      ? payload.batch
      : null;

    if (cid == null && !batch) throw new Error('missing competence_id');

    const pid = findPersonId(payload) || (yield call(waitForProfileId));

    const args = batch || [cid];

    const data = yield all(args.map(arg => {
      const competence_id = findCompetenceId(arg);

      if (!competence_id) {
        console.error('missing competence_id', arg);

        return null;
      }

      return retry(() => axios.request({
        method: 'GET',
        url: `${backendUrlV2}/competences/${competence_id}`,
        withCredentials: true,
      }).then(({data}) => data));
    }));

    if (typeof successAction === 'function') yield put(successAction({
      pid,
      data: freeze(data),
    }));

    return data;
  } catch (error) {
    console.error(error);

    return null;
  }
}

export function* fetchChildrenCompetencesAPI(action) {
  try {
    const {payload} = action || {};

    const {successAction = apiFetchChildrenCompetences.success} = payload || {};

    const cid = findCompetenceId(payload);

    if (cid == null) throw new Error('missing competence_id');

    const skipSuccessAction = payload?.skipSuccessAction ?? false;
    const fetchExtraData = payload?.fetchExtraData ?? false;

    const orgId = findOrgId(payload) || (yield call(waitForOrgId));
    const pid = findPersonId(payload) || (yield call(waitForProfileId));

    if (orgId == null || pid == null) throw new Error('missing org_id or person_id');

    const {onEnd} = payload || {};

    const {data: childrenCompetences} = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrl}/persons/get_children_competences_json/${pid}/${cid}/${orgId}/0`,
      params: {
        state: payload?.state ?? 'all',
        limit: payload?.limit ?? 100,
        fields: payload?.fields ?? DEFAULT_FIELDS_CHILDREN_COMPETENCES,
      },
      withCredentials: true,
    }));

    if (fetchExtraData) {
      const ids = childrenCompetences?.map(competence => findCompetenceId(competence)) || [];

      if (ids.length) {
        yield call(fetchCompetenceAPI, {
          payload: {
            batch: ids,
            pid,
          },
        });
      }
    }

    if (
      !skipSuccessAction && childrenCompetences?.length
      && typeof successAction === 'function'
    ) yield put(successAction({
      pid,
      cid,
      data: freeze(childrenCompetences),
    }));

    if (onEnd) onEnd(childrenCompetences);

    return {
      cid,
      data: childrenCompetences,
    };
  } catch (error) {
    console.error(error);

    if (action?.payload?.onError) action.payload.onError(error);
    if (action?.payload?.onEnd) action.payload.onEnd(null);

    return null;
  }
}
