import memoize from 'micro-memoize';
import {createSelector} from 'reselect';
import {buildCompetencesTreeV2} from '@utils/competence.utils';
import {emptyArr, emptyObj} from '@utils/constants';
import {getImageUrl} from '@utils/data.utils';
import {filterNullishProps, isObjectWithKeys} from '@utils/misc.utils';
import {getRoleId, getRoleTitle, getRoleType, roleIsPosition, sortRoleByType} from '@utils/roles.utils';
import {createDeepEqualSelector} from '@utils/selectors.utils';
import {cleanPhoneNumber} from '@utils/string.utils';
import {selectedPersonV2InitialState} from '../initial-state/employees.selected-person.initial-state';
import {isTypeRoleOrOnboarding} from '../util/competence-identity';
import {getEmployees} from './employees.selectors';

const setsObjectToArrayObject = obj => {
  if (!isObjectWithKeys(obj)) return emptyObj;

  for (const key of Object.keys(obj)) {
    obj[key] = [...obj?.[key] || []];
  }

  return obj;
};

export const getRoleSectionType = (
  type,
  requirementName = 'requirement', // sometimes 'mandatory' is used (api calls), should be standardised, but for now...
) => {
  if (type === 'other') return 'other';
  if (type === 'optional') return 'optional';

  return requirementName;
};

export const emptySummary = Object.freeze({
  requirement: emptyArr,
  optional: emptyArr,
  other: emptyArr,
});

export const summaryKeys = Object.keys(emptySummary);
export const emptySummaryMap = Object.freeze(Object.fromEntries(summaryKeys.map(key => [key, emptyObj])));

export const emptyRolesData = Object.freeze({
  roles: emptyObj,
  sectionRoleIds: emptySummary,
});

export const getSelectedPersonV2 = state => state?.employees?.selectedPersonV2 || selectedPersonV2InitialState;

export const getSelectedPersonV2Id = state => state.employees?.selectedPersonV2.personId;
export const getSelectedPersonV2Username = state => state.employees?.selectedPersonV2.username;
export const getSelectedPersonV2OrgId = state => state.employees?.selectedPersonV2.orgId;
export const getSelectedPersonV2IsInitialized = state => state.employees?.selectedPersonV2.initialized;

const _getSelectedPersonV2ComponentState = state => state?.employees?.selectedPersonV2?.componentState || selectedPersonV2InitialState.componentState;
const _getSelectedPersonV2APIData = state => state?.employees?.selectedPersonV2?.apiData || selectedPersonV2InitialState.apiData;

export const _getSelectedPersonV2Fullname = state => state?.employees?.selectedPersonV2?.apiData?.fullname;

export const _getSelectedPersonV2Person = state => state?.employees?.selectedPersonV2?.apiData?.person || selectedPersonV2InitialState.apiData.person;
const _getSelectedPersonV2Roles = state => state?.employees?.selectedPersonV2?.apiData?.roles || selectedPersonV2InitialState.apiData.roles;
const _getSelectedPersonV2Summary = state => state?.employees?.selectedPersonV2?.apiData?.summary || selectedPersonV2InitialState.apiData.summary;

export const _getSelectedPersonV2LoginCount = state => state?.employees?.selectedPersonV2?.apiData?.loginCount ?? selectedPersonV2InitialState.apiData.loginCount;
export const _getSelectedPersonV2Competencelevel = state => state?.employees?.selectedPersonV2?.apiData?.competencelevel ?? selectedPersonV2InitialState.apiData.competencelevel;

const _getSelectedPersonV2Events = state => state?.employees?.selectedPersonV2?.apiData?.events || selectedPersonV2InitialState.apiData.events;
const _getSelectedPersonV2Report = state => state?.employees?.selectedPersonV2?.apiData?.report || selectedPersonV2InitialState.apiData.report;
const _getSelectedPersonV2Competences = state => state?.employees?.selectedPersonV2?.competences || selectedPersonV2InitialState.competences;
const _getSelectedPersonV2RoleCompetenceIds = state => state?.employees?.selectedPersonV2?.roleCompetenceIds || selectedPersonV2InitialState.roleCompetenceIds;

export const _getSelectedPersonV2PersonCompetences = state => state?.employees?.selectedPersonV2?.personCompetences || selectedPersonV2InitialState.personCompetences;

export const selectedPersonRolesDataSelector = createDeepEqualSelector(
  _getSelectedPersonV2Summary,
  _getSelectedPersonV2Roles,
  (summary, roles) => {
    const hasSummaryData = summaryKeys.some(section => !!summary?.[section]?.length);
    const hasRolesData = !!roles?.length;

    if (!hasSummaryData && !hasRolesData) return emptyRolesData;

    const sectionRoleIds = {};
    const allRolesData = {};

    for (const section of summaryKeys) {
      if (!summary?.[section]?.length) continue;

      sectionRoleIds[section] = new Set();

      for (const role of summary[section]) {
        const rid = getRoleId(role);

        if (rid == null) continue;

        sectionRoleIds[section].add(rid);

        const {
          files,
          description,
          type,
          missing_count,
          passed_count,
          startdate,
          enddate,
        } = role;

        if (!allRolesData[rid]) {
          const roleData = {
            id: rid,
            role_id: rid,
            type,
            title: getRoleTitle(role),
            description,
            startdate,
            enddate,
            ...!!files?.length && {files},
          };

          allRolesData[rid] = {
            ...filterNullishProps(roleData),
            sections: {},
          };
        }

        allRolesData[rid].sections[section] = {
          missing_count,
          passed_count,
        };
      }
    }

    if (hasRolesData) {
      for (const role of roles) {
        const rid = getRoleId(role);

        if (rid == null) continue;

        const {
          startdate,
          enddate,
          kiosk,
          future,
          is_position,
          optional_competences,
          required_competences,
          optional_passed_count,
          optional_missing_count,
          required_passed_count,
          required_missing_count,
          competence_ids,
        } = role;

        const roleData = {
          role_id: rid,
          title: getRoleTitle(role),
          type: getRoleType(role),
          startdate,
          enddate,
          future,
          kiosk,
          is_position,
        };

        const nonNullish = filterNullishProps(roleData);

        allRolesData[rid] = {
          ...allRolesData[rid],
          ...nonNullish,
        };

        const hasOptional = optional_passed_count != null
          || optional_missing_count != null
          || optional_competences?.length != null
          || competence_ids?.optional?.length != null;

        const hasRequired = required_passed_count != null
          || required_missing_count != null
          || required_competences?.length != null
          || competence_ids?.requirement?.length != null;

        if (!hasOptional && !hasRequired && !future) continue;

        if (!allRolesData[rid].sections) allRolesData[rid].sections = {};

        if (hasOptional) {
          if (sectionRoleIds.optional == null) {
            sectionRoleIds.optional = new Set();
          }

          sectionRoleIds.optional.add(rid);

          if (allRolesData[rid].sections?.optional?.passed_count == null) {
            allRolesData[rid].sections.optional = {
              passed_count: optional_passed_count ?? optional_competences?.filter?.(c => c?.passed === 100)?.length ?? 0,
              missing_count: optional_missing_count ?? (optional_competences?.length ?? 0) - (optional_passed_count ?? 0),
            };
          }
        }

        if (hasRequired || !hasOptional) {
          if (sectionRoleIds.requirement == null) {
            sectionRoleIds.requirement = new Set();
          }

          sectionRoleIds.requirement.add(rid);

          if (allRolesData[rid].sections?.requirement?.passed_count == null) {
            allRolesData[rid].sections.requirement = {
              passed_count: required_passed_count ?? required_competences?.filter?.(c => c?.passed === 100)?.length ?? 0,
              missing_count: required_missing_count ?? (required_competences?.length ?? 0) - (required_passed_count ?? 0),
            };
          }
        }
      }
    }

    return {
      roles: allRolesData,
      sectionRoleIds: setsObjectToArrayObject(sectionRoleIds),
    };
  },
);

export const competencesWithPersonCompetencesSelector = createDeepEqualSelector(
  getSelectedPersonV2Id,
  _getSelectedPersonV2Competences,
  _getSelectedPersonV2PersonCompetences,
  (personId, competences, personCompetencesMap) => {
    if (!personId || !personCompetencesMap?.[personId] || !isObjectWithKeys(competences)) return competences;

    const personCompetences = personCompetencesMap[personId];

    const data = {};

    for (const cid of Object.keys(competences)) {
      data[cid] = {
        ...personCompetences?.[cid]?.data,
        ...competences[cid]?.data,
        personCompetence: personCompetences?.[cid]?.data,
      };
    }

    return data;
  },
);

export const competenceTreeSelector = createDeepEqualSelector(
  competencesWithPersonCompetencesSelector,
  competences => buildCompetencesTreeV2(competences), // buildCompetencesTree(competences),
);

export const createSummarySectionRoleIdsSelector = memoize(sectionType => {
  const section = getRoleSectionType(sectionType, 'requirement');

  return createDeepEqualSelector(
    selectedPersonRolesDataSelector,
    ({roles, sectionRoleIds}) => sectionRoleIds?.[section]?.toSorted?.((aId, bId) => sortRoleByType(roles?.[aId], roles?.[bId])) || emptyArr,
  );
});

export const selectedPersonV2SectionRolesWithPassedInfoSelector = memoize(sectionType => {
  const section = getRoleSectionType(sectionType, 'requirement');

  return createSelector(
    selectedPersonRolesDataSelector,
    _getSelectedPersonV2RoleCompetenceIds,
    competenceTreeSelector,
    (
      {roles: rolesData},
      roleCompetenceIds,
      competences,
    ) => {
      if (!isObjectWithKeys(rolesData)) return emptyObj;

      const data = {};

      for (const rid of Object.keys(rolesData)) {
        const role = rolesData[rid];

        if (role?.role_id == null || !role.sections?.[sectionType]) continue;

        const {sections: roleSections} = role;

        let {
          missing_count,
          passed_count,
        } = roleSections?.[section] || {};

        const competenceIds = roleCompetenceIds?.[rid]?.[section];

        // If we have fetched competences for this role, count completed competences.
        // Otherwise, use passed_count from the summary role object.
        if (role.role_id > 0 && competenceIds?.length) {
          let childrenPassed = 0;
          let fetchedCompetenceData = false;

          for (const cid of competenceIds) {
            const {competence_id, passed} = competences?.[cid] || {};

            if (!competence_id) continue;
            fetchedCompetenceData = true;
            if (passed === 100) childrenPassed++;
          }

          if (fetchedCompetenceData) {
            missing_count = competenceIds.length - childrenPassed;
            passed_count = childrenPassed;
          }
        }

        data[rid] = {
          ...role,
          passed_count,
          missing_count,
          competence_ids: competenceIds,
        };
      }

      return data;
    },
  );
});

export const selectedPersonV2SingleRoleSelector = memoize((sectionType, roleId) => {
  const section = getRoleSectionType(sectionType, 'requirement');

  return createDeepEqualSelector(
    selectedPersonV2SectionRolesWithPassedInfoSelector(section),
    roles => roles?.[roleId] || emptyObj,
  );
});

export const selectedPersonV2SingleRoleWithChildrenSelector = memoize((sectionType, roleId) => {
  const section = getRoleSectionType(sectionType, 'requirement');

  return createDeepEqualSelector(
    selectedPersonV2SectionRolesWithPassedInfoSelector(section),
    competenceTreeSelector,
    (roles, competences) => {
      const role = roles?.[roleId];

      if (!role?.competence_ids?.length) return role || emptyObj;

      return {
        ...role,
        children: role.competence_ids.map(cid => competences?.[cid]).filter(Boolean),
      };
    },
  );
});

export const createRoleCompetenceSelector = memoize(cid => createDeepEqualSelector(
  competenceTreeSelector,
  tree => tree?.[cid] || emptyObj,
));

const selectProfileData = ({profile: {person: {data}}}) => data;

export const getSelectedPersonReport = state => state?.employees?.selectedPersonV2?.apiData?.report || selectedPersonV2InitialState.apiData.report;
export const getSelectedPersonEvents = state => state?.employees?.selectedPersonV2?.apiData?.events || selectedPersonV2InitialState.apiData.events;

// migrated selectors:
export const selectedPersonRequirementsCountSelector = createSelector(
  selectedPersonV2SectionRolesWithPassedInfoSelector('requirement'),
  (roles = {}) => {
    let totalRoles = 0;
    let totalCompetences = 0;

    for (const key of Object.keys(roles)) {
      const role = roles[key];

      if (!isTypeRoleOrOnboarding(role) && (!role?.is_position && getRoleType(role) !== 'crew')) continue;

      totalCompetences += (role.passed_count || 0) + (role.missing_count || 0);
      totalRoles++;
    }

    return {
      competences: totalCompetences,
      roles: totalRoles,
    };
  },
);

export const createPersonDataSelector = memoize(personId => createDeepEqualSelector(
  selectProfileData,
  _getSelectedPersonV2APIData,
  getEmployees,
  (profileData, selectedPersonData, employees) => {
    const isCurrentSelectedPerson = personId === selectedPersonData?.person?.person_id;
    const isMe = !!personId && profileData?.person_id === personId;

    const employee = employees.byId[personId]
      ?? (isMe && !!profileData && {
        ...profileData,
        profile_image: {url: getImageUrl(profileData?.profile_image?.url)},
      });

    if (!employee) return isCurrentSelectedPerson
      ? selectedPersonData.person
      : selectedPersonV2InitialState.apiData.person;

    const positions = isCurrentSelectedPerson
      && selectedPersonData?.positions
      || isMe && profileData?.positions
      || employee.active_personroles?.filter?.(roleIsPosition)
      || emptyArr;

    return {
      ...employee,
      mobile: cleanPhoneNumber(employee.mobile),
      positions,
      position: positions?.[0],
      pending_checklists: employee.pending_checklists || 0,
    };
  },
));
