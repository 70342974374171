import * as React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {useLocation} from 'react-router-dom/cjs/react-router-dom.min';
import {selectSessionId} from '@selectors/auth.selectors';
import {Route} from '@src/app.constants';

const LoginRedirect = () => {
  const referrer = useLocation();

  return (
    <Redirect
      to={{
        pathname: '/login',
        state: {referrer},
      }}
    />
  );
};

const PrivateRoute = props => {
  const sessionId = useSelector(selectSessionId);

  return React.useMemo(() => sessionId
    ? <Route {...props} />
    : <LoginRedirect />, [sessionId, props]);
};

export default PrivateRoute;
