import {createSelector} from 'reselect';
import * as T from '@types/load.types';
import {validIntOrNull} from '@utils/number.utils';
import {createOrGetConfigObject} from '../util/config.util';

export const selectConfig = ({config}) => config;

export const getIsConfigLoaded = createSelector(selectConfig, ({status}) => status === T.LoadStatuses.LOADED);

export const getConfigObject = createSelector(
  selectConfig,
  ({data, __meta}) => createOrGetConfigObject(data, __meta),
);

export const getDefaultImage = createSelector(getConfigObject, config => config.getProperty('params.default-img'));

export const getCurrTrack = createSelector(
  getConfigObject,
  configObject => validIntOrNull(localStorage.getItem('track'))
    ?? validIntOrNull(configObject.getProperty('params.default-track')),
);

export const getCurrLanguage = createSelector(getConfigObject, configObject => {
  const language = localStorage.getItem('language')
    || configObject.getProperty('params.default-language')
    || 'no';

  return language;
});

export const getPropertiesForCurrLangAndTrackSticker = createSelector(getConfigObject, configObject => {
  if (!configObject.data) {
    return null;
  }

  const track = localStorage.getItem('track')
    || configObject.getProperty('params.default-track');

  const language
    = localStorage.getItem('language')
    || configObject.getProperty('params.default-language')
    || 'no';

  return configObject.getProperty(`routes.atlas.configByTrackAndLang.${track}.${language}.verify.sticker`);
});

export const getPropertiesForCurrLangAndTrackBadge = createSelector(getConfigObject, configObject => {
  if (!configObject.data) {
    return null;
  }

  const track = localStorage.getItem('track')
    || configObject.getProperty('params.default-track');

  const language = localStorage.getItem('language')
    || configObject.getProperty('params.default-language')
    || 'no';

  return configObject.getProperty(`routes.atlas.configByTrackAndLang.${track}.${language}.badge`);
});

export const getPropertiesForCurrLangAndTrack = createSelector(getConfigObject, configObject => {
  if (!configObject?.data) return null;

  const track = localStorage.getItem('track')
    || configObject.getProperty('params.default-track');

  const language = localStorage.getItem('language')
    || configObject.getProperty('params.default-language')
    || 'no';

  try {
    const properties = {};
    // atlas-config (config for the map)
    const atlasConfigByMapIdAndLang = configObject.getProperty('routes.atlas.configByTrackAndLang');
    const atlasConfigForCurrTrack = atlasConfigByMapIdAndLang?.[track];
    const atlasConfigForCurrTrackAndLang = atlasConfigForCurrTrack?.[language];

    if (atlasConfigByMapIdAndLang) {
      properties.atlas = {redirectAfterOutroFinished: atlasConfigForCurrTrackAndLang?.outro?.redirectAfterFinished};
    }

    // == course catalog config
    const featuredTilesConfigByTrack = configObject.getProperty('routes.course-catalog.customToplevelSelector.featuredTiles.configByTrack');
    const courseCatalogConfigForCurrTrack = configObject.getProperty('routes.course-catalog.configByTrack')?.[track];
    const featuredTilesConfigForCurrTrack = !!featuredTilesConfigByTrack?.[track]
      && configObject.getProperty('routes.course-catalog.customToplevelSelector.featuredTiles.configByTrack')?.[track]?.featuredTiles
      || [];

    if (configObject.getProperty('routes.course-catalog.configByTrack')) {
      properties.courseCatalog = {
        ...courseCatalogConfigForCurrTrack,
        featuredTiles: featuredTilesConfigForCurrTrack,
      };
    }

    const menuConfig = configObject.getProperty('params.menu');
    const menuConfigByTrack = configObject.getProperty('params.menu.configByTrack');

    const menuConfigForMapId = menuConfigByTrack?.[track];
    const menuConfigForMapIdAndLanguage = menuConfigForMapId?.configByLang?.[language];

    properties.menu = {
      ...menuConfig,
      ...menuConfigForMapId,
      ...menuConfigForMapIdAndLanguage,
    };

    return properties;
  } catch (error) {
    alert(`Manglende eller feil konfigurering for løype med navn ${track}`);
    console.error(`Manglende konfigurering for løype med id ${track}`);
    console.error(error);

    return {};
  }
});

export const selectIsSelfSignEnabled = createSelector(
  getConfigObject,
  config => config.getProperty('person.self-sign'),
);

export const selectIsLearningPathsEnabled = createSelector(
  getConfigObject,
  config => config.getProperty('routes.my-education.widgets.learning-path.enabled'),
);

export const selectNewsDefaultCMSId = createSelector(
  getConfigObject,
  config => config.getProperty('routes.my-education.widgets.news.default-cms'),
);

// ConfigObject-properties (learningportal.json) used in Way:
// const uniqueStrs = [
//   'employees.manage',
//   'employees.person.competences',
//   'employees.person.roles.position',
//   'map.useHexagonSvg',
//   'params.access',
//   'params.customerType',
//   'params.default-img',
//   'params.default-lang',
//   'params.default-language',
//   'params.default-route',
//   'params.default-title-key',
//   'params.default-track',
//   'params.kiosk',
//   'params.login',
//   'params.map-img.backgroundPosition',
//   'params.menu',
//   'params.menu.access',
//   'params.menu.baseColorDesktop',
//   'params.menu.baseColorMobile',
//   'params.menu.configByCourseGroupId.${courseGroupId}.baseColor',
//   'params.menu.configByCourseGroupId.${courseGroupId}.gradient',
//   'params.menu.configByTrack',
//   'params.menu.configByTrack.${track}.configByLang.${lang}.logo',
//   'params.menu.data',
//   'params.menu.logo',
//   'params.menu.type',
//   'params.reloading-indicator-variant',
//   'params.sign-with',
//   'params.start-route',
//   'params.theme',
//   'person.cv',
//   'person.self-sign',
//   'reports.data.panelet.data',
//   'reports.links',
//   'routes.admin.tracks.simpleView',
//   'routes.ai-content-creator.background-colors',
//   'routes.ai-content-creator.enable',
//   'routes.ai-content-creator.layout.left.image',
//   'routes.ai-content-creator.tenant',
//   'routes.atlas.configByTrackAndLang',
//   'routes.atlas.configByTrackAndLang.${track}.${language}.badge',
//   'routes.atlas.configByTrackAndLang.${track}.${language}.outro.redirectAfterFinished',
//   'routes.atlas.configByTrackAndLang.${track}.${language}.verify.sticker',
//   'routes.atlas.trackAlias',
//   'routes.atlas.variant',
//   'routes.course-catalog.allowChildCompetenceForGroupIds',
//   'routes.course-catalog.autostartCourseTypes',
//   'routes.course-catalog.configByTrack',
//   'routes.course-catalog.customToplevelSelector.featuredTiles',
//   'routes.course-catalog.customToplevelSelector.featuredTiles.configByTrack.${trackId}.featuredTiles',
//   'routes.course-catalog.customToplevelSelector.featuredTiles.configByTrack.${trackId}.featuredTiles.contentIds',
//   'routes.course-catalog.customToplevelSelector.featuredTiles.configByTrack.${trackId}.outro',
//   'routes.course-catalog.disalowwedCompetenceGroupIds',
//   'routes.course-catalog.layout.sections',
//   'routes.course-catalog.layout.options',
//   'routes.course-catalog.startAtGroupId',
//   'routes.employees.profile.edit',
//   'routes.employees.profile.fields',
//   'routes.help.backend.defaultPath',
//   'routes.help.backend.searchUrl',
//   'routes.help.backend.url',
//   'routes.my-education.widgets.learning-path.enabled',
//   'routes.my-education.widgets.news.default-cms',
//   'routes.profile.enable',
//   'routes.{moduleName}.disable',
//   'routes.{moduleName}.enable',
// ];
