import axios from 'axios';
import {all, call, put, select, spawn, takeEvery} from 'redux-saga/effects';
import {
  competenceRemove,
  competenceRemoveFailure,
  competenceRemoveRequest,
  competenceRemoveSuccess,
  competenceSave,
  competenceSaveFailure,
  competenceSaveRequest,
  competencesToggle,
  competencesToggleFailure,
  competencesToggleRequest,
  competencesToggleSuccess,
} from '@actions/competences.actions';
import {coursesStartCourse} from '@actions/courses.actions';
import {
  employeesFetchChecklists,
  employeesFetchSelectedPerson,
  selectedPersonActions,
} from '@actions/employees.actions';
import {fetchMapCourses as fetchMapCoursesAction, selectMapCourse, setMapIsVerified} from '@actions/map.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import {profileFetchPerson, profileUpdatePassedCompetences} from '@actions/profile.actions';
import {backendUrl} from '@config';
import {
  competenceTreeSelector,
  getSelectedPersonV2Id,
  getSelectedPersonV2Username,
} from '@selectors/employees.selected-person.selectors';
import {getMapVerification} from '@selectors/map.selectors';
import {selectMapIsOutroCompleted, selectMapOutro} from '@selectors/map.selectors.new';
import {
  // getOrganisationId,
  getProfile,
  selectIsManager,
  selectProfileFullname,
  selectProfileId,
  selectProfileUserName,
} from '@selectors/profile.selectors';
import {i18n} from '@src/i18n';
import {getAllParentIds, isObjectWithKeys} from '@utils/misc.utils';
import {validIntOrNull} from '@utils/number.utils';
import {retry} from '@utils/sagas.utils';
import {getLocalTimestamp} from '@utils/time.utils';
import {fetchChildrenCompetencesAPI} from '../api/competences';
import {fetchPersonCompetenceAPI} from '../api/person-competences';
import {findCompetenceId, findPersonId} from '../util/competence-identity';
import {getParentTrackIdsFromCompetenceOrId} from './profile.sagas';

const CompetenceAxios = axios.create({
  headers: {'X-Grape-Lang': localStorage.getItem('language')},
  withCredentials: true,
});

const getMapId = obj => {
  if (!isObjectWithKeys(obj)) return null;

  const mapId = obj.mapId
    || obj.trackId
    || obj.competence?.mapId
    || obj.competence?.trackId
    || null;

  if (mapId) return Number(mapId);

  return null;
};

function* toggleCompetence(action) {
  const {
    trackId: trackIdPayload,
    type,
    pid,
    size,
    disableNotifications,
    disableStatusUpdateCheck,
    noRefetchCompetencesAfterPosting,
    onSuccess,
    onError,
    checkRaw,
    onEnd,
    showMapOutroIfRelevant,
  } = action?.payload || {};

  const competenceId = findCompetenceId(action?.payload);
  let mapId = getMapId(action?.payload);

  const isManager = yield select(selectIsManager);
  const profileId = yield select(selectProfileId);
  let userName = yield select(selectProfileUserName);
  const personId = pid || profileId;
  const isSelf = personId === profileId;
  const profileFullname = yield select(selectProfileFullname);

  try {
    const pathname = window.location.pathname;
    const isCheckedAsManager = size === 'dashboard' || isManager && ['both', 'manager'].includes(checkRaw);

    if (competenceId && !mapId) mapId = yield call(getParentTrackIdsFromCompetenceOrId, {competenceId});

    const trackId = trackIdPayload || mapId || null;

    yield put(competencesToggleRequest({
      personId: pid,
      cid: competenceId,
    }));

    let resp = null;
    let notificationSent = false;

    if (isCheckedAsManager) {
      if (competenceId == null) throw new Error('Missing competenceId');

      /* THIS IS EMPLOYEE, SO IT IS MANAGER THAT DOES THIS. */
      const action_name = `checklist_item_manager_${competenceId}`;

      resp = yield retry(() => CompetenceAxios.request({
        method: 'POST',
        url: `${backendUrl}/courses/setchecklistitem`,
        params: {
          name: action_name,
          value: true,
          person_id: pid,
        },
        withCredentials: true,
      }));

      if (pathname.includes('/dashboard')) {
        yield put(employeesFetchChecklists());
      } else if (!pathname.includes('/learning-path') && !pathname.includes('/atlas/')) {
        // this is when viewing employee in employee-modal
        userName = yield select(getSelectedPersonV2Username);

        yield put(employeesFetchSelectedPerson({
          userName,
          refresh: true,
          partialUpdate: {
            competencelevel: true,
            summary: true,
          },
        }));
      }

      if (!disableNotifications) {
        notificationSent = true;

        yield put(notificationsAdd({
          notification: {
            text: 'Sjekkliste er oppdatert.',
            color: 'green',
          },
        }));
      }
    }

    if (isSelf && (!checkRaw || ['both', 'either', 'self'].includes(checkRaw))) {
      try {
        resp = yield retry(() => CompetenceAxios.request({
          method: 'POST',
          url: `${backendUrl}/api/personcompetences`,
          params: {
            competence_id: competenceId,
            user_name: userName,
            passed: 100,
            action: 'on',
          },
          withCredentials: true,
        }));

        if (!disableNotifications && !notificationSent) {
          yield put(notificationsAdd({
            notification: {
              text: 'Sjekkliste er oppdatert.',
              color: 'green',
            },
          }));
        }
      } catch (error) {
        yield put(notificationsAdd({
          notification: {
            text: error.message,
            color: 'red',
          },
        }));
      }
    }

    const personCompetence = resp?.data?.personcompetences?.[0];
    const now = getLocalTimestamp();

    const manager_check = personCompetence?.manager_check ?? (isCheckedAsManager ? now : undefined);
    const self_check = personCompetence?.self_check ?? (isSelf ? now : undefined);

    yield put(competencesToggleSuccess({
      personId,
      cid: competenceId,
      mapId,
      trackId,
      type,
      data: {
        ...personCompetence,
        manager_check,
        self_check,
        is_check_manager_name: isCheckedAsManager && !isSelf
          ? profileFullname
          : undefined,
        passed: personCompetence?.passed ?? (isCheckedAsManager ? 100 : 0),
      },
      responseData: personCompetence,
    }));

    // check if this action did verify the map
    const mapVerification = yield select(getMapVerification);

    const verifiedMap = !!personCompetence?.id && !!mapVerification?.id
      && !personCompetence.cancelled && mapVerification.id === personCompetence.competence_id;

    if (verifiedMap) {
      // console.log('we should reload. At least open next dot?');
      yield put(setMapIsVerified());
      yield put(fetchMapCoursesAction({
        id: mapId,
        refetch: true,
        selectFirstDot: false,
        skipComponentUpdate: true,
      }));
    }
    const mapOutroId = (yield select(selectMapOutro))?.id;
    const shouldStartMapOutro = verifiedMap && showMapOutroIfRelevant
      && !!mapOutroId && !(yield select(selectMapIsOutroCompleted));

    if (isSelf && shouldStartMapOutro) {
      yield put(selectMapCourse({id: mapOutroId}));
      yield put(coursesStartCourse({
        cid: mapOutroId,
        type: 'nano',
        mapId,
      }));
    }

    if (isSelf && !noRefetchCompetencesAfterPosting) {
      yield put(profileUpdatePassedCompetences({
        disableStatusUpdateCheck,
        skipCloseCourse: shouldStartMapOutro,
      }));
    }

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (onError) onError();
    console.error(error);
    yield put(competencesToggleFailure({error}));
  }

  if (onEnd) onEnd();
}

function* removeCompetence(action) {
  const {data, type, callback, onError, onSuccess, onEnd, notify = true} = action.payload || {};
  const {orgId, person_id, course_id, userName} = data || {};

  if (type === 'RESET_PERSON_COMPETENCES') { // todo: move to separate action/saga
    let empty = true;

    try {
      const {person_ids} = data || {};

      if (!person_ids?.length) throw new Error('missing person_ids');

      const errors = [];

      for (const pid of person_ids) {
        const competenceIds = yield retry(() => CompetenceAxios.request({
          method: 'GET',
          url: `${backendUrl}/reports/personfullcompetencereportjson/${pid}?fields=competence(competence_id)`,
          withCredentials: true,
        }).then(res => res.data?.competences?.map?.(({competence}) => validIntOrNull(competence?.competence_id))?.filter?.(id => !!id)));

        if (!competenceIds?.length) continue;

        empty = false;

        const uniqueIds = [...new Set(competenceIds)];

        yield all(uniqueIds.map(function*(id) {
          let err;

          try {
            const res = yield retry(() => CompetenceAxios.request({
              method: 'POST',
              url: `${backendUrl}/persons/remove_competence/${orgId}/${pid}/${id}`,
              params: {json: 1},
              withCredentials: true,
            }));

            if (!res.data.valid) {
              err = res.data;
            }
          } catch (error) {
            err = error;
          }

          if (err) {
            errors.push(err);
          }
        }));
      }

      if (errors.length) {
        console.error(errors);

        yield put(notificationsAdd({
          notification: {
            color: 'red',
            text: 'Feil: ' + errors.length + ' personkompetanse' + (errors.length > 1 && 'r' || '') + ' kunne ikke slettes. Se konsollen for detaljer.',
          },
        }));

        if (onError) onError(errors);
      } else if (!empty) {
        if (onSuccess) onSuccess();
        yield put(notificationsAdd({
          notification: {
            text: 'Slettet alle personkompetanser for valgte personer.',
            color: 'green',
          },
        }));
      }
    } catch (error) {
      console.error(error);

      if (onError) onError(error);

      yield put(notificationsAdd({
        notification: {
          text: 'Noe gikk galt.',
          color: 'red',
        },
      }));
    }

    if (empty) {
      yield put(notificationsAdd({notification: {text: 'Ingen personkompetanser å slette.'}}));
    }

    if (onEnd) onEnd();

    return;
  }

  try {
    yield put(competenceRemoveRequest({type}));

    if (type) {
      yield put(employeesFetchSelectedPerson.request({partialUpdate: {summary: true}}));
    }

    const resp = yield retry(() => CompetenceAxios.request({
      method: 'POST',
      url: `${backendUrl}/persons/remove_competence/${orgId}/${person_id}/${course_id}`,
      params: {json: 1},
      withCredentials: true,
    }));

    if (resp.data.valid) {
      yield put(competenceRemoveSuccess({
        cid: course_id,
        person_id,
        userName,
        type,
        notify,
      }));

      if (callback) callback();
    } else {
      yield put(competenceRemoveFailure());
      yield put(notificationsAdd({
        notification: {
          text: i18n('person.competence-unable-removed'),
          color: 'red',
        },
      }));
      if (type) yield put(employeesFetchSelectedPerson.success());
    }
  } catch (error) {
    console.error(error);
    yield put(competenceRemoveFailure({error}));
    if (onError) {
      onError(error);
    }
  }
}

const FILE_UPLOAD_AXIOS_CONFIG = {
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'multipart/form-data',
  },
};

function* saveCompetence(action) {
  const {
    data = {},
    files,
    role,
    onEnd,
  } = action?.payload || {};

  const cid = findCompetenceId(action?.payload);
  const selectedPersonId = yield select(getSelectedPersonV2Id);
  const pid = findPersonId(action?.payload) || selectedPersonId;

  try {
    yield put(competenceSaveRequest({role}));

    const formData = new FormData();

    if (files) {
      if (files.length === 1) {
        files.forEach(file =>
          formData.append('certificate', file));
      } else {
        files.forEach(file =>
          formData.append('certificates', file));
      }
    }

    formData.append('json', 1);
    formData.append('person_id', data.person_id);
    formData.append('course_id', data.course_id);
    formData.append('comment', data.comment);
    formData.append('valid_until', data.valid_until);
    formData.append('verifieddate', data.verifieddate);
    formData.append('registered_manually', 'on');

    /*

    json: 1,
      person_id: data.person_id,
      course_id: data.course_id,
      comment: data.comment,
      valid_until: data.valid_until,
      verifieddate: data.verifieddate,
      registered_manually: 'on',

    const {data: responseData} = yield api.post(`files/savemanagefiles/${type}/${id}`, formData, FILE_UPLOAD_AXIOS_CONFIG);
*/
    const instance = axios.create({baseURL: backendUrl});

    instance.defaults.withCredentials = true;

    const {data: responseData} = yield instance.post(
      `/persons/updatecompetence/${data.phceId}/${data.orgId}`,
      formData,
      {...FILE_UPLOAD_AXIOS_CONFIG},
    );

    yield put(notificationsAdd({
      notification: {
        text: i18n('person.user-updated-success'),
        color: 'green',
      },
    }));

    const {data: profile} = yield select(getProfile);

    if (data.userName === profile?.user_name) {
      yield put(profileFetchPerson({refresh: true}));
    }

    if (pid === selectedPersonId) {
      const competences = yield select(competenceTreeSelector);
      const competence = competences[cid] || {};

      const parentIds = getAllParentIds(cid, competences);

      if (parentIds.length) {
        yield put(selectedPersonActions.fetchPersonCompetence.success({
          pid,
          cid,
          data: {
            ...data,
            competence_id: cid,
            passed: 100,
            ...responseData,
          },
        }));

        const selectedPersonUsername = yield select(getSelectedPersonV2Username);

        // for (const parentId of parentIds) {
        //   yield spawn(fetchChildrenCompetencesAPI, {
        //     payload: {
        //       pid,
        //       username: selectedPersonUsername,
        //       cid: parentId,
        //       successAction: selectedPersonActions.fetchChildrenCompetences.success,
        //     },
        //   });
        // }

        // if (parentIds.length) {
        const calls = [];

        for (const parentId of parentIds) {
          calls.push(call(fetchChildrenCompetencesAPI, {
            payload: {
              pid,
              username: selectedPersonUsername,
              cid: parentId,
              successAction: selectedPersonActions.fetchChildrenCompetences.success,
            },
          }));
        }

        yield all(calls);

        yield spawn(fetchPersonCompetenceAPI, {
          payload: {
            pid,
            username: selectedPersonUsername,
            batch: parentIds,
            successAction: selectedPersonActions.fetchPersonCompetence.success,
          },
        });
      }

      yield put(employeesFetchSelectedPerson({
        userName: data.userName,
        refresh: true,
        updatePartial: {competencelevel: true},
      }));
    }

    if (onEnd) onEnd();
  } catch (error) {
    console.error(error);
    yield put(competenceSaveFailure({error}));
  }
}

function* watchCompetenceRemoved(action) {
  const {cid, person_id, type, userName, notify} = action.payload || {};

  if (notify !== false) yield put(notificationsAdd({
    notification: {
      text: i18n('person.competence-removed'),
      color: 'green',
    },
  }));

  if (type === 'RESET_PERSON_COMPETENCES') {
    return;
  }

  const selectedPersonId = yield select(getSelectedPersonV2Id);

  if (type && person_id && selectedPersonId === person_id) {
    yield put(selectedPersonActions.removePersonCompetence.success({competence_id: cid}));

    const competences = yield select(competenceTreeSelector);
    const parentIds = getAllParentIds(cid, competences);

    const selectedPersonUsername = yield select(getSelectedPersonV2Username);

    if (parentIds.length) {
      const calls = [];

      for (const parentId of parentIds) {
        calls.push(call(fetchChildrenCompetencesAPI, {
          payload: {
            pid: person_id,
            username: selectedPersonUsername,
            cid: parentId,
            successAction: selectedPersonActions.fetchChildrenCompetences.success,
          },
        }));
      }

      yield all(calls);

      yield spawn(fetchPersonCompetenceAPI, {
        payload: {
          pid: person_id,
          username: selectedPersonUsername,
          batch: parentIds,
          successAction: selectedPersonActions.fetchPersonCompetence.success,
        },
      });
    }
  }

  const profileUserName = yield select(selectProfileUserName);

  if (!userName || userName === profileUserName) {
    yield put(profileFetchPerson({refresh: true}));
  }
  if (userName) {
    yield put(employeesFetchSelectedPerson({
      userName,
      refresh: true,
      partialUpdate: {
        competencelevel: true,
        summary: true,
      },
    }));
  }
}

const exportObj = [
  takeEvery(competencesToggle().type, toggleCompetence),
  takeEvery(competenceSave().type, saveCompetence),
  takeEvery(competenceRemoveSuccess().type, watchCompetenceRemoved),
  takeEvery(competenceRemove().type, removeCompetence),
];

export default exportObj;
