import Cookies from 'js-cookie';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {profileFetchPerson, profileSetActiveOrgId} from '@actions/profile.actions';
import {THEME_SET_CUSTOM_THEME, themeSetCSSVars, themeSetCustomTheme} from '@actions/theme.actions';
import {getBrandId} from '@selectors/profile.selectors';
import {createCustomTheme} from '@styles/theme-objects/util/create-custom-theme';
import {isObjectWithKeys} from '@utils/misc.utils';
import {validIntOrNull} from '@utils/number.utils';
import {retry} from '@utils/sagas.utils';
import {defaultThemeKey, getThemeCSSVarsByThemeKey, getThemeKeyByBrandId, getValidThemeKey, styleInjectThemeCSSVars} from '@utils/themes.utils';
import {fetchOrgCustomThemeAPI} from '../api/theme';
import {waitForOrgId} from './app.sagas';
import {waitForConfigObject} from './config.sagas';

const themeObjectMap = {
  red: () => import(/* webpackChunkName: "theme.red" */ '@styles/theme-objects/red'),
  gray: () => import(/* webpackChunkName: "theme.gray" */ '@styles/theme-objects/gray'),
  green: () => import(/* webpackChunkName: "theme.green" */ '@styles/theme-objects/green'),
  orange: () => import(/* webpackChunkName: "theme.orange" */ '@styles/theme-objects/orange'),
  blue: () => import(/* webpackChunkName: "theme.blue" */ '@styles/theme-objects/blue'),
};

// function* setOrganisationCustomTheme(action) {
//   const theme = action?.payload?.theme;

//   if (!isObjectWithKeys(theme)) return;

//   const orgId = action?.payload?.orgId || (yield call(waitForOrgId, true));

//   try {
//     const {data} = yield retry(() => axios.request({
//       method: 'POST',
//       url: `${backendUrlV2}/organisation/${orgId}/theme`,
//       data: theme,
//       withCredentials: true,
//     }));

//     if (data?.success) {
//       yield put(themeSetCustomTheme({
//         themeKey: 'custom',
//         customTheme: theme,
//       }));

//       yield put(notificationsAdd({
//         notification: {
//           text: 'Temainnstillinger lagret for organisasjonen',
//           color: 'red',
//         },
//       }));
//     } else {
//       throw new Error('Failed to set custom theme');
//     }
//   } catch (error) {
//     console.error(error);

//     yield put(notificationsAdd({
//       notification: {
//         text: 'En feil oppstod ved lagring av tema',
//         color: 'red',
//       },
//     }));
//   }
// }

async function lazyLoadThemeObject(themeKey) {
  if (!themeObjectMap[themeKey]) return {};

  try {
    const themeObject = await themeObjectMap[themeKey]()
      .then(module => module.default);

    return themeObject;
  } catch (error) {
    console.error(error);

    return {};
  }
}

function setLocalStorageThemeKey(themeKey) {
  localStorage.setItem('theme', themeKey);
}

function* injectCSSVarsByThemeKey(themeKey) {
  try {
    const key = getValidThemeKey(themeKey);
    const cssVars = getThemeCSSVarsByThemeKey(themeKey);

    setLocalStorageThemeKey(key);
    yield put(themeSetCSSVars({cssVars}));
    styleInjectThemeCSSVars(cssVars);
  } catch (error) {
    console.error(error);
  }
}

function* setCSSVarsByActiveBrandId() {
  try {
    const brandId = yield select(getBrandId);

    localStorage.setItem('brandId', brandId);

    const cssVars = getThemeKeyByBrandId(brandId);

    yield call(injectCSSVarsByThemeKey, cssVars);
  } catch (error) {
    console.error(error);
  }
}

function* fetchOrganisationCustomTheme(action) {
  const orgId = validIntOrNull(action?.payload?.orgId || action?.payload) || (yield call(waitForOrgId, true));
  const themeKeyArg = action.payload?.key;

  try {
    const customThemeData = yield retry(() => fetchOrgCustomThemeAPI({orgId}));

    const configObject = yield call(waitForConfigObject, true);
    const configThemeKey = configObject.getProperty('params.theme');

    const themeKey = themeKeyArg ?? (configThemeKey ? getValidThemeKey(configThemeKey) : defaultThemeKey);
    const baseThemeObject = yield call(lazyLoadThemeObject, themeKey);

    const customTheme = isObjectWithKeys(customThemeData)
      ? createCustomTheme(customThemeData)
      : {};

    yield put(themeSetCustomTheme({
      themeKey,
      orgId,
      customTheme: {
        ...baseThemeObject,
        ...customTheme,
      },
    }));
  } catch (error) {
    console.error(error);
  }
}

// eslint-disable-next-line require-yield
function* watchCustomTheme(action) {
  // images are located at different locations, so we need to use the proper one
  const defaultLogoWaymaker = '/images/snapper.svg';
  const defaultLogoGrape = '/static/assets/logo.svg';

  Cookies.set('menuLogo', (action.payload?.customTheme?.logoUrl || '') .replace(defaultLogoWaymaker, defaultLogoGrape) || '', {domain: '.snapper.no'});
  Cookies.set('menuBgColor', action.payload?.customTheme.mainMenuBackgroundColor || '', {domain: '.snapper.no'});
  Cookies.set('menuTxtColor', action.payload?.customTheme.mainMenuColor || '', {domain: '.snapper.no'});
  Cookies.set('menuIconBgColor', action.payload?.customTheme.scheme?.primary.color100, {domain: 'snapper.no'});
  Cookies.set('menuIconColor', action.payload?.customTheme.scheme?.primary.color700, {domain: 'snapper.no'});
}

const exportObj = [
  takeLatest(`${profileFetchPerson.success}`, setCSSVarsByActiveBrandId),
  takeLatest(profileSetActiveOrgId, fetchOrganisationCustomTheme),
  takeLatest(THEME_SET_CUSTOM_THEME, watchCustomTheme),
];

export default exportObj;
