/* eslint-disable unicorn/no-for-loop */
import {original, produce} from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import {AUTH_LOGOUT_SUCCESS} from '@actions/auth.actions';
import {COMPETENCES_TOGGLE_FAILURE, COMPETENCES_TOGGLE_REQUEST, COMPETENCES_TOGGLE_SUCCESS} from '@actions/competences.actions';
import {RESET_APP, RESET_STORE} from '@actions/global.actions';
import * as mapCoursesActions from '@actions/map.actions';
import * as T from '@types/load.types';
import {emptyArr, emptyObj} from '@utils/constants';
import {isObjectWithKeys, mergeCompetencesNonNullish} from '@utils/misc.utils';
import {findCompetenceId} from '../util/competence-identity';

export const initialState = {
  track: {
    data: emptyObj,
    tracks: emptyArr, // todo: rename to children
    status: T.LoadStatuses.NOT_LOADED,
    parentTrack: {
      data: emptyObj,
      tracks: emptyArr, // todo: rename to children
      status: T.LoadStatuses.NOT_LOADED,
    },
  },
  mapCourses: {
    isFetching: false,
    data: null,
    error: null,
    mapIsVerified: false,
    outroIsDone: null,
    status: T.LoadStatuses.NOT_LOADED,
  },
  mapVerificationCompetence: {
    isFetching: false,
    data: null,
    error: null,
  },
  selectedMapCourse: {data: null},
  competenceById: emptyObj,
  childrenIdsByCompetenceId: emptyObj,
  toggleCompetenceProgress: emptyObj,

  extraDataByCompetenceId: emptyObj, // [competenceId]: {
  //   data: {},
  //   lastFetched: 0,
  //   status: T.LoadStatuses.NOT_LOADED,
  //   error: null,
  // },

  completeExtraDataStatusByTrackId: emptyObj, // [trackId]: LoadStatuses.NOT_LOADED,
};

const openOrDone = new Set(['OPEN', 'DONE']);
const isOpenOrDone = dott => openOrDone.has(dott.status) || openOrDone.has(dott.real_status);

const map = (state = initialState, action) => {
  switch (action.type) {
  case AUTH_LOGOUT_SUCCESS:
  case RESET_APP:
  case RESET_STORE: {
    return initialState;
  }
  case mapCoursesActions.MAP_UNLOCK_NEXT_DOTT: {
    const current = state.mapCourses?.data?.tracks?.[0]?.dotts;

    let idxOfFirstOpen = current?.findIndex?.(dott => dott.status === 'OPEN');

    if (idxOfFirstOpen === undefined || idxOfFirstOpen === -1) return state;

    const next = produce(current, draft => {
      draft[idxOfFirstOpen].status = 'DONE';
      draft[idxOfFirstOpen].real_status = 'DONE';

      while (idxOfFirstOpen + 1 < current.length) {
        if (current[idxOfFirstOpen + 1].real_status === 'LOCKED') {
          draft[idxOfFirstOpen + 1].status = 'OPEN';
          draft[idxOfFirstOpen + 1].real_status = 'OPEN';

          return;
        }

        if (isOpenOrDone(current[idxOfFirstOpen + 1])) {
          draft[idxOfFirstOpen + 1].status = 'DONE';
          draft[idxOfFirstOpen + 1].real_status = 'DONE';
        }

        idxOfFirstOpen++;
      }
    });

    return {
      ...state,
      mapCourses: {
        ...state.mapCourses,
        data: {
          ...state.mapCourses.data,
          tracks: [
            {
              ...state.mapCourses.data.tracks[0],
              dotts: next,
            },
          ],
        },
      },
    };
  }
  case mapCoursesActions.FETCH_TRACK_EXTRA_DATA_REQUEST: {
    const {
      trackId,
      cids = [],
      all = false,
    } = action.payload || {};

    return produce(state, draft => {
      if (all && trackId) {
        draft.completeExtraDataStatusByTrackId[trackId] = T.LoadStatuses.IS_LOADING;
      }

      if (!cids?.length) return;

      cids.forEach(cid => {
        draft.extraDataByCompetenceId[cid] = {
          ...draft.extraDataByCompetenceId[cid],
          error: null,
          status: T.LoadStatuses.IS_LOADING,
        };
      });
    });
  }
  case mapCoursesActions.FETCH_TRACK_EXTRA_DATA_SUCCEEDED: {
    if (!action.payload) state;

    const {
      trackId,
      joinedData = {},
      all = false,
    } = action.payload || {};

    const cids = isObjectWithKeys(joinedData)
      ? Object.keys(joinedData)
      : [];

    if (!cids.length && !all) return state;

    return produce(state, draft => {
      if (all && trackId) {
        draft.completeExtraDataStatusByTrackId[trackId] = T.LoadStatuses.LOADED;
      }
      if (!cids.length) return;

      for (const cid in cids) {
        draft.extraDataByCompetenceId[cid] = {
          ...draft.extraDataByCompetenceId[cid],
          data: joinedData[cid],
          status: T.LoadStatuses.LOADED,
          lastFetched: Date.now(),
        };
      }
    });
  }
  case mapCoursesActions.FETCH_TRACK_EXTRA_DATA_FAILED: {
    const {cids = [], trackId, all = false, error, ...rest} = action.payload || {};

    return produce(state, draft => {
      if (all && trackId) {
        draft.completeExtraDataStatusByTrackId[trackId] = T.LoadStatuses.FAILED;
      }
      if (!cids?.length) return;

      cids.forEach(cid => {
        draft.extraDataByCompetenceId[cid] = {
          ...draft.extraDataByCompetenceId[cid],
          error,
          status: T.LoadStatuses.FAILED,
        };
      });
    });
  }
  case COMPETENCES_TOGGLE_REQUEST: {
    const {cid} = action.payload || {};

    return {
      ...state,
      toggleCompetenceProgress: {
        ...state.toggleCompetenceProgress,
        [cid]: true,
      },
    };
  }
  case COMPETENCES_TOGGLE_FAILURE:
  case COMPETENCES_TOGGLE_SUCCESS: {
    const {cid} = action.payload || {};

    return {
      ...state,
      toggleCompetenceProgress: {
        ...state.toggleCompetenceProgress,
        [cid]: false,
      },
    };
  }
  case mapCoursesActions.INSERT_OR_UPDATE_CHILDREN_COMPETENCES: {
    const {parentId, children = {}} = action.payload || {};
    const {byId = {}, data = []} = children || {};

    let newById = null;
    let newData = null;

    if (isObjectWithKeys(byId)) newById = byId;
    if (data?.length) newData = data;

    if (!newById && !newData) return state;

    const prevById = state.competenceById;

    if (!newById) {
      newById = data?.reduce?.((acc, item) => {
        const cid = findCompetenceId(item);

        if (cid == null) return acc;

        const prev = prevById[cid];

        acc[cid] = mergeCompetencesNonNullish(cloneDeep(original(prev)), item);

        return acc;
      }, {}) || emptyObj;
    } else if (!newData) {
      newData = Object.values(byId || {});
    }

    return produce(state, draft => {
      draft.competenceById = {
        ...draft.competenceById,
        ...newById,
      };

      if (parentId == null) return;

      draft.childrenIdsByCompetenceId[parentId] = [
        ...(draft.childrenIdsByCompetenceId[parentId] || []).filter(id => !newById[id]),
        ...newData.map(item => item?.competence_id || item?.id) || [],
      ].filter(Boolean);
    });
  }
  case mapCoursesActions.TRACK_SET_COMPETENCE_CHILDREN: {
    const {id, data} = action.payload;

    if (!id || !data?.length || !state?.track?.tracks?.length) return state;

    const targetIndex = state.track.tracks.findIndex(track => findCompetenceId(track) === id);

    if (targetIndex === -1) return state;

    const newState = produce(state.track.tracks, draft => {
      draft[targetIndex].children = data;
    });

    return {
      ...state,
      track: {
        ...state.track,
        tracks: newState,
      },
    };
  }
  case mapCoursesActions.FETCH_MAP_COMPETENCE_REQUEST: {
    return {
      ...state,
      selectedMapCourse: {
        ...state.selectedMapCourse,
        data: {
          ...state.selectedMapCourse.data,
          request: T.LoadStatuses.IS_LOADING,
        },
      },
    };
  }

  case mapCoursesActions.FETCH_TRACK_REQUEST: {
    return {
      ...state,
      track: {
        ...state.track,
        status: T.LoadStatuses.IS_LOADING,
      },
    };
  }

  case mapCoursesActions.FETCH_TRACK_SUCCEEDED: {
    if (!action.payload?.data) return state;

    return {
      ...state,
      track: {
        ...state.track,
        status: action.payload.skipLoadStatus
          ? state.track.status
          : T.LoadStatuses.LOADED,
        ...action.payload.data === undefined
          ? {}
          : {data: action.payload.data || initialState.track.data},
        ...action.payload.tracks === undefined
          ? {}
          : {tracks: action.payload.tracks || initialState.track.tracks},
        ...action.payload.parentTrack === undefined
          ? {}
          : {parentTrack: action.payload.parentTrack || initialState.track.parentTrack},
      },
    };
  }

  case mapCoursesActions.FETCH_TRACK_FAILED: {
    return {
      ...state,
      track: {
        ...state.track,
        status: T.LoadStatuses.FAILED,
        errorReason: action.payload.errorReason,
      },
    };
  }

  case mapCoursesActions.FETCH_MAP_COMPETENCE_SUCCEEDED: {
    return {
      ...state,
      selectedMapCourse: {
        ...state.selectedMapCourse,
        data: {
          ...state.selectedMapCourse.data,
          files: action.payload.data.files,
          request: T.LoadStatuses.LOADED,
        },
      },
    };
  }
  case mapCoursesActions.FETCH_MAP_COURSES_REQUEST: {
    return {
      ...state,
      mapCourses: {
        ...state.mapCourses,
        isFetching: true,
        status: action?.payload?.refetch
          ? T.LoadStatuses.IS_LOADING_MORE
          : T.LoadStatuses.IS_LOADING,
      },
    };
  }
  case mapCoursesActions.SET_MAP_IS_VERIFIED:
  case mapCoursesActions.SET_VERIFICATION_SUCCESS: {
    return {
      ...state,
      mapCourses: {
        ...state.mapCourses,
        mapIsVerified: true,
      },
    };
  }
  case mapCoursesActions.FETCH_MAP_COURSES_SUCCEEDED: {
    return {
      ...state,
      mapCourses: {
        ...state.mapCourses,
        error: null,
        data: action.payload.data,
        isFetching: false,
        status: T.LoadStatuses.LOADED,
      },
    };
  }

  case mapCoursesActions.FETCH_MAP_COURSES_FAILED: {
    return {
      ...state,
      mapCourses: {
        ...initialState.mapCourses,
        error: action.payload.error,
        isFetching: false,
        status: T.LoadStatuses.FAILED,
      },
    };
  }

  case mapCoursesActions.FETCH_MAP_VERIFICATION_COMPETENCE_REQUEST: {
    return {
      ...state,
      mapVerificationCompetence: {
        ...state.mapVerificationCompetence,
        isFetching: true,
      },
    };
  }
  case mapCoursesActions.FETCH_MAP_VERIFICATION_COMPETENCE_SUCCEEDED: {
    return {
      ...state,
      mapVerificationCompetence: {
        ...state.mapVerificationCompetence,
        error: null,
        data: action.payload.data,
        isFetching: false,
      },
    };
  }

  case mapCoursesActions.FETCH_MAP_VERIFICATION_COMPETENCE_FAILED: {
    return {
      ...state,
      mapVerificationCompetence: {
        ...initialState.mapVerificationCompetence,
        error: action.payload.error,
        isFetching: false,
      },
    };
  }

  // case mapCoursesActions.SET_MAP_IS_VERIFIED: {
  //   return {
  //     ...state,
  //     mapCourses: {
  //       ...state.mapCourses,
  //       mapIsVerified: true,
  //     },
  //   };
  // }

  case mapCoursesActions.SELECT_MAP_COURSE: {
    return {
      ...state,
      selectedMapCourse: {
        ...state.selectedMapCourse,
        data: action.payload,
      },
    };
  }

  case mapCoursesActions.SET_OUTRO_IS_COMPLETED: {
    return {
      ...state,
      mapCourses: {
        ...state.mapCourses,
        outroIsDone: true,
      },
    };
  }

  case mapCoursesActions.SET_OUTRO_IS_NOT_COMPLETED: {
    return {
      ...state,
      mapCourses: {
        ...state.mapCourses,
        outroIsDone: false,
      },
    };
  }

  case mapCoursesActions.SET_MAP_IS_NOT_VERIFIED: {
    return {
      ...state,
      mapCourses: {
        ...state.mapCourses,
        mapIsVerified: false,
      },
    };
  }
  default: {
    return state;
  }
  }
};

export default map;
