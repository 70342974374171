export const en = {
  globals: {
    to: 'To',
    from: 'From',
    'no-period-selected': 'No time period selected',
    'change-period': 'Change period',
    close: 'Close',
    submit: 'Submit',
    'error-loading-competence': 'Error loading competence',
    'profile-image-delete-success': 'Profile image deleted',
    resources: 'Resources',
    'your-resources': 'Your resources',
    'no-employees-selected': 'No employees selected',
    'loading-progression': 'Loading progression',
    completions: 'Completions',
    'delete-x': 'Delete [x]',
    'delete-confirm': 'Are you sure you want to delete this item?',
    'delete-confirm-no-undo': 'Are you sure you want to delete this item? This action cannot be undone.',
    'delete-confirm-x': 'Are you sure you want to delete [x]?',
    'delete-confirm-x-no-undo': 'Are you sure you want to delete [x]? This action cannot be undone.',
    'x-deleted': '[x] deleted',
    'action-no-undo': 'This action cannot be undone.',
    'error-occurred': 'An error occurred',
    'error-try-again': 'An error occurred. Please try again.',
    'all-completions': 'All completions',
    'show-all-completions': 'Show all completions',
    'invalid-phone-number': 'Invalid phone number',
    reflection: 'Reflection',
    text: 'Text',
    bulletpoints: 'Bulletpoints',
    quiz: 'Quiz',
    user: 'User',
    tasks: 'Tasks',
    your: 'Your',
    deadline: 'Deadline',
    play: 'Play',
    sign: 'Sign',
    email: 'Email',
    locked: 'Locked',
    show: 'Show',
    register: 'Register',
    start: 'Start',
    scale: 'Scale',
    textfield: 'Textfield',
    'dott-element': '«Dot» (element)',
    question: 'Question',
    evaluation: 'Evaluation',
    'input-missing-fields': 'Please fill in all required fields',
    role: 'Role',
    competence: 'Competence',
    opens: 'Opens',
    type: 'Type',
    'last-changed': 'Last changed',
    completion: 'Completion',
    'this-completion': 'This completion',
    'all-completions-of-this-competence': 'All completions of this competence',
    'no-users': 'No users',
    'opened-after': 'Is opened after',
    'add-image': 'Upload image',
    active: 'Active',
    deactivated: 'Deactivated',
    'save-close': 'Save and close',
    minutesShort: 'min',
    'new': 'New',
    create: 'Add new',
    'color-code': 'Color code',
    no: 'No',
    yes: 'Yes',
    editing: 'Editing',
    'theme-pick': 'Choose a theme',
    'hex-value': 'or enter a hex value',
    'company-logo': 'Company Logo',
    'company-logo-upload-txt': 'Click to upload your logo',
    open: 'Open',
    progression: 'Progression',
    cancel: 'Cancel',
    logout: 'Logout',
    search: 'Search',
    save: 'Save',
    'save-theme': 'Save theme',
    'saved-success': 'Saved!',
    back: 'Back',
    done: 'Done',
    hours: 'Hours',
    'something-went-wrong': 'Something went wrong',
    'track-done': 'You have finished all items',
    'enter-text': 'Enter more than 3 chars',
    all: 'All',
    next: 'Next',
    previous: 'Previous',
    'next-page': 'Next page',
    'the-competence': 'The competence',
    'previous-page': 'Previous page',
    'first-page': 'First page',
    'last-page': 'Last page',
    confirm: 'Send',
    now: 'Now',
    grade: 'Grade',
    'has-read': 'Read',
    you: 'You',
    updating: 'Updating',
    'requirement-deleted': 'The requirement is removed',
    download: 'Download',
    'clear-search': 'Clear search',
    fetching: 'Fetching data',
    'create-new': 'Create new',
    hide: 'Hide',
    approve: 'Approve',
    choose: 'Choose',
    'fetching-data': 'Collecting data',
    add: 'Add',
    loading: 'Loading',
    'saving-data': 'Saving data',
    saving: 'Saving',
    'loading-page': 'Loading',
    'loading-nano': 'Loading bit-learning',
    'show-all': 'View all',
    'required-field': 'Required field',
    'read-more': 'Read more',
    ok: 'OK',
    and: 'and',
    others: 'others',
    'delete': 'Delete',
    deleting: 'Deleting',
    edit: 'Edit',
    name: 'Name',
    'go-back': 'Go back',
    'last-updated': 'Last updated',
    competencelevel: 'Competence level',
    'unsupported-browser': 'Du er i en nettleser som ikke støttes. For å komme til den nye Læringsportalen må du åpne en annen nettleser, Chrome eller Edge, utenfor Citrix-miljøet.',
    'invalid-year-field': 'Invalid year',
    about: 'About',
    change: 'Change',
    'invalid-date': 'Invalid date',
    date: 'Date',
    duration: 'Duration',
    allok: 'Confirm',
    'toggle-datepicker': 'Toggle date picker',
    'sort-alpha': 'A-Z',
    'sort-alpha-desc': 'Z-A',
    'you-sure': 'Are you sure?',
    password: 'Password',
    username: 'Username',
    'open-close-toggle': 'Open/Close',
    send: 'Send',
    choice: 'Choice',
    move: 'Move',
    completed: 'Completed',
    'view-all': 'View all',
    'mandatory-field': 'Required field',
    searching: 'Searching',
    'no-results-found': 'No results found',
    'no-hits-for-search-x': 'The search "[x]" gave no results',
    untitled: 'Untitled',
    'course-types': {
      verification: 'Verification',
      ecourse: 'E-Course',
      checklist: 'Checklist',
      course: 'Course',
      appraisal: 'Appraisal',
      practical_training: 'Practical training',
      other_education_and_training: 'Other training',
      formalcompetence: 'Formal Competence',
      digital_signature: 'Digital Signature',
      complex: 'Complex Competence',
      equivalents: 'Competence Equivalents',
      nano: 'Nano',
      certificate: 'Certificate',
      checklist_item: 'Checklist item',
      track: 'Track',
      content: 'Content',
      Content: 'Content',
      group: 'Module course',
      skill: 'Skill',
    },
    'avg-reading-time': 'Avg. time',
    error: 'Error',
    'click-to-expand': 'Click to expand',
    'click-to-collapse': 'Click to collapse',
    'goto-x': 'Go to [x]',
    reset: 'Reset',
    repeat: 'Repeat',
    'load-more': 'Load more',
    'unsaved-prompt': 'Are you sure you want to leave the page? Any unsaved progress will be lost.',
    upload: 'File uploaded',
    'page-no-x': 'Page [x]',
    image: 'Image',
    home: 'Home',
    'remove-search': 'Reset',
    reject: 'Reject',
    'competencelevel-sub': 'Total competence level',
    'email-sendt': 'Email sent',
    remove: 'Remove',
    saved: 'Saved',
    'delete-success': 'File was deleted',
    replace: 'Replace',
    status: {
      completed: 'Completed',
      passed: 'Passed',
      'in-progress': 'In progress',
      'not-started': 'Not started',
      'not-available': 'Not available',
      waiting: 'Waiting',
      'waiting-for-approval': 'Waiting for approval',
      approved: 'Approved',
      rejected: 'Rejected',
      'not-submitted': 'Not submitted',
      draft: 'Draft',
      registered: 'Registered',
      'competence-registered': 'Competence registered',
      'x-percent-completed': '[x]% completed',
      expired: 'Expired',
      'expired-at-x': 'Expired at [x]',
    },
    'found-no-users': 'No users found',
    'found-no-competences': 'No competences found',
  },
  notifications: {
    'file-uploaded': 'File uploaded',
    'something-went-wrong': 'Something went wrong',
  },
  track: {
    'open-learningpath': 'Open learning path',
    'your-track': 'Your learning track ',
    'estimated-time': 'Estimated time',
    tasks: 'Tasks',
    'my-assignments': 'My tasks',
    progress: 'You have completed [x] of [y] tasks',
    'you-must-do': 'Opens after [x]',
    're-take': 'Retake learning path',
    'no-elements-in-path': 'This learning path has no elements ...',
    'one-task-remaining': '1 task remaining',
    'x-tasks-remaining': '[x] tasks remaining',
    'x-of-y-tasks-completed': '[x] of [y] tasks completed',
  },
  manage: {
    roles: 'Roles',
    'roles-text': "Manage active roles in your organisation. Click 'Add role' to create a new role or click on an existing role to edit its competency profile.",
    'requirements-recommended': 'Requirements / recommended',
    'users-with-role': 'People who have the role',
    'no-users-with-role': 'None of your users have this role.',
    'no-roles-title': 'No roles found',
    'no-roles-text': 'No roles found. Click "Add role" to create a new role.',
    'no-competences-title': 'No competences found',
    'no-competences-text': 'No competences found. Click "Add competence" to create a new competence.',
    competences: 'Competences',
    'competences-text': 'Add or edit competences',
    tracks: 'Learning paths',
    'tracks-text': 'Add or edit learning paths',
  },
  mypage: {
    expires: 'Competences that will expire',
    'my-course-enrollments': 'My course enrollments',
    'my-course-enrollments-waitlist': 'You are on a waitlist',
  },
  map: {
    'open-preview': 'Show track',
    'open-content': 'Content',
    'open-stats': 'Open',
    'search-for-competences': 'Search for competences',
    'create-new-bit': 'Create new',
    'error-creating-map': 'The learning path or elements within it where not created.',
    headers: [
      'Name', 'Number of requirements', 'Last updated', 'Status',
    ],
    'map-draft': 'Learning path is not published',
    'remove-dot-item': 'Remove item',
    'remove-dot-item-txt': 'Are you sure you want to remove this item? This action cannot be undone.',
    'new-course': 'Add new',
    'remove-map': 'Remove learning path',
    'map-active': 'Active learning path',
    week: 'Week',
    locked: 'Locked',
    part: 'Part',
    start: {
      nano: 'Start',
      track: 'Open',
      other: 'Start',
    },
    'my-progress': 'My progress',
    'open-track': 'Open learning path',
    'repeat-track': 'Repeat learning path',
    'start-your-track': 'Start your learning path',
    gohome: 'Go to resourcepage',
    'verification-progress': 'Progress',
    'remove-map-warning': 'Remove item',
    'remove-map-warning-txt': 'Are you sure you want to remove this item?<br/><br/>All content and associated completions for all users will be removed',
    'map-not-found': 'This learning path does not exist',
    'error-loading-map': 'Could not open the learning path',
    'show-type-map': 'Map view',
    'show-type-map-short': 'Map view',
    'show-type-map-header': 'Learning path as a map',
    'show-type-map-text': 'Present the Learning path as a map (forced order). Ideal for shorter Learning paths.',
    'show-type-track': 'Composite Learning path',
    'show-type-track-header': 'Composite Learning path',
    'show-type-track-short': 'Composite',
    'show-type-track-text': 'Present the Learning path as composed of different modules and requirements. Ideal for larger Learning paths.',
    'show-type-list': 'List view',
    'show-type-list-short': 'List view',
    'show-type-list-header': 'Learning path as a list',
    'show-type-list-text': 'Present the Learning path as a long list of requirements where the user is free to decide the order in which the requirements are completed.',
    dots: {
      autostart: 'Autostart',
      name: 'Name',
      type: 'Course type',
    },
    settings: {
      nano: 'Content',
      maps: 'Maps',
      general: 'General',
      title: 'Title',
      'delete': 'Remove object',
      layout: 'Layout',
      active: 'Active',
      'active-text': 'The Learning path should be available to run',
      'title-text': 'Displayed on the top of your Learning path',
      'delete-item': 'Delete Learning path',
      'delete-item-text': 'Here you can delete the Learning path. Note that all statistics and reports associated with this Learning path is removed.',
      description: 'Description',
      'display-as-list': 'Display as list',
      'display-as-list-text': 'Display the learning path as a list instead of a map',
      'image-alt-text': 'Image alt text',
      'description-text': 'Describe the Learning path so that your users will get a gist of the content.',
      'background-overlay': 'Background overlay',
      'choose-background': 'Choose background',
      'choose-background-text': 'Upload or choose a background image from the library',
      'general-header': 'Title & description',
      'general-header-text': 'Name the Learning path and give a short description of the content.',
      color: 'Color',
      'active-color': 'Active color',
      'completed-color': 'Completed color',
      opacity: 'Opacity',
      'path-color': 'Path color',
      'remove-image': 'Remove image',
      save: 'Save settings',
      'change-background': 'Change background',
      'pre-requirement': 'Pre-requirements',
      messages: 'Messages',
      badges: 'Badges',
      'badge-header': 'Badge',
      'badge-header-text': 'Customize the end of the Learning path with your own badge',
      'resources-text': 'Connect a competence group to the Learning path. Creates a link to "My resources".',
      'use-resources': 'Activate',
      'resources-choose': 'Choose competence group',
      'resources-choose-text': 'Competences will be shown in "My resources".',
      'use-badge': 'Use badge',
      'use-badge-html': 'Text for badge',
      'use-badge-html-text': 'Text shown to the user when the Learning path is completed',
      'layout-header': 'Adjust the view of the Learning path',
      'layout-header-text': 'Select how your Learning path should be presented to the user',
    },
    stats: {
      'dots-completed': 'Completed tasks',
      'users-finished': 'Users finished / started',
      'bits-finished': 'Total completed tasks',
      'persons-tab': 'Persons',
      'answers-tab': 'Answers',
    },
    'competence-map': 'Competence map',
    preview: 'Preview',
  },
  resources: {
    displayMore: 'Display more',
    title: 'Title',
    'add-checklist-item': 'Add new checklist item',
    type: 'Type',
    editor: {
      editTitle: 'Editing',
      'bit-learning': 'Bit element',
      checklist: 'Checklist - coming soon',
      'digital-signatur': 'Digital signature - coming soon',
      learningElement: 'Learning element',
      scorm: 'Ecourse (scorm) - coming soon',
      signature: 'Signature',
      name: 'Name',
      text: 'Text',
      status: 'Status',
      isPublished: 'Published',
      duration: 'Duration',
      'create-map': {
        'step1-text': 'You must give the learning path a name in order to create elements and add content.',
        'step1-subtext': 'Give the learning path a name',
        'step2-text': 'Upload or select an image that will be the background of the map for the learning path.<br/><br/>You can remove/change this image later',
        'step3-text': 'Here you select the number of elements you want to add content to, and display in the map.<br/><br/>You can remove and/or add more items later.',
        'step3-subtext': 'Choose how many items you want to start with',
      },
      uploadFile: 'Upload file',
      pressButton: 'Click here or drag your file to upload',
      imageAlt: 'Alternative text',
      selectImage: 'Select image',
      dragAndDropFiles: 'Drag & drop files',
      'create-new-map': 'Create new learning path',
      deleteImage: 'Delete image',
      'abstract': 'Teaser',
      'has-file': 'Course has a file uploaded',
      uploading: 'File is uploading',
      upload: 'The file is uploaded',
      description2: 'Description',
      'checked-by': 'To be checked by',
    },
    files: 'Files',
    'new-checklist-item': 'New checklist item',
  },
  content: {
    ecourse: 'E-Course',
    checklist: 'Checklist',
    nano: 'Bit-learning',
    track: 'Track',
    signature: 'Signature',
    digital_signature: 'Digital signature',
    checklist_item: 'Checklist item',
    formalcompetence: 'Formal competence',
    verification: 'Verification',
    course: 'Course',
    equivalents: 'Competence equivalents',
    complex: 'Complex competence',
    certificate: 'Certificate',
  },
  cv: {
    'my-cvs': 'My CVs',
    'cv-delete-failure': 'Error deleting the CV',
    'cv-fetch-failure': 'Could not fetch this CV',
    'cvs-fetch-failure': 'Could not fetch CVs',
    'add-cv-instruction': "Click 'Edit CV' to add.",
    'no-endyear-without-startyear': 'Start-year also needs to be present',
    'delete-success': 'The CV was deleted',
    'enddate-is-before-startdate': 'To date has to be after from date',
    'no-endyear-without-startdate': 'From date also needs to be present',
    'endyear-is-before-startyear': 'Start year needs to be after end year',
    'remove-experience': 'Remove experience',
    'confirm-delete-cv': 'Do you really want to delete this CV?',
    'no-desc-added': 'No description added.',
    'none-added-yet': 'No CVs added yet. Click "Create new" to create one.',
    'form-elements': {
      name: 'Title',
      description: 'General description of your competence',
      'work-position': 'Position',
      'work-company': 'Company',
      'work-fromdate': 'From',
      'work-todate': 'To (empty if still present)',
      location: 'Location',
      'education-name': 'Name of education',
      'education-start-year': 'Started (year)',
      'education-end-year': 'Finished (year)',
      'project-name': 'Name',
      'project-description': 'Description',
      'project-from-date': 'From',
      'project-to-date': 'To (blank if still ongoing)',
      'elected-role': 'Position / Role',
      'elected-company': 'Company / Business',
      'elected-fromdate': 'From',
      'elected-todate': 'To (empty if not finished / quitted)',
      'language-name': 'Language',
      'language-skill': 'Proficiency in the language',
    },
    'missing-data': 'No CV added',
    'no-items-of-kind': {
      work: 'No work experiences added yet.',
      education: 'No education experiences added yet.',
      project: 'No project experiences added yet.',
      language: 'No language skills added yet.',
      elected: 'No experiences from voluntary work / honorary posts added yet.',
    },
    'experience-kind-headers': {
      work: 'Work',
      education: 'Education',
      project: 'Project',
      elected: 'Voluntary work',
      language: 'Language',
      formal: 'Formal',
      training: 'Training and courses',
      skills: 'Skills',
    },
    'experience-kind-descriptions': {
      work: "Register all of your relevant work experiences. It is up to you to consider what is relevant or not. If you have many years working experience, add the most important and relevant for the job you are seeking. Also remember to avoid 'holes in your CV' (periods without any entries in the CV). If the CV have such periods, you may have excluded some items.",
      education: 'List of my formal education.',
      project: 'Relevant projects I want to highlight.',
      elected: 'Voluntary work and honorary posts that I have had.',
      language: 'My written and oral language skills.',
    },
    'add-new-of-kind': {
      work: 'Add new work experience',
      education: 'Add new education',
      project: 'Add a new project',
      elected: 'Add new experience for voluntary work',
      language: 'Add new language skill',
    },
  },
  role: {
    start: 'Start your education',
    tasks: 'Tasks',
    task: 'Task',
    'my-tracks': 'Learning paths',
    mandatory: 'Mandatory',
    progress: 'Progress',
    free: 'Optional',
    'competence-for-role': 'Competence for role',
    'failed-to-load-competence-for-role': 'Could not fetch competences',
    'no-required-competence-for-x': 'No required competences for [x]',
    'tasks-related-to': 'My assignments for my role as',
    'completed-tasks-related-to': 'My completed assignments for my role as',
    'tasks-optional-to': 'Optional assignments for',
    'completed-header': 'Completed assignments',
    'completed-roles': 'Completed roles',
    'completed-roles-text': 'You have completed all assignments for the following roles:',
    'continue': 'Continue education',
    repeat: 'Repeat competence',
    'learning-progression': 'Hi, [x]',
    'learning-progression-txt': 'Here you will find an overview of your training initiatives and progress on these.',
    'awaiting-on-approval-from-manager': 'Awaiting approval from manager',
    'showing-of': 'Showing [x] of [y]',
    'open-checklist': 'Open checklist',
    'open-competence': 'Open competence',
    'only-show-noncompleted-tasks': 'Only show non-completed tasks',
    'my-roles': 'My roles and Learning Paths',
    'learning-path': 'Learning path',
    'promoted-articles': 'Promoted articles',
    'promoted-articles-viewall': 'View all',
    'promoted-articles-sub-heading': 'Here you will find an overview of relevant articles.',
    content: 'Content',
    onboarding: 'Onboarding',
    'onboarding-text': 'Onboarding, or organisational socialization, is a term coined in the 1970s referring to the process by which new employees gain the knowledge, skills, and behaviors needed to become valuable members of an organisation.',
    'my-roles-text': 'Here you will find an overview of your roles and the associated competence requirements',
    'learning-path-text': 'Dictumst sagittis nullam venenatis, dui fermentum eu ornare pharetra. Turpis eget ultrices vulputate non fringilla. At suspendisse sed eu mauris.',
  },
  competence: {
    'relevant-resources': 'Relevant resources',
    'uploaded-files': 'Uploaded files',
    'my-uploaded-files': 'My uploaded files',
    'x-uploaded-files': 'Uploaded by [x]',
    'file-deadline-passed': 'The deadline for uploading files has passed',
    'click-here-to-upload': 'Click here to upload',
    'no-upcoming-events': 'No upcoming events found.',
    expired: 'Expired',
    expires: 'Expires',
    sign: 'Sign',
    'confirm-to-sign': 'I acknowledge I have read and understood the contents of this form:',
    'you-have-signed': 'You have signed',
    signed: 'Signed',
    completed: 'Completed',
    registered: 'Registered',
    'signed-at-x': 'You signed at [x]',
    'registered-at-x': 'Registered at [x]',
    'completed-at-x': 'Completed at [x]',
    'user-checked': 'User has checked',
    'user-checked-at-x': 'User checked at [x]',
    password: 'Enter your password',
    deadline: 'Deadline',
    'x-of-y': '[x] of [y]',
    personEvent: 'Event today',
    'personEvents--future': 'Upcoming events',
    'personEvent--future': 'Upcoming event',
    'personEvents--passed': 'Past events',
    'personEvent--passed': 'Past event',
    'personEvents--today': 'Events today',
    'personEvent--today': 'Event today',
    'show-x-of-y': 'Showing [x] of [y]',
    'expiring-or-expired-competences': 'Expiring competences',
    courseActivity: 'Course today',
    components: 'Components',
    passed: 'Passed',
    'must-manager-sign': 'Your manager must approve this',
    'must-manager-sign-competence': 'Your manager must approve this competence',
    'manager-check': 'Approve task',
    'manager-checked': 'Checked by manager',
    'user-must-check': 'User must approve the task',
    'you-checked': 'You checked this',
    expiringCompetence: 'Expiring competence',
    'expiringCompetence--passed': 'Expired competence',
    'expiringCompetences--passed': 'Expired competences',
    'expiringCompetence--today': 'Expiring competence today',
    'expiringCompetences--today': 'Expiring competences today',
    'expiringCompetence--future': 'Expiring competence',
    'expiringCompetences--future': 'Expiring competences',
    missing: 'Missing',
    'not-mandatory': 'Not mandatory',
    'finished-and-waiting-on-approval': 'You are done - waiting on confirmation from leader',
    'no-completed-training': 'You have no completed training',
    'no-competences-found': 'No competences found',
    expiringCompetences: 'Expiring competences',
    'add-competence': 'Add competence',
    'add-completed-competence': 'Add completed competence',
  },
  'course-catalog': {
    catalog: 'Course catalog',
    'search-courses': 'Search for courses',
    categories: 'Categories',
    'course-section-classroom': 'Classroom',
    'course-section-all': 'All courses',
    'course-section-ecourse': 'Ecourses',
    'e-course': 'E-courses',
    course: 'Other courses',
    'course-type': 'Course type',
    'new-first': 'Sort by newest',
    'old-first': 'Sort by oldest',
    'sign-up': 'Sign up',
    'sign-self-up': 'Sign up',
    'sign-employees-up': 'Sign up employees',
    'show-classroom': 'View classroom courses',
    'show-digital': 'View digital courses',
    'no-courses-found': 'Did not find any courses in this category',
    'classroom-courses': 'Classroom courses',
    'no-hits-for-search-x': 'The search [x] gave no results',
    'back-to-main-view': 'Back to main view',
    'load-more-courses': 'Load more courses',
  },
  search: {
    organisations: 'ORGANISATIONS',
    persons: 'PEOPLE',
  },
  employees: {
    'org-delete-title': 'Delete unit',
    'org-delete-x-text': 'Are you sure you want to delete <b>[x]</b>?',
    'org-delete-success': 'The unit was deleted',
    search: 'Search employee',
    employee: 'Employee',
    employees: 'Employees',
    'add-change-roles': 'Add / change roles',
    'change-org': 'Change organisation',
    'search-for-employees': 'Search for employees',
    'show-tree': 'View as tree',
    total: 'Total',
    'report-roles': 'View roles',
    'report-view-all': 'View rest of [x] reports',
    'report-competences': 'View by Competence',
    'report-view': 'View report',
    verification: 'Verification',
    'course-competence-overview': 'Notifications',
    'course-overview': 'Classroom courses',
    'no-courses': 'No one has signed up for courses',
    'expire-header': 'Competence warnings!',
    'create-new-success': 'A new user was created',
    'view-items': 'View',
    'create-new-failure': 'Could not create a new user, please try again.',
    'no-access-to-profile': "You don't have access to this profile",
    'expire-will-plural': '[x] competences has either expired or will expire',
    'expire-will': '[x] competence has either expired or will expire',
    'nb-units': 'Number of units',
    'nb-employees': 'Number of employees',
    progress: 'Progression',
    actions: 'Actions',
    'add-employee': 'Add employee',
    'move-employee': 'Move employee',
    'export-to-excel': 'Export to Excel',
    'total-employees': 'Total employees',
    'min-letters': 'Enter [x] letters to start searching.',
    'choose-position': 'Choose position',
    enddate: 'End date',
    'absent-from': 'Absent from(including)',
    'absent-to': 'Absent to(including)',
    absence: 'Absence',
    'has-absence': 'The person has been set to absence.',
    'load-more-employees': 'Load more employees',
    'add-to-list': 'Add to worklist',
    'add-to-list-text-disabled': 'Select employees to add to list',
    'add-to-list-text': 'Add to worklist for follow-up and assign roles',
    'person-added': 'Person added to worklist',
    'persons-added': 'Persons added to worklist',
    'person-removed': 'Person removed from worklist',
    'persons-removed': 'Persons removed from worklist',
    'worklist-cleared': 'Worklist cleared',
    'employees-text': 'List of all employees in the current unit',
    'company-updated': 'The unit is updated',
    'company-added': 'Added new unit',
    firstname: 'First name',
    lastname: 'Last name',
    'all-completed': 'All completed',
    missing: 'Missing competence',
    roles: 'Role',
    positions: 'Position',
    name: 'Name',
    competence: 'Competence',
    'sendt-from': 'Sent by',
    'task-approved': 'Approved [title] for [fullname]',
    'checklist-approved': 'Checklist item approved for [fullname]',
    'task-rejected': 'Rejected [title] for [fullname]',
    'no-tasks-header': 'You have no tasks',
    'no-tasks-header-text': 'Good work! You can now have a cup of coffee and relax.',
    'course-events': 'Course overview',
    'course-wait-for': 'Waiting for approval',
    'action-approve-reject': 'Approve / Reject',
    'report-search': 'Competence search',
  },
  menu: {
    logout: 'Sign out',
    employees: 'My department',
    'my-page': 'My page',
    'roles-text': 'Manage active roles by assigning relevant competencies. Access a role by clicking on it, and make modifications to its competency profile or add new ones as needed.',
    grape: 'Snapper Competence',
    'grape-text': 'Set up competences and courses',
    'course-catalog': 'Course catalog',
    dashboard: 'Dashboard',
    logoutTxt: 'Sign out of all systems',
    maps: 'Maps',
    people: 'People',
    onboarding: 'Onboarding',
    'my-onboarding-text': 'My onboarding ',
    settings: 'Settings',
    resources: 'Resources',
    admin: 'Administration',
    services: 'Your services',
    'all-services': 'All your services',
    'waymaker-text': 'User backend for maps and tasks',
    'add-new-map': 'Add new map',
    'add-new-map-title': 'Give the new map a title',
    'add-persons': 'Add persons',
    administration: 'Administration',
    content: 'Content',
    persons: 'Persons',
    'persons-text': 'Add and control persons and what they should have access to',
    'my-maps': 'My maps',
    'my-maps-text': 'View and create your maps',
    overview: 'Overview',
    'resource-pages': 'Resources',
    'resource-pages-text': 'Resource pages containing additional information',
    summary: 'Summary',
    units: 'Units',
    worklist: 'My worklist',
    roles: 'Roles',
    'add-new-map-description': 'Et sit libero, eu lacus. Est amet, malesuada facilisi amet, tempor vestibulum. Adipiscing cras tempor faucibus nisl blandit porttitor eu, interdum scelerisque.',
    help: 'Help',
  },
  course: {
    start: 'Start course',
    restart: 'Start course again',
    repeat: 'Repeat',
    'classroom-course': 'Classroom course',
    'sign-up': 'Sign up',
    'sign-off': 'Sign off',
    'sign-you-off': 'Sign me off',
    'error-load-course': 'Could not load the course',
    'sign-up-yourself': 'Sign me up',
    'persons-confirmed': 'Persons that is confirmed',
    'yourself-already-signed-on': 'You are already signed up',
    'sign-up-employees': 'Sign up employees',
    'sign-up-employees-for-course': 'Sign up employees for course',
    'sign-up-employees-from-[x]-for-course': 'Sign up employees from [x] for course',
    'no-employees-to-choose': 'No employees to choose',
    'go-to-course-track': 'Go to course track',
    'confirmed-text': 'You are signed up',
    'choose-employees-for-signup': 'Select employees you want to sign up',
    'employees-signed-up': 'Employees that are signed up',
    'sign-up-employees-for-course-missing-employees': 'You have to choose an employee from the list to sign up.',
    'employees-waiting-for-confirmation': 'Are waiting for confirmation',
    'confirm-sign-off': 'Confirm sign off',
    'delete-file': 'Delete file',
    finish: 'Finish',
    'sign-off-person-x-question': 'Do you wish to sign off [x]?',
    'sign-on-self-x-question': 'Do you wish to sign on [x]?',
    'sign-off-self-x-question': 'Do you wish to sign off <b>[x]</b>?',
    'delete-file-for-person-question': 'Do you wish to delete this file?',
    'confirm-enrollment': 'Confirm enrollment',
    sign: 'Sign',
    seats: '[x]/[y] registered',
    waitlist: 'Waitlist',
    'course-is-full': 'The course is full',
    'sign-up-wait': 'Sign up on waitlist',
    'sign-up-gone': 'Sign up deadline has passed',
    'persons-waitlist': 'Persons on waitlist',
    'confirm-delete-file-for-person': 'Confirm delete file',
    'go-to-course': 'Go to course',
    'persons-need-confirm': 'Persons waiting for verification',
    'persons-where-signed-up': 'The following people were registered',
    'persons-where-not-signed-up': 'The following people were not registered',
    'when-completed-instructions': "<p class='header'>Done with the course?</p><p>Press <span class='italic'>Done</span> when you have completed the course.</p>",
    'did-finish': 'Done',
    'browser-has-popup-blocker': 'Your browser is configured to block pop-up windows.',
    passed: 'Completed',
    missing: 'Missing',
    'browser-has-popup-blocker-start-instructions': 'Press &laquo;Start e-course&raquo; to open the course.',
    'loading-events': 'Loading events',
    'failed-to-load-events': 'Failed to load events',
    'all-employees-from-x-signed-up': 'All employees from [x] are already signed up',
    'all-employees-signed-up': 'All employees are already signed up',
    'export-to-excel': 'Export to Excel',
  },
  reports: {
    'number-of-requirements': 'Number of requirements',
    report: 'Summary',
    loading: 'Loading report',
    'select-report': 'Select report',
    total: 'All competences',
    'by-role': 'Comptences by role',
    completed: 'Completed',
    'by-competence': 'Competences',
    statistics: 'Statistics',
    'create-report': 'Create report',
    'change-search': 'Change search',
    units: 'Units',
    competence: 'Competences',
    completion_grade: 'Completion grade',
    'passing-grade': 'Completion grade',
    week: 'Week',
    noWeeks: 'The search found no weeks',
    'has-not-competence': 'Has not competence',
    'has-competence': 'Has competence',
    'persons-who-has': 'Persons that has demand',
    overview: 'Overview',
    'persons-overview': 'Person overview',
    'units-overview': 'Units',
    'course-diploma': 'Course diploma',
    search: 'Competences',
    'your-reports': 'Saved reports',
    brand: 'Brand',
    employees: 'Nb. employees',
    persons: 'Persons',
    expirering: 'Expiring competences',
    track: 'Track',
    'back-to-tracks': 'Back to learning paths',
    answers: 'User answers',
    progress: 'Progress',
    'result-from-answers': 'Result',
    'user-has-answered': 'User answers',
    'correct-answer': 'Correct answer',
    'last-active': 'Last active in track',
    'not-passed': 'Not completed',
    'one-person-answered': '1 person answered this question',
    'x-persons-answered': '[x] persons answered this question',
    'one-answer': '1 registered answer',
    'x-answers': '[x] registered answers',
    'none-answers': 'No registered answers yet',
    'competence-search': 'Competence search',
    'track-search': 'Learning paths',
    inputs: {
      units: {
        organisations: 'Units',
        selectOrganisation: 'Select unit',
        searchPickOrganisation: 'Search or select unit',
        label: 'Select one or more units',
        closeLabel: 'Close section',
      },
      competence: {
        label: 'Select one or more competences',
        title: 'Competence',
        openLabel: 'Select competence',
        closeLabel: 'Close section',
        placeHolder: 'Search or select competences',
        noResult: 'No results',
      },
      roles: {
        searchPickRoles: 'Search or select roles',
        label: 'Select roles',
        openLabel: 'Select roles',
        closeLabel: 'Close section',
      },
      tracks: {
        searchPickTracks: 'Search or select learning path',
        label: 'Select learning path',
        openLabel: 'Select learning path',
        closeLabel: 'Close section',
      },
      week: {
        label: 'Select period',
        title: 'Historic data',
        openLabel: 'Select week',
        closeLabel: 'Close section',
        lastYear: 'Last year',
        last6Months: '6 mth',
        lastMonth: '1 mth',
      },
    },
  },
  person: {
    'logins-last-30-days': 'Logins last 30 days',
    user: 'User',
    users: 'Users',
    firstname: 'First name',
    lastname: 'Last name',
    position: 'Position',
    'user-cvs-fetch-pdf': 'Fetch as PDF',
    'user-cvs-fetch-word': 'Fetch as Word-document',
    email: 'Email',
    phone: 'Phone',
    mobile: 'Mobile',
    role: 'Role',
    'user-has-no-cvs': 'No CV has been registered for this user.',
    employee_code: 'Employee code',
    birthdate: 'Birthdate',
    jobtitle: 'Title',
    status: 'Status',
    'profile-picture': 'Profile picture',
    'click-to-upload-profile-picture': 'Click to upload image',
    'click-to-edit-profile-picture': 'Click to edit image',
    'upload-profile-picture': 'Upload image',
    'change-profile-picture': 'Edit image',
    'delete-profile-picture': 'Remove image',
    'competence-name': '[name] competence',
    'open-full-overview': 'Open competence list',
    'add-role': 'Change/add roles',
    'edit-competence': 'Add competence',
    'edit-roles': 'Edit roles',
    'change-information': 'Change information',
    'change-personalia': 'Edit profile',
    roles: 'Roles',
    'change-password': 'Change password',
    'old-password': 'Old password',
    'new-password': 'New password',
    'repeat-new-password': 'Repeat new password',
    'password-requirements': 'Password requirements: At least 8 characters, use uppercase and lowercase letters, as well as digits or special characters. Please note that the nordic letters æ-ø-å does not work as characters in a password.',
    'passwords-not-matching': 'The passwords does not match',
    'missing-old-password': 'Old password is required',
    'password-illegal-chars': 'The password cannot contain special characters or spaces',
    'username-illegal-chars': 'The username cannot contain special characters or spaces',
    'password-too-short': 'The password needs to be at least 8 characters long',
    'password-empty': 'The password cannot be empty',
    'password-change-success': 'The password is changed',
    'password-change-failure': 'Could not change the password',
    send: 'Send',
    'view-files': 'View users files',
    'view-cv': "View CV's",
    'user-files': '[x] files',
    'user-cvs': "[x] CV's",
    'competence-removed': 'The competence is removed',
    'course-enrollments': 'Course enrollments',
    'course-enrollments-for-x': 'Course enrollments for [x]',
    'x-is-on-waitlist': '[x] is on waitlist',
    'add-competence': 'Add personal competences',
    'add-competences': {
      requirement: 'Personal requirements',
      'add-requirement': 'Add personal requirements',
      competence: 'Completed competences',
      'add-as': 'Add as',
      'add-as-txt': 'her er en lengre tekst som forklarer det du kan gjøre...',
      'selected-files': 'Selected files',
      files: 'Files',
      'delete-file': 'Delete file',
      certificate: 'Add certificate',
      'certificate-txt': 'Upload a course certificate',
      comments: 'Comments',
      'choose-competence': 'Choose competence',
      'competence-details': 'Competence details',
      'do-you': 'Do you want to remove theese competences as person requirement?',
      valid_until: 'Valid to',
      'completed-date': 'Completed / registered',
      'selected-competences': 'Selected competences',
      'select-competence': 'Select competence',
      searching: 'Searching ...',
      'search-result': 'Search result',
      'select-a-category': 'Select from categories',
      'search-for': 'Search for competences',
      'selected-courses': 'Selected competences',
      'did-not-find': 'Did not find any competences',
      unselect: 'remove',
      add: 'Add competence',
      remove: 'Remove competence',
      'add-completed': 'Add a completed competence',
      'add-ob': 'Add mandatory competence',
      'add-ob-txt': 'A mandatory competence is something user must fulfill',
      'add-recomended': 'Add a recommended competence',
      'add-recomended-txt': 'A user do not need to finish a recommended competence',
      'remove-recomended': 'Remove a recommended competence',
      'remove-recomended-txt': 'A user do not need to finish a recommended competence',
      register: 'Register',
      dropfiles: 'Drag and drop files or',
      '': 'Selected competences',
      'add-remove': 'Add or remove competences',
    },
    profile: 'Profile',
    'no-roles-to-choose': 'No roles to choose',
    'username-desc': ' ',
    'employee-number-desc': ' ',
    'your-profile-updated-success': 'Updated your profile',
    'your-profile-updated-failure': 'Could not update your profile',
    'requirements-and-roles': 'Requirements / roles',
    'user-has-x-requirements-tooltip': 'User is assigned [x] requirements',
    'user-has-x-roles-tooltip': 'User is tagged with [x] roles',
    menu: {
      profile: 'Profile',
      profileTxt: 'Edit profile and change password',
      messages: 'Messages',
      messagesTxt: 'Show all messages',
      competences: 'My competences',
      competencesTxt: 'View all your competences and download course certificates',
      logout: 'Sign out',
      logoutTxt: 'Sign out of the system',
      messagesTxtUnread: 'You have [x] unread messages',
      messagesTxtOneUnread: 'You have 1 unread message',
      messagesTxt100OrMoreUnread: 'You have 100 or more unread messages',
      help: 'Help',
      helpTxt: 'Find help and read documentation',
    },
    'send-message-to-x': 'Send a message to [x]',
    'change-employment': 'Change employment',
    'you-have-now': 'You are now impersonating',
    'overtake-bart': 'Overtake identity',
    overtake: 'Overtake',
    'overtake-identity': 'Overtake identity?',
    'overtake-warning': 'You are now in the process of taking over identity of [x]. All actions will be logged as this user. Are you sure you want to take over identity to this user?',
    'change-employment-success': 'Updated with new date',
    'competence-report': 'Competence report',
    'change-employment-failure': 'Could not update the user',
    'send-new-password-to-user': 'Send a new password to user',
    'send-new-password-to-x-users': 'Send a new password to [x] users',
    'send-new-password-to-x-users-are-you-sure': 'Are you sure you want to send a new password to [x] users?',
    'password-reset-success': 'Sent new password to:',
    'password-reset-failure': 'Could not send new password to:',
    'send-new-password-to-user-success': 'A new password has been sent to the user',
    'send-new-password-to-user-failure': 'Could not send a new password',
    'send-new-password-to-user-desc': 'A new one-time password will be created and sent to the user by email.',
    'send-new-password': 'Send new password',
    'change-username-pwd': 'Change username/password',
    'complete-competence-report': 'Complete competence report',
    'send-username-and-password': 'Send username and password',
    'update-username-password-success': 'Password and username was changed',
    'update-username-password-failure': 'Could not change username/password',
    'position-required': 'Position is required',
    'role-select-default': 'Choose from the list',
    'invalid-mobile': 'Invalid mobile no',
    'person-required': 'A person needs to be selected',
    'user-updated-success': 'User updated',
    'users-updated-success': 'Users updated',
    'user-has-no-files': 'User has no files',
    'confirm-delete-file': 'Are you sure you want to delete this file?',
    'person-files': 'Person filer',
    types: {
      mandatory: 'Mandatory competences',
      optional: 'Optional competences',
      recomended: 'Recommended',
      other: 'Other competences',
    },
    'competence-overview': 'Competence overview',
    level: {
      total: 'Total',
      mandatory: 'Mandatory',
      required: 'Mandatory',
      recomended: 'Recommended',
      recommended: 'Recommended',
      personal: 'Personal',
    },
    'cancel-competence-success': 'Approval cancelled',
    'register-sendt-competence': 'Sent for approval',
    'register-competence-cancel': 'Cancel approval',
    'register-competence': 'Register new competence',
    'register-send-competence': 'Send for approval',
    'registered-competence': 'Competence sent for approval',
    'registered-competence-passed': 'Competence approved',
    'waiting-for-approval': 'Waiting for approval',
    'competence-unable-removed': 'Competence was not deleted',
    'roles-tab': 'Roles',
    'competence-overview-tab': 'Competence overview',
    'cv-tab': 'CV',
    'files-tab': 'Files',
  },
  message: {
    subject: 'Subject',
    text: 'Text',
    'no-messages': 'No messages',
    'my-messages': 'My messages',
    'form-missing-subject': 'Subject is required',
    'form-missing-body': 'The message text is required',
    'message-sent-success': 'Message sent',
    'failed-to-load': 'Could not fetch messages',
  },
  login: {
    login: 'Login',
    forgot: 'Forgot password?',
    'select-language': 'Select your language',
    'missing-user': 'Do you not have an user? Register',
    'invalid-user': 'Username or password is incorrect.',
    username: 'Username',
    'recive-new': 'Receive new password',
    'username-placeholder': 'user@domain.com',
    password: 'Password',
    'send-new-password-success': 'A new password has been created and sent',
    'send-new-password-failure': 'Error sending new password',
    'password-placeholder': 'At least 4 chars',
    version: 'Version',
    progress: 'Logging in ...',
    'new-password': 'Get new password',
    'send-new-password': 'Send new password',
    'no-user': 'Did not find any user',
  },
  'date-and-time': {
    yars: 'Years',
    year: 'Year',
    months: 'Months',
    month: 'Month',
    day: 'Day',
    days: 'Days',
    minute: 'Minute',
    hour: 'Hour',
    hours: 'Hours',
    'year-abbr': 'Yr',
    'years-abbr': 'Yrs',
    'month-abbr': 'Mth',
    'months-abbr': 'Mths',
    'day-abbr': 'Day',
    'days-abbr': 'Days',
    'browser-date-format-local': 'mm/dd/yyyy',
    minutes: 'Minutes',
    seconds: 'Seconds',
  },
  report: {
    'back-to-tracks': 'Back to tracks',
    'not-started': 'Not started',
    track: 'Track',
    progress: 'Progress',
    'no-results-title': 'No results',
    'no-results-text': 'No user has started on your track',
    'last-active': 'Last active',
    'users-total-onboarding': 'Total progress on onboarding',
    'users-total-onboarding-txt': 'Here you can see the users total progress on onboarding',
    completed: 'Completed',
    unfinished: 'Unfinished',
    finished: 'Finished',
    noPersons: 'The search found no persons',
    'users-total-track': 'Users total track progression',
    'users-total-track-txt': 'Here you can see the users total progress on all tracks and completion rate on them.',
    grade: 'Grade',
    date: 'Date',
    time: 'Time used',
    passed: 'Passed',
    statistics: 'Statistics',
    answers: 'User answers',
    'result-from-answers': 'Result',
    'user-has-answered': 'User answers',
    'correct-answer': 'Correct answer',
    'not-passed': 'Not completed',
  },
  persons: {
    'modify-data': 'Edit profile',
    'send-message': 'Send message',
    'edit-person-data': 'Edit profile',
    manager: 'Make user admin',
    name: 'Name',
    email: 'E-mail',
    mobile: 'Mobile',
    'remove-person': 'Remove person',
    'add-persons-intro': 'Lorem ipsum intro text',
    'email-invalid': 'Invalid e-mail address',
    'email-taken': 'E-mail already registered',
    leader: 'Leader',
    'import-from-excel': 'Import from Excel',
    'click-to-upload': 'Click to upload',
    'or-dnd': 'or drag and drop',
    'xlsx-or-numbers': 'XLSX or Numbers',
    'download-template': 'Download .xlsx template',
    'send-invites': 'Send invites',
    'add-person-header': 'Add people to your team',
    'is-manager': 'User is administrator (remove)',
    'are-you-sure': 'Are you sure?',
    'are-you-sure-txt': 'Are you sure you want to remove this person?',
    'import-found-count': 'Import found [count] people',
    'import-description': 'Lorem ipsum import description',
    'registered-heading': 'Persons already registered will not be added!',
    'registered-description': 'Persons already registered description',
    message: {
      subject: 'Subject',
      text: 'Text',
    },
  },
  help: {
    home: 'Home',
    topics: 'Topics',
    'searchbar-placeholder': 'Search topics, articles',
    'no-results-for-search-header': 'Did not find any documents',
    'no-results-for-x-desc': 'Your search for "[x]" did not match any documents. Please try again. ',
    'searchpage-header': 'Search',
    'no-results-for-search': 'Did not find any documents',
    'startpage-header': 'Help',
    'searchpage-header-txt': 'Find information',
    'search-for-x-gave-y-hits': 'Your search for "[x]" gave [y] hits',
    'search-for-x-gave-none-hits': 'Your search for "[x]" gave no hits',
    'submit-a-question': 'Submit a question',
    'send-question-header': 'I need help!',
    'send-question-instructions': "Didn't find the answer to your question? Send us an inquiry here, or on support@snapper.no",
    'send-question-form': {
      question: 'Question',
      desc: 'Please write a longer text',
      submit: 'Submit question',
      'submit-ok-desc': 'We have received your request!',
      'submit-ok-close': 'Close',
    },
  },
  onboarding: {
    before: 'Before startup',
    week: 'First week',
    month: 'First month',
    'first-three': 'First three months',
    'first-six': 'First six months',
    'before-text': 'Start-up is approaching, and we are looking forward to meeting you! Here is an overview of measures you can take before you start with us.',
    'week-text': 'Welcome aboard! Here is an overview of measures we would like you to complete during your first week.',
    'month-text': 'Here is an overview of measures we would like you to complete during your first month.',
    'first-three-text': 'Here is an overview of measures we would like you to complete during your first three months.',
    'first-six-text': 'Here is an overview of measures we would like you to complete during the first six months.',
  },
  worklist: {
    'header-text': 'My worklist',
    'header-text-txt': 'Here you can perform tasks',
    'clear-list': 'Clear list',
    'add-role': 'Add role',
    'send-mail': 'Send email',
  },
  'self-sign': {
    'cancel-competence': 'Reject competence?',
    cancel: 'Cancel approval',
    'do-you-wish': 'Do you wish to reject <b>[fullname] - [title]</b>',
    'do-you-wish-approve': 'Do you wish to approve <b>[fullname] - [title]?</b>',
    'confirm-approve': 'Confirm approval',
    confirm: 'Confirm',
  },
  GO_TO_TRAINERCOURSES: 'Go to trainer certificate',
  GO_BACK: 'Trener.nif.no',
  pages: {
    persons: 'Persons',
    'add-persons': 'Add persons',
    'persons-text': 'Add and control persons and what they should have access to',
    'add-new-map': 'Add new map',
    'add-new-map-description': 'Et sit libero, eu lacus. Est amet, malesuada facilisi amet, tempor vestibulum. Adipiscing cras tempor faucibus nisl blandit porttitor eu, interdum scelerisque.',
    'add-new-map-placeholder': 'Enter map name',
    'add-new-map-title': 'Give the new map a title',
    administration: 'Administration',
    content: 'Content',
    'create-new-map': 'Create new map',
    help: 'Help',
    'help-text': 'Here you can find all the answers you are looking for',
    'help-title': 'Do you need help?',
    'is-active-track': 'is active track',
    maps: 'Maps',
    'my-maps': 'My maps',
    'my-maps-text': 'View and create your maps',
    'new-map': 'Create your next map in seconds',
    onboarding: 'Onboarding',
    overview: 'Overview',
    'resource-pages': 'Resources',
    'resource-pages-text': 'Resource pages containing additional information',
    settings: 'Settings',
    'simple-onboarding': 'Simple onboarding',
    summary: 'Summary',
  },
  errors: {'default': 'An error has occurred. Please try again later.'},
  panelet: {
    'competence-status': 'Competence status',
    'complete-competence-status': 'Complete competence status',
  },
};
