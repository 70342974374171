import axios from 'axios';
import {freeze} from 'immer';
import {
  all,
  call,
  put,
  spawn,
} from 'redux-saga/effects';
import {apiFetchChildrenCompetences, apiFetchPersonCompetence} from '@actions/api.actions';
import {backendUrl} from '@config';
import {waitForOrgId, waitForProfileId, waitForUsername} from '@sagas/app.sagas';
import {retry} from '@utils/sagas.utils';
import {findCompetenceCheckedBy, findCompetenceId, findCompetencePassed, findCompetencePassedDate, findOrgId, findPersonId, findUsername} from '../util/competence-identity';
import {fetchChildrenCompetencesAPI} from './competences';

export function* fetchPersonCompetenceAPI(action) {
  const {payload} = action || {};

  const {successAction = apiFetchPersonCompetence.success} = payload || {};

  const cid = findCompetenceId(payload);

  const batch = Array.isArray(payload?.batch)
    ? payload.batch
    : null;

  if (!cid && !batch) return null;

  const username = findUsername(payload) || (yield call(waitForUsername));
  const pid = findPersonId(payload) || (yield call(waitForProfileId));

  const args = batch || [cid];

  try {
    const data = yield all(args.map(arg => {
      const competence_id = findCompetenceId(arg);

      if (!competence_id) return null;

      return retry(() => axios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences/${competence_id}`,
        params: {
          fields: 'title,date,description,valid_until,requirement_type,certificate_url,short_description,competence_id,competence_type,'
            + 'competence(files,competence_title,checked_by,short_description,title,competence_type,competence_type_id,competence_id)'
            + 'passed,manager_check_user_id,checklist',
          limit: 101,
          user_name: username,
        },
        withCredentials: true,
      }).then(res => {
        const phce = res?.data?.personcompetences?.[0];

        const ret = phce
          ? {
            ...phce,
            id: null,
            phce_id: phce?.id,
            competence_id: findCompetenceId(phce),
            passed: findCompetencePassed(phce),
            checked_by: findCompetenceCheckedBy(phce),
            passed_date: findCompetencePassedDate(phce),
          }
          : null;

        return ret;
      }));
    }).filter(Boolean));

    if (typeof successAction === 'function') yield put(successAction({
      cid,
      pid,
      data: data?.length
        ? freeze(data)
        : null,
    }));

    return data;
  } catch (error) {
    console.error(error);

    return null;
  }
}

export function* fetchCompetenceChildrenProgressAPI(action) {
  try {
    const {payload} = action || {};

    const {successAction = apiFetchChildrenCompetences.success} = payload || {};

    const cid = findCompetenceId(payload);

    const batch = Array.isArray(payload?.batch)
      ? payload.batch
      : null;

    if (cid == null && !batch?.length) throw new Error('missing competence_id');

    const args = batch || [cid];

    const orgId = findOrgId(payload) || (yield call(waitForOrgId));
    const personId = findPersonId(payload) || (yield call(waitForProfileId));

    if (orgId == null || personId == null) throw new Error('missing org_id or person_id');

    const data = yield all(args.map(arg => {
      const competence_id = findCompetenceId(arg);

      if (!competence_id) {
        console.error('missing competence_id');

        return null;
      }

      return call(fetchChildrenCompetencesAPI, {
        payload: {
          cid: competence_id,
          personId,
          orgId,
          successAction,
          skipUpdateAction: true,
        },
      });
    }).filter(Boolean));

    yield put(apiFetchChildrenCompetences.success({
      pid: personId,
      batchData: freeze(data),
    }));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchParentAndChildrenPersonCompetencesAPI(action) {
  try {
    const {payload} = action || {};

    const cid = findCompetenceId(payload);

    const batch = Array.isArray(payload?.batch)
      ? payload.batch
      : null;

    if (cid == null && !batch?.length) throw new Error('missing competence_id');

    const orgId = findOrgId(payload) || (yield call(waitForOrgId));
    const personId = findPersonId(payload) || (yield call(waitForProfileId));
    const username = findUsername(payload) || (yield call(waitForUsername));

    const args = batch || [cid];

    yield spawn(fetchPersonCompetenceAPI, {
      payload: {
        batch: args,
        username,
        personId,
      },
    });

    const childrenData = yield all(args.map(arg => {
      const competence_id = findCompetenceId(arg);

      if (!competence_id) {
        console.error('missing competence_id');

        return null;
      }

      return call(fetchChildrenCompetencesAPI, {
        payload: {
          cid: competence_id,
          personId,
          orgId,

          skipUpdateAction: true,
        },
      });
    }).filter(Boolean));

    yield put(apiFetchChildrenCompetences.success({
      pid: personId,
      batchData: freeze(childrenData),
    }));
  } catch (error) {
    console.error(error);
  }
}
