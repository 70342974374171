import memoize from 'micro-memoize';
import {createSelector} from 'reselect';
import {createReducerAndActions} from '@snapper/core';
import {ReduxModuleIds} from '@src/redux/lazy/constants';
import * as T from '@types/load.types';

export const appInitialState = {
  reduxModules: {
    [ReduxModuleIds.employees]: T.LoadStatuses.NOT_LOADED,
    [ReduxModuleIds.admin]: T.LoadStatuses.NOT_LOADED,
    [ReduxModuleIds.panelet]: T.LoadStatuses.NOT_LOADED,
  },
};

export const selectReduxModuleLoadStatuses = ({app: {reduxModules}}) => reduxModules;

export const selectReduxModuleLoadStatusById = memoize(moduleId => createSelector(
  selectReduxModuleLoadStatuses,
  loadStatuses => loadStatuses[moduleId],
));

const appActions = {
  appInjectReduxModule: {
    request: (state, action) => {
      const moduleId = action.payload;

      if (!moduleId) return;

      state.reduxModules[moduleId] = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      const moduleId = action.payload;

      if (!moduleId) return;

      state.reduxModules[moduleId] = T.LoadStatuses.LOADED;
    },
    failure: (state, action) => {
      const moduleId = action.payload;

      if (!moduleId) return;

      state.reduxModules[moduleId] = T.LoadStatuses.FAILED;
    },
  },
};

const {actions, reducer} = createReducerAndActions({
  prefix: 'app',
  initialState: appInitialState,
  actions: appActions,
});

export {reducer as appReducerImmer};

export default reducer;

export const {appInjectReduxModule} = actions;
