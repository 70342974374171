import axios from 'axios';
import Cookies from 'js-cookie';
import qs from 'qs';
import {
  call,
  delay,
  put,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {apiFetchRoles} from '@actions/api.actions';
import {appInjectReduxModule} from '@actions/app.actions';
import {
  AUTH_CHECK_LOGIN_METHOD_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT_SUCCESS,
  AUTH_USER_IS_CONFIRMED_NOT_LOGGED_IN,
  authBartUser,
  authCheckIfLoggedIn,
  authGetAuthStatusAndStartLoading,
  authGotoNext,
  authGotoOn,
  authLoginFailure,
  authLoginNewPasswordFailure,
  authLoginNewPasswordRequest,
  authLoginNewPasswordSuccess,
  authLoginRequest,
  authLoginSuccess,
  authLogoutRequest,
  authLogoutSuccess,
  authPollAuthStatus,
  authTransferUser,
  authTransferUserSuccess,
  authUnauthorized,
  authUserIsConfirmedAuthorized,
  authUserIsConfirmedNotAuthorized,
} from '@actions/auth.actions';
import {configGetConfig} from '@actions/config.actions';
import {
  employeesFetchOrganisation,
  employeesGetWorklist,
} from '@actions/employees.actions';
import {RESET_STORE} from '@actions/global.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import {
  profileFetchPerson,
  profileFetchPersonSummary,
  profileSetManager,
  profileSetSpecialroles,
  profileUpdateActiveOrgId,
} from '@actions/profile.actions';
import {
  userSetPartial,
  userSuccess,
} from '@actions/user.actions';
import {
  backendUrl,
  backendUrlFull,
  backendUrlV2,
  nanoLearningLoginUrl,
} from '@config';
import {waitForConfigObject} from '@sagas/config.sagas';
import {selectAPIRoles} from '@selectors/api.selectors';
import {
  selectIsKioskMode,
  selectProfileId,
  selectProfileRolesSummary,
  selectProfileUserName,
} from '@selectors/profile.selectors';
import * as Sentry from '@sentry/react';
import {i18n} from '@src/i18n';
import {AdminNames} from '@types/admin-names';
import {LoadStatuses} from '@types/load.types';
import {getBartId} from '@utils/data.utils';
import {isLoadedOrLoading} from '@utils/loadstatus.utils';
import {last} from '@utils/misc.utils';
import {validIntOrNull} from '@utils/number.utils';
import {retry} from '@utils/sagas.utils';
import {isStringWithLength} from '@utils/string.utils';
import {getKioskStartUrl, isTypeRoleOrOnboarding} from '../util/competence-identity';
import {waitForOrgId} from './app.sagas';
import {fetchFullPerson} from './profile.sagas';

export const sessionStorageParseWorklist = () => {
  const data = JSON.parse(sessionStorage.getItem('working-pids'));

  if (!data?.length) return [];

  return data.map(entry => {
    if (typeof entry === 'string') {
      const [pid, orgId] = entry.split('-');

      return [validIntOrNull(pid), validIntOrNull(orgId)];
    } else if (Array.isArray(entry)) {
      const [pid, orgId] = entry;

      return [validIntOrNull(pid), validIntOrNull(orgId)];
    }

    return null;
  }).filter(entry => entry?.[0] != null);
};

export const sessionStorageSetWorklist = (data = []) => {
  sessionStorage.setItem('working-pids', JSON.stringify(data));
};

export const sessionStorageClearWorklist = () => {
  sessionStorage.removeItem('working-pids');
};

const watchConfirmedNotAuthorized = takeLatest([
  AUTH_LOGIN_FAILURE,
  AUTH_CHECK_LOGIN_METHOD_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_USER_IS_CONFIRMED_NOT_LOGGED_IN,
  // AUTH_CHECK_LOGIN_METHOD_FAILURE,
], function* userNotLoggedInSaga(action) {
  console.log('not logged in');
  const bartId = getBartId();

  if (bartId) {
    localStorage.removeItem('learningportalConfig');
    localStorage.removeItem('config-pid');
    localStorage.removeItem('bart');
  }

  if (action.type === AUTH_LOGIN_FAILURE) {
    console.log('login failure');

    return;
  }

  if (action.type === AUTH_CHECK_LOGIN_METHOD_SUCCESS && action.payload?.message !== 'no-user') {
    return;
  }

  localStorage.removeItem('track');
  localStorage.removeItem('config');
  localStorage.removeItem('orgId');
  
  Cookies.remove("track");

  sessionStorageClearWorklist();

  Cookies.remove('tg-visit');
  Sentry.setUser(null);
  yield put({type: RESET_STORE});
});

function* gotoNext(action) {
  yield put(authGotoOn());
  window.location.reload();
}

function* watchInjectedReduxModule(action) {
  const moduleName = action?.payload;

  if (moduleName === 'employees') {
    const orgId = yield call(waitForOrgId, true);

    if (orgId != null) yield put(employeesFetchOrganisation({orgId}));

    try {
      yield put(employeesGetWorklist({ids: sessionStorageParseWorklist()}));
    } catch (error) {
      console.log(error);
    }
  }
}

/**
* Get startUrl for kiosk mode
* * If more than one role: `/my-education`
* * If single role, check the competences of the role:
*   * If no track, or more than one track: `/my-education/role/{roleId}`
*   * If single track and not completed or without resources: `/learning-path/{trackId}`
*   * If single track and completed with resources: `/learning-path/{trackId}/resources`
* @returns {'/my-education' | '/my-education/role/{roleId}' | '/learning-path/{trackId}' | '/learning-path/{trackId}/resources'}
*/
function* getKioskModeStartUrlSaga() {
  const configObject = yield call(waitForConfigObject, true);
  const configStartUrl = configObject?.getProperty?.('params.start-route') || '';

  const {
    requirement: currentRequirement = [],
    trackData: currentTrackData,
  } = (yield select(selectProfileRolesSummary)) || {};

  let trackData = currentTrackData.status === LoadStatuses.LOADED
    ? currentTrackData.data
    : null;

  // If already loaded summary trackData, we don't need to wait for profileFetchPersonSummary
  if (trackData != null) {
    const roles = currentRequirement.filter(isTypeRoleOrOnboarding);

    return getKioskStartUrl(roles, trackData, configStartUrl);
  }

  // If summary not yet loaded, wait for profileFetchPerson or profileFetchPersonSummary
  while (true) {
    const {payload} = yield take([
      profileFetchPerson.success,
      profileFetchPersonSummary.success,
    ]);

    const roles = (
      payload?.person?.roles // from profileFetchPerson
      || payload?.summary?.requirement // from profileFetchPersonSummary
      || []
    ).filter(isTypeRoleOrOnboarding);

    // If more than one role, set startUrl to /my-education
    if (roles.length > 1) return '/my-education';

    trackData ??= payload?.summary?.trackData;

    if (!trackData) {
      const curSummaryTrackData = (yield select(selectProfileRolesSummary))?.trackData;

      if (curSummaryTrackData?.status === LoadStatuses.LOADED) {
        trackData = curSummaryTrackData.data;
      }
    }

    if (!trackData) continue;

    return getKioskStartUrl(roles, trackData, configStartUrl);
  }
}

function* watchUserIsConfirmedAuthorized(action) {
  const {
    authProfile,
    start_url,
    username: payloadUsername,
    person_id: payloadPersonId,
    userMeData,
  } = action?.payload || {};

  const username = authProfile?.user_name || payloadUsername || userMeData?.username;
  const personId = authProfile?.person_id || payloadPersonId || userMeData?.person_id;

  try {
    if (username || personId) {
      yield put(userSetPartial({
        username,
        personId,
      }));
      if (username) {
        yield put(profileFetchPersonSummary());
      }
    } else {
      throw new Error('no user_name');
    }

    const configObject = yield call(waitForConfigObject, true);
    const configStartUrl = configObject?.getProperty?.('params.start-route') || '';

    yield spawn(fetchFullPerson, {payload: {userName: username}});

    const isKioskMode = yield select(selectIsKioskMode);

    const kioskStartUrl = isKioskMode
      ? yield call(getKioskModeStartUrlSaga)
      : null;

    yield put(authLoginSuccess({
      authProfile,
      start_url: kioskStartUrl || start_url || configStartUrl || '/my-education',
    }));
  } catch (error) {
    console.error(error);
    yield put(authUserIsConfirmedNotAuthorized());
  }
}

const watchSetManagerOrSpecialRole = takeLatest(
  [
    profileSetManager().type,
    profileSetSpecialroles().type,
  ],
  function* onSetManagerOrSpecialRole(action) {
    if (!(action?.type === profileSetManager().type || action?.payload?.superuser)) return;

    if (!isLoadedOrLoading(yield select(selectAPIRoles))) {
      yield put(apiFetchRoles());
    }
  },
);

const watchSetUserSuccess = takeLatest(
  `${userSuccess().type}`,
  function* onSuccess(action) {
    try {
      const {payload: userMeData = {}} = action || {};

      const prevUsername = yield select(selectProfileUserName);
      const prevPersonId = yield select(selectProfileId);

      const bartId = getBartId();

      if (bartId == null) {
        const isManager = !!userMeData?.group_names?.length && userMeData.group_names.some(name => isStringWithLength(name) && AdminNames.has(name.toLowerCase()));

        if (isManager) {
          yield put(profileSetManager());
        }

        let orgIdFromPath = null;

        if (isManager && window?.location?.pathname?.startsWith?.('/employees/')) {
          orgIdFromPath = validIntOrNull(last(window.location.pathname.split('/')));
        }

        if (orgIdFromPath != null) {
          yield put(profileUpdateActiveOrgId(orgIdFromPath));
        } else if (userMeData?.orgs?.length === 1) {
          yield put(profileUpdateActiveOrgId(userMeData.orgs[0]));
        }
      }

      if (
        bartId == null
        && (
          prevUsername && userMeData.username && prevUsername !== userMeData.username
          || prevPersonId && userMeData.person_id && prevPersonId !== userMeData.person_id
        )
      ) {
        window.location.reload();
      } else {
        Sentry.setUser(userMeData);
      }
    } catch (error) {
      Sentry.setUser(null);
      console.error(error);
    }
  },
);

export function* fetchUserMe() {
  try {
    const {data} = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrlV2}/user/me`,
      withCredentials: true,
    }));

    if (!data?.person_id) throw new Error('Invalid user');

    return data;
  } catch (error) {
    console.error(error);

    return null;
  }
}

export function* setCurrentUser(userMeData) {
  try {
    const user = userMeData || (yield call(fetchUserMe));

    if (!user?.person_id) throw new Error('Invalid user');

    const bartId = getBartId();

    if (bartId == null) yield put(userSuccess(user));
  } catch (error) {
    yield put(authUserIsConfirmedNotAuthorized({error}));
  }
}

function* login(action) {
  const {username, password} = action.payload;

  let res;
  let statusCode;

  try {
    res = yield retry(() => axios.request({
      method: 'POST',
      url: `${backendUrl}/api/login`,
      data: qs.stringify({
        password,
        user_name: username,
        login: 1,
        full: 1,
      }),
      withCredentials: true,
    }));

    statusCode = res?.status;

    const {data: {login: loginData, valid} = {}} = res || {};

    if (!valid) {
      statusCode = 401;
      Cookies.remove('tg-visit');
      // yield put({type: 'RESET_STORE'});
      throw new Error('Invalid user');
    }

    const {session_id, start_url} = loginData;

    Cookies.set('tg-visit', session_id);
    Cookies.set('identity_login_attempted', 0);

    yield spawn(setCurrentUser);

    const redirectUrl = new URLSearchParams(window.location.search).get('redirect') || start_url;

    yield put(authUserIsConfirmedAuthorized({
      authProfile: loginData,
      start_url: redirectUrl,
    }));

    yield put(configGetConfig());
  } catch (error) {
    console.error('login error', error);

    const code = statusCode ?? error?.response?.status;

    // if not 401 unauthorized, notify user to try again
    if (code !== 401) {
      yield put(notificationsAdd({
        notification: {
          text: i18n('globals.error-try-again'),
          color: 'red',
        },
      }));
    }

    yield put(authLoginFailure({
      error,
      statusCode: code,
    }));
  }
}

function* newPassword(action) {
  try {
    const {username} = action.payload;
    const {data} = yield retry(() => axios.request({
      method: 'POST',
      params: {
        json: 1,
        ajax: 1,
        email_or_user_name: username,
      },
      url: `${backendUrl}/sendreminder`,
      withCredentials: true,
    }));

    if (data.statuscode === 0) {
      yield put(authLoginNewPasswordSuccess({message: data.status}));
      yield put(notificationsAdd({
        notification: {
          text: i18n('login.send-new-password-success'),
          color: 'green',
        },
      }));
    } else {
      yield put(authLoginNewPasswordFailure({message: data.status}));
      yield put(notificationsAdd({
        notification: {
          text: i18n('login.send-new-password-failure'),
          color: 'red',
        },
      }));
    }
  } catch (error) {
    console.error(error);
    yield put(authLoginNewPasswordFailure({}));
  }
}

function* logout() {
  try {
    localStorage.removeItem('track');
    localStorage.removeItem('config');
    localStorage.removeItem('bart');
    localStorage.removeItem('orgId');
    
    Cookies.remove("track");

    // localStorage.removeItem('theme');
    // localStorage.removeItem('learningportalConfig');
    sessionStorageClearWorklist();

    if (nanoLearningLoginUrl) {
      window.location.replace(backendUrl + '/logout?redirect=/auth/logout');
    } else {
      yield retry(() => axios.request({
        method: 'POST',
        url: `${backendUrl}/api/logout`,
        withCredentials: true,
      }));
      Cookies.remove('identity_login_attempted');
      Cookies.remove('tg-visit');
    }

    yield put(authLogoutSuccess());
  } catch (error) {
    console.error(error);
    Cookies.remove('identity_login_attempted');
    Cookies.remove('tg-visit');
    yield put(authLogoutSuccess());
  }

  //  yield put({type: 'RESET_STORE'});
}

function* transferUser() {
  const {data} = yield retry(() => axios.request({
    method: 'POST',
    url: `${backendUrlFull}/transfer`,
    data: {token: Cookies.get('tg-visit')},
    withCredentials: true,
    credentials: 'same-origin',
  }));

  if (data.valid) {
    yield put(authTransferUserSuccess());
  }

  return true;
}

function* bartUser(action) {
  const {person_id} = action?.payload?.person || {};

  if (!person_id) {
    yield put(notificationsAdd({
      notification: {
        text: i18n('globals.error-occurred'),
        color: 'red',
      },
    }));

    return false;
  }

  localStorage.setItem('bart', person_id);
  localStorage.removeItem('orgId');

  yield retry(() => axios.request({
    method: 'GET',
    url: `${backendUrl}/persons/impersonate/${person_id}?json=1`,
    withCredentials: true,
  }));

  window.location = '/';

  /* DEBUG!
  const { data } = yield call(() =>
    axios.request({
      method: 'GET',
      url: `${backendUrl}/api/login`,
      withCredentials: false,
    })
  ); */

  // return data.valid;
  return true;
}

function* fetchCurrentLoginData() {
  try {
    const {data} = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrl}/api/login`,
      withCredentials: false,
    }));

    if (!data?.valid) return null;

    Cookies.set('identity_login_attempted', 0);

    yield put(authUserIsConfirmedAuthorized({
      authProfile: data.login,
      start_url: data.login.start_url,
    }));

    return data;
  } catch (error) {
    yield put(authUserIsConfirmedNotAuthorized());
    console.error(error);

    return null;
  }
}

export function* checkIfLoggedIn() {
  const sessionId = Cookies.get('tg-visit');

  if (!sessionId) return null;

  const bartId = getBartId();

  if (bartId != null) {
    const data = yield call(fetchCurrentLoginData);

    return data;
  }

  const userMeData = yield call(fetchUserMe);

  let loginData = {
    valid: false,
    login: {},
  };

  if (userMeData?.person_id && userMeData?.username) {
    const name = userMeData.fullname?.split(' ') || [];

    loginData = {
      valid: true,
      login: {
        ...userMeData,
        firstname: name.slice(0, -1).join(' '),
        lastname: last(name),
        user_name: userMeData.username,
        ts: new Date().toISOString(),
        session_id: sessionId,
        email: userMeData.email,
      },
      userMeData,
    };

    yield call(setCurrentUser, userMeData);
  } else {
    Sentry.setUser(null);
    Cookies.remove('tg-visit');

    return null;
  }

  return loginData;
}

function* getAuthStatusAndStartLoading() {
  const loginData = yield call(checkIfLoggedIn);

  const isValid = loginData?.valid;

  if (isValid) {
    Cookies.set('identity_login_attempted', 0);
    yield put(configGetConfig());
  }

  yield isValid
    ? put(authUserIsConfirmedAuthorized({
      authProfile: loginData.login,
      userMeData: loginData.userMeData,
      redirectUrl: new URLSearchParams(window.location.search).get('redirect') || loginData.login?.start_url,
    }))
    : put(authUserIsConfirmedNotAuthorized());
}

function* pollCheckUser(action) {
  const {intervalMs, isInit} = action.payload;

  if (isInit) {
    yield spawn(getAuthStatusAndStartLoading);
  } else {
    const loginData = yield call(checkIfLoggedIn);

    if (!loginData?.valid) {
      const profileId = yield select(selectProfileId);

      if (profileId) {
        yield put(authUserIsConfirmedNotAuthorized());
      }
    }
  }

  // try to emulate set interval
  yield delay(intervalMs);
  yield call(pollCheckUser, {payload: {intervalMs}});
}

const exportObj = [
  watchSetManagerOrSpecialRole,
  watchSetUserSuccess,
  watchConfirmedNotAuthorized,
  takeLatest(authLoginRequest().type, login),
  takeLatest(authLogoutRequest().type, logout),
  takeLatest(authLoginNewPasswordRequest().type, newPassword),
  takeLatest(authGotoNext().type, gotoNext),
  takeEvery(authUnauthorized().type, logout),
  takeEvery(authBartUser().type, bartUser),
  takeLatest(authTransferUser().type, transferUser),
  takeLatest(authCheckIfLoggedIn().type, checkIfLoggedIn),
  takeLatest(
    authGetAuthStatusAndStartLoading().type,
    getAuthStatusAndStartLoading,
  ),
  takeLatest(authPollAuthStatus().type, pollCheckUser),
  takeLatest(authUserIsConfirmedAuthorized().type, watchUserIsConfirmedAuthorized),
  takeLatest(`${appInjectReduxModule.success}`, watchInjectedReduxModule),
];

export default exportObj;
