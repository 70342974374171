/* eslint-disable camelcase */
/* eslint-disable no-console */
import axios from 'axios';
import {freeze} from 'immer';
import {all, call, put, takeLatest} from 'redux-saga/effects';
import {apiFetchCompetence} from '@actions/api.actions';
import * as authActions from '@actions/auth.actions';
import * as employeesActions from '@actions/employees.actions';
import {
  profileFetchRole,
  profileFetchRoles,
  profileSelfSign,
} from '@actions/profile.actions';
import * as rolesActions from '@actions/roles.actions';
import {
  ROUTER_PROFILE_COMPETENCES_REPORT_DID_MOUNT,
  routerRoleContainerDidMount,
} from '@actions/router.actions';
import {backendUrl, backendUrlV2} from '@config';
import {i18n} from '@src/i18n';
import {isObjectWithKeys} from '@utils/misc.utils';
import {retry} from '@utils/sagas.utils';
import {findCompetenceId, isSelfSignOrChecklistItemCompetence} from '../util/competence-identity';
import {waitForProfileId, waitForUsername} from './app.sagas';

const RolesAxios = axios.create({
  headers: {'X-Grape-Lang': localStorage.getItem('language')},
  withCredentials: true,
});

function* fetchRoles(action) {
  try {
    let {rid, userName} = action?.payload || {};

    if (!userName) userName = yield call(waitForUsername);

    rid = rid || '';

    yield put(rolesActions.fetchRolesRequest());

    const roles = yield retry(() => RolesAxios.request({
      method: 'GET',
      url: `${backendUrl}/api/roles/${rid}`,
      params: {
        role_meta_types: 'position,role',
        user_name: userName,
        ...!rid && {
          summary: 1,
          fields: 'title,id',
        },
      },
      withCredentials: true,
    }).then(response => response.data.roles));

    yield put(rolesActions.fetchRolesSuccess({roles}));
  } catch (error) {
    if (error.code === 401) {
      yield put(authActions.authUnauthorized());
    }
    yield put(rolesActions.fetchRolesFailure({error}));
  }
}

/*
 *   FETCHROLESREQUIRED
 *   Get the roles and requierments for this section.
 * */

export function* fetchRolesRequired() {
  try {
    const userName = yield call(waitForUsername);

    yield put(profileFetchRoles.request());

    const roles = yield retry(() => RolesAxios.request({
      method: 'GET',
      url: `${backendUrl}/api/roles`,
      params: {
        fields: 'title,role_id,description,required_competences(title,id,weight,short_description,competence_type_id)',
        role_meta_types: 'position,role',
        summary: 1,
        user_name: userName,
      },
      withCredentials: true,
    }).then(response => response.data.roles));

    yield put(profileFetchRoles.success({roles}));
  } catch (error) {
    if (error.code === 401) {
      yield put(authActions.authUnauthorized({error}));
    }
    yield put(profileFetchRoles.failure({error}));
  }
}

export function* fetchRoleSaga(action) {
  const {
    roleId = null,
    userName,
    skipLoadStatus: refresh = false,
  } = isObjectWithKeys(action?.payload)
    ? action.payload
    : {roleId: action.payload};

  if (roleId == null) return;

  let user_name = userName;

  const profileId = yield call(waitForProfileId);
  const profileUserName = yield call(waitForUsername);

  if (!profileUserName) return;

  if (user_name === undefined) {
    user_name = profileUserName;
  }

  const isProfileRole = user_name === profileUserName;

  yield isProfileRole
    ? put(profileFetchRole.request({
      refresh,
      roleId,
    }))
    : put(rolesActions.fetchRoleRequest({
      roleId,
      refresh,
    }));

  try {
    const {
      role,
      competences,
      summary = null,
    } = yield all({
      role: retry(() => RolesAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/roles/${roleId}`,
        params: {
          fields: 'title,description,files,startdate,roletype_name,required_competences(grace_period),optional_competences(grace_period)',
          user_name,
          role_ids: roleId,
        },
        withCredentials: true,
      }).then(response => response.data.roles[0])),
      competences: retry(() => RolesAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          fields:
            'title,description,short_description,valid_until,requirement_type,grace_period,certificate_url,'
            + 'description,competence_id,competence_type,'
            + 'competence(files,checked_by,short_description,description,title,competence_type,competence_type_id,competence_id,grace_period),'
            + 'passed,manager_check_user_id',
          state: 'all',
          limit: 101,
          user_name,
          role_ids: roleId,
        },
        withCredentials: true,
      })
        .then(response => response.data.personcompetences)),
      summary: isProfileRole
        ? null
        : retry(() => axios.request({
          method: 'POST',
          url: `${backendUrlV2}/dashboard/search/summary`,
          data: [{role_ids: [roleId]}],
          headers: {'Content-Type': 'application/json'},
          withCredentials: true,
        }).then(response => response.data?.[0])),
    });

    if (competences?.length && isProfileRole) {
      yield put(apiFetchCompetence.success({
        pid: profileId,
        data: freeze(competences.map(competence => !!competence?.competence?.checked_by || !isSelfSignOrChecklistItemCompetence(competence)
          ? competence
          : {
            ...competence,
            competence: {
              ...competence?.competence,
              checked_by: 'manager',
            },
          })),
      }));
    }

    const hasGrace = role.required_competences?.some(rc => rc.grace_period > 0);

    if (hasGrace && role.roletype_name === 'Onboarding') {
      const dates = {};
      const onboardingStages = [];

      role.required_competences.forEach(({grace_period}) => {
        dates[grace_period] = 1;

        switch (grace_period) {
        case 0: {
          onboardingStages.push([i18n('onboarding.week'), 7]);

          break;
        }
        case 30: {
          onboardingStages.push([i18n('onboarding.month'), 30]);

          break;
        }
        case 90: {
          onboardingStages.push([i18n('onboarding.first-three'), 90]);

          break;
        }
        default: {
          break;
        }
        }
      });

      role.onboardingStages = onboardingStages;
    }

    competences.forEach(competence => {
      competence.competence.competence_title = competence.competence.title;
      competence.competence.children = [];
      competence.children = [];

      const cid = findCompetenceId(competence);

      competence.grace_period = 0;
      const grace_required = role.required_competences?.find?.(rc => rc?.id === cid);

      if (grace_required) {
        competence.grace_period = grace_required.grace_period;
      } else {
        const grace_option = role.optional_competences?.find?.(rc => rc?.id === cid);

        if (grace_option) {
          competence.grace_period = grace_option.grace_period;
        }
      }

      competence.competence.competence_type = competence.competence_type;

      competence.sort = competence.requirement_type === 'Mandatory'
        ? role.required_competences?.findIndex?.(r => r?.id === cid)
        : role.optional_competences?.findIndex?.(r => r?.id === cid);

      competence.competence.cover = competence.competence.files?.find(f => f.title === 'cover');
    });

    role.requirements = competences.sort(function(a, b) {
      return a.sort - b.sort;
    });
    role.hasGrace = hasGrace;

    yield isProfileRole
      ? put(profileFetchRole.success({role}))
      : put(rolesActions.fetchRoleSuccess({
        role: {
          ...role,
          summary,
        },
      }));
  } catch (error) {
    if (error.code === 401) {
      yield put(authActions.authUnauthorized({error}));
    }
    yield isProfileRole
      ? put(profileFetchRole.failure({error}))
      : put(rolesActions.fetchRoleFailure({roleId}));
  }
}

export function* fetchRolesMainPage(action) {
  yield call(waitForProfileId);
  yield put(profileSelfSign({refresh: true}));
  yield call(fetchRoleSaga, action);
}

export function* fetchOrganisations() {
  try {
    const userName = yield call(waitForUsername);

    yield put(rolesActions.fetchOrganisationsRequest());

    const organisations = yield retry(() => RolesAxios
      .request({
        method: 'GET',
        url: `${backendUrl}/api/organisations`,
        withCredentials: true,
        params: {user_name: userName},
      })
      .then(response => response.data.organisations));

    yield put(rolesActions.fetchOrganisationsSuccess({organisations}));
  } catch (error) {
    if (error.code === 401) {
      yield put(authActions.authUnauthorized({error}));
    }
    yield put(rolesActions.fetchOrganisationsFailure({error}));
  }
}

const exportObj = [
  takeLatest(rolesActions.FETCH_ROLE, fetchRoleSaga),
  takeLatest(routerRoleContainerDidMount().type, fetchRolesMainPage),
  takeLatest(rolesActions.FETCH_ROLES_REQUIRED, fetchRolesRequired),
  takeLatest([
    rolesActions.FETCH_ROLES,
    `${employeesActions.employeesInitAddView}`,
    ROUTER_PROFILE_COMPETENCES_REPORT_DID_MOUNT,
  ], fetchRoles),
  takeLatest([
    `${employeesActions.employeesInitEditView}`,
    `${employeesActions.employeesInitAddView}`,
    ROUTER_PROFILE_COMPETENCES_REPORT_DID_MOUNT,
  ], fetchOrganisations),
];

export default exportObj;
