import React from 'react';
import {useSelector} from 'react-redux';
import {selectReduxModuleLoadStatusById} from '@actions/app.actions';
import {LoadStatuses} from '@types/load.types';
import {useInjectModule} from '../hooks';

export const ReduxModuleInjector = React.memo(({
  moduleId,
  disabled,
  renderAllways,
  children,
}) => {
  const loadStatus = useSelector(selectReduxModuleLoadStatusById(moduleId));
  const disableInitialLoad = disabled || loadStatus !== LoadStatuses.NOT_LOADED;

  useInjectModule(moduleId, disableInitialLoad);

  const isLoaded = loadStatus === LoadStatuses.LOADED;
  const shouldRender = renderAllways || isLoaded && !disabled;

  if (shouldRender) return children || null;

  return null;
}, (prev, next) => prev.renderAllways === next.renderAllways
  && prev.disabled === next.disabled
  && prev.moduleId === next.moduleId);

// export const EmployeesModuleInjector = React.memo(({disabled}) => (
//   <ReduxModuleInjector
//     moduleId={ReduxModuleIds.employees}
//     disabled={disabled}
//   />
// ));

// export const AdminModuleInjector = React.memo(({disabled}) => (
//   <ReduxModuleInjector
//     moduleId={ReduxModuleIds.admin}
//     disabled={disabled}
//   />
// ));
