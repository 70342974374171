import {mapDataToCalendarItems} from '@routes/employees/util';
import {emptyArr} from '@utils/constants';

const eventsArgs = {
  eventType: 'personEvent',
  keys: {
    dateStart: 'startdate',
    dateEnd: 'enddate',
    id: 'id',
    title: 'title',
  },
  options: {
    hideDeadlines: true,
    hideEnding: true,
  },
};

const expiringArgs = {
  eventType: 'expiringCompetence',
  keys: {
    dateStart: 'valid_until',
    dateEnd: 'valid_until',
    id: 'competence_id',
    title: 'competence_title',
  },
};

export const getPersonCalendarItems = ({
  expiringData,
  eventsData,
}) => mapDataToCalendarItems([
  ...expiringData?.length
    ? [{
      ...expiringArgs,
      data: expiringData,
    }]
    : [],
  ...eventsData?.length
    ? [{
      ...eventsArgs,
      data: eventsData,
    }]
    : [],
]);

export const sortRoles = (rolesData, competencesById) => {
  if (!rolesData?.length) return {
    roles: emptyArr,
    hidden: emptyArr,
    complete: emptyArr,
  };

  const roles = [];
  const hidden = [];
  const complete = [];

  const getRole = role => {
    const {
      track_id,
      competence_ids,
      missing_count,
      passed_count,
    } = role || {};

    const requirement = competence_ids?.required || competence_ids?.requirement || competence_ids?.mandatory;

    if (
      !requirement?.length
      && !competence_ids?.optional?.length
    ) return role;

    if (track_id) {
      if (!competencesById?.[track_id]) return {
        ...role,
        trackId: track_id,
      };

      const {
        status,
        children_completed_count,
        children_count,
        passed,
      } = competencesById[track_id];

      return {
        ...role,
        trackId: track_id,
        missing_count: passed === 100 ? 0 : 1,
        passed_count: passed === 100 ? 1 : 0,
        trackData: {
          competence_id: track_id,
          status,
          children_completed_count,
          children_count,
        },
      };
    }

    if (!missing_count || !requirement?.length) return role;

    // Calculate passed_count from role's required competences, since we sometimes get stale server data from summary endpoint.
    let newPassedCount = 0;

    for (const cid of requirement) {
      const {passed} = competencesById?.[cid] || {};

      if (passed === 100) {
        newPassedCount += 1;
      }
    }

    newPassedCount = Math.max(newPassedCount, passed_count || 0);
    const newMissingCount = Math.min(requirement.length - newPassedCount, missing_count);

    return {
      ...role,
      missing_count: newMissingCount,
      passed_count: newPassedCount,
    };
  };

  const rolesDataWithCompetence = rolesData.map(getRole);

  const [firstRole, ...restRoles] = rolesDataWithCompetence;

  let allReq = firstRole.passed_count + firstRole.missing_count;
  let allMissing = firstRole.missing_count;

  if (firstRole.missing_count > 0) {
    roles.push(firstRole);
  } else if (firstRole.passed_count) {
    complete.push(firstRole);
  }

  // TODO: optimize, we dont need to loop over this again ...

  if (restRoles?.length) {
    restRoles
      .sort((a, b) => {
        if (!a || !b) return 0;

        if (a?.missing_count === b?.missing_count) {
          return (a?.title || '').localeCompare(b?.title || '');
        }

        return (b?.missing_count || 0) - (a?.missing_count || 0);
      })
      .forEach((role, i) => {
        if (!role?.passed_count && !role?.missing_count) return;

        allReq += (role.passed_count || 0) + (role.missing_count || 0);
        allMissing += role.missing_count || 0;

        if (role.missing_count === 0) {
          complete.push(role);
        } else if (i < 8) {
          roles.push(role);
        } else {
          hidden.push(role);
        }
      });
  }

  const progress = allMissing === 0
    ? 0
    : 100 - Math.round(allMissing / allReq * 100);

  return {
    progress,
    roles: roles.length ? roles : emptyArr,
    hidden: hidden.length ? hidden : emptyArr,
    complete: complete.length ? complete : emptyArr,
  };
};

export const eventsPartitionWaitlistConfirmed = events => {
  const waitlist = [];
  const confirmed = [];

  events?.forEach?.(event => {
    if (event?.waitlist === true) {
      waitlist.push(event);
    } else if (event?.waitlist === false) {
      confirmed.push(event);
    }
  });

  return {
    waitlist: waitlist.length ? waitlist : emptyArr,
    confirmed: confirmed.length ? confirmed : emptyArr,
  };
};
