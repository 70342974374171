import blueTheme from '@styles/theme-global-css-vars/blue';
// import {defaultThemeCSSVarDict} from '@styles/theme-global-css-vars/default';
import greenTheme from '@styles/theme-global-css-vars/green';
import lightGreenTheme from '@styles/theme-global-css-vars/lightGreen';
import redTheme from '@styles/theme-global-css-vars/red';
import yellowTheme from '@styles/theme-global-css-vars/yellow';
import {isObjectWithKeys} from '@utils/misc.utils';
import {isStringWithLength} from './string.utils';

export const defaultThemeKey = 'blue';

export const themeVarsByKey = {
  blue: blueTheme,
  green: greenTheme,
  lightGreen: lightGreenTheme,
  red: redTheme,
  yellow: yellowTheme,
  orange: null,
};

export const BrandIds = {
  Kontor: 6,
  Matkroken: 10,
  CoopPrix: 11,
  CoopMega: 12,
  Extra: 13,
  CoopMarked: 14,
  Annetformat: 15,
  ObsHypermarked: 16,
  ObsBygg: 17,
  Byggmix: 18,
  ExtraBygg: 19,
  Samvirkelag: 20,
  Faghandel: 21,
  Medlemsvalgtportal: 22,
  Elektro: null, // ?
};

export const objectKeysToDashKeys = theme => Object.keys(theme).reduce(
  (obj, prop) => Object.assign({}, obj, {[`--${prop}`]: theme[prop]}),
  {},
);

export const createStylesFromThemeVars = themeVariables => `
    :root {
      ${Object.keys(themeVariables)
  .map(propName => `${propName}: ${themeVariables[propName]};`)
  .join(' ')}
    }`;

export const defaultThemeGlobalCSSVars = objectKeysToDashKeys(blueTheme);// defaultThemeCSSVarDict);

export const getThemeKeyByBrandId = id => {
  switch (Number(id)) {
  default:
  case BrandIds.ObsHypermarked:
  case BrandIds.ObsBygg:
  case BrandIds.ExtraBygg:
  case BrandIds.Faghandel:
  case BrandIds.Medlemsvalgtportal: {
    return 'blue';
  }
  case BrandIds.Extra:
  case BrandIds.Matkroken:
  case BrandIds.CoopMarked: {
    return 'red';
  }
  case BrandIds.CoopPrix:
  case BrandIds.Elektro: {
    return 'yellow';
  }
  case BrandIds.CoopMega: {
    return 'green';
  }
  case BrandIds.Byggmix: {
    return 'lightGreen';
  }
  }
};

export function getValidThemeKey(themeKey) {
  if (!isStringWithLength(themeKey) || !themeVarsByKey.hasOwnProperty(themeKey)) {
    return defaultThemeKey;
  }

  return themeKey;
}

export const getThemeCSSVarsByThemeKey = themeKey => {
  if (themeKey === defaultThemeKey || themeVarsByKey[themeKey] == null) return defaultThemeGlobalCSSVars;

  return {
    ...defaultThemeGlobalCSSVars,
    ...objectKeysToDashKeys(themeVarsByKey[themeKey]),
  };
};

export const getCSSVarsByBrandId = id => {
  const themeKey = getThemeKeyByBrandId(id);

  return getThemeCSSVarsByThemeKey(themeKey);
};

export const styleInjectThemeCSSVars = themeVariables => {
  if (!isObjectWithKeys(themeVariables)) return;

  /* const isDefaultTheme = Object.keys(themeVariables)
    .every(key => themeVariables[key]?.toLowerCase?.() === defaultThemeGlobalCSSVars[key]?.toLowerCase?.());*/

  // if (isDefaultTheme) return;

  const styles = createStylesFromThemeVars(themeVariables);
  const styleNode = document.createElement('style');

  styleNode.type = 'text/css';
  styleNode.innerHTML = styles;
  document.head.append(styleNode);
};
