import * as React from 'react';
import memoize from 'micro-memoize';
import {createSelector} from 'reselect';
import {createContext, useContextSelector} from 'use-context-selector';
import {last, memoedGetByPath} from './misc.utils';

export const withContextProvider = (ContextProvider, Component) => React.memo(props => (
  <ContextProvider {...props}>
    <Component {...props} />
  </ContextProvider>
));

export const withContextProviders = (...args) => {
  const providers = args.slice(0, -1);
  const Component = last(args);

  return React.memo(props =>
    providers.reduceRight(
      (acc, Provider) =>
        <Provider {...props}>{acc}</Provider>
      , <Component {...props} />,
    ));
};

export const makeContext = ({
  initialState = {},
  reducer,
  initializer,
  actions = {},
}) => {
  const Context = createContext();

  const useCtxSelector = selector => useContextSelector(Context, v => selector(v[0]));
  const useCtxDispatch = () => useContextSelector(Context, v => v[1]);

  const selectState = state => state || initialState;
  const useCtxState = () => useCtxSelector(selectState);

  const createCtxSelector = memoize(keyOrFn => typeof keyOrFn === 'function'
    ? createSelector(
      selectState,
      keyOrFn,
    )
    : typeof keyOrFn === 'string'
      ? createSelector(
        selectState,
        state => memoedGetByPath(state, keyOrFn),
      )
      : state => state);

  const useCtxValue = keyOrFn => useCtxSelector(createCtxSelector(keyOrFn));

  const useCtxActions = () => {
    const dispatch = useCtxDispatch();

    const actionsWithDispatch = React.useMemo(() => {
      const boundActions = {};

      for (const key in actions) {
        boundActions[key] = (...args) => dispatch(actions[key](...args));
      }

      return boundActions;
    }, [dispatch]);

    return actionsWithDispatch;
  };

  const Provider = ({children}) => (
    <Context.Provider value={React.useReducer(reducer, initialState, initializer)}>
      {children}
    </Context.Provider>
  );

  return {
    Context,
    Provider,
    createCtxSelector,
    useCtxSelector,
    useCtxDispatch,
    useCtxActions,
    useCtxState,
    useCtxValue,
  };
};

/*
const {
  Context: TestContext,
  Provider: TestProvider,
  useCtxSelector: useTestSelector,
  useCtxDispatch: useTestDispatch,
  createMemoizedSelector: createTestMemoizedSelector,
  useCtxActions: useTestActions,
  useCtxValue: useTestValue,
  useCtxState: useTestState,
} = makeContext({
  actions: {
    testAction: payload => ({
      type: 'TEST_ACTION',
      payload,
    }),
  },
  initialState: {
    test1: 'test1 state',
    test2: 'test2 state',
    test3: 'test3 state',
  },
  reducer: (state, action) => {
    switch (action.type) {
    case 'TEST_ACTION': {
      const key = `test${action.payload}`;

      return {
        ...state,
        [key]: state[key] + ' [updated]',
      };
    }
    default: {
      return state;
    }
    }
  },
  // initializer: getInitialEditorState,
});

const Test1 = () => {
  const test1 = useTestValue('test1');

  return (
    <div>
      <div>Test1</div>
      {Math.random()}
      <div>{test1}</div>
    </div>
  );
};

const Test2 = () => {
  const test2 = useTestValue(state => state.test2);

  return (
    <div>
      <div>Test2</div>
      {Math.random()}

      <div>{test2}</div>
    </div>
  );
};

const Test3 = () => {
  const test3 = useTestValue(({test3}) => test3);

  return (
    <div>
      <div>Test1</div>
      {Math.random()}
      <div>{test3}</div>
    </div>
  );
};

export const TestComponent = withContextProvider(
  TestProvider,
  () => {
    const actions = useTestActions();

    return (
      <div>
        <Test1 />
        <Test2 />
        <Test3 />
        <button onClick={() => actions.testAction(1)}>update 1</button>
        <button onClick={() => actions.testAction(2)}>update 2</button>
        <button onClick={() => actions.testAction(3)}>update 3</button>
      </div>
    );
  },
);
*/
