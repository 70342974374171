import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {css, Global, useTheme} from '@emotion/react';
import {myEducation} from '@routes/routes.manifest';
import {selectAuthStartUrl} from '@selectors/auth.selectors';
import {getConfigObject} from '@selectors/config.selectors';
import {getIsMobile, selectLogoUrl} from '@selectors/global.selectors';
import {getMessageUnreadCount, selectProfileFullname, selectProfileId} from '@selectors/profile.selectors';
import {Menu as SnapperMenu} from '@snapper/menu';
import {i18n} from '@src/i18n';
import {getMessagesText} from '@utils/data.utils';
import {getRouteWithParams} from '@utils/routes.utils';
import './desktop-menu/desktop-menu.scss';

const menuCss = css`
  min-height: 60px;
  justify-content: center !important;
  flex-basis: auto !important;

  .menu-items-container {
    display: none;
  }
`;

const getKioskModeMenuData = ({
  history,
  // mainMenuColor,
  enableEditProfile = true,
  mainMenuBackgroundColor,
  logoUrl,
  startUrl,
  fullname,
  unreadMessagesCount,
  personId,
}) => ({
  config: {
    height: '60px',
    primaryColor: mainMenuBackgroundColor,
  },
  search: null,
  logo: {
    src: logoUrl || '/images/snapper.svg',
    href: startUrl || '/',
  },
  items: null,
  profile: {
    displayName: fullname,
    notification: unreadMessagesCount,
    items: [
      {
        label: i18n('person.menu.competences'),
        subLabel: i18n('person.menu.competencesTxt'),
        onClick: () => {
          history.push(getRouteWithParams(myEducation.cvView.path, {personId}));
        },
        type: 'featureLink',
      },
      ...enableEditProfile
        ? [{
          label: i18n('person.menu.profile'),
          subLabel: i18n('person.menu.profileTxt'),
          onClick: () => {
            history.push(getRouteWithParams(myEducation.profileView.path, {personId}));
          },
          type: 'featureLink',
        }]
        : [],
      {
        label: i18n('person.menu.messages'),
        subLabel: getMessagesText(unreadMessagesCount),
        onClick: () => {
          history.push(getRouteWithParams(myEducation.messagesView.path, {personId}));
        },
        type: 'featureLink',
      },
      {type: 'separator'},
      // ...helpPagesUrl
      //   ? [
      //     {
      //       label: i18n('person.menu.help'),
      //       subLabel: i18n('person.menu.helpTxt'),
      //       onClick: () => {
      //         history.push(getRouteWithParams(help.startpage, {}));
      //       },
      //       type: 'featureLink',
      //     },
      //   ]
      //   : [],
      {
        label: i18n('person.menu.logout'),
        subLabel: i18n('person.menu.logoutTxt'),
        onClick: () => {
          history.push('/logout');
        },
        type: 'featureLink',
      },
    ],
  },
});

const StylesOverride = () => (
  <Global
    styles={{'.menu-two .app-wrapper__content__main:nth-child(5)': {top: '70px'}}}
  />
);

const KioskModeMenu = React.memo(() => {
  const history = useHistory();
  const isMobile = useSelector(getIsMobile);
  const configObject = useSelector(getConfigObject);
  const menuConfig = configObject.getProperty('params.menu');

  const {mainMenuColor, mainMenuBackgroundColor} = useTheme();

  const personId = useSelector(selectProfileId);
  const fullname = useSelector(selectProfileFullname);
  const unreadMessagesCount = useSelector(getMessageUnreadCount);

  const startUrl = useSelector(selectAuthStartUrl);
  const logoUrl = useSelector(selectLogoUrl);

  const data = React.useMemo(() => getKioskModeMenuData({
    history,
    logoUrl: logoUrl || '/images/snapper.svg',
    startUrl: startUrl?.replace?.(/\/resources$/, '') || '/',
    mainMenuColor,
    mainMenuBackgroundColor: mainMenuBackgroundColor || menuConfig?.color,
    unReadMessagesCount: unreadMessagesCount,
    fullname,
    personId,
  }), [history, logoUrl, startUrl, mainMenuColor, mainMenuBackgroundColor, unreadMessagesCount, fullname, personId, menuConfig]);

  const menuData = useMemo(() => ({
    ...data,
    ...isMobile
      ? {
        items: data.profile.items,
        profile: null,
      }
      : null,
    isMobile,
  }), [isMobile, data]);

  return (
    <>
      <StylesOverride />
      <div className="desktop-menu kiosk-mode-menu">
        <SnapperMenu
          history={history}
          data={menuData}
          className="desktop-menu-wrapper kiosk-mode-menu-wrapper"
          css={menuCss}
        />
      </div>
    </>
  );
});

export default KioskModeMenu;
