const isDev = process.env.NODE_ENV === 'development';

export function createModalInitialState(modalId, config, initialState) {
  if (isDev && !config) throw new Error('Modal config is required.');
  const state = {isOpen: false};
  const {
    not_null = [],
    optional = [],
  } = config || {};

  [...not_null, ...optional].forEach(key => {
    if (typeof key === 'object') {
      const {
        key: payloadKey,
        type,
      } = key || {};

      if (!payloadKey) throw new Error('Payload key is required.', key);

      state[payloadKey] = type === 'boolean' ? false : null;
    } else {
      state[key] = null;
    }
  });

  return {
    ...state,
    ...initialState,
    modalId,
  };
};
