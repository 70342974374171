import {put, select, takeLatest} from 'redux-saga/effects';
import * as componentsActions from '@actions/components.actions';
import {getCourseCatalogComponentState} from '@selectors/components.selectors';
import {getConfigObject} from '@selectors/config.selectors';
import {defaultCourseCatalogLayout, initLayoutConfig} from '../util/components.util';

const resourcesConfig = {
  sections: [
    {
      id: 'main',
      widgets: [
        {
          id: 'coursegroups',
          variant: 'tiles',
        },
        {id: 'title'},
        {
          id: 'catalog',
          options: {
            gridColumns: 3,
            pageSize: 9,
            cardVariant: 'default',
            enableUpcomingEventsView: false,
            enableCompetencesView: true,
          },
        },
      ],
    },
  ],
};

function* initCourseCatalogLayout(action) {
  const {layoutType} = action.payload || {};

  yield put(componentsActions.setCourseCatalogLayoutRequest({layoutType}));

  try {
    const configObject = yield select(getConfigObject);

    const layoutConfig = layoutType === 'resources'
      ? resourcesConfig
      : configObject.getProperty('routes.course-catalog.layout') || defaultCourseCatalogLayout;

    const config = initLayoutConfig(layoutConfig);

    yield put(componentsActions.setCourseCatalogLayoutSuccess({
      config,
      layoutType,
    }));
  } catch (error) {
    yield put(componentsActions.setCourseCatalogLayoutFailure({
      error,
      layoutType,
    }));
  }
}

function* courseCatalogSetActiveView(action) {
  try {
    const {activeView} = action.payload || {};
    const {activeView: prevActiveView} = yield select(getCourseCatalogComponentState);

    if (activeView !== prevActiveView) {
      yield put(componentsActions.setCourseCatalogComponentsState({activeView}));
    }
  } catch (error) {
    console.error(error);
  }
}

const exportObj = [
  takeLatest(componentsActions.COMPONENTS_SET_COURSE_CATALOG_LAYOUT, initCourseCatalogLayout),
  takeLatest(componentsActions.COMPONENTS_SET_COURSE_CATALOG_ACTIVE_VIEW, courseCatalogSetActiveView),
];

export default exportObj;
