import * as React from 'react';
import {useSelector} from 'react-redux';
import {createContext, useContextSelector} from 'use-context-selector';
import PersonAvatar from '@components/person-avatar/person-avatar';
import {getEmployees} from '@selectors/employees.selectors';

const defaultAvatarStyle = {
  marginLeft: 0,
  marginRight: '.333rem',
  lineHeight: 1.1,
  marginTop: '.1rem',
};

const EmployeesDataContext = createContext({});
const useEmployeesDataContext = () => useContextSelector(EmployeesDataContext, ctx => ctx);

export const EmployeesDataProvider = ({children}) => {
  const {byId: employeeById = {}} = useSelector(getEmployees);

  const getEmployee = React.useCallback(id => employeeById[id], [employeeById]);
  const getUsername = React.useCallback(employeeId => getEmployee(employeeId)?.user_name, [getEmployee]);
  const getAvatar = React.useCallback(({
    personId,
    avatarSize = 22,
    fontSize = '13px',
    style = defaultAvatarStyle,
    showNotificationCount = true,
  }) => {
    const employee = getEmployee(personId);

    if (!employee) return null;

    return (
      <PersonAvatar
        size={avatarSize}
        person={employee}
        notificationCount={showNotificationCount ? employee?.pending_checklists : null}
        className="employee-card__avatar"
        fontSize={fontSize}
        style={style}
      />
    );
  }, [getEmployee]);

  const ctxValue = React.useMemo(() => ({
    getAvatar,
    getUsername,
  }), [getAvatar, getUsername]);

  return (
    <EmployeesDataContext.Provider value={ctxValue}>
      {children}
    </EmployeesDataContext.Provider>
  );
};

export default useEmployeesDataContext;
