import axios from 'axios';

export const retryFetch = async ({
  fetchFn,
  onStart,
  onSuccess,
  onFail,
  onEnd,
  maxRetries = 4,
}) => {
  let retryCount = 0;

  if (onStart) onStart();

  const retry = async () => {
    try {
      const data = await fetchFn();

      if (data) {
        if (onEnd) onEnd(data);
        if (onSuccess) onSuccess(data);

        return data;
      }

      throw new Error('No data');
    } catch (error) {
      // dont retry if request was canceled with AbortController
      if (error?.name === 'CanceledError') {
        if (onEnd) onEnd(null);

        return null;
      }
      retryCount++;
      if (retryCount < maxRetries) return retry();
      if (onFail) onFail(error);
      if (onEnd) onEnd(null);

      return null;
    }
  };

  return retry();
};

export const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

export const axiosRetry = async (...args) => {
  const {
    url,
    method = 'GET',
    data,
    params,
    maxRetries = 3,
    headers,
    delay = 500,
    withCredentials = true,
    timeout,
    cancelToken,
    responseType,
  } = typeof args[0] === 'string'
    ? {
      url: args[0],
      ...args[1],
    }
    : args[0] || {};

  let retries = 0;

  while (retries < maxRetries) {
    try {
      const response = await axios.request({
        url,
        method,
        data,
        params,
        headers,
        withCredentials,
        timeout,
        cancelToken,
        responseType,
      });

      return response;
    } catch (error) {
      if (retries === maxRetries - 1) throw error;

      await wait(delay);

      retries++;
    }
  }

  return null;
};
