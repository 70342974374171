import {takeEvery} from 'redux-saga/effects';
import * as apiActions from '@actions/api.actions';
import * as competencesSagas from './competences';
import * as coursegroupsSagas from './coursegroups';
import * as personCompetencesSagas from './person-competences';
import * as rolesSagas from './roles';

const apiSagas = [
  takeEvery(apiActions.apiFetchCompetence, competencesSagas.fetchCompetenceAPI),
  takeEvery(apiActions.apiFetchChildrenCompetences, competencesSagas.fetchChildrenCompetencesAPI),
  takeEvery(apiActions.apiFetchPersonCompetence, personCompetencesSagas.fetchPersonCompetenceAPI),
  takeEvery(apiActions.apiFetchCoursegroups, coursegroupsSagas.fetchCoursegroupsAPI),
  takeEvery(apiActions.apiFetchRoles, rolesSagas.fetchRolesAPI),
  takeEvery(apiActions.apiFetchPersonRoles, rolesSagas.fetchPersonRolesAPI),
  takeEvery(apiActions.apiFetchRoleCompetences, rolesSagas.fetchRoleCompetencesAPI),

  // takeEvery(apiFetchTrack, fetchCompetenceChildrenProgressAPI),
];

export default apiSagas;
