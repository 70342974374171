import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import {backendUrl} from '@config';
import {employees} from '@routes/routes.manifest';
import {useModalsContext} from '@src/features/modals/context/modals-context';
import {i18n} from '@src/i18n';
import {axiosRetry} from '@utils/async.utils';
import {emptyArr} from '@utils/constants';
import {getRouteWithParams} from '@utils/routes.utils';
import * as styles from './search-bar-styles';

const cancelMsg = 'Operation canceled by the user.';
const emptyResult = [[], [], true];

const SearchBar = ({
  term,
  showMenu,
}) => {
  const source = useRef();
  const history = useHistory();

  const [result, setResult] = useState(emptyArr);
  const [status, setStatus] = useState(0);

  const {employee: {open: openEmployeeModal}} = useModalsContext();

  useEffect(() => {
    if (term.length <= 2) {
      setStatus(0);
    } else {
      if (source.current) {
        source.current.cancel(cancelMsg);
      }
      const tokenSource = axios.CancelToken.source();

      source.current = tokenSource;
      setStatus(1);
      axiosRetry({
        url: `${backendUrl}/persons/search?mode=ac&n=100&term=${term}`,
        method: 'GET',
        withCredentials: true,
        cancelToken: tokenSource.token,
      }).then(res => {
        const {data} = res || {};

        setStatus(2);
        if (data) setResult(data);
      })
        .catch(error => {
          console.error(error, {term});
          if (!axios.isCancel(error) || term.length <= 2) {
            // setStatus(0);
            setResult(emptyArr);
          }
        });
    }
  }, [term]);

  const openItem = useCallback((item, linkType) => {
    showMenu(false);

    if (item.category === 'Enhet' || linkType === 'company') {
      const orgUrlSplit = item.url.split('/').splice(-1);
      const orgId = orgUrlSplit[0].includes('pid=')
        ? orgUrlSplit[0].split('#')[0]
        : orgUrlSplit[0];

      history.push(getRouteWithParams(employees.employees, {orgId}));
    } else {
      const orgId = Number(item.url.split('/').splice(-1)[0].split('#')[0]);
      const personId = Number(item.url.split('#!pid=')[1]);

      if (personId) {
        openEmployeeModal({
          orgId,
          personId,
          userName: item.username,
        });
      }
    }
  }, [history, showMenu, openEmployeeModal]);

  const [orgs, persons, noResults] = useMemo(() => {
    if (status !== 2 || !Array.isArray(result) || !result?.length || result.length === 1 && result[0]?.noresults) return emptyResult;

    return result.reduce((acc, item) => {
      const {category} = item;

      if (category === 'Enhet') {
        acc[0].push(item);
      } else if (category === 'Person') {
        acc[1].push(item);
      }

      return acc;
    }, [[], [], false]);
  }, [result, status]);

  return (
    <div>
      {(status === 0 || term?.length <= 2) && (
        <>
          {i18n('globals.enter-text')}
        </>
      ) || status === 1 && (
        <div>
          {i18n('globals.searching')}
        </div>
      ) || status === 2 && (
        <styles.SearchResult>
          {!!orgs?.length && (
            <styles.Section>
              <h4>{i18n('search.organisations')}</h4>
              {orgs.map(res => (
                <styles.Item key={res.url}>
                  <styles.CompanyIco>
                    {res.label.slice(0, 1)}
                  </styles.CompanyIco>
                  <styles.CompanyName onClick={() => openItem(res)}>
                    {res.label}
                  </styles.CompanyName>
                </styles.Item>
              ))}
            </styles.Section>
          )}
          {!!persons?.length && (
            <styles.Section>
              <h4>{i18n('search.persons')}</h4>
              {persons.map(res => (
                <styles.Item key={res.url}>
                  <>
                    <styles.PersonIco>
                      {res.label.slice(0, 1)}
                    </styles.PersonIco>
                    <styles.Name>
                      <div onClick={() => openItem(res)}>
                        {res.label.split(' (')[0]}
                      </div>
                      <styles.Company onClick={() => openItem(res, 'company')}>
                        (
                        {res.label.split(' (')[1]}
                      </styles.Company>
                    </styles.Name>
                  </>
                </styles.Item>
              ))}
            </styles.Section>
          )}
          {noResults && i18n('globals.no-hits-for-search-x', {functionArgs: {x: term}})}
        </styles.SearchResult>
      )}
    </div>
  );
};

export default SearchBar;
