import memoize from 'micro-memoize';
import {createSelector, createStructuredSelector} from 'reselect';
import {
  getConfigObject,
  getPropertiesForCurrLangAndTrackBadge as selectMapBadge,
  getPropertiesForCurrLangAndTrackSticker as selectMapSticker,
} from '@selectors/config.selectors';
import {LoadStatuses} from '@types/load.types';
import {getImageUrl} from '@utils/data.utils';
import {createDeepEqualSelector} from '@utils/selectors.utils';
import {isStringWithLength} from '@utils/string.utils';
import {emptyArr, emptyObj} from '../../common/utils/constants';
import {getLoading} from './alert.selectors';
import {getIsMobile} from './global.selectors';

const eatFirstAndLastChar = str => str?.slice?.(1, -1) || '';

const extraDataNotLoadedObj = Object.freeze({
  data: null,
  lastFetched: 0,
  status: LoadStatuses.NOT_LOADED,
  error: null,
});

export const selectMap = ({map}) => map;
export const getMapCourses = ({map: {mapCourses} = {}} = {}) => mapCourses;

export const selectMapStyles = ({map}) => map?.mapCourses?.data?.tracks?.[0]?.styles;

export const getSelectedMapCourse = ({map: {selectedMapCourse: {data = {}} = {}} = {}}) => data;

const selectMapTrackData = ({map: {track: {data} = {}} = {}} = {}) => data || emptyObj;

export const selectTrackCompleteExtraDataStatus = ({map: {completeExtraDataStatusByTrackId} = {}}) => completeExtraDataStatusByTrackId;
export const selectTrackCompetenceExtraData = ({map: {extraDataByCompetenceId} = {}}) => extraDataByCompetenceId;

export const extraDataByCompetenceIdSelector = memoize(cid => createSelector(
  selectTrackCompetenceExtraData,
  extraDataByCompetenceId => extraDataByCompetenceId?.[cid] || extraDataNotLoadedObj,
));

export const completeExtraDataStatusByTrackIdSelector = memoize(trackId => createSelector(
  selectTrackCompleteExtraDataStatus,
  completeExtraDataStatusByTrackId => completeExtraDataStatusByTrackId?.[trackId] || LoadStatuses.NOT_LOADED,
));

export const selectToggleCompetenceProgress = ({map: {toggleCompetenceProgress}}) => toggleCompetenceProgress;
export const selectMapTracks = ({map: {mapCourses} = {}} = {}) => mapCourses?.data?.tracks || emptyArr;
export const selectMapFirstTrack = ({map: {mapCourses} = {}} = {}) => mapCourses?.data?.tracks?.[0] || emptyObj;

export const selectMapIsOutroCompleted = ({map: {mapCourses: {outroIsDone} = {}} = {}}) => outroIsDone;
export const getIsMapVerified = ({map: {mapCourses: {mapIsVerified} = {}} = {}} = {}) => mapIsVerified;

export const getInitializeMyCoursesView = ({map: {initializeMyCoursesView} = {}} = {}) => initializeMyCoursesView;
export const selectMapVerificationCompetence = ({map: {mapVerificationCompetence} = {}} = {}) => mapVerificationCompetence;

// get all courses in map, with outro and verification
export const selectAllMapDotts = ({map: {mapCourses: track} = {}} = {}) => track?.data?.tracks?.[0]?.dotts || emptyArr;
export const selectMapLayout = ({map: {track: {data: trackData} = {}} = {}} = {}) => trackData?.layout || '';

export const selectMapCourseIsFetching = ({map: {mapCourses} = {}} = {}) => !!mapCourses?.isFetching;
export const selectTrackData = ({map: {mapCourses: track} = {}} = {}) => track?.data || null;

const selectMapTrackLoadStatus = ({map: {mapCourses: track} = {}} = {}) => track?.status || LoadStatuses.NOT_LOADED;
const selectMapTrackIsFetching = ({map: {mapCourses: track} = {}} = {}) => track?.isFetching && !track?.error;

const selectMapSelectedDotId = ({map: {selectedMapCourse: {data: selectedDot = {}} = {}} = {}}) => selectedDot?.id;
const selectMapSelectedDotStatus = ({map: {selectedMapCourse: {data: selectedDot = {}} = {}} = {}}) => selectedDot?.status;

const selectMapIsGridLayout = createSelector(
  selectTrackData,
  getIsMobile,
  (track, isMobile) => track?.layout === 'grid' && !isMobile,
);

export const selectMapTrackId = ({map: {mapCourses} = {}} = {}) => mapCourses?.data?.id;
const selectMapShouldHideDotsAndPath = ({map: {mapCourses} = {}}) => !mapCourses?.data?.id;
const selectMapTitle = ({map: {mapCourses} = {}}) => mapCourses?.data?.title || '';
const selectMapDescription = ({map: {mapCourses} = {}}) => mapCourses?.data?.description || '';
const _selectMapBackgroundImage = ({map: {mapCourses} = {}}) => mapCourses?.data?.tracks?.[0]?.image;

const selectMapBackgroundImageUrl = createSelector(
  _selectMapBackgroundImage,
  image => image && getImageUrl(image) || '',
);

export const getLayoutClassname = createSelector(
  selectMapLayout,
  layout => layout === 'track' ? 'hide-menu' : '',
);

// given a course-title like this This is a title :param1: :param2:
// return a list of the params like this ["param1","param2"]
export const getCourseTitleParams = courseTitle => {
  if (!isStringWithLength(courseTitle)) return emptyArr;

  const params = courseTitle.match(/:[^:]+?:/g);

  return params?.length
    ? params.map(p => eatFirstAndLastChar(p).toLowerCase())
    : emptyArr;
};

export const selectMapOutro = createSelector(
  selectAllMapDotts,
  dotts => dotts?.length
    ? dotts.find(t => getCourseTitleParams(t.title).includes('outro'))
    : null,
);

export const getMapVerification = createSelector(
  selectAllMapDotts,
  dotts => dotts?.length
    ? dotts.find(t => getCourseTitleParams(t.title).includes('verification'))
    : null,
);

// get courses in map, with outro and verification filtered out if exists
export const selectMapDottsWithoutVerificationAndOutro = createDeepEqualSelector(
  selectAllMapDotts,
  selectMapOutro,
  getMapVerification,
  (dotts, outro, verification) => dotts?.length
    ? dotts.filter(d => d.id !== outro?.id && d.id !== verification?.id)
    : emptyArr,
);

export const selectFirstMapDot = createSelector(
  selectMapDottsWithoutVerificationAndOutro,
  dotts => dotts?.[0] || null,
);

export const getIsAllMapDotsCompleted = createSelector(
  selectMapDottsWithoutVerificationAndOutro,
  dots => {
    if (!dots?.length) return null;

    for (const dot of dots) {
      if (dot.status !== 'DONE') return false;
    }

    return true;
  },
);

export const selectIsMapActivated = createSelector(
  getConfigObject,
  configObject => configObject?.isMapActivated == null
    ? null
    : configObject.isMapActivated,
);

export const getIsFirstMapDotCompleted = createSelector(
  selectFirstMapDot,
  dottOrNull => dottOrNull?.status === 'DONE',
);
// return value:
// true if the entire map is completed(including possible outro and/or verification)
// false if the map is not completed
// null if we dont know yet(we are still waiting for the answer)
export const getIsMapCompleted = createSelector(
  selectIsMapActivated,
  getMapVerification,
  selectMapOutro,
  getIsAllMapDotsCompleted,
  selectMapIsOutroCompleted,
  getIsMapVerified,
  (isMapActivated, mapVerification, mapOutro, allDotsInMapCompleted, outroIsDone, verifyIsDone) => {
    if (mapVerification === null || mapOutro === null || isMapActivated === null) return null;
    if (isMapActivated != null && !isMapActivated) return true;
    // uncomment the following to disable the map
    // return true;
    if (!mapVerification && !mapOutro) return allDotsInMapCompleted === null ? null : allDotsInMapCompleted;
    if (mapVerification && !mapOutro) return verifyIsDone === null ? null : verifyIsDone;
    if (mapOutro) return outroIsDone === null ? null : outroIsDone;

    return null;
  },
);

export const a = 123;

const findDottIndex = (dotts, statusOrId) => {
  if (!dotts?.length) return null;

  const isId = typeof statusOrId === 'number';

  return isId
    ? dotts.findIndex(({id}) => id === statusOrId)
    : dotts.findIndex(({status}) => status === statusOrId);
};

const selectMapDottsIsNull = createSelector(
  selectMapDottsWithoutVerificationAndOutro,
  dotts => !dotts,
);

const selectMapIsReadyToShowVerification = createSelector(
  [
    selectMapCourseIsFetching,
    selectMapDottsIsNull,
    getIsMapVerified,
    getIsAllMapDotsCompleted,
  ],
  (mapCourseIsFetching, dottsIsNull, isMapVerified, isAllMapDotsCompleted) => {
    const readyToShowVerification = !!(
      isAllMapDotsCompleted
      && isMapVerified === false
      && !mapCourseIsFetching
      && !dottsIsNull
    );

    return readyToShowVerification;
  },
);

const selectMapFirstOpenDot = createSelector(
  [
    selectMapDottsWithoutVerificationAndOutro,
    getIsAllMapDotsCompleted,
  ],
  (dotts, isAllMapDotsCompleted) => {
    const firstOpenDot = isAllMapDotsCompleted || !dotts?.length
      ? null
      : dotts.find(({status}) => status === 'OPEN');

    return firstOpenDot;
  },
);

export const selectMapSelectedDotIndex = createSelector(
  [
    selectMapDottsWithoutVerificationAndOutro,
    getSelectedMapCourse,
  ],
  (dotts, selectedDot) => {
    const selectedDotIndex = !selectedDot?.id || !dotts?.length
      ? null
      : findDottIndex(dotts, selectedDot.id);

    return selectedDotIndex;
  },
);

const selectMapBackgroundPosition = createSelector(
  getConfigObject,
  configObject => configObject?.getProperty?.('params.map-img.backgroundPosition') || '80% 50%',
);

export const selectMapCompletedCoursesLength = createSelector(
  selectMapDottsWithoutVerificationAndOutro,
  dotts => dotts?.filter?.(({status}) => status === 'DONE')?.length || null,
);

export const selectMapBackground = createStructuredSelector({
  backgroundImage: selectMapBackgroundImageUrl,
  backgroundPosition: selectMapBackgroundPosition,
});

const selectIsLoadingVerification = createSelector(
  selectMapIsReadyToShowVerification,
  selectMapVerificationCompetence,
  (isReady, verificationCompetence) => isReady
    && verificationCompetence?.isFetching
    && !verificationCompetence?.error,
);

const selectMapShouldShowOutro = createSelector(
  getIsMapVerified,
  getIsAllMapDotsCompleted,
  selectMapOutro,
  selectMapIsOutroCompleted,
  (verified, completed, outro, outroDone) => !!(verified && completed && !!outro?.id && outroDone === false),
);

const selectMapShouldFetchVerificationCompetence = createSelector(
  selectMapIsReadyToShowVerification,
  selectMapVerificationCompetence,
  (isReady, verification) => isReady
    && !verification?.data
    && !verification?.isFetching
    && !verification?.error,
);

const selectMapVerificationId = createSelector(
  getMapVerification,
  verification => verification?.id,
);

const selectMapIsLoadingInitial = createSelector(
  selectMapTrackLoadStatus,
  getLoading,
  selectMapDottsIsNull,
  selectMapTrackIsFetching,
  selectIsLoadingVerification,
  selectTrackData,
  getIsMapVerified,
  selectMapIsOutroCompleted,
  (loadStatus, isLoading, isDottsNull, isLoadingTrack, isLoadingVerification, track, verified, outroDone) => loadStatus !== LoadStatuses.IS_LOADING_MORE
    && (isLoading
    || isDottsNull
    || isLoadingTrack
    || isLoadingVerification
    || !track && !(verified && !outroDone)),
);

const selectMapBadgeText = createSelector(
  selectMapSelectedDotId,
  selectMapBadge,
  (selectedDotId, badgeInfo) => selectedDotId === 'badge'
    ? badgeInfo?.text?.content || ''
    : null,
);

export const selectMapShouldShowCongratsAndVerifyPage = createSelector(
  selectMapIsReadyToShowVerification,
  selectMapVerificationCompetence,
  (isReady, verification) => isReady && !!verification?.data,
);

export const selectMapActiveCourseId = createSelector(
  selectMapShouldShowCongratsAndVerifyPage,
  selectMapSelectedDotId,
  (shouldShowCongratsAndVerifyPage, selectedDotId) => !shouldShowCongratsAndVerifyPage && selectedDotId,
);

export const selectMapStateForComponent = createStructuredSelector({
  badgeText: selectMapBadgeText,
  sticker: selectMapSticker,
  shouldShowCongratsAndVerifyPage: selectMapShouldShowCongratsAndVerifyPage,
  mapVerificationCompetence: selectMapVerificationCompetence,
  isLoading: selectMapIsLoadingInitial,
  noDots: selectMapDottsIsNull,
  status: selectMapSelectedDotStatus,
  selectedDotIndex: selectMapSelectedDotIndex,
  isOutroDone: selectMapIsOutroCompleted,
  mapTitle: selectMapTitle,
  mapDescription: selectMapDescription,
  loadStatus: selectMapTrackLoadStatus,
});

export const selectMapStateForHook = createStructuredSelector({
  shouldShowOutro: selectMapShouldShowOutro,
  shouldFetchVerificationCompetence: selectMapShouldFetchVerificationCompetence,
  verificationId: selectMapVerificationId,
  outro: selectMapOutro,
  dotts: selectMapDottsWithoutVerificationAndOutro,
  firstOpenDot: selectMapFirstOpenDot,
  backgroundImage: selectMapBackgroundImageUrl,
});

export const selectMapProps = createStructuredSelector({
  hideDotsAndPath: selectMapShouldHideDotsAndPath,
  activeCourseID: selectMapActiveCourseId,
  completedCourses: selectMapCompletedCoursesLength,
  allCompleted: getIsAllMapDotsCompleted,
  backgroundImage: selectMapBackgroundImageUrl,
  backgrondPosition: selectMapBackgroundPosition,
  finishedWithFirstMapRuntrough: selectMapIsOutroCompleted,
  badge: selectMapBadge,
  sticker: selectMapSticker,
  key: selectMapTrackId,
  grid: selectMapIsGridLayout,
});
