import React, {useCallback, useMemo} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {myEducation} from '@routes/routes.manifest';
import {getOrganisationId} from '@selectors/profile.selectors';
import {useModalsContext} from '@src/features/modals/context/modals-context';
import {getImageUrl} from '@utils/data.utils';
import {loadableRetry} from '@utils/loadable-retry';
import './person-avatar.scss';

const AvatarLayers = loadableRetry(() => {
  console.log('load avatar.layers');

  return import(/* webpackChunkName: "avatar.layers" */ './avatar-layers');
}, {name: 'avatar.layers'});

const LinkIf = ({to, href, children, ...props}) => {
  if (to) {
    return (
      <Link
        to={to}
        {...props}
      >
        {children}
      </Link>
    );
  }

  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        {children}
      </a>
    );
  }

  return children;
};

const getPersonColor = () => '#E6EBEF';

const getPersonInitials = (person = {}) => {
  if (person?.firstname || person?.lastname) return `${person?.firstname?.[0] || ''}${person?.lastname?.[0] || ''}`.toUpperCase();
  if (person?.fullname) return person.fullname.split(' ').map(name => name[0])
    .join('')
    .toUpperCase();

  return 'AA';
};

const PersonAvatar = React.memo(({
  imageUrl,
  editable,
  size,
  link,
  to,
  href,
  person: personData,
  className,
  style,
  fontSize,
  avatar: hasAvatar,
  notificationCount,
  isFetching,
  ...restProps
}) => {
  const orgId = useSelector(getOrganisationId);

  const avatarUrl = imageUrl || personData?.profile_image?.url;

  const avatar = useMemo(() => {
    if (hasAvatar && personData?.avatar) {
      try {
        return JSON.parse(personData.avatar);
      } catch {
        return null;
      }
    }

    return null;
  }, [hasAvatar, personData?.avatar]);

  const hasImage = avatarUrl && !avatar;

  const {personInitials, personColor, initialsStyle} = useMemo(() => hasImage
    ? {}
    : {
      personInitials: getPersonInitials(personData),
      personColor: getPersonColor(),
      initialsStyle: fontSize ? {fontSize} : {},
    }, [hasImage, personData, fontSize]);

  const clickable = link || to || editable;

  const hoverStyle = useMemo(() => ({
    backgroundColor: personColor,
    cursor: 'pointer',
  }), [personColor]);

  const history = useHistory();
  const {employee: {open: openEmployeeModal}} = useModalsContext();

  const handleClick = useCallback(e => {
    if (personData && link) {
      openEmployeeModal({
        userName: personData?.user_name,
        personId: personData?.person_id || personData?.id,
        orgId,
      });

      return;
    }

    if (href) {
      window.open(href);
    } else if (to) {
      history.push(to);
    } else if (editable) {
      history.push(myEducation.profileView.path);
    }
  }, [personData, link, href, to, editable, history, openEmployeeModal, orgId]);

  const wrapperStyle = useMemo(() => ({
    ...style,
    ...size && {
      width: size,
      height: size,
      minHeight: size,
      minWidth: size,
    },
  }), [style, size]);

  const imgStyle = useMemo(() => ({
    backgroundImage: hasImage ? `url(${getImageUrl(avatarUrl)})` : 'none',
    backgroundColor: personColor,
    ...clickable ? hoverStyle : {},
  }), [avatarUrl, personColor, clickable, hoverStyle, hasImage]);

  return (
    <LinkIf
      to={to || to !== false && (
        editable && myEducation.profileView.path
        || personData && link)}
      href={href}
      onClick={handleClick}
    >
      <div
        className={cx('person-avatar', className)}
        style={wrapperStyle}
      >
        <div
          className={cx('person-avatar__image')}
          title={editable ? 'Rediger profil' : personData?.fullname || ''}
          style={imgStyle}
          {...restProps}
        >
          {!!avatar
            && <AvatarLayers avatar={avatar} />
           || !hasImage && (
             <span
               className="person-avatar__initials"
               style={initialsStyle}
             >
               {personInitials}
             </span>
           )}
        </div>

        {!!notificationCount && (
          <div className="person-avatar__notification-count person-avatar__notification-count-enter-done">
            {notificationCount}
          </div>
        )}
      </div>
    </LinkIf>
  );
});

PersonAvatar.propTypes = {
  person: PropTypes.shape({}).isRequired,
  imageUrl: PropTypes.string,
  size: PropTypes.string,
  fontSize: PropTypes.string,
  editable: PropTypes.bool,
  avatar: PropTypes.bool,
  link: PropTypes.bool,
  notificationCount: PropTypes.number,
  to: PropTypes.string,
  href: PropTypes.string,
  noBackground: PropTypes.bool,
};

PersonAvatar.defaultProps = {
  imageUrl: undefined,
  size: undefined,
  fontSize: undefined,
  editable: false,
  avatar: false,
  link: false,
  notificationCount: 0,
  to: null,
  href: null,
  noBackground: false,
};

export default PersonAvatar;
