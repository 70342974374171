import {RESET_APP, RESET_STORE} from '@actions/global.actions';
import {isObjectWithKeys} from '@utils/misc.utils';
import {validateModalId} from '../util/validate';
import {MODALS_PUT_MODAL, MODALS_UPDATE_MODAL_STATE} from './actions';

const formatPayload = payload => {
  if (!Array.isArray(payload)) return payload;

  const [firstArg, secondArg] = payload;

  if (typeof firstArg === 'string') {
    return {
      modalId: firstArg,
      payload: secondArg || {},
    };
  }

  if (isObjectWithKeys(firstArg)) return firstArg;

  return payload;
};

export function createReducer(initialState) {
  return function reducer(state = initialState, action) {
    switch (action.type) {
    case RESET_STORE:
    case RESET_APP: {
      return initialState;
    }
    case MODALS_PUT_MODAL: {
      const {
        modalId = null,
        isOpen = true,
        closeAll = false,
        payload: modalPayload = {},
      } = formatPayload(action.payload);

      if (closeAll) return initialState;

      validateModalId(modalId); // This does nothing in prod, but will throw an error in dev

      if (!isOpen) {
        return {
          ...state,
          [modalId]: initialState[modalId],
        };
      }

      // validateModalPayload(modalId, modalPayload); // This does nothing in prod, but will throw an error in dev

      return {
        ...state,
        [modalId]: {
          ...initialState[modalId],
          ...modalPayload,
          isOpen: true,
        },
      };
    }
    case MODALS_UPDATE_MODAL_STATE: {
      const {
        modalId = null,
        payload: modalPayload = {},
      } = formatPayload(action.payload);

      validateModalId(modalId); // This does nothing in prod, but will throw an error in dev

      // validateModalPayload(modalId, modalPayload); // This does nothing in prod, but will throw an error in dev

      return {
        ...state,
        [modalId]: {
          ...state[modalId],
          ...modalPayload,
        },
      };
    }
    default: {
      return state;
    }
    }
  };
}
