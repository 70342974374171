import {getItem, isValidArgObj, partitionDate} from '@components/calendar-with-tooltip/util.jsx';
import {i18n} from '@src/i18n';
import {fastDeepEquals} from '@src/store/util/fast-deep-equals';
import {roleIsPosition} from '@utils/roles.utils';
import {parseDate} from '@utils/time.utils';

export const arrayIncludesObject = (array, object) => Array.isArray(array) && array.some(el => fastDeepEquals(object, el));

export const mapDataToCalendarItems = (args, {
  data,
  keys,
  eventType,
  options,
} = args) => {
  if (!Array.isArray(args)) {
    if (!isValidArgObj(args)) return null;
    args = [args];
  }

  const items = {};

  args.forEach(arg => {
    const {
      data,
      eventType,
      keys,
      options,
    } = arg;

    const {hideDeadlines, hideEnding} = options || {};

    if (!data?.length) return;

    data.forEach?.(item => {
      const {
        dateStart,
        dateEnd,
        title,
        id,
        location,
      } = getItem(item, keys);

      const {dayNumber: startDayNum, yearMonthStr: startYM} = partitionDate(dateStart) || {};
      const {dayNumber: endDayNum, yearMonthStr: endYM} = partitionDate(dateEnd) || {};

      if (!(startDayNum != null && startYM != null || endDayNum != null && endYM != null)) return;

      if (startYM != null && !(startYM in items)) {
        items[startYM] = {};
      }

      if (startDayNum != null && !(startDayNum in items[startYM])) {
        items[startYM][startDayNum] = [];
      }

      const endOnSameDay = `${startYM}-${startDayNum}` === `${endYM}-${endDayNum}`;

      const calendarItem = {
        data: item,
        title,
        id,
        location,
        eventType,
        dateStart,
        dateEnd,
        endOnSameDay,
      };

      if (endOnSameDay) {
        calendarItem.dateType = 'single_day';

        if (items?.[startYM]?.[startDayNum]) items[startYM][startDayNum].push(calendarItem);

        return;
      }

      calendarItem.dateType = 'start';

      if (items?.[startYM]?.[startDayNum]) items[startYM][startDayNum].push(calendarItem);

      if (!hideEnding) {
        if (endYM != null && !(endYM in items)) {
          items[endYM] = {};
        }

        if (endDayNum != null && items?.[endYM] && !(endDayNum in items[endYM])) {
          items[endYM][endDayNum] = [];
        }

        if (endDayNum != null && items?.[endYM]?.[endDayNum]) items[endYM][endDayNum].push({
          ...calendarItem,
          dateType: 'end',
        });
      }

      if (item?.sign_on_deadline) {
        if (hideDeadlines) return;

        const deadline = partitionDate(parseDate(item.sign_on_deadline));

        if (deadline?.date == null || deadline?.dayNumber == null || deadline?.yearMonthStr == null) {
          return;
        }

        const [
          deadlineDate,
          deadlineDayNum,
          deadlineYM,
        ] = [deadline.date, deadline.dayNumber, deadline.yearMonthStr];

        if (deadlineYM != null && !(deadlineYM in items)) {
          items[deadlineYM] = {};
        }

        if (deadlineDayNum != null && items?.[deadlineYM] && !(deadlineDayNum in items[deadlineYM])) {
          items[deadlineYM][deadlineDayNum] = [];
        }

        const deadlineObj = {
          data: item,
          title,
          id,
          eventType,
          location,
          dateStart: deadlineDate,
          dateEnd: deadlineDate,
          endOnSameDay: true,
          dateType: 'deadline',
        };

        if (items?.[deadlineYM]?.[deadlineDayNum] && !arrayIncludesObject(items[deadlineYM][deadlineDayNum], deadlineObj)) {
          items[deadlineYM][deadlineDayNum].push(deadlineObj);
        }
      }
    });
  });

  return items;
};

export const getActionbarItems = actionBarConfig => {
  const items = [];

  if (!actionBarConfig || actionBarConfig.add) {
    items.push({
      label: i18n('employees.add-employee'),
      value: 'add-employee',
    });
  }

  if (!actionBarConfig || actionBarConfig.move) {
    items.push({
      label: i18n('employees.move-employee'),
      value: 'move-employee',
    });
  }

  if (!actionBarConfig || actionBarConfig.export) {
    items.push({
      label: i18n('employees.export-to-excel'),
      value: 'export-excel',
    });
  }

  return items;
};

export const getEmployeePosition = (orgId, roles) => {
  if (!roles?.length) return '';

  const targetRole = roles?.find(roleIsPosition);

  return targetRole?.role_display_name || targetRole?.title || '';
};
