import {select, take} from 'redux-saga/effects';
import {appInjectReduxModule, selectReduxModuleLoadStatuses} from '@actions/app.actions';
import {authUserIsConfirmedAuthorized} from '@actions/auth.actions';
import {
  profileFetchPerson,
  profileFetchPersonFull,
  profileSetActiveOrgId,
} from '@actions/profile.actions';
import {userSetPartial, userSuccess} from '@actions/user.actions';
import {
  getOrganisationId,
  getProfile,
  selectProfileId,
  selectProfileUserName,
} from '@selectors/profile.selectors';
import {LoadStatuses} from '@types/load.types';
import {getBartId} from '@utils/data.utils';
import {isObjectWithKeys} from '@utils/misc.utils';
import {validIntOrNull} from '@utils/number.utils';

const __IS_DEV__ = process.env.NODE_ENV === 'development';

// common util sagas

export function* waitForInjectedReduxModule(moduleId) {
  const statuses = yield select(selectReduxModuleLoadStatuses);

  if (__IS_DEV__ && (!moduleId || !statuses.hasOwnProperty(moduleId))) {
    throw new Error(`Status for redux-module ${moduleId} not found`);
  }

  while (statuses[moduleId] !== LoadStatuses.LOADED) {
    const action = yield take(`${appInjectReduxModule.success}`);

    if (action?.payload === moduleId) return true;
  }

  return true;
}

export function* waitForProfile(returnProfile) {
  let profile = yield select(getProfile);

  if (profile?.data?.person_id != null) return returnProfile ? profile : null;

  while (true) {
    yield take([
      `${profileFetchPersonFull.success}`,
      `${profileFetchPerson.success}`,
    ]);
    profile = yield select(getProfile);

    if (profile?.data?.person_id) return returnProfile ? profile : null;
  }
}

export function* waitForOrgId() {
  let orgId = yield select(getOrganisationId);

  if (orgId) return orgId;

  while (true) {
    const action = yield take(`${profileSetActiveOrgId}`);

    orgId = validIntOrNull(action?.payload?.orgId || action?.payload) || (yield select(getOrganisationId));

    if (orgId) return orgId;
  }
}

export function* waitForUsername() {
  const currentUsername = yield select(selectProfileUserName);

  if (currentUsername) return currentUsername;

  while (true) {
    const action = yield take([
      `${authUserIsConfirmedAuthorized().type}`,
      `${userSuccess().type}`,
      `${userSetPartial().type}`,
      `${profileFetchPerson.success}`,
      `${profileFetchPersonFull.success}`,
    ]);

    const bartId = getBartId();

    if (bartId != null) {
      const payloadPid = action?.payload?.person?.person_id
        || action?.payload?.person_id
        || action?.payload?.authProfile?.person_id;

      if (payloadPid && bartId !== payloadPid) {
        continue;
      }
    }

    const payloadUsername = action?.payload?.person?.user_name
      || action?.payload?.username
      || action?.payload?.authProfile?.user_name;

    if (payloadUsername) return payloadUsername;

    const username = yield select(selectProfileUserName);

    if (username) return username;
  }
}

export function* waitForProfileId() {
  const currentPid = getBartId() ?? (yield select(selectProfileId));

  if (currentPid) return currentPid;

  while (true) {
    const action = yield take([
        `${userSuccess().type}`,
        `${userSetPartial().type}`,
        `${profileFetchPerson.success}`,
    ]);

    const {payload} = action || {};

    if (!isObjectWithKeys(payload)) continue;

    const pid = payload.personId
      ?? payload.person?.person_id
      ?? payload.person_id
      ?? payload.authProfile?.person_id;

    if (pid != null) return pid;

    const personId = yield select(selectProfileId);

    if (personId) return personId;
  }
}
