const prefix = 'map';

export const SET_MAP_COURSES_DATA = `${prefix} SET MAP COURSES DATA`;

export const setMapCoursesData = payload => ({
  type: SET_MAP_COURSES_DATA,
  payload,
});

export const INSERT_OR_UPDATE_CHILDREN_COMPETENCES = `${prefix} INSERT OR UPDATE CHILDREN COMPETENCES`;

export const insertOrUpdateChildrenCompetences = payload => ({
  type: INSERT_OR_UPDATE_CHILDREN_COMPETENCES,
  payload,
});

export const FETCH_TRACK = 'FETCH_TRACK';
export const FETCH_TRACK_REQUEST = 'FETCH_TRACK_REQUEST';
export const FETCH_TRACK_SUCCEEDED = 'FETCH_TRACK_SUCCEEDED';
export const FETCH_TRACK_FAILED = 'FETCH_TRACK_FAILED';

export const fetchTrack = payload => ({
  type: FETCH_TRACK,
  payload,
});

export const fetchTrackRequest = payload => ({
  type: FETCH_TRACK_REQUEST,
  payload,
});

export const fetchTrackSuccess = payload => ({
  type: FETCH_TRACK_SUCCEEDED,
  payload,
});

export const fetchTrackFailure = payload => ({
  type: FETCH_TRACK_FAILED,
  payload,
});

export const FETCH_TRACK_EXTRA_DATA = 'FETCH_TRACK_EXTRA_DATA';
export const FETCH_TRACK_EXTRA_DATA_REQUEST = 'FETCH_TRACK_EXTRA_DATA_REQUEST';
export const FETCH_TRACK_EXTRA_DATA_SUCCEEDED = 'FETCH_TRACK_EXTRA_DATA_SUCCEEDED';
export const FETCH_TRACK_EXTRA_DATA_FAILED = 'FETCH_TRACK_EXTRA_DATA_FAILED';
export const fetchTrackExtraData = payload => ({
  type: FETCH_TRACK_EXTRA_DATA,
  payload,
});
export const fetchTrackExtraDataRequest = payload => ({
  type: FETCH_TRACK_EXTRA_DATA_REQUEST,
  payload,
});
export const fetchTrackExtraDataSuccess = payload => ({
  type: FETCH_TRACK_EXTRA_DATA_SUCCEEDED,
  payload,
});
export const fetchTrackExtraDataFailure = payload => ({
  type: FETCH_TRACK_EXTRA_DATA_FAILED,
  payload,
});

export const FETCH_MAP_COURSES = `${prefix} FETCH MAP COURSES`;
export const FETCH_MAP_COURSES_REQUEST = `${prefix} FETCH MAP COURSES REQUEST`;
export const FETCH_MAP_COURSES_SUCCEEDED = `${prefix} FETCH MAP COURSES REQUEST SUCCEEDED`;
export const FETCH_MAP_COURSES_FAILED = `${prefix} FETCH MAP COURSES REQUEST FAILED`;

export const fetchMapCourses = payload => ({
  type: FETCH_MAP_COURSES,
  payload,
});

export const fetchMapCoursesRequest = payload => ({
  type: FETCH_MAP_COURSES_REQUEST,
  payload,
});

export const fetchMapCoursesSucceeded = payload => ({
  type: FETCH_MAP_COURSES_SUCCEEDED,
  payload,
});

export const fetchMapCoursesFailed = payload => ({
  type: FETCH_MAP_COURSES_FAILED,
  payload,
});

export const FETCH_MAP_COMPETENCE = 'FETCH_MAP_COMPETENCE';
export const FETCH_MAP_COMPETENCE_REQUEST = 'FETCH_MAP_COMPETENCE_REQUEST';
export const FETCH_MAP_COMPETENCE_SUCCEEDED = 'FETCH_MAP_COMPETENCE_SUCCEEDED';
export const FETCH_MAP_COMPETENCE_FAILED = 'FETCH_MAP_COMPETENCE_FAILED';

export const fetchMapCompetence = payload => ({
  type: FETCH_MAP_COMPETENCE,
  payload,
});

export const fetchMapCompetenceRequest = payload => ({
  type: FETCH_MAP_COMPETENCE_REQUEST,
  payload,
});

export const fetchMapCompetenceSucceeded = payload => ({
  type: FETCH_MAP_COMPETENCE_SUCCEEDED,
  payload,
});

export const fetchMapCompetenceFailed = payload => ({
  type: FETCH_MAP_COMPETENCE_FAILED,
  payload,
});

export const FETCH_MAP_VERIFICATION_COMPETENCE = `${prefix} FETCH MAP VERIFICATION_COMPETENCE`;
export const FETCH_MAP_VERIFICATION_COMPETENCE_REQUEST = `${prefix} FETCH MAP VERIFICATION_COMPETENCE REQUEST`;
export const FETCH_MAP_VERIFICATION_COMPETENCE_SUCCEEDED = `${prefix} FETCH MAP VERIFICATION_COMPETENCE REQUEST SUCCEEDED`;
export const FETCH_MAP_VERIFICATION_COMPETENCE_FAILED = `${prefix} FETCH MAP VERIFICATION_COMPETENCE REQUEST FAILED`;

export const fetchMapVerificationCompetence = payload => ({
  type: FETCH_MAP_VERIFICATION_COMPETENCE,
  payload,
});

export const fetchMapVerificationCompetenceRequest = () => ({type: FETCH_MAP_VERIFICATION_COMPETENCE_REQUEST});

export const fetchMapVerificationCompetenceSucceeded = payload => ({
  type: FETCH_MAP_VERIFICATION_COMPETENCE_SUCCEEDED,
  payload,
});

export const fetchMapVerificationCompetenceFailed = payload => ({
  type: FETCH_MAP_VERIFICATION_COMPETENCE_FAILED,
  payload,
});

export const SET_VERIFICATION = `${prefix}/SET_VERIFICATION`;
export const SET_VERIFICATION_REQUEST = `${prefix}/SET_VERIFICATION/REQUEST`;
export const SET_VERIFICATION_SUCCESS = `${prefix}/SET_VERIFICATION/SUCCESS`;
export const SET_VERIFICATION_FAILURE = `${prefix}/SET_VERIFICATION/FAILURE`;

export const setVerification = payload => ({
  type: SET_VERIFICATION,
  payload,
});

export const setVerificationRequest = () => ({type: SET_VERIFICATION_REQUEST});

export const setVerificationSuccess = payload => ({
  type: SET_VERIFICATION_SUCCESS,
  payload,
});

export const setVerificationFailure = payload => ({
  type: SET_VERIFICATION_FAILURE,
  payload,
});

export const SELECT_MAP_COURSE = `${prefix} SELECT MAP COURSE`;

export const selectMapCourse = payload => ({
  type: SELECT_MAP_COURSE,
  payload,
});

export const SET_MAP_IS_VERIFIED = `${prefix} SET MAP IS VERIFIED`;

export const setMapIsVerified = payload => ({
  type: SET_MAP_IS_VERIFIED,
  payload,
});

export const SET_MAP_IS_NOT_VERIFIED = `${prefix} SET MAP IS NOT VERIFIED`;

export const setMapIsNotVerified = payload => ({
  type: SET_MAP_IS_NOT_VERIFIED,
  payload,
});

export const SET_OUTRO_IS_COMPLETED = `${prefix} SET OUTRO IS COMPLETED`;

export const setOutroIsCompleted = payload => ({
  type: SET_OUTRO_IS_COMPLETED,
  payload,
});

export const SET_OUTRO_IS_NOT_COMPLETED = `${prefix} SET OUTRO IS NOT COMPLETED`;

export const setOutroIsNotCompleted = payload => ({
  type: SET_OUTRO_IS_NOT_COMPLETED,
  payload,
});

export const TRACK_SET_COMPETENCE_CHILDREN = `${prefix} TRACK_SET_COMPETENCE_CHILDREN`;

export const trackSetCompetenceChildren = payload => ({
  type: TRACK_SET_COMPETENCE_CHILDREN,
  payload,
});

export const MAP_UNLOCK_NEXT_DOTT = `${prefix} MAP_UNLOCK_NEXT_DOTT`;

export const mapUnlockNextDott = payload => ({
  type: MAP_UNLOCK_NEXT_DOTT,
  payload,
});
