import {
  updateFetchStateFailure,
  updateFetchStateRequest,
  updateFetchStateSuccess,
} from '@utils/loadstatus.utils';
import {isObjectWithKeys} from '@utils/misc.utils';

const DEV = process.env.NODE_ENV === 'development';

const defaultReducers = {
  request: updateFetchStateRequest,
  success: updateFetchStateSuccess,
  failure: updateFetchStateFailure,
};

const defaultActions = {
  request: true,
  success: true,
  failure: true,
};

const getUpdateFunction = (actionKey, stateKey, options) => {
  if (!options?.[actionKey]) return null;

  const defaultReducer = defaultReducers?.[actionKey];
  const extraReducer = options?.[actionKey];

  return function updateImmerDraft(draftState, action) {
    try {
      if (typeof extraReducer === 'function') {
        const res = extraReducer(draftState, action);

        if (res === false) return;
      }

      if (defaultReducer) defaultReducer(draftState[stateKey], action?.payload);
    } catch (error) {
      console.error(error);
    }
  };
};

export function createDefaultFetchActionsAndReducers(initialState, stateKey, options) {
  if (DEV && !initialState?.[stateKey]) {
    throw new Error(`No state with key ${stateKey} found`);
  }

  const {
    request = true,
    success = true,
    failure = true,
  } = options || defaultActions;

  const actions = {
    ...!!request && {request: getUpdateFunction('request', stateKey, options || defaultActions)},
    ...!!success && {success: getUpdateFunction('success', stateKey, options || defaultActions)},
    ...!!failure && {failure: getUpdateFunction('failure', stateKey, options || defaultActions)},
  };

  return isObjectWithKeys(actions) ? actions : null;
};
