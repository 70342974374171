import * as React from 'react';
import memoize from 'micro-memoize';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {modalsCloseModal} from './store/actions';
import {selectModals} from './store/selectors';
import {
  modalComponents,
  modalIdsByAccessLevel,
  modalOptions,
} from './registry';

const isDev = process.env.NODE_ENV === 'development';

const selectByAccessLevel = memoize(accessLevel => createSelector(
  selectModals,
  state => modalIdsByAccessLevel?.[accessLevel]?.reduce((acc, id) => {
    acc[id] = state[id];

    return acc;
  }, {}),
));

const selectRenderedIds = memoize(accessLevel => createSelector(
  selectByAccessLevel(accessLevel),
  state => Object.keys(state).filter(id => state[id]?.isOpen || modalOptions[id]?.renderAllways),
  {
    // New in 4.1: Pass options through to the built-in `defaultMemoize` function
    memoizeOptions: {
      // equalityCheck: shallowEqual,
      maxSize: 50,
      resultEqualityCheck: shallowEqual,
    },
  },
));

// renders all modals defined in ./registry.js, for the given access level
// this is to allow lazy loading of modal components based on the user's access level
// if a modal is not open, it will not be rendered, unless it has renderAllways option
export const ModalsRenderer = React.memo(({accessLevel = 0}) => {
  const dispatch = useDispatch();
  const renderedIds = useSelector(selectRenderedIds(accessLevel));

  const renderedElements = React.useRef({});

  const handleClose = React.useCallback(id => {
    dispatch(modalsCloseModal(id));
    if (!modalOptions[id]?.renderAllways) renderedElements.current[id] = null;
  }, [dispatch]);

  return (
    <React.Fragment key={accessLevel}>
      {renderedIds.map(id => {
        if (renderedElements.current[id]) {
          return renderedElements.current[id];
        }

        const Component = modalComponents[id];

        if (!Component) {
          if (isDev) throw new Error(`Modal component for id "${id}" not found!`);

          return null;
        }

        // return (
        //   <Component
        //     key={id}
        //     close={handleClose.bind(null, id)}
        //   />
        // );

        renderedElements.current[id] = (
          <Component
            key={id}
            close={handleClose.bind(null, id)}
          />
        );

        return renderedElements.current[id];
      })}
    </React.Fragment>
  );
});
