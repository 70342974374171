import axios from 'axios';
import {call, delay, put, race, select, take} from 'redux-saga/effects';
import {AUTH_LOGOUT_REQUEST, authUserIsConfirmedNotAuthorized} from '@actions/auth.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import {backendUrlV2} from '@config';
import {selectIsLoggedIn} from '@selectors/auth.selectors';
import {selectProfileId} from '@selectors/profile.selectors';
import {getBartId} from './data.utils';

export const is401error = error => [
  error?.status,
  error?.request?.status,
  error?.response?.status,
  error?.errorcode,
  error?.request?.errorcode,
  error?.response?.errorcode,
  error?.code,
  error?.request?.code,
  error?.response?.code,
  error?.response?.data?.errorcode,
  error?.response?.data?.status,
  error?.response?.data?.code,
].includes(401);

export function* handle401error(error) {
  const isLoggedIn = yield select(selectIsLoggedIn);
  const personId = yield select(selectProfileId);

  let isLoggedOut = !isLoggedIn || !personId;

  if (!isLoggedOut) {
    try {
      const {data} = yield call(axios.request, {
        method: 'GET',
        url: `${backendUrlV2}/user/me`,
        withCredentials: true,
      });

      if (
        data.person_id
        && personId !== data.person_id
        && data.person_id !== getBartId()
      ) isLoggedOut = true;
    } catch (error_) {
      if (is401error(error_)) isLoggedOut = true;
    }
  }

  if (isLoggedOut) {
    yield put(authUserIsConfirmedNotAuthorized());
    const pathname = window.location.pathname;

    if (!pathname.includes('/login')) {
      yield put(notificationsAdd({
        notification: {
          color: 'red',
          text: 'Økt utløpt. Vennligst logg inn på nytt.',
        },
      }));
    }
  } else {
    // yield put(notificationsAdd({
    //   notification: {
    //     color: 'red',
    //     text: 'Du har ikke tilgang til denne siden.',
    //   },
    // }));
  }

  throw error;
}

function* retrySaga(
  callback,
  attempts = 1,
  wait = 2000,
  ...args
) {
  for (let i = 0; i <= attempts; i += 1) {
    try {
      const [logout, response] = yield race([
        take(AUTH_LOGOUT_REQUEST),
        call(callback, ...args),
      ]);

      if (logout) throw new Error('Cancelled');

      return response;
    } catch (error) {
      if (error?.message === 'Cancelled') throw error;

      // on 401 unauthorized error, check if we are currently logged in
      if (is401error(error)) {
        yield call(handle401error, error);
      }

      if (i < attempts) {
        yield delay(wait);
      } else {
        throw error;
      }
    }
  }

  return callback;
}

export const retry = function(callback, attempts, wait) {
  return call(retrySaga, callback, attempts, wait);
};
