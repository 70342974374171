import axios from 'axios';
import {call, put} from 'redux-saga/effects';
import {apiFetchCoursegroups} from '@actions/api.actions';
import {backendUrlV2} from '@config';
import {waitForOrgId} from '@sagas/app.sagas';
import {retry} from '@utils/sagas.utils';

export function* fetchCoursegroupsAPI(action) {
  const orgId = action?.payload?.orgId || (yield call(waitForOrgId));

  try {
    yield put(apiFetchCoursegroups.request({orgId}));

    const {data} = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrlV2}/coursegroups/`,
      withCredentials: true,
    }));

    yield put(apiFetchCoursegroups.success({
      orgId,
      data,
    }));

    return data;
  } catch (error) {
    console.error(error);

    yield put(apiFetchCoursegroups.failure({
      orgId,
      error,
    }));

    return null;
  }
}
