export const no = {
  globals: {
    to: 'Til',
    from: 'Fra',
    'no-period-selected': 'Ingen tidsperiode valgt.',
    'change-period': 'Endre periode',
    submit: 'Fullfør',
    close: 'Lukk',
    'color-code': 'Fargekode',
    'error-loading-competence': 'Kunne ikke laste kompetansen',
    'profile-image-delete-success': 'Profilbildet ble slettet',
    resources: 'Ressurser',
    'your-resources': 'Dine ressurser',
    'no-employees-selected': 'Ingen ansatte valgt',
    'loading-progression': 'Laster progresjon',
    completions: 'Gjennomføringer',
    'delete-x': 'Slett [x]',
    'delete-confirm': 'Er du sikker på at du vil slette dette elementet?',
    'delete-confirm-no-undo':
      'Er du sikker på at du vil slette dette elementet? Handlingen kan ikke reverseres.',
    'delete-confirm-x': 'Er du sikker på at du vil slette [x]?',
    'delete-confirm-x-no-undo':
      'Er du sikker på at du vil slette [x]? Handlingen kan ikke reverseres.',
    'action-no-undo': 'Handlingen kan ikke reverseres.',
    'x-deleted': '[x] slettet',
    'error-occurred': 'En feil oppstod',
    'error-try-again': 'En feil oppstod. Vennligst prøv igjen.',
    'all-completions': 'Alle gjennomføringer',
    'show-all-completions': 'Vis alle gjennomføringer',
    'invalid-phone-number': 'Ugyldig telefonnummer',
    reflection: 'Refleksjon',
    text: 'Tekst',
    bulletpoints: 'Bulletpoints',
    quiz: 'Quiz',
    user: 'Bruker',
    tasks: 'Oppgaver',
    deadline: 'Frist',
    play: 'Spill av',
    sign: 'Signer',
    email: 'E-post',
    locked: 'Låst',
    show: 'Vis',
    register: 'Registrer',
    start: 'Start',
    scale: 'Skala',
    'invalid-date': 'Ugyldig dato',
    textfield: 'Tekstfelt',
    'dott-element': '«Dott» (element)',
    question: 'Spørsmål',
    evaluation: 'Evaluering',
    'input-missing-fields': 'Vennligst fyll ut alle obligatoriske felt',
    role: 'Rolle',
    competence: 'Kompetanse',
    'the-competence': 'Kompetansen',
    opens: 'Åpnes',
    type: 'Type',
    'last-changed': 'Sist endret',
    completion: 'Gjennomføring',
    'this-completion': 'Denne gjennomføringen',
    'all-completions-of-this-competence':
      'Alle gjennomføringer av denne kompetansen',
    'no-users': 'Ingen brukere',
    'opened-after': 'Åpnes etter',
    'add-image': 'Last opp fil',
    active: 'Aktivt',
    deactivated: 'Deaktivert',
    'page-no-x': 'Side [x]',
    'toggle-datepicker': 'Åpne/lukke datovelger',
    'save-close': 'Lagre og lukk',
    minutesShort: 'min',
    'new': 'Ny',
    create: 'Legg til',
    upload: 'Filen ble lastet opp',
    image: 'Bilde',
    no: 'Nei',
    yes: 'Ja',
    editing: 'Redigerer',
    'theme-pick': 'Velg tema',
    'hex-value': 'eller skriv inn hex-verdi',
    'company-logo': 'Logo',
    'company-logo-upload-txt': 'Last opp logo',
    open: 'Åpne',
    'found-no-users': 'Finner ingen brukere',
    'found-no-competences': 'Fant ingen kompetanser',
    progression: 'Progresjon',
    cancel: 'Avbryt',
    logout: 'Logg ut',
    search: 'Søk',
    'enter-text': 'Skriv inn navn på person som du søker eller navn på bedrift',
    save: 'Lagre',
    'save-theme': 'Lagre tema',
    'saved-success': 'Lagret!',
    saving: 'Lagrer',
    home: 'Hjem',
    back: 'Tilbake',
    'something-went-wrong': 'Noe gikk galt',
    next: 'Neste',
    previous: 'Forrige',
    'next-page': 'Neste side',
    'previous-page': 'Forrige side',
    'first-page': 'Første side',
    'last-page': 'Siste side',
    done: 'Ferdig',
    hours: 'Timer',
    'track-done': 'Kompetanseløp er fullført',
    all: 'Alle',
    now: 'Nå',
    confirm: 'Send',
    grade: 'karakter',
    updating: 'Oppdaterer',
    'go-back': 'Tilbake',
    'has-read': 'Lest',
    'clear-search': 'Fjern søk',
    'remove-search': 'Nullstill',
    fetching: 'Henter data',
    allok: 'Bekreft',
    hide: 'Skjul',
    download: 'Last ned',
    'requirement-deleted': 'Kravet er fjernet',
    'delete': 'Slett',
    deleting: 'Fjerner',
    approve: 'Godkjenn',
    reject: 'Avvis',
    'saving-data': 'Lagrer data',
    choose: 'Velg',
    'fetching-data': 'Henter data',
    change: 'Endre',
    add: 'Legg til',
    loading: 'Laster',
    'loading-page': 'Laster',
    'loading-nano': 'Laster bit-learning',
    edit: 'Rediger',
    'show-all': 'Vis alle',
    'create-new': 'Opprett ny',
    'required-field': 'Må fylles ut',
    'invalid-year-field': 'Ugyldig årstall',
    'read-more': 'Les mer',
    ok: 'OK',
    and: 'og',
    others: 'andre',
    'view-all': 'Vis alle',
    competencelevel: 'Kompetansenivå',
    'competencelevel-sub': 'Totalt kompetansenivå',
    'unsupported-browser':
      'Du er i en nettleser som ikke støttes. For å komme til den nye Læringsportalen må du åpne en annen nettleser.',
    name: 'Navn',
    'last-updated': 'Sist oppdatert',
    about: 'Om',
    'open-close-toggle': 'Åpne/Lukke',
    date: 'Dato',
    duration: 'Varighet',
    'sort-alpha': 'A-Å',
    'sort-alpha-desc': 'Å-A',
    'you-sure': 'Er du sikker?',
    send: 'Send',
    password: 'Passord',
    you: 'Deg',
    username: 'Brukernavn',
    choice: 'Valg',
    move: 'Flytt',
    error: 'Error',
    completed: 'Gjennomført',
    'mandatory-field': 'Obligatorisk felt',
    searching: 'Søker',
    'no-results-found': 'Fant ingen resultater',
    'no-hits-for-search-x': 'Søket «[x]» ga ingen resultater',
    untitled: 'Uten tittel',
    'course-types': {
      verification: 'Verifikasjon',
      ecourse: 'E-Kurs',
      appraisal: 'Utviklingsplan',
      checklist: 'Sjekkliste',
      course: 'Kurs',
      practical_training: 'Praktisk trening',
      other_education_and_training: 'Annen trening',
      formalcompetence: 'Formalkompetanse',
      digital_signature: 'Digital signatur',
      complex: 'Kompleks kompetanse',
      equivalents: 'Alternative kompetanser',
      nano: 'Bit',
      track: 'Læringsløp',
      certificate: 'Sertifikat',
      checklist_item: 'Sjekklistepunkt',
      // track: 'Spor',
      content: 'Innhold',
      Content: 'Innhold',
      group: 'Modulkurs',
      skill: 'Ferdighet',
    },
    'click-to-expand': 'Klikk for å utvide',
    'click-to-collapse': 'Klikk for å lukke',
    'goto-x': 'Gå til [x]',
    reset: 'Nullstill',
    'avg-reading-time': 'Gj. snitt tid',
    repeat: 'Repeter',
    'load-more': 'Last inn flere',
    'unsaved-prompt':
      'Er du sikker på at du vil lukke siden? Endringer som ikke er lagret vil bli tapt.',
    'email-sendt': 'E-post er sendt',
    remove: 'Fjern',
    saved: 'Lagret',
    'delete-success': 'Filen ble slettet',
    replace: 'Erstatte',
    status: {
      completed: 'Fullført',
      passed: 'Fullført',
      'in-progress': 'Ikke fullført',
      'not-started': 'Ikke startet',
      'not-available': 'Ikke tilgjengelig',
      waiting: 'Venter på godkjenning',
      'waiting-for-approval': 'Venter på godkjenning',
      approved: 'Godkjent',
      rejected: 'Avvist',
      'not-submitted': 'Ikke sendt inn',
      draft: 'Utkast',
      registered: 'Registrert',
      'competence-registered': 'Kompetansen ble registrert',
      'x-percent-completed': '[x]% fullført',
      expired: 'Utløpt',
      'expired-at-x': 'Utløpt [x]',
    },
  },
  notifications: {
    'file-uploaded': 'Filen ble lastet opp',
    'something-went-wrong': 'Noe gikk galt',
  },
  track: {
    'open-learningpath': 'Åpne læringstiltak',
    'your-track': 'Ditt opplæringsløp ',
    progress: 'Du har fullført [x] av [y] oppgaver',
    'you-must-do': 'Åpnes etter [x]',
    'my-assignments': 'Mine oppgaver',
    'estimated-time': 'Estimert tid',
    tasks: 'Oppgaver',
    're-take': 'Ta læringsløp på nytt',
    'no-elements-in-path': 'Dette læringsløpet har ingen elementer...',
    'one-task-remaining': '1 oppgave gjenstår',
    'x-tasks-remaining': '[x] oppgaver gjenstår',
    'x-of-y-tasks-completed': '[x] av [y] oppgaver fullført',
  },
  manage: {
    roles: 'Roller',
    'roles-text':
      'Administrer aktive roller i din organisasjon. Klikk på "Legg til" for å opprette en ny rolle, eller klikk på en eksisterende rolle for å redigere dens kompetanseprofil.',
    'requirements-recommended': 'Antall krav / anbefalt',
    'users-with-role': 'Personer som har rollen',
    'no-users-with-role': 'Ingen av dine brukere har denne rollen',
    'no-roles-title': 'Ingen roller funnet',
    'no-roles-text':
      'Ingen roller funnet. Klikk på "Legg til" for å opprette en ny rolle.',
    'no-competences-title': 'Ingen kompetanser funnet',
    'no-competences-text':
      'Klikk på "Legg til" for å opprette en ny kompetanse.',
    competences: 'Kompetanser',
    'competences-text': 'Endre og legg til kompetanser',
    tracks: 'Læringsløp',
    'tracks-text': 'Endre og legg til læringsløp',
  },
  mypage: {
    expires: 'Kompetanser som utløper',
    'my-course-enrollments': 'Kurspåmeldinger',
    'my-course-enrollments-waitlist': 'Du er på venteliste',
  },
  map: {
    'open-preview': 'Vis læringsløp',
    'open-content': 'Innhold',
    'create-new-bit': 'Lag ny',
    'my-progress': 'Min framgang',
    'search-for-competences': 'Søk etter kompetanser',
    'error-creating-map':
      'Læringsløpet eller elementer i læringsløpet ble ikke opprettet.',
    'open-stats': 'Åpne',
    headers: ['Navn', 'Antall tiltak', 'Sist oppdatert', 'Status'],
    'map-draft': 'Læringsløp er satt som kladd',
    'remove-dot-item': 'Fjerne læringstiltak?',
    'remove-dot-item-txt':
      'Er du sikker på at du ønsker å fjerne dette tiltaket?<br/><br/>Da fjerner du alt innholdet og alle tilknyttede gjennomføringer for alle brukere',
    'new-course': 'Nytt opplæringstiltak',
    week: 'Uke',
    'competence-map': 'Kompetansekart',
    'remove-map': 'Fjern læringstiltak',
    'map-active': 'Læringsløp er aktivt',
    locked: 'Låst',
    part: 'Del',
    start: {
      nano: 'Start',
      track: 'Åpne',
      other: 'Start',
    },
    'open-track': 'Åpne læringsløp',
    'map-removed': 'Læringsløpet er fjernet',
    'repeat-track': 'Repeter læringsløp',
    'start-your-track': 'Start ditt læringsløp',
    gohome: 'Gå til Ressursside',
    'verification-progress': 'Fremdrift',
    'remove-map-warning': 'Fjern læringstiltak',
    'remove-map-warning-txt':
      'Er du sikker på at du ønsker å fjerne dette tiltaket?<br/><br/>Da fjerner du alt innholdet og alle tilknyttede gjennomføringer for alle brukere',
    'map-not-found': 'Dette læringsløpet finnes ikke',
    'error-loading-map': 'Kunne ikke åpne læringsløpet',
    dots: {
      autostart: 'Autostart',
      name: 'Navn',
      type: 'Type',
    },
    'show-type-map': 'Kartvisning',
    'show-type-map-short': 'Kartvisning',
    'show-type-map-header': 'Læringsløp som kart',
    'show-type-map-text':
      'Presenter Læringsløpet som et kart (tvungen rekkefølge). Passer for kortere Læringsløp.',
    'show-type-track': 'Sammensatt Læringsløp',
    'show-type-track-header': 'Sammensatt Læringsløp',
    'show-type-track-short': 'Sammensatte',
    'show-type-track-text':
      'Presenter Læringsløpet sammensatt av ulike moduler og tiltak. Passer for større læringsløp.',
    'show-type-list': 'Listevisning',
    'show-type-list-short': 'Liste',
    'show-type-list-header': 'Læringsløp som liste',
    'show-type-list-text':
      'Presenter Læringsløpet i en lang liste med tiltak hvor brukeren fritt kan velge rekkefølge for gjennomføring.',
    settings: {
      nano: 'Innhold',
      maps: 'Kart',
      general: 'Generelt',
      title: 'Tittel på læringsløp',
      'title-text': 'Vises på toppen av ditt læringsløp',
      'delete': 'Fjern objekt',
      layout: 'Layout',
      'how-many-dots': 'Velg hvor mange elementer du ønsker å starte med',
      'active-color': 'Farge for aktiv',
      'completed-color': 'Farge for fullført',
      active: 'Aktivt',
      'use-badge-html': 'Tekst til merke',
      'use-badge-html-text':
        'Tekst som vises til brukeren ved fullført Læringsløp',
      'highscore-header': 'Topplister og merker',
      'highscore-header-text':
        'Hvis du har et læringsløp som inneholder spørsmål med poeng kan du sette opp poengoversikt og angi om du ønsker å benytte et merke ved avslutning av Læringsløp.',
      'use-badge': 'Bruk merke',
      'use-highscore': 'Vis poengoversikt',
      'use-highscore-text':
        'For å vise oversikt over poeng må det være minst ett element som benytter poeng inkludert i Læringsløpet.',
      'badge-header': 'Merke',
      'badge-header-text':
        'Tilpass avslutningen på Læringsløpet med et eget merke.',
      'resources-text':
        'Knytt en kompetansegruppe til Læringsløpet. Oppretter en lenke til "Mine ressurser".',
      'use-resources': 'Aktiver',
      'resources-choose': 'Velg kompetansegruppe',
      'resources-choose-text': 'Kompetanser vil vises i "Mine ressurser"',
      'active-text': 'Læringsløpet skal være tilgjengelig for gjennomføring',
      'general-header': 'Tittel & beskrivelse',
      'general-header-text':
        'Gi et navn til Læringsløpet og gi en kort beskrivelse av innholdet.',
      description: 'Beskrivelse',
      'description-text':
        'Beskriv Læringsløpet så dine brukere vet hva de skal igjennom.',
      'display-as-list': 'Vis læringsløpet som liste i stedet for kart',
      'display-as-list-text':
        'Vis læringsløpet som en liste i stedet for en kartvisning',
      'image-alt-text': 'Alternativ tekst for bilde',
      'background-overlay': 'Bakgrunnsoverlegg',
      'choose-background': 'Velg bakgrunnsbilde',
      'choose-background-text':
        'Last opp eller velg et bakgrunnsbilde fra biblioteket',
      color: 'Farge',
      opacity: 'Gjennomsiktighet',
      'path-color': 'Dot og sti farge',
      'remove-image': 'Fjern bilde',
      save: 'Lagre innstillinger',
      'change-background': 'Endre bakgrunnsbilde',
      'pre-requirement': 'Forkunnskaper',
      messages: 'Varsel',
      'delete-item': 'Fjern Læringsløp',
      'delete-item-text':
        'Her kan du fjerne ditt Læringsløp fra oversikten. NB! Vær oppmerksom på at all statistikk og rapporter tilknyttet Læringsløp blir slettet.',
      badges: 'Merke',
      'reward-header': 'Justere på Highscore og belønninger',
      'reward-header-text':
        'Et sit libero, eu lacus. Est amet, malesuada facilisi amet, tempor vestibulum. Adipiscing cras tempor faucibus nisl blandit porttitor eu, interdum scelerisque.',
      'layout-header': 'Justere visning av Læringsløp',
      'layout-header-text':
        'Velg hvordan Læringsløpet ditt skal presenteres visuelt for brukerne.',
    },
    stats: {
      'dots-completed': 'Fullførte læringstiltak',
      'users-finished': 'Brukere fullført / startet',
      'bits-finished': 'Totalt gjennomførte tiltak',
      'persons-tab': 'Personer',
      'answers-tab': 'Svar',
    },
    preview: 'Forhåndsvis',
  },
  resources: {
    displayMore: 'Vis mer',
    type: 'Type',
    title: 'Tittel',
    'add-checklist-item': 'Legg til nytt sjekkliste punkt',
    editor: {
      editTitle: 'Rediger',
      'bit-learning': 'Bit-element',
      checklist: 'Sjekkliste - kommer snart',
      'digital-signatur': 'Digital signatur  - kommer snart',
      learningElement: 'Læringselement',
      scorm: 'Ekurs (scorm) - kommer snart',
      signature: 'Signatur',
      name: 'Navn',
      text: 'Tekst',
      status: 'Status',
      isPublished: 'publisert',
      duration: 'Varighet',
      uploadFile: 'Last opp fil',
      pressButton: 'Klikk her eller dra inn din fil for å laste opp',
      imageAlt: 'Alternativ tekst',
      selectImage: 'Velg bilde',
      dragAndDropFiles: 'Dra & slipp filer',
      'create-new-map': 'Opprett nytt læringsløp',
      'create-map': {
        'step1-text':
          'Du må gi læringsløpet et navn for å kunne opprette elementer og legge inn innhold.',
        'step1-subtext': 'Gi læringsløpet et navn',
        'step2-text':
          'Last opp eller velg et bilde som skal være bakgrunnen på kartet for Læringsløpet.<br/><br/>Du kan fjerne/endre dette bildet senere',
        'step3-text':
          'Her velger du antall elementer du ønsker å legge inn innhold i, og vise i kartet.<br/><br/>Du kan fjerne og/eller legge til flere elementer senere.',
        'step3-subtext': 'Velg hvor mange elementer du ønsker å starte med',
      },
      deleteImage: 'Fjern bilde',
      'abstract': 'Abstrakt',
      'has-file': 'Kurset har en fil lastet opp',
      uploading: 'Filen lastes opp',
      upload: 'Filen er nå lastet opp',
      description2: 'Beskrivelse',
      'checked-by': 'Skal sjekkes av',
    },
    files: 'Filer',
    'new-checklist-item': 'Nytt sjekkliste-punkt',
  },
  content: {
    ecourse: 'E-Kurs',
    nano: 'Bit-learning',
    checklist: 'Sjekkliste',
    track: 'Spor',
    signature: 'Signatur',
    digital_signature: 'Digital signatur',
    checklist_item: 'Sjekkliste-punkt',
    formalcompetence: 'Formalkompetanse',
    verification: 'Verifikasjon',
    course: 'Kurs',
    equivalents: 'Kompetanseekvivalenter',
    complex: 'Kompleks kompetanse',
    certificate: 'Sertifikat',
  },
  onboarding: {
    before: 'Før oppstart',
    'before-text':
      'Det nærmer seg oppstart, og vi gleder oss til å møte deg!<br/>Her er en oversikt over tiltak du kan gjennomføre før du begynner hos oss.',
    week: 'Første uke',
    'week-text':
      '<p>Velkommen ombord!</p><p>Her er en oversikt over tiltak vi ønsker at du gjennomfører i løpet av din første uke.</p>',
    month: 'Første mnd.',
    'month-text':
      'Her er en oversikt over tiltak vi ønsker at du gjennomfører i løpet av din første måned.',
    'first-three': 'Første 3 mnd.',
    'first-three-text':
      'Her er en oversikt over tiltak vi ønsker at du gjennomfører i løpet av dine første 3 måneder.',
    'first-six': 'Første 6 mnd.',
    'first-six-text':
      'Her er en oversikt over tiltak vi ønsker at du gjennomfører i løpet av det første halvåret.',
  },
  reports: {
    'number-of-requirements': 'Antall krav',
    report: 'Oppsummering',
    completed: 'Fullført',
    'select-report': 'Velg rapport',
    total: 'Totalt',
    loading: 'Laster rapport',
    'by-role': 'Per rolle',
    'by-competence': 'Per kompetanse',
    statistics: 'Statistikk',
    'create-report': 'Oppdater søk',
    'change-search': 'Endre søk',
    units: 'Enheter',
    dashboard: 'Panelet',
    'competence-search': 'Kompetansesøk',
    'track-search': 'Læringsløpssøk',
    reports: 'Rapporter',
    competence: 'Kompetanser',
    completion_grade: 'Gjennomføringsgrad',
    'passing-grade': 'Gjennomføringsgrad',
    week: 'Uke',
    noWeeks: 'Søket fant ingen uker',
    'course-diploma': 'Kursbevis',
    'has-not-competence': 'Har ikke kompetansene',
    'has-competence': 'Har kompetansene',
    'persons-who-has': 'Personer som har kravet',
    overview: 'Oversikt',
    'persons-overview': 'Personoversikt',
    'units-overview': 'Enheter',
    brand: 'Brand',
    search: 'Kompetanser',
    expirering: 'Kompetanser på utløp',
    'your-reports': 'Lagrede rapporter',
    employees: 'Ant. ansatte',
    persons: 'Personer',
    track: 'Spor',
    'back-to-tracks': 'Tilbake til læringsløp',
    answers: 'Brukerens svar',
    progress: 'Framskritt',
    finished: 'Fullført',
    'result-from-answers': 'Resultat',
    'user-has-answered': 'Brukerens svar',
    'correct-answer': 'Riktig svar',
    'last-active': 'Sist aktiv i løype',
    'not-passed': 'Ikke fullført',
    'one-person-answered': '1 person har svart på dette spørsmålet',
    'x-persons-answered': '[x] personer har svart på dette spørsmålet',
    'one-answered': 'Det er registrert svar',
    'x-answered': 'Det er [x] registrerte svar',
    'none-answered': 'Det er ingen registrerte svar enda',
    'export-to-excel': 'Eksporter til Excel',
    inputs: {
      units: {
        organisations: 'Enheter',
        selectOrganisation: 'Velg enhet',
        searchPickOrganisation: 'Søk eller velg enhet',
        label: 'Velg en eller flere enheter',
        closeLabel: 'Lukk seksjon',
      },
      competence: {
        label: 'Velg en eller flere kompetanser',
        title: 'Kompetanse',
        openLabel: 'Velg kompetanse',
        closeLabel: 'Lukk seksjon',
        placeHolder: 'Søk eller velg kompetanser',
        noResult: 'Ingen resultater',
      },
      roles: {
        searchPickRoles: 'Søk eller velg roller',
        label: 'Velg roller',
        openLabel: 'Velg roller',
        closeLabel: 'Lukk seksjon',
      },
      tracks: {
        searchPickTracks: 'Søk eller velg læringsløp',
        label: 'Velg læringsløp',
        openLabel: 'Velg læringsløp',
        closeLabel: 'Lukk seksjon',
      },
      week: {
        label: 'Velg periode',
        title: 'Historiske tall',
        openLabel: 'Velg uke',
        closeLabel: 'Lukk seksjon',
        lastYear: 'Siste året',
        last6Months: '6 mnd',
        lastMonth: '1 mnd',
      },
    },
  },
  role: {
    start: 'Start opplæring',
    progress: 'Framgang',
    tasks: 'Oppgaver',
    task: 'Oppgave',
    'completed-header': 'Gjennomført opplæring',
    'completed-roles': 'Fullførte roller',
    'completed-roles-text':
      'Her finner du oversikt over roller hvor du har fullført alle kompetansekrav.',
    'open-competence': 'Åpne kompetanse',
    'open-checklist': 'Åpne sjekkliste',
    'my-tracks': 'Læringsløp',
    'tasks-related-to': 'Mine oppgaver for min rolle som',
    'completed-tasks-related-to': 'Mine fullførte oppgaver for min rolle som',
    'continue': 'Fortsett opplæring',
    repeat: 'Ta kompetanse på nytt',
    'learning-progression': 'Hei, [x] din læringsprogresjon',
    mandatory: 'Obligatorisk',
    'failed-to-load-competence-for-role': 'Problem med henting av kompetanser',
    'no-required-competence-for-x': 'Ingen obligatoriske kompetanser for [x]',
    'competence-for-role': 'Kompetanse for rolle',
    free: 'Frivillig',
    onboarding: 'Onboarding',
    'onboarding-text':
      'Onboarding, også kjent som organisatorisk sosialisering, er ledelsesjargong som først ble opprettet på 1970-tallet som refererer til mekanismen for hvordan nyansatte tilegner seg nødvendig kunnskap, ferdigheter og atferd for å bli effektive organisasjonsmedlemmer og innsidere.',
    'learning-progression-txt':
      'Læringsløpet du gjennomfører består av flere aktiviteter. Indikatoren under viser hvor langt du har kommet i gjennomføringen for hele kursløpet. Progresjon vist på kursnivå viser gjennomføringsgrad på spesifikt kurs.',
    'tasks-optional-to': 'Anbefalte kompetanser',
    'awaiting-on-approval-from-manager': 'Venter på godkjenning fra leder',
    'showing-of': 'Viser [x] av [y]',
    'only-show-noncompleted-tasks': 'Vis bare oppgaver som ikke er fullførte',
    'my-roles': 'Mine roller og læringsløp',
    'my-roles-text':
      'Her finner du oversikt over dine roller og tilhørende kompetansekrav',
    'my-roles-text-kioskmode':
      'Her finner du oversikt over dine læringsløp og prosentvis gjennomføring',
    'learning-path': 'Læringsløp',
    'learning-path-text':
      'Dictumst sagittis nullam venenatis, dui fermentum eu ornare pharetra. Turpis eget ultrices vulputate non fringilla. At suspendisse sed eu mauris.',
    'promoted-articles': 'Promoterte artikler',
    'promoted-articles-viewall': 'Se alle',
    'promoted-articles-sub-heading':
      'Her finner du oversikt over relevante artikler',
    content: 'Content',
  },
  worklist: {
    'header-text': 'Min arbeidsliste',
    'header-text-txt': 'Her kan du utføre oppgaver',
    'clear-list': 'Tøm liste',
    'add-role': 'Legg til rolle',
    'send-mail': 'Send e-post',
  },
  menu: {
    employees: 'Min avdeling',
    units: 'Enheter',
    'my-page': 'Min side',
    worklist: 'Min arbeidsliste',
    roles: 'Roller',
    'roles-text': 'Se og redigere roller som er satt opp i systemet',
    'course-catalog': 'Kurskatalog',
    dashboard: 'Dashboard',
    overview: 'Oversikt',
    grape: 'Snapper Kompetanse',
    'grape-text': 'Sett opp kompetanser og kurs',
    onboarding: 'Onboarding',
    'my-onboarding-text': 'Se og administrer dine læringsløp',
    'persons-text':
      'Legg til nye medarbeidere og få oversikt over deres progresjon',
    'waymaker-text': 'User backend for maps and tasks',
    'resource-pages-text': 'Resource pages containing additional information',
    logoutTxt: 'Logg ut av alle systemer',
    'add-new-map-description':
      'Et sit libero, eu lacus. Est amet, malesuada facilisi amet, tempor vestibulum. Adipiscing cras tempor faucibus nisl blandit porttitor eu, interdum scelerisque.',
    help: 'Hjelp',
    administration: 'Administrasjon',
    content: 'Innhold',
    maps: 'Læringsløp',
    people: 'Mitt team',
    'my-maps-text': 'Vis og lage dine læringsløp',
    'all-services': 'Alle dine tjenester',
    'add-persons': 'Legg til personer',
    'add-new-map': 'Legg til nytt læringsløp',
    'my-maps': 'Mine læringsløp',
    persons: 'Personer',
    'resource-pages': 'Ressurssider',
    logout: 'Logout',
    services: 'Tjenester',
    admin: 'Administrasjon',
    settings: 'Innstillinger',
    'add-new-map-title': 'Gi det nye læringsløpet et navn',
    summary: 'Oppsummering',
    resources: 'Ressurser',
  },
  competence: {
    'relevant-resources': 'Relevante ressurser',
    'uploaded-files': 'Opplastede filer',
    'my-uploaded-files': 'Mine opplastede filer',
    'x-uploaded-files': '[x] sine opplastede filer',
    'file-deadline-passed': 'Fristen for å laste opp filer er utløpt',
    'click-here-to-upload': 'Klikk her for å laste opp',
    'no-upcoming-events': 'Ingen kommende arrangementer funnet.',
    expired: 'Løp ut',
    expires: 'Løper ut',
    sign: 'Signer',
    'no-competences-found': 'Ingen kompetanser funnet',
    'confirm-to-sign': 'Jeg bekrefter at jeg har lest og forstått innholdet:',
    signed: 'Signert',
    completed: 'Fullført',
    registered: 'Registrert',
    'you-have-signed': 'Du har signert',
    'signed-at-x': 'Du signerte [x]',
    'registered-at-x': 'Registrert [x]',
    'completed-at-x': 'Fullført [x]',
    expiringCompetence: 'Kompetanse som utløper',
    expiringCompetences: 'Kompetanser som utløper',
    'expiringCompetence--passed': 'Utløpt kompetanse',
    'expiringCompetences--passed': 'Utløpte kompetanser',
    'expiringCompetence--today': 'Kompetanse som utløper i dag',
    'expiringCompetences--today': 'Kompetanser som utløper i dag',
    'expiringCompetence--future': 'Kompetanse som utløper',
    'expiringCompetences--future': 'Kompetanser som utløper',
    deadline: 'Påmeldingsfrist',
    courseActivity: 'Kurs i dag',
    personEvent: 'Kurs i dag',
    'personEvents--future': 'Kommende kurs',
    'personEvent--future': 'Kommende kurs',
    'personEvents--passed': 'Tidligere kurs',
    'personEvent--passed': 'Tidligere kurs',
    'personEvents--today': 'Kurs i dag',
    'personEvent--today': 'Kurs i dag',
    'user-checked-at-x': 'Brukere signerte [x]',
    password: 'Skriv inn ditt passord',
    'x-of-y': '[x] av [y]',
    'must-manager-sign': 'En leder må godkjenne denne',
    'must-manager-sign-competence': 'En leder må godkjenne denne kompetansen',
    'manager-check': 'Godkjenn oppgave',
    'manager-checked': 'Godkjent av',
    'user-must-check': 'Bruker må godkjenne denne',
    'you-checked': 'Du godkjente denne',
    'user-checked': 'Bruker godkjente denne',
    missing: 'Mangler obligatorisk kompetanse',
    'not-mandatory': 'Ikke obligatorisk',
    passed: 'Godkjent',
    'show-x-of-y': 'Viser [x] av [y]',
    components: 'Komponenter',
    'expiring-or-expired-competences': 'Kompetanse som utløper',
    'finished-and-waiting-on-approval':
      'Du er ferdig, venter på godkjenning av leder',
    'no-completed-training': 'Du har <i>ingen</i> gjennomført opplæring.',
    'add-competence': 'Legg til kompetanse',
    'add-completed-competence': 'Legg til fullført kompetanse',
  },
  'self-sign': {
    'cancel-competence': 'Avvise kompetanse?',
    cancel: 'Avbryt godkjenning',
    'do-you-wish': 'Ønsker du å avvise <b>[fullname] - [title]</b>?',
    'do-you-wish-approve': 'Ønsker du å godkjenne <b>[fullname] - [title]?</b>',
    'confirm-approve': 'Bekreft godkjenning',
    confirm: 'Bekreft',
  },
  'course-catalog': {
    catalog: 'Kurskatalog',
    'search-courses': 'Søk etter kurs',
    'e-course': 'E-læringskurs',
    course: 'Kurs',
    'course-section-ecourse': 'Kurskatalog',
    'course-section-classroom': 'Kommende kurs',
    'course-section-all': 'Alle kurs',
    'course-type': 'Kurs-type',
    'new-first': 'Nyeste først',
    'old-first': 'Eldste først',
    'sign-up': 'Meld på',
    'sign-self-up': 'Meld deg på',
    categories: 'Kategorier',
    'sign-employees-up': 'Meld deg på',
    'show-classroom': 'Vis klasseromskurs',
    'show-digital': 'Vis digitale kurs',
    'no-courses-found': 'Fant ingen kurs i denne kategorien.',
    'classroom-courses': 'Klasseromskurs',
    'no-hits-for-search-x': 'Fant ingen treff med søkeordet [x]',
    'back-to-main-view': 'Tilbake til hovedvisning',
    'load-more-courses': 'Last inn flere kurs',
  },
  search: {
    organisations: 'ORGANISASJONER',
    persons: 'PERSONER',
  },
  cv: {
    'my-cvs': 'Mine CV-er',
    'remove-experience': 'Fjern erfaring',
    'no-endyear-without-startyear': 'Må også ha med start-år',
    'no-endyear-without-startdate': 'Må også ha med fra-dato',
    'enddate-is-before-startdate': 'Til-dato må komme etter fra-dato',
    'endyear-is-before-startyear': 'Start-år må komme etter slutt-år',
    'cv-delete-failure': 'Feil ved sletting av CV-en',
    'cv-fetch-failure': 'Kunne ikke hente denne CV-en',
    'delete-success': 'CV-en ble slettet',
    'confirm-delete-cv': 'Ønsker du virkelig å slette denne CV-en?',
    'missing-data': 'Ingen CV er lagt til',
    'add-cv-instruction': "Trykk på «Rediger CV»' for å legge inn.",
    'no-desc-added': 'Ingen beskrivelse er lagt inn.',
    'cvs-fetch-failure': 'Kunne ikke hente CV-er',
    'none-added-yet':
      'Ingen CV-er lagt til enda. Trykk på «Opprett ny» for å opprette en.',
    'no-items-of-kind': {
      work: 'Ingen arbeidserfaringer er lagt til enda.',
      education: 'Ingen utdanninger er lagt til enda.',
      project: 'Ingen prosjekter er lagt til enda.',
      language: 'Ingen språkkunnskaper er lagt til enda.',
      elected:
        'Ingen erfaringer fra frivillig arbeid / tillitsverv er lagt til enda.',
    },
    'experience-kind-headers': {
      work: 'Erfaring',
      education: 'Utdanning',
      training: 'Opplæring og kurs',
      skills: 'Ferdigheter',
      project: 'Prosjekt',
      formal: 'Formell',
      elected: 'Frivillig arbeid / tillitsverv',
      language: 'Språk',
    },
    'experience-kind-descriptions': {
      work: 'Registrer alle dine relevante jobberfaringer. Det er opp til deg selv å vurdere hva som er relevant eller ikke. Har du mange år i arbeidslivet, ta med det viktigste og det som er relevant ift. jobben du søker. Husk også å unngå “hull i CV-en” (perioder uten noen oppføringer i CV-en). Har du det, har du kanskje utelukket noe som bør være med.',
      education: 'Liste over min formelle utdanning.',
      project: 'Relevante prosjekter jeg ønsker å fremheve.',
      elected: 'Frivillig arbeid og tillitsverv jeg har hatt.',
      language: 'Mine skriftlige og muntlige språkferdigheter.',
    },
    'add-new-of-kind': {
      work: 'Legg til ny arbeidserfaring',
      education: 'Legg til ny utdanning',
      project: 'Legg til nytt prosjekt',
      elected: 'Legg til ny arbeidserfaring for frivillig arbeid / tillitsverv',
      language: 'Legg til ny språkferdighet',
    },
    'form-elements': {
      name: 'Tittel',
      description: 'Generell beskrivelse av din kompetanse',
      'work-position': 'Stilling',
      'work-company': 'Selskap',
      'work-fromdate': 'Fra',
      'work-todate': 'Til (tom hvis du ikke har sluttet)',
      location: 'Sted',
      'education-name': 'Navn på utdanning',
      'education-start-year': 'Start (år)',
      'education-end-year': 'Slutt (år)',
      'project-name': 'Navn',
      'project-description': 'Beskrivelse',
      'project-from-date': 'Fra',
      'project-to-date': 'Til (blank hvis du ikke er ferdig)',
      'elected-role': 'Stilling / Rolle',
      'elected-company': 'Selskap / Bedrift',
      'elected-fromdate': 'Fra',
      'elected-todate': 'Til (tom hvis du ikke er ferdig / har sluttet)',
      'language-name': 'Språk',
      'language-skill': 'Ferdighet i språket',
    },
  },
  employees: {
    'org-delete-title': 'Slette enhet',
    'org-delete-x-text': 'Er du sikker på at du vil slette <b>[x]</b>?',
    'org-delete-success': 'Enheten ble slettet.',
    search: 'Søk ansatt',
    employee: 'Ansatt',
    employees: 'Ansatte',
    'add-change-roles': 'Legg til/endre roller',
    'employees-text': 'Liste over alle ansatte i aktuell enhet',
    'company-updated': 'Enheten er oppdatert',
    'company-added': 'Opprettet ny enhet',
    firstname: 'Fornavn',
    lastname: 'Etternavn',
    'all-completed': 'Alt fullført',
    missing: 'Mangler kompetanse',
    progress: 'Progresjon',
    roles: 'Rolle',
    positions: 'Stilling',
    'add-to-list': 'Legg i arbeidsliste',
    'add-to-list-text-disabled': 'Velg ansatte for å legge til i arbeidsliste',
    'add-to-list-text':
      'Legg til i arbeidsliste for oppfølging og tildeling av roller',
    'person-added': 'Person lagt til i arbeidslisten.',
    'persons-added': 'Personer lagt til i arbeidslisten.',
    'person-removed': 'Person fjernet fra arbeidslisten.',
    'persons-removed': 'Personer fjernet fra arbeidslisten.',
    'worklist-cleared': 'Arbeidslisten er tømt.',
    'worklist-sent-bulk-message': 'Melding sendt til [x] personer',
    'worklist-sent-bulk-message-to-single-person': 'Melding sendt til 1 person',
    'change-org': 'Bytt organisasjon',
    name: 'Navn',
    competence: 'Kompetanse',
    'sendt-from': 'Sendt av',
    'task-approved': 'Godkjente [title] for [fullname]',
    'checklist-approved': 'Sjekklistepunkt godkjent for [fullname]',
    'task-rejected': 'Avviste [title] for [fullname]',
    'show-tree': 'Trevisning',
    'no-tasks-header': 'Du har ikke noen oppgaver',
    'no-tasks-header-text':
      'Veldig bra jobbet! Du kan nå ta en kopp kaffe og slappe av.',
    'course-competence-overview': 'Varslingssenter',
    verification: 'Verifikasjon',
    'course-events': 'Kursoversikt',
    'course-wait-for': 'Venter på godkjenning',
    'action-approve-reject': 'Godkjenn / Fjern',
    'course-overview': 'Kursoversikt',
    'no-courses': 'Ingen av dine ansatte er satt på kurs.',
    'nb-units': 'Antall enheter',
    'create-new-success': 'En ny bruker ble opprettet',
    'create-new-failure':
      'Kunne ikke opprette en ny bruker, vennligst forsøk igjen.',
    'nb-employees': 'Antall ansatte',
    'total-employees': 'Total ansatte',
    'min-letters': 'Skriv [x] bokstaver til for å søke.',
    total: 'Total',
    'report-search': 'Kompetansesøk',
    'report-roles': 'Vis roller',
    'report-view-all': 'Vis alle [x] rapporter',
    'report-competences': 'Vis per kompetanse',
    'report-view': 'Vis rapport',
    'expire-header': 'Kompetanse varsel!',
    'expire-will-plural': '[x] kompetanser har gått ut eller vil gå ut',
    'no-access-to-profile': 'Du har ikke tilgang til denne profilen',
    'expire-will': '[x] kompetanse har gått ut eller vil gå ut',
    actions: 'Handlinger',
    'add-employee': 'Legg til ansatt',
    'move-employee': 'Flytt ansatt',
    'export-to-excel': 'Eksporter til Excel',
    'search-for-employees': 'Søk etter ansatte',
    'choose-position': 'Velg stilling',
    enddate: 'Sluttdato',
    'view-items': 'Vis',
    'absent-from': 'Fraværende f.o.m',
    'absent-to': 'Fraværende t.o.m',
    absence: 'Fravær',
    'has-absence': 'Personen er satt med fravær.',
    'load-more-employees': 'Last inn flere ansatte',
  },
  course: {
    start: 'Start kurs',
    restart: 'Start kurs igjen',
    repeat: 'Repeter',
    'error-load-course': 'Kunne ikke laste inn kurset',
    'classroom-course': 'Klasseromskurs',
    'on-waitinglist': 'Du står på venteliste',
    'confirmed-text': 'Du har meldt deg på',
    'when-completed-instructions':
      "<p class='header'>Ferdig med kurset?</p><p>Trykk på <span class='italic'>Ferdig</span> når du har fullført kurset.</p>",
    'did-finish': 'Ferdig',
    'browser-has-popup-blocker':
      'Nettleseren din er satt opp med Pop-up blokkering.',
    'browser-has-popup-blocker-start-instructions':
      'Trykk &laquo;Start kurs&raquo; for å åpne kurset.',
    'no-employees-to-choose': 'Ingen ansatte å velge',
    'sign-up': 'Meld på',
    'sign-up-gone': 'Påmeldingsfristen er utløpt',
    'sign-up-wait': 'Meld på venteliste',
    'sign-already-you-wait': 'Du er allerede på venteliste',
    'sign-up-you-wait': 'Meld deg på venteliste',
    'sign-up-wait-employee': 'Meld ansatte på venteliste',
    'sign-off': 'Meld av',
    'sign-you-off': 'Meld deg av',
    seats: '[x]/[y] påmeldt',
    'course-is-full': 'Kurset er fullt',
    'go-to-course': 'Gå til kurs',
    'go-to-course-track': 'Gå til kursløp',
    'persons-confirmed': 'Personer som skal på kurs',
    'persons-waitlist': 'Personer som står på venteliste',
    'persons-need-confirm': 'Personer venter på godkjenning',
    waitlist: 'Venteliste',
    'sign-up-yourself': 'Meld på meg',
    'yourself-already-signed-on': 'Du er allerede påmeldt',
    'sign-up-employees': 'Meld på ansatte',
    'sign-up-employees-for-course': 'Meld ansatte på kurs',
    'sign-up-employees-from-[x]-for-course': 'Meld ansatte fra [x] på kurs',
    'sign-up-employees-for-course-missing-employees':
      'Du må velge en ansatt fra listen for å melde på',
    'choose-employees-for-signup': 'Velg ansatte for påmelding',
    'all-employees-from-x-signed-up':
      'Alle ansatte fra [x] er allerede meldt på',
    'all-employees-signed-up': 'Alle ansatte er allerede meldt på',
    'employees-signed-up': 'Påmeldte ansatte',
    'employees-waiting-for-confirmation': 'Venter på bekreftelse',
    'confirm-sign-off': 'Bekreft avmelding',
    'confirm-enrollment': 'Bekreft påmelding',
    'delete-file': 'Slett fil',
    'confirm-delete-file-for-person': 'Bekreft sletting av fil',
    'delete-file-for-person-question': 'Ønsker du å slette denne filen?',
    finish: 'Fullfør',
    sign: 'Signer',
    'sign-off-person-x-question': 'Ønsker du å melde av [x]?',
    'sign-on-self-x-question': 'Ønsker du å melde deg på [x]?',
    'sign-off-self-x-question': 'Ønsker du å melde deg av <b>[x]</b>?',
    'persons-where-signed-up': 'Følgende personer ble påmeldt',
    'persons-where-not-signed-up': 'Følgende personer ble ikke påmeldt',
    passed: 'Fullført',
    missing: 'Mangler',
    'loading-events': 'Laster arrangementer',
    'failed-to-load-events': 'Laster arrangementer',
  },
  person: {
    'logins-last-30-days': 'Innlogginger siste 30 dager',
    user: 'Bruker',
    users: 'Brukere',
    firstname: 'Fornavn',
    lastname: 'Etternavn',
    position: 'Stilling',
    email: 'E-post',
    phone: 'Telefon',
    mobile: 'Mobil',
    status: 'Status',
    employee_code: 'Ansattnummer',
    birthdate: 'Fødselsdato',
    jobtitle: 'Tittel',
    'competence-overview': ' Kompetanser med et blikk',
    level: {
      total: 'Totalt',
      mandatory: 'Obligatorisk',
      required: 'Obligatorisk',
      recomended: 'Anbefalt',
      recommended: 'Anbefalt',
      personal: 'Personlige',
    },
    role: 'Rolle',
    'cancel-competence-success': 'Godkjenning avbrutt',
    'register-sendt-competence': 'Sendt til godkjenning',
    'register-competence-cancel': 'Avbryt godkjenning',
    'register-competence': 'Registrer ny kompetanse',
    'register-send-competence': 'Send til godkjenning',
    'registered-competence': 'Kompetanse sendt til godkjenning',
    'registered-competence-passed': 'Kompetansen er godkjent',
    'waiting-for-approval': 'Venter på godkjenning',
    'user-has-no-cvs': 'Det er ikke registrert noen CV på denne brukeren.',
    'user-cvs-fetch-pdf': 'Hent CV som PDF',
    'user-cvs-fetch-word': 'Hent CV som Word-dokument',
    'profile-picture': 'Profilbilde',
    'click-to-upload-profile-picture': 'Klikk for å laste opp bilde',
    'click-to-edit-profile-picture': 'Klikk for å endre bilde',
    'upload-profile-picture': 'Last opp bilde',
    'change-profile-picture': 'Endre bilde',
    'delete-profile-picture': 'Fjern bilde',
    'competence-name': '[name] sin kompetanse',
    'open-full-overview': 'Åpne kompetanseoversikt',
    'add-role': 'Endre/legg til rolle',
    'edit-competence': 'Endre/legg til kompetanse',
    'edit-roles': 'Rediger roller',
    'change-information': 'Endre informasjon',
    'change-personalia': 'Endre personalia',
    'update-username-password-success': 'Passord og brukernavn er endret',
    'update-username-password-failure':
      'Feil i oppdatering av brukernavn/passord',
    'change-password': 'Endre passord',
    'old-password': 'Gammelt passord',
    'new-password': 'Nytt passord',
    'repeat-new-password': 'Gjenta nytt passord',
    'passwords-not-matching': 'Passordene er ikke like',
    'missing-old-password': 'Må fylle inn gammelt passord',
    'password-too-short': 'Passord må være minimum 8 tegn',
    'password-empty': 'Passord kan ikke være tomt',
    'password-illegal-chars':
      'Passord kan ikke inneholde spesialsymboler eller mellomrom',
    'username-illegal-chars':
      'Brukernavn kan ikke inneholde spesialsymboler eller mellomrom',
    'password-requirements':
      'Krav til passord: Minimum 8 tegn, bruke store og små bokstaver, samt tall eller "@", ".","_" og "-". Vær oppmerksom på at bokstavene æ-ø-å ikke fungerer som tegn i et passord.',
    'password-change-success': 'Passordet er endret',
    'password-change-failure': 'Feil ved endring av passord',
    send: 'Send',
    'competence-removed': 'Kompetansen er slettet',
    'competence-unable-removed': 'Kompetansen ble ikke slettet',
    roles: 'Roller',
    'course-enrollments': 'Kurspåmeldinger',
    'course-enrollments-for-x': '[x] sine påmeldte kurs',
    'x-is-on-waitlist': '[x] står på venteliste',
    'add-competence': 'Legg til',
    profile: 'Profil',
    'competence-report': 'Kompetanserapport',
    'view-files': 'Vis personens filer',
    'view-cv': 'Vis CV',
    'no-roles-to-choose': 'Ingen roller å velge',
    menu: {
      profile: 'Profil',
      profileTxt: 'Redigere profil og endre passord',
      messages: 'Meldinger',
      messagesTxt: 'Vis alle meldinger',
      messagesTxtUnread: 'Du har [x] uleste meldinger',
      messagesTxtOneUnread: 'Du har 1 ulest melding',
      messagesTxt100OrMoreUnread: 'Du har 100 eller flere uleste meldinger',
      competences: 'Mine kompetanser',
      competencesTxt:
        'Få oversikt på alle dine kompetanser og last ned kursbevis',
      help: 'Hjelp',
      helpTxt: 'Finn hjelp og les dokumentasjon',
      logout: 'Logg ut',
      logoutTxt: 'Logg ut fra systemet',
    },
    'add-competences': {
      requirement: 'Personlige krav',
      'add-requirement': 'Legg til personlige krav',
      competence: 'Fullførte kompetanser',
      'add-as': 'Legg til som',
      register: 'Registrer',
      dropfiles: 'Dra og slipp filer eller',
      'choose-competence': 'Velg kompetanse',
      'competence-details': 'Detaljer',
      'add-as-txt': 'her er en lengre tekst som forklarer det du kan gjøre...',
      'selected-files': 'Valgte filer',
      files: 'Filer',
      'delete-file': 'Slett fil',
      certificate: 'Legg til sertifikat',
      'certificate-txt': 'Last opp kurssertifikat',
      comments: 'Kommentarer',
      'do-you': 'Ønsker du og fjerne disse kompetanser som personlige krav?',
      'completed-date': 'Fullført/registrert',
      valid_until: 'Gyldig til',
      'select-competence': 'Velg kompetanse',
      searching: 'Søker..',
      'search-result': 'Søkeresultat',
      'select-a-category': 'Velg fra kategori',
      'search-for': 'Søk etter kompetanser',
      'selected-courses': 'Valgte kompetanser',
      '': 'Valgte kompetanser',
      'did-not-find': 'Fant ikke noen kompetanser',
      unselect: 'fjern',
      add: 'Legg til kompetanse',
      remove: 'Fjern kompetanse',
      'add-completed': 'Legg til fullført kompetanse',
      'add-ob': 'Legg til fullført kompetanse',
      'add-ob-txt':
        'En fullført kompetanse er at brukeren har oppnådd denne kompetansen.',
      'add-recomended': 'Legg til personlige kompetansekrav',
      'add-recomended-txt':
        'Gi brukeren personlige kompetansekrav utenom de som roller og stillinger krever.',
      'remove-recomended': 'Fjern personlige kompetansekrav',
      'remove-recomended-txt': 'Fjern krav som er satt på brukeren. ',
      'selected-competences': 'Valgte kompetanser',
      'add-remove': 'Legg til/fjern kompetanser',
    },
    'send-message-to-x': 'Send melding til [x]',
    'change-employment': 'Endre ansettelse',
    'overtake-bart': 'Ta over identitet',
    overtake: 'Ta over',
    'overtake-identity': 'Ta over identitet?',
    'overtake-warning':
      'Du er nå i ferd med å overta identiteten til [x]. Alle handlinger vil bli loggført som denne brukeren. Er du sikker på at du vil overta identiteten til denne brukeren?',
    'you-have-now': 'Du har nå tatt over identiteten til',
    'change-employment-success': 'Oppdatert med ny dato',
    'change-employment-failure': 'Problem med oppdatering av brukeren.',
    'send-new-password-to-user': 'Send nytt passord til bruker',
    'send-new-password-to-x-users': 'Send nytt passord til [x] brukere',
    'send-new-password-to-x-users-are-you-sure':
      'Er du sikker på at du ønsker å sende nytt passord til [x] brukere?',
    'password-reset-success': 'Nytt passord er sendt til:',
    'password-reset-failure': 'Feil ved sending av nytt passord:',
    'send-new-password-to-user-success':
      'Et nytt passord er sendt til brukeren',
    'send-new-password-to-user-failure': 'Feil, fikk ikke sendt nytt passord',
    'send-new-password-to-user-desc':
      'Et nytt engangspassord blir opprettet og sendt til brukeren på e-post.',
    'send-new-password': 'Send nytt passord',
    'change-username-pwd': 'Endre brukernavn/passord',
    'complete-competence-report': 'Fullstendig kompetanserapport',
    'username-desc': ' <br /> ',
    'employee-number-desc': ' ',
    'send-username-and-password': 'Send brukernavn og passord',
    'position-required': 'Du må velge en stilling',
    'person-required': 'Du må velge en person',
    'role-select-default': 'Velg fra listen',
    'invalid-mobile': 'Ugyldig telefonnummer',
    'user-updated-success': 'Bruker oppdatert',
    'users-updated-success': 'Brukere oppdatert',
    'user-files': '[x] filer',
    'user-cvs': '[x] CV-er',
    'user-has-no-files': 'Brukeren har ikke noen filer lastet opp',
    'confirm-delete-file':
      'Er du sikker på at du ønsker og slette denne filen?',
    'your-profile-updated-success': 'Din profil er oppdatert',
    'your-profile-updated-failure': 'Feil i oppdatering av din profil',
    'person-files': 'Person filer',
    'requirements-and-roles': 'Krav / roller',
    'user-has-x-requirements-tooltip': 'Bruker er tildelt [x] krav',
    'user-has-x-roles-tooltip': 'Bruker er tagget med [x] roller',
    types: {
      mandatory: 'Obligatorisk kompetanse',
      optional: 'Anbefalt kompetanse',
      recomended: 'Anbefalt',
      other: 'Annen kompetanse',
    },
    'roles-tab': 'Roller',
    'competence-overview-tab': 'Kompetanseoversikt',
    'cv-tab': 'CV',
    'files-tab': 'Filer',
  },
  message: {
    subject: 'Emne',
    text: 'Tekst',
    'no-messages': 'Du har ikke mottatt noen meldinger.',
    'my-messages': 'Mine meldinger',
    'form-missing-subject': 'Emne må fylles ut',
    'form-missing-body': 'Meldingsteksten må fylles ut',
    'failed-to-load': 'Kunne ikke hente meldinger',
    'message-sent-success': 'Melding sent',
  },
  login: {
    login: 'Logg inn',
    'select-language': 'Velg ditt språk',
    forgot: 'Glemt passord?',
    'recive-new': 'Få nytt passord',
    'missing-user': 'Har du ikke en bruker? Registrer deg',
    'invalid-user': 'Brukernavn eller passord stemmer ikke.',
    'send-new-password-success': 'Et nytt passord har blitt opprettet og sendt',
    'send-new-password-failure': 'Feil ved sending av nytt passord',
    username: 'Brukernavn',
    'username-placeholder': 'bruker@domene.no',
    password: 'Passord',
    'password-placeholder': 'Minst 4 tegn',
    version: 'Versjonsnummer',
    progress: 'Logger inn ...',
    'new-password': 'Få nytt passord',
    'send-new-password': 'Send nytt passord',
    'no-user': 'Fant ingen bruker',
  },
  'date-and-time': {
    yars: 'År',
    year: 'År',
    months: 'Måneder',
    month: 'Måned',
    day: 'Dag',
    days: 'Dager',
    hour: 'Time',
    hours: 'Timer',
    minute: 'Minutt',
    minutes: 'Minutter',
    'year-abbr': 'År',
    'years-abbr': 'År',
    'month-abbr': 'Mnd',
    'months-abbr': 'Mnd',
    'day-abbr': 'Dag',
    'days-abbr': 'Dager',
    'browser-date-format-local': 'dd.mm.åååå',
  },
  report: {
    grade: 'Karakter',
    date: 'Dato',
    time: 'Tid brukt',
    'not-started': 'Ikke påbegynt',
    passed: 'Godkjent',
    'no-results-title': 'Ingen gjennomføring',
    'no-results-text':
      'Ingen av dine brukere har gjennomført noen opplæringstiltak.',
    completed: 'Gjennomført',
    unfinished: 'Ikke fullført',
    finished: 'Fullført',
    'users-total-track': 'Total progresjon på læringsløp',
    'users-total-track-txt':
      'Her finner du brukerens totale progresjon på alle løyper og gjennomføringsgrad på dem.',
    'users-total-onboarding': 'Total progresjon på onboarding',
    'users-total-onboarding-txt':
      'Her finner du brukerens totale progresjon på onboarding',
    track: 'Spor',
    'back-to-tracks': 'Tilbake til læringsløp',
    statistics: 'Statistikk',
    answers: 'Brukerens svar',
    progress: 'Framskritt',
    'result-from-answers': 'Resultat',
    'user-has-answered': 'Brukerens svar',
    'correct-answer': 'Riktig svar',
    'last-active': 'Sist aktiv i løype',
    'not-passed': 'Ikke fullført',
  },
  persons: {
    'modify-data': 'Rediger personalia',
    'send-message': 'Send melding',
    'edit-person-data': 'Endre personalia',
    manager: 'Gjør brukere til administrator',
    name: 'Navn',
    email: 'E-post',
    mobile: 'Mobil',
    'remove-person': 'Fjern person',
    'add-persons-intro':
      'Her kan du legge til brukere eller laste opp mange gjennom excel fil',
    'email-invalid': 'Ugyldig e-postadresse',
    'email-taken': 'Brukere er allerede registret i systemet',
    leader: 'Sett som leder',
    'import-from-excel': 'Importer brukere fra excel',
    'click-to-upload': 'Klikk for og laste opp fil',
    'or-dnd': 'eller dra inn din',
    'xlsx-or-numbers': 'excel eller numbers fil',
    'download-template': 'Last ned excel-mal',
    'view-details': 'Vis detaljer',
    'send-invites': 'Send invitasjoner',
    'add-person-header': 'Legg til personer',
    'is-manager': 'Bruker er administrator (fjern)',
    'are-you-sure': 'Er du sikker?',
    'are-you-sure-txt': 'Er du sikker på at du vil fjerne denne personen?',
    'import-found-count': 'Fant [count] personer',
    'registered-heading':
      'Personer som allerede er registrert vil ikke bli lagt til',
    message: {
      subject: 'Emne',
      text: 'Tekst',
    },
  },
  help: {
    home: 'Hjem',
    'searchbar-placeholder': 'Søk etter kategorier, artikler',
    topics: 'Kategorier',
    'no-results-for-search': 'Fant ikke noen dokumenter',
    'no-results-for-x-desc':
      'Ditt søk etter "[x]" samsvarte ikke med noen dokumenter. Vennligst prøv igjen.',
    'searchpage-header': 'Søk',
    'searchpage-header-txt': 'Finn informasjon om tjenesten',
    'startpage-header': 'Hjelp',
    'search-for-x-gave-y-hits': 'Ditt søk på "[x]" gav [y] treff',
    'search-for-x-gave-none-hits': 'Ditt søk på "[x]" gav ingen treff',
    'submit-a-question': 'Send inn spørsmål',
    'send-question-header': 'Jeg trenger hjelp!',
    'send-question-instructions':
      'Fant du ikke svaret på det du lurte på? Send oss en henvendelse her, eller på support@snapper.no',
    'send-question-form': {
      question: 'Spørsmål',
      desc: 'Beskriv hva du lurer på eller forsøker å finne ut',
      submit: 'Send inn spørsmål',
      'submit-ok-desc': 'Vi har mottatt din forespørsel!',
      'submit-ok-close': 'Lukk',
    },
  },
  GO_TO_TRAINERCOURSES: 'Gå til trenerattest',
  GO_BACK: 'Trener.nif.no',
  pages: {
    persons: 'Personer',
    'add-persons': 'Legg til personer',
    'persons-text': 'Alle registrerte personer',
    'add-new-map': 'Legg til nytt læringsløp',
    'add-new-map-description':
      'Et sit libero, eu lacus. Est amet, malesuada facilisi amet, tempor vestibulum. Adipiscing cras tempor faucibus nisl blandit porttitor eu, interdum scelerisque.',
    'add-new-map-placeholder': 'Skriv inn navn',
    'add-new-map-title': 'Gi navn på ditt læringsløp',
    content: 'Innhold',
    help: 'Hjelp',
    'my-maps': 'Mine læringsløp',
    maps: 'Læringsløp',
    onboarding: 'Onboarding',
    'onboarding-txt':
      'Her har du oversikt over dine løyper/læringsløp. Her kan du vise de, åpne de for å redigere eller velge om de skal være synlige og/eller skjult',
    'is-active-track': 'er aktivt læringsløp',
    administration: 'Administrasjon',
    'my-maps-text': 'Alle mine læringsløp',
    'new-map': 'Opprett ditt nye læringsløp på sekunder',
    'create-new-map': 'Opprett nytt læringsløp',
    'help-title': 'Trenger du hjelp?',
    'help-text':
      'Hjelpeinformasjon finner du her. Vi har beskrevet hvordan du lager læringsløp og alt annet du kan finne på i løsningen. Om du ikke finner det du søker - ta kontakt. Lykke til.',
    overview: 'Oversikt',
    'resource-pages': 'Resurssider',
    'resource-pages-text': 'Resurssider som du har tilgang til',
    settings: 'Innstillinger',
    summary: 'Oppsummering',
  },
  errors: {'default': 'Noe gikk galt. Vennligst prøv igjen senere.'},
  panelet: {
    'competence-status': 'Kompetansestatus',
    'complete-competence-status': 'Komplett kompetansestatus',
  },
};
