import {isEquivalentsCompetence} from '@src/store/util/competence-identity';
import {verifyPassed} from '@utils/data.utils';
import {validIntOrNull} from './number.utils';

export const getIsExpiredCompetence = competence => {
  if (!competence?.valid_until) return null;

  return competence?.expired === true
    || competence?.status === 'expired'
    || verifyPassed(competence) === false;
};

export const getCompetenceStatus = competence => getIsExpiredCompetence(competence) && 'expired'
  || competence?.passed == null
  ? null
  : competence?.passed === 100
    ? 'passed'
    : competence?.passed === 50 && ['both', 'manager'].includes(competence?.checked_by)
      ? 'waiting_for_approval'
      : (competence?.passed || 0) > 0
        ? 'in_progress'
        : 'not_started';

export const getCompetencePassed = (competence, competencesMap, passedCidsSet) => {
  if (competence?.competence_id == null) return null;
  if (competence.valid_until && getIsExpiredCompetence(competence)) return 0;

  let totalPassed = competence.passed;

  if (totalPassed === 100 || passedCidsSet?.has?.(competence.competence_id)) {
    return 100;
  }

  // checklist-items have their own rules for calculating passed
  if (competence.checklist?.check_raw) {
    const {
      check_raw,
      self_check,
      manager_check,
      is_check_manager,
      is_check_user,
    } = competence.checklist;

    let checklistPassed = 0;

    if (is_check_manager || manager_check) checklistPassed += 50;
    if (is_check_user || self_check) checklistPassed += 50;

    if (
      check_raw === 'either' && checklistPassed
      || check_raw === 'self' && (self_check || is_check_user)
      || check_raw === 'manager' && (manager_check || is_check_manager)
    ) return 100;

    return checklistPassed;
  }

  if (!competence.children_ids?.length) return totalPassed;

  // If any child of equivalents competence is completed, the whole competence is completed.
  if (isEquivalentsCompetence(competence)) {
    for (const childCid of competence.children_ids) {
      if (childCid != null && getCompetencePassed(competencesMap?.[childCid]) === 100) {
        totalPassed = 100;

        break;
      }
    }
  } else {
    // Compare parent passed against average passed for all children.
    let childrenPassed = 0;
    let childrenCount = 0;

    for (const childCid of competence.children_ids) {
      childrenCount += 1;

      if (childCid != null && getCompetencePassed(competencesMap?.[childCid]) === 100) {
        childrenPassed += 1;
      }
    }

    totalPassed = childrenPassed === childrenCount
      ? 100
      : Math.max(totalPassed || 0, Math.round(childrenPassed / childrenCount * 100));
  }

  return totalPassed;
};

export const buildCompetencesTreeV2 = (competencesMap, passedCidsSet) => {
  const res = {};
  const visitedNodes = new Set();

  const calculateAvgPassed = cid => {
    if (visitedNodes.has(cid)) {
      return null;
    }

    visitedNodes.add(cid);

    const competence = competencesMap[cid];

    if (!competence) {
      return null;
    }

    const passed = getCompetencePassed(competence, competencesMap, passedCidsSet) || 0;

    let childrenPassed = passed;
    let childrenCount = 1;

    if (competence.children_ids) {
      for (const childId of competence.children_ids) {
        const childResult = calculateAvgPassed(childId);

        if (childResult !== null) {
          childrenPassed += childResult.childrenPassed;
          childrenCount += childResult.childrenCount;
        }
      }
    }

    visitedNodes.delete(cid);

    return {
      childrenPassed,
      childrenCount,
      passed,
      childrenAvgPassed: childrenPassed / childrenCount,
    };
  };

  for (const cid of Object.keys(competencesMap)) {
    const competence_id = validIntOrNull(cid);

    res[competence_id] = calculateAvgPassed(competence_id);

    if (!res[competence_id]) {
      continue;
    }

    res[competence_id].childrenPassed -= res[competence_id].passed;
    res[competence_id].childrenCount -= 1;
    res[competence_id].childrenAvgPassed = res[competence_id].childrenCount
      ? Math.round(res[competence_id].childrenPassed / res[competence_id].childrenCount)
      : 0;
  }

  for (const cid of Object.keys(res)) {
    if (res[cid] == null) continue;

    const competence = competencesMap[cid];

    if (!competence) continue;

    let directChildrenCompletedCount = 0;
    let directChildrenCount = 0;

    let children_passed_date = null;

    if (competence.children_ids?.length) {
      for (const childId of competence.children_ids) {
        directChildrenCount += 1;
        if (res[childId]?.passed === 100) {
          directChildrenCompletedCount += 1;
        }

        if (competencesMap[childId]?.passed_date && (!children_passed_date || new Date(children_passed_date) < new Date(competencesMap[childId]?.passed_date))) {
          children_passed_date = competencesMap[childId]?.passed_date;
        }
      }
    }

    const directChildrenAvgPassed = directChildrenCount
      ? Math.round(directChildrenCompletedCount / directChildrenCount * 100)
      : 0;

    const passed /* allwaysUseChildrenProgress
      ? children_passed
      : */ = Math.max(directChildrenAvgPassed || 0, res[cid].passed || 0);

    const passed_date = competence?.passed_date || passed === 100 && children_passed_date;

    Object.assign(
      res[cid],
      competence,
      {
        passed,
        passed_date,
        tree_children_passed: res[cid].childrenAvgPassed,
        // children_all_passed: res[cid].childrenPassed,
        tree_children_count: res[cid].childrenCount,
        children_count: directChildrenCount,
        children_completed_count: directChildrenCompletedCount,
        children_passed: directChildrenAvgPassed,
      },
    );

    res[cid].status = getCompetenceStatus(res[cid]);
    res[cid].passed = getCompetencePassed(res[cid], competencesMap, passedCidsSet);
    res[cid].expired = getIsExpiredCompetence(res[cid]);

    if (res[cid].passed === 100) {
      res[cid].children_passed_date = children_passed_date;
    }

    res[cid].waiting_for_approval = res[cid].status === 'waiting_for_approval';

    res[cid].passedInfo = {
      checkedBy: competence?.checked_by,
      isPassed: res[cid].passed === 100,
      passed: res[cid].passed,
      completed: directChildrenCompletedCount || (res[cid].status === 'passed' && !directChildrenCount ? 1 : 0),
      total: directChildrenCount || 1,
      passedPercent: res[cid].passed,
      waitingForApproval: res[cid].waiting_for_approval,
      status: res[cid].status,
    };

    if (competence.children_ids?.length) {
      res[cid].children = [];

      for (const childId of competence.children_ids) {
        if (res[childId] == null) continue;

        res[cid].children.push(res[childId]);
      }
    }
  }

  return res;
};
