import {courseCatalog, employees} from '@routes/routes.manifest';
import {isObjectWithKeys} from './misc.utils';

export const getRouteWithParams = (url = '', params = {}) => {
  if (!url) return '';
  if (!isObjectWithKeys(params)) return url;

  return Object.entries(params)
    .reduce(
      (createdUrl, [paramKey, paramValue]) => createdUrl
        .replace(`:${paramKey}?`, paramValue || '')
        .replace(`:${paramKey}`, paramValue || ''),
      url,
    )
    // if the url is eg /articles/:id
    // and params is {item: /a-article}
    // we dont want to get /articles//a-article
    .replace('//', '/')
    // if the url is eg /track/:mapId?
    // and mapId is not present in params
    // we dont want to get /track/:mapId?
    .replaceAll(/\/:[^/]+?\?/g, '');
};

export const getEmployeePreviewPath = userName =>
  getRouteWithParams(employees.employeePreview, {userName});

export const getCoursePath = cid =>
  getRouteWithParams(courseCatalog.coursePreview.path, {cid});
