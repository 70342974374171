import memoize from 'micro-memoize';
import {createSelector} from 'reselect';
import {LoadStatuses} from '@types/load.types';
import {emptyArr, emptyObj} from '@utils/constants';

export const selectAPICompetences = ({api: {competences}}) => competences;
export const selectAPITracks = ({api: {tracks}}) => tracks;
export const selectAPIPersonCompetences = ({api: {personCompetences}}) => personCompetences;

export const selectAPICompetence = ({api: {competences}}, id) => competences[id];
export const selectAPITrack = ({api: {tracks}}, id) => tracks[id];
export const selectAPIPersonCompetence = ({api: {personCompetences}}, id) => personCompetences[id];

export const selectAPICompetenceStatus = ({api: {competences}}, id) => competences[id]?.status || LoadStatuses.NOT_LOADED;
export const selectAPITrackStatus = ({api: {tracks}}, id) => tracks[id]?.status || LoadStatuses.NOT_LOADED;
export const selectAPIPersonCompetenceStatus = ({api: {personCompetences}}, id) => personCompetences[id]?.status || LoadStatuses.NOT_LOADED;

export const selectAPICoursegroups = ({api: {coursegroups}}) => coursegroups;

export const selectAPIRoles = ({api: {roles}}) => roles;
export const selectAPIPersonRoles = ({api: {personRoles}}) => personRoles;
export const selectAPIRoleCompetences = ({api: {roleCompetences}}) => roleCompetences;

const sortRoles = items => items?.length
  ? items.toSorted((a, b) => {
    const aTitle = a?.title?.toLowerCase() || '';
    const bTitle = b?.title?.toLowerCase() || '';

    return aTitle.localeCompare(bTitle) + (a.role_type_id - b.role_type_id);
  })
  : emptyArr;

const createIdGetter = idKey => idKey.includes('|')
  ? item => idKey.split('|').reduce((acc, key) => acc ?? item[key], null)
  : item => item[idKey];

const emptyNormalizedData = {
  data: emptyObj,
  ids: emptyArr,
};

export const createNormalizedDataSelector = memoize((selector, idKey = 'id', prepareFn) => {
  const getId = createIdGetter(idKey);

  return createSelector(
    selector,
    origResult => {
      const origData = origResult?.hasOwnProperty?.('data')
        ? origResult.data
        : origResult;

      const isArr = Array.isArray(origData);
      const isEmpty = !origData || (isArr
        ? !origData.length
        : typeof origData === 'object' && !Object.keys(origData).length);

      if (isEmpty) return {
        ...emptyNormalizedData,
        status: origResult?.status || LoadStatuses.NOT_LOADED,
      };

      const dataArr = isArr
        ? origData
        : Object.values(origData);
      const preparedData = typeof prepareFn === 'function'
        ? prepareFn(dataArr)
        : dataArr;

      const ids = new Set();
      const byId = {};

      for (const item of preparedData) {
        const id = getId(item);

        if (id == null) continue;

        ids.add(id);
        byId[id] = item;
      }

      return {
        data: byId,
        ids: [...ids],
        status: origResult?.status || LoadStatuses.LOADED,
      };
    },
  );
});

export const selectRolesNormalized = createNormalizedDataSelector(selectAPIRoles, 'role_id', sortRoles);

export const createRoleCompetencesSelector = memoize((roleId, personId) => createSelector(
  selectAPIRoleCompetences,
  roleCompetences => roleCompetences[roleId] || emptyObj,
));

export const createPersonRolesSelector = memoize(personId => createSelector(
  selectAPIPersonRoles,
  personRoles => personRoles[personId] || emptyObj,
));
