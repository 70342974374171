import dayjs from 'dayjs';
import {backendUrl} from '@config';
import {i18n} from '@src/i18n';
import {validIntOrNull} from './number.utils';
import {isStringWithLength} from './string.utils';

export const getMessagesText = messagesCount => {
  if (messagesCount === 100) return i18n('person.menu.messagesTxt100OrMoreUnread');
  if (messagesCount === 1) return i18n('person.menu.messagesTxtOneUnread');
  if (messagesCount > 1) return i18n('person.menu.messagesTxtUnread', {functionArgs: {x: messagesCount}});

  return i18n('person.menu.messagesTxt');
};

export const getBartId = () => validIntOrNull(localStorage.getItem('bart')); // this should be replaced with a selector

// gets the full url for an image, possibly prepending the backend-url if the url is absolute
export const getImageUrl = src => {
  if (!isStringWithLength(src)) return '';

  if (src.startsWith(backendUrl)) {
    return src;
  }
  // urls starting with /images/ are for local images in waymaker, we dont want to load these from the backend url
  if (src.charAt(0) === '/' && !src.startsWith('/images/')) {
    return backendUrl + src;
  }

  return src;
};

export const getAccess = (orgAccess, userAccess) => {
  const access = {
    onboarding: false,
    learning: false,
    userAccess: userAccess && userAccess || 'crew',
    gathering: false,
    users: 20,
    maps: 5,
    learingportal: false,
  };

  if (orgAccess.length === 4) {
    access.onboarding = orgAccess[0] === '1' && true || false;
    orgAccess = orgAccess.slice(1);
  }

  if (orgAccess.length === 3) {
    access.learning = orgAccess[0] === '1' && true || false;
    orgAccess = orgAccess.slice(1);
  }

  if (orgAccess.length === 2) {
    access.meeting = orgAccess[0] === '1' && true || false;
    access.learingportal = orgAccess[0] === '2' && true || false;
    orgAccess = orgAccess.slice(1);
  }

  if (orgAccess.length === 1 && orgAccess[0] === '3') {
    access.users = 200;
  } else if (orgAccess.length === 1 && orgAccess[0] === '2') {
    access.users = 50;
  }

  return access;
};

export const getTwoletterLangCodeFromNavigatorLanguage = () => {
  const navLang = !!navigator.language && navigator.language.toLocaleLowerCase();

  if (!navLang) {
    return null;
  }

  if (navLang.startsWith('en-')) {
    return 'en';
  }

  return {
    'nb-no': 'no',
    'nn-no': 'no',
  }[navLang] || 'no';
};

export const verifyPassed = competence => {
  if (!competence || !competence?.passed) return false;

  if (competence.passed === 100 && competence.valid_until) {
    let validUntil = dayjs(competence.valid_until, 'DD.MM.YYYY');

    if (!validUntil.isValid()) validUntil = dayjs(competence.valid_until);
    if (!validUntil.isValid()) validUntil = null;

    if (validUntil && validUntil.isBefore(dayjs())) return false;
  }

  return competence.passed === 100;
};

export const eventHasStarted = event => dayjs().isAfter(dayjs(event.startdate));
export const eventHasEnded = event => dayjs().isAfter(dayjs(event.enddate));
