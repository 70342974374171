import {Route as ReactRouterRoute} from 'react-router-dom';
import {
  SENTRY_ENABLE,
  SENTRY_ERROR_BOUNDARY,
  SENTRY_ROUTING,
} from '@config';
import * as Sentry from '@sentry/react';
import {ErrorFallback} from './app.components';
import {wrap} from './app.util';

const wrappers = SENTRY_ENABLE
  ? [
    ...SENTRY_ROUTING ? [Sentry.withSentryRouting] : [],
    ...SENTRY_ERROR_BOUNDARY ? [[Sentry.withErrorBoundary, {fallback: <ErrorFallback />}]] : [],
  ]
  : [];

export const Route = wrap(wrappers, ReactRouterRoute);
