import memoize from 'micro-memoize';
import {createSelector} from 'reselect';
import {LoadStatuses} from '@types/load.types';
import {isObjectWithKeys} from '@utils/misc.utils';
import {emptyArr, emptyObj} from '../../common/utils/constants';
import {selectEmployeesList} from './employees.selectors';

export const selectRoles = ({roles: {roles}}) => roles;
export const _selectRolesData = ({roles: {roles: {data} = {}} = {}}) => data;
export const selectRolesOrganisations = ({roles: {organisations}}) => organisations;
export const selectRoleDetails = ({roles: {roleDetails}}) => roleDetails;

const _createRoleByIdSelector = memoize(roleId => createSelector(
  selectRoleDetails,
  (roleDetails = {}) => {
    const {
      byId: roleById = {},
      status: rolesStatus = LoadStatuses.NOT_LOADED,
    } = roleDetails;

    const {
      data = null,
      status = LoadStatuses.NOT_LOADED,
    } = roleById[roleId] || {};

    return {
      data,
      status: rolesStatus === LoadStatuses.IS_LOADING
        ? LoadStatuses.IS_LOADING
        : status,
    };
  },
));

export const createEmployeesByRoleIdSelector = memoize(roleId => createSelector(
  selectEmployeesList,
  employeesState => {
    const {
      byId: employeeById = {},
      allIds: allEmployeeIds,
    } = employeesState || {};

    if (!isObjectWithKeys(employeeById)) return emptyArr;

    const role_id = Number(roleId);
    const roleEmployees = [];

    for (const employeeId of allEmployeeIds) {
      const employee = employeeById[employeeId];
      const {roleIds = []} = employee || {}; // currently not in use. change in fetchEmployees saga

      if (roleIds.includes(role_id)) roleEmployees.push(employee);
    }

    return roleEmployees;
  },
));

export const createRoleDetailsSelector = memoize(roleId => createSelector(
  _createRoleByIdSelector(roleId),
  createEmployeesByRoleIdSelector(roleId),
  (roleDetails = emptyObj, roleEmployees = emptyArr) => {
    if (roleId == null) return emptyObj;

    const {data, status} = roleDetails;

    if (status !== LoadStatuses.LOADED) return {
      data,
      employees: emptyArr,
      status,
    };

    return {
      data,
      employees: roleEmployees,
      status,
    };
  },
));
