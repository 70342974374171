import React from 'react';
import {useDispatch} from 'react-redux';
import {createContext, useContext} from 'use-context-selector';
import {snake2Camel} from '@utils/string.utils';
import {
  modalActions,
  modalIdsByAccessLevel,
} from '../registry';
import {MODALS_PUT_MODAL} from '../store/actions';

export const ModalsContext = createContext(composeActions(() => {}));

/**
 * This hook is used to access modal actions from anywhere in the app.
 * See {@link ModalIds} for the list of modal ids (camelCase ids will be used as keys in the returned object)
 *
 * @returns {object} The modal actions.
 * @example
 * const {courseBuilder: {open, close}} = useModalsContext();
*/
export const useModalsContext = () => useContext(ModalsContext);

function composeActions(dispatch, access = 5) {
  const withDispatch = {
    closeAllModals: () => dispatch({
      type: MODALS_PUT_MODAL,
      payload: {closeAll: true},
    }),
  };

  for (let i = 0; i <= access; i++) {
    modalIdsByAccessLevel[i]?.forEach(modalId => {
      if (!modalActions[modalId]) return;

      const camelCaseId = snake2Camel(modalId);

      withDispatch[camelCaseId] = {
        open: payload => modalActions[modalId].open
          .forEach(action => dispatch(action(payload))),
        close: payload => modalActions[modalId].close
          .forEach(action => dispatch(action(payload))),
      };
      withDispatch[modalId] = withDispatch[camelCaseId];
    });
  }

  return withDispatch;
};

/**
 * This provider is used to provide the modal actions to the entire app.
 * It is used in the root of the app.
*/
export const ModalsProvider = ({children}) => {
  const dispatch = useDispatch();
  const actions = React.useMemo(() => composeActions(dispatch), [dispatch]);

  return (
    <ModalsContext.Provider value={actions}>
      {children}
    </ModalsContext.Provider>
  );
};
