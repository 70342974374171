import * as React from 'react';
import {useSelector} from 'react-redux';
import {useHistory as useReactRouterHistory} from 'react-router-dom';
import {selectSessionId} from '@selectors/auth.selectors';
import {isStringWithLength} from '@utils/string.utils';

const appPaths = [
  '/dashboard/panelet',
  '/dashboard/search',
  '/dashboard/competence',
  '/dashboard/content',
  '/employees',
  '/employees/worklist',
  '/my-education',
  '/admin/tracks',
  '/admin/roles',
];

const getPathWithCurrentToken = (sessionId, pathname) => {
  if (!sessionId || !pathname?.includes?.('?token=')) return pathname;

  const [baseUrl, rest] = pathname.split('?token=');
  const [_, query] = rest.split('&');

  return `${baseUrl}?token=${sessionId}&${query}`;
};

export const useWrappedHistory = () => {
  const routerHistory = useReactRouterHistory();
  const routerHistoryPush = routerHistory.push;

  const sessionId = useSelector(selectSessionId);

  const push = React.useCallback(pathname => {
    if (!isStringWithLength(pathname)) return;

    // internal urls
    if (pathname === '/' || pathname.startsWith('/')) {
      routerHistoryPush('/');

      return;
    }

    for (const appPath of appPaths) {
      if (pathname.includes(appPath)) {
        routerHistoryPush(appPath);

        return;
      }
    }

    // external urls (grape)
    if (pathname.startsWith('https://')) {
      window.location.href = getPathWithCurrentToken(sessionId, pathname);
    }
  }, [routerHistoryPush, sessionId]);

  return {
    ...routerHistory,
    push,
  };
};

export const useCustomHistory = enable => enable
  ? useWrappedHistory
  : useReactRouterHistory;
