/* eslint-disable unicorn/prefer-spread */
import {reducer as formReducer} from 'redux-form';
import {createResponsiveStateReducer} from 'redux-responsive';
import {apiReducer} from '@actions/api.actions';
import {appReducerImmer} from '@actions/app.actions';
import {coursesReducerImmer} from '@actions/courses.actions';
import {profileImmerReducer} from '@actions/profile.actions';
import {ENABLE_REDUX_TESTING} from '@config';
import alertReducer from '@reducers/alert.reducer';
import authReducer from '@reducers/auth.reducer';
import cmsReducer from '@reducers/cms.reducers';
import componentsReducer from '@reducers/components.reducer';
import configReducer from '@reducers/config.reducers';
import helpReducer from '@reducers/help.reducer';
import manageReducer from '@reducers/manage.reducer';
import mapReducer from '@reducers/map.reducers';
import messagesReducer from '@reducers/messages.reducer';
import newsReducer from '@reducers/news.reducer';
import notificationsReducer from '@reducers/notifications.reducer';
import rolesReducer from '@reducers/roles.reducer';
import themeReducer from '@reducers/theme.reducer';
import {userReducer} from '@reducers/user.reducer';
import {combineReducers} from '@reduxjs/toolkit';
import {size} from '@styles/device';
import {modalsReducer} from '../features/modals/registry';

const enableReduxTesting = process.env.NODE_ENV !== 'production' && ENABLE_REDUX_TESTING;

// from here https://github.com/AlecAivazis/redux-responsive
const defaultBreakpoints = {
  extraSmall: 480,
  small: 768,
  medium: 992,
  mobileCourseCatalog: Number.parseInt(
    size.mobileCourseCatalog.split('px')[0],
    10,
  ),
  large: 1200,
};

const browserReducer = createResponsiveStateReducer(defaultBreakpoints);

// action type to replace the entire store with a new state (see below)
const TESTING_REPLACE_STATE_ACTION = 'TESTING_REPLACE_STATE';

// wrapped reducer which allows an action to replace the entire store* (see below)
// this is useful for mocking when testing for example
// all other actions are sent to the normal reducer as usual
function createReplaceStateReducer(rootReducer) {
  return function reducer(state, action) {
    if (action.type === TESTING_REPLACE_STATE_ACTION) {
      return {
        ...action.payload.state,
        auth: state.auth, // contains stuff like session id
        config: state.config, // also contains a function - not serializable
        browser: state.browser, // contains info for curr screen size
      };
    }

    return rootReducer(state, action);
  };
}

export function createRootReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    user: userReducer,
    app: appReducerImmer,
    modals: modalsReducer,
    auth: authReducer,
    alert: alertReducer,
    courses: coursesReducerImmer,
    profile: profileImmerReducer,
    messages: messagesReducer,
    roles: rolesReducer,
    form: formReducer,
    browser: browserReducer,
    theme: themeReducer,
    manage: manageReducer,
    notifications: notificationsReducer,
    news: newsReducer,
    config: configReducer,
    cms: cmsReducer,
    map: mapReducer,
    components: componentsReducer,
    help: helpReducer,
    api: apiReducer,
    // employees: null, // to be lazy loaded
    // admin: null, // to be lazy loaded
    // panelet: null, // to be lazy loaded
    ...injectedReducers,
  });

  return enableReduxTesting
    ? createReplaceStateReducer(rootReducer)
    : rootReducer;
}
