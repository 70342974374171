import assignWith from 'lodash/assignWith';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {shallowEqual, useSelector} from 'react-redux';
import {
  createSelector,
  createSelectorCreator,
  lruMemoize as defaultMemoize,
  referenceEqualityCheck as defaultEqualityCheck,
} from 'reselect'; // lruMemoize/referenceEqualityCheck/weakMapMemoize/unstable_autotrackMemoize
import {emptyArr} from './constants';
import {isObjectWithKeys} from './misc.utils';
import {validIntOrNull} from './number.utils';

const isEqualJSON = (a, b) => typeof a === 'string'
  ? a === b
  : JSON.stringify(a) === JSON.stringify(b);

export const createJSONEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqualJSON,
);

export function useShallowEqualSelector(selector) {
  return useSelector(selector, shallowEqual);
}

export function useDeepEqualSelector(selector) {
  return useSelector(selector, isEqual);
}

export function memoizeResult(func) {
  let lastArgs = null;
  let lastResult = null;

  // we reference arguments instead of spreading them for performance reasons
  return function shallowCompare() {
    if (!shallowEqual(lastArgs, arguments)) {
      // apply arguments instead of spreading for performance.
      const result = Reflect.apply(func, null, arguments);

      if (!shallowEqual(lastResult, result)) {
        lastResult = result;
      }
    }

    lastArgs = arguments;

    return lastResult;
  };
}

// Use this selector when you want a shallow comparison of the arguments and you want to memoize the result
// try and use this only when your selector returns an array of ids
export const createIdsSelector = createSelectorCreator(memoizeResult);

// Use this selector when you want a shallow comparison of the arguments and you don't need to memoize the result
export const createShallowSelector = createSelectorCreator(defaultMemoize, shallowEqual);

// create a "selector creator" that uses lodash.isEqual instead of a shallow
export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

function specialMemoize(
  func,
  resultEqCheck,
  argEqCheck = defaultEqualityCheck,
) {
  let lastArgs = null;
  let lastResult = null;
  const isEqualToLastArg = (value, index) => argEqCheck(value, lastArgs[index]);

  return (...args) => {
    if (
      lastArgs === null
      || lastArgs.length !== args.length
      || !args.every(isEqualToLastArg)
    ) {
      // Only update result if it has changed according to resultEqCheck
      const nextResult = func(...args);

      if (!resultEqCheck(lastResult, nextResult)) {
        lastResult = nextResult;
      }
    }
    lastArgs = args;

    return lastResult;
  };
}

export const createUserDetailItemSelector = createSelectorCreator(
  specialMemoize,
  (prev, next) => {
    if (!prev || !next) {
      return false;
    }

    return (
      prev.id === next.id
      && prev?.user?.is_followed === next?.user?.is_followed
    );
  },
);

export const isFalsyOrEmpty = value => !value || isEmpty(value);

export const merge = (dest, ...sources) => {
  const customizer = (objValue, srcValue) => {
    if (isFalsyOrEmpty(srcValue)) return objValue;
    if (objValue === undefined && srcValue !== undefined) return srcValue;

    return srcValue;
  };

  return assignWith(dest, ...sources, customizer);
};

export const createObjectNumberIdsSelector = selector => createSelector(
  selector,
  data => {
    if (!isObjectWithKeys(data)) return emptyArr;

    const res = Object.keys(data || {})
      .map(validIntOrNull)
      .filter(Boolean);

    if (!res.length) return emptyArr;

    return res;
  },
);
