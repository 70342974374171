import dayjs from 'dayjs';
import {i18n, i18ndayjs} from '@src/i18n';
import {isObjectWithKeys} from './misc.utils';
import {isStringWithLength} from './string.utils';

const SECONDS = 'SECONDS';
const MINUTES = 'MINUTES';
const HOURS = 'HOURS';
const DAYS = 'DAYS';

export const TimeUnits = {
  SECONDS,
  MINUTES,
  HOURS,
  DAYS,
};

export const TimeUnitToMs = {
  [SECONDS]: 1000,
  [MINUTES]: 1000 * 60,
  [HOURS]: 1000 * 60 * 60,
  [DAYS]: 1000 * 60 * 60 * 24,
};

export const emptyDate = () => {
  const d = new Date();

  d.setMilliseconds(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setHours(0);
  d.setFullYear(0);
  d.setMonth(0);
  d.setDate(1);

  return d;
};

// https://stackoverflow.com/a/44198641
export const isValidDate = date =>
  !!date
  && Object.prototype.toString.call(date) === '[object Date]'
  && !Number.isNaN(date);

export const lastDateThisYear = () => {
  const d = new Date();

  d.setMonth(11);
  d.setDate(31);
  d.setHours(23, 59, 59);

  return d;
};

export const lastDateNextYear = () => {
  const d = lastDateThisYear();

  d.setFullYear(d.getFullYear() + 1);

  return d;
};

export const getDateOfWeek = (w, y) => {
  var d = 1 + (w - 1) * 7; // 1st of January + 7 days for each week

  return new Date(y, 0, d);
};

export const daysDifference = (dateMax, dateMin) => dateMax.diff(dateMin, 'days');

export const getDateNumLocal = date => {
  const obj = i18ndayjs(date);
  const dateNum = `${obj.date()}`;
  const cardinal = obj.format('Do').replace(dateNum, '');

  return [`${dateNum.padStart(2, '0')}`, `${cardinal}`];
};

export const parseDate = dateStr => {
  if (!dateStr || typeof dateStr !== 'string') return null;

  const date = Date.parse(dateStr.replaceAll(' ', 'T'));

  return date ? new Date(date) : null;
};

export const getTimestamp = () => Math.round(Date.now() / 1000);
export const unixTimestampMs = () => dayjs().valueOf();

export const isValidTimeunit = unit => isStringWithLength(unit) && TimeUnits.hasOwnProperty(unit);

export const getMilliseconds = (num, timeunit) => {
  if (isObjectWithKeys(num)) {
    let total = 0;

    Object.keys(num).forEach(key => {
      const upperCaseKey = key.toUpperCase();

      if (isValidTimeunit(upperCaseKey)) {
        total += num[key] * TimeUnitToMs[upperCaseKey];
      }
    });

    return total;
  }
  if (typeof num === 'number') {
    if (isValidTimeunit(timeunit)) return num * TimeUnitToMs[timeunit];

    return num;
  }

  return 0;
};

export const getLocalTimestamp = () => dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS');

export const formatDateStr = (dateStr, outFormat = 'DD.MM.YYYY HH:mm') => {
  dateStr = dateStr?.toString?.();

  if (!dateStr || typeof dateStr !== 'string') return null;

  let dayjsObj = dayjs(dateStr, 'YYYY-MM-DD HH:mm:ss');

  if (dayjsObj.isValid()) {
    return outFormat === null
      ? dayjsObj.toISOString()
      : dayjsObj.format(outFormat);
  } else {
    dayjsObj = dayjs(dateStr, 'DD.MM.YYYY HH:mm');
    if (dayjsObj.isValid()) {
      return outFormat === null
        ? dayjsObj.toISOString()
        : dayjsObj.format(outFormat);
    }

    try {
      let [datePart, timePart] = dateStr.split(' ');

      if (datePart) datePart = datePart.replaceAll('.', '-');
      if (timePart) timePart = timePart.replaceAll('.', ':');

      const str = `${datePart} ${timePart}`.trim();

      dayjsObj = dayjs(`${str}`, 'YYYY-MM-DD' + (timePart ? ' HH:mm:ss' : ''));

      if (dayjsObj.isValid()) {
        return outFormat === null
          ? dayjsObj.toISOString()
          : dayjsObj.format(outFormat);
      }
    } catch {
      return null;
    }
  }

  return null;
};

export const getYearMonthString = date => {
  const obj = i18ndayjs(date);

  if (!obj.isValid()) return '';

  return obj.format('YYYY-MM');
};

export const getDurationName = (metric, plural) => plural
  ? i18n('date-and-time.' + metric, {lowerCase: true}, metric)
  : i18n('date-and-time.' + metric.slice(0, - 1), {lowerCase: true}, metric);

export const getDurationText = durations => durations?.length
  ? durations
    .map(({duration, type}) => `${duration} ${getDurationName(type, duration !== 1)}`)
    .join(' ')
  : '';

export const filterFutureDate = (date, ...args) => {
  if (!date) return null;

  const _args = args?.length && args || [100, 'years'];

  return dayjs(date).isAfter(dayjs().add(..._args))
    ? null
    : date;
};
