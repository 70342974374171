/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable-next-line simple-import-sort/imports */
// import './polyfills';
import * as React from 'react';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import {createBrowserHistory} from 'history';
import {ClearCacheProvider} from 'react-clear-cache';
import * as ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter, Router as ReactRouter} from 'react-router-dom';
import {authPollAuthStatus} from '@actions/auth.actions';
import {ENABLE_REDUX_TESTING, SENTRY_ENABLE, SENTRY_ROUTING, version} from '@config';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faAngleRight} from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import {faArrowRight} from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import {faAt} from '@fortawesome/pro-solid-svg-icons/faAt';
import {faBars} from '@fortawesome/pro-solid-svg-icons/faBars';
import {faCalendar} from '@fortawesome/pro-solid-svg-icons/faCalendar';
import {faCalendarAlt} from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
import {faCheck} from '@fortawesome/pro-solid-svg-icons/faCheck';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import {faChevronRight} from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import {faChevronUp} from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import {faCog} from '@fortawesome/pro-solid-svg-icons/faCog';
import {faFileAlt} from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import {faFileExcel} from '@fortawesome/pro-solid-svg-icons/faFileExcel';
import {faHourglass} from '@fortawesome/pro-solid-svg-icons/faHourglass';
import {faPlay} from '@fortawesome/pro-solid-svg-icons/faPlay';
import {faPlus} from '@fortawesome/pro-solid-svg-icons/faPlus';
import {faRedo} from '@fortawesome/pro-solid-svg-icons/faRedo';
import {faSearch} from '@fortawesome/pro-solid-svg-icons/faSearch';
import {faThumbsUp} from '@fortawesome/pro-solid-svg-icons/faThumbsUp';
import {faTimes} from '@fortawesome/pro-solid-svg-icons/faTimes';
import {faTrash} from '@fortawesome/pro-solid-svg-icons/faTrash';
import {faTrashAlt} from '@fortawesome/pro-solid-svg-icons/faTrashAlt';
import {faUser} from '@fortawesome/pro-solid-svg-icons/faUser';
import {faUserCheck} from '@fortawesome/pro-solid-svg-icons/faUserCheck';
import * as Sentry from '@sentry/react';
import {store} from '@src/redux';
import {defaultThemeGlobalCSSVars, styleInjectThemeCSSVars} from '@utils/themes.utils';
import {ModalsProvider} from './features/modals/context/modals-context';
import App from './app';
import {setLanguage} from './app.util';
import {env} from './env';
import {ThemeProvider} from './providers';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import '@styles/app.scss';
import 'dayjs/locale/nb';

const sentryRouting = SENTRY_ENABLE && SENTRY_ROUTING ? {history: createBrowserHistory()} : {};
const Router = SENTRY_ENABLE && SENTRY_ROUTING ? ReactRouter : BrowserRouter;

dayjs.extend(weekOfYear);

library.add(
  faTrashAlt,
  faFileAlt,
  faChevronRight,
  faThumbsUp,
  faUser,
  faPlus,
  faHourglass,
  faCalendar,
  faAngleRight,
  faCog,
  faAt,
  faRedo,
  faTimes,
  faCalendarAlt,
  faPlay,
  faArrowRight,
  faSearch,
  faBars,
  faUserCheck,
  faChevronUp,
  faChevronDown,
  faFileExcel,
  faCheck,
  faTrash,
);

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'production' && ENABLE_REDUX_TESTING) {
  window.store = store;
}

// the process is like this:
// 1. check if the user is logged in or not, display loading while we wait
// 2. if not logged in: display login, else: continue to step 3
// 3. get config, display loading while we wait
// 4. when the config is done loading, load the rest
// store.dispatch(authGetAuthStatusAndStartLoading());

// polling for auth status
store.dispatch(authPollAuthStatus({
  intervalMs: 1000 * 60 * 59, // 59 minutes, since the session expires after 60 minutes
  isInit: true,
}));

setLanguage();
styleInjectThemeCSSVars(defaultThemeGlobalCSSVars);

if (SENTRY_ENABLE) {
  setTimeout(() => {
    console.log({
      version,
      release: `waymaker@${version}.${process.env.REACT_APP_GIT_SHA?.replace(/\s/g, '')}`,
    });

    Sentry.init({
      dsn: 'https://86b43514fafa44f1830181ed78f2d228@o1035034.ingest.sentry.io/6003562',
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: [/^\/apiv2\//],
          ...sentryRouting.history && {routingInstrumentation: Sentry.reactRouterV5Instrumentation(sentryRouting.history)},
        }),
      ],
      release: `waymaker@${version}.${process.env.REACT_APP_GIT_SHA?.replace(/\s/g, '')}`,
      tracesSampleRate: 0.1,
    });
  }, 0);
}

const root = ReactDOM.createRoot(document.querySelector('#root'));

root.render(<Provider store={store}>
  <ThemeProvider>
    <ClearCacheProvider duration={300_000}>
      <Router
        {...sentryRouting}
        basename={process.env.REACT_APP_MOUNT_PATH || env.REACT_APP_MOUNT_PATH}
      >
        <ModalsProvider>
          <App />
        </ModalsProvider>
      </Router>
    </ClearCacheProvider>
  </ThemeProvider>
</Provider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
