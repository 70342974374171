import {freeze} from 'immer';
import {call, put} from 'redux-saga/effects';
import {notificationsAdd} from '@actions/notifications.actions';
import {waitForOrgId, waitForProfileId, waitForUsername} from '@sagas/app.sagas';
import {axiosRetry} from '@utils/async.utils';
import {emptyObj} from '@utils/constants';

export const defaultAPISagaFallbacks = {
  orgId: waitForOrgId,
  personId: waitForProfileId,
  username: waitForUsername,
};

export const defaultAPISagaOptions = {
  fallbacks: defaultAPISagaFallbacks,
  getNotification: null,
};

function* getPayloadWithFallbacks(action, fallbacks) {
  if (!action || !fallbacks) return action?.payload || emptyObj;

  const {payload} = action || {};

  const args = {};

  for (const key in fallbacks) {
    if (payload?.[key] !== undefined) continue;

    args[key] = typeof fallbacks[key] === 'function'
      ? yield call(fallbacks[key], action)
      : fallbacks[key];
  }

  return {
    ...payload,
    ...args,
  };
}

export function createFetchSaga(
  actionCreators,
  promiseOrUrl,
  options = defaultAPISagaOptions,
) {
  if (
    !promiseOrUrl
    || !actionCreators.success
  ) throw new Error('Invalid arguments for createFetchSaga');

  let promise = promiseOrUrl;

  if (typeof promiseOrUrl === 'string') {
    promise = () => axiosRetry(promiseOrUrl).then(({data}) => data);
  }

  function* fetchSaga(action) {
    const {
      onSuccess,
      onError,
      onEnd,
      getNotification: payloadGetNotification,
    } = action?.payload || {};

    const getNotification = payloadGetNotification === undefined
      ? options?.getNotification
      : payloadGetNotification;

    let err, data, payload;

    try {
      payload = yield call(getPayloadWithFallbacks, action, options?.fallbacks);

      if (actionCreators.request) {
        yield put(actionCreators.request(payload));
      }

      data = yield call(promise, payload);
    } catch (error) {
      err = error;
    }

    if (err) {
      try {
        if (actionCreators.failure) {
          yield put(actionCreators.failure({
            ...payload,
            error: err,
          }));
        }
        if (onError) onError(err);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        yield put(actionCreators.success({
          ...payload,
          data: !!data && typeof data === 'object'
            ? freeze(data)
            : data,
        }));
        if (onSuccess) onSuccess(data);
      } catch (error) {
        console.error(error);
      }
    }
    if (getNotification) {
      const notification = getNotification({
        data,
        error: err,
        payload,
      });

      if (notification) yield put(notificationsAdd(notification));
    }
    if (onEnd) onEnd();
  }

  return fetchSaga;
}
