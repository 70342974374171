import {freeze} from 'immer';
import {call, put, select, spawn, takeEvery} from 'redux-saga/effects';
import {
  apiFetchCompetence,
  // apiFetchMapData,
  apiFetchPersonCompetence,
} from '@actions/api.actions';
import {competencesToggleSuccess} from '@actions/competences.actions';
import {coursesCourseFinished, coursesSignCourse} from '@actions/courses.actions';
import {employeesSaveVerification, selectedPersonActions} from '@actions/employees.actions';
import {insertOrUpdateChildrenCompetences} from '@actions/map.actions';
import {
  profileCreateSelfSign,
  profileSelfSign,
  profileUpdateOneCompetence,
  profileUpdatePassedCompetences,
  profileUpdatePendingChecklists,
} from '@actions/profile.actions';
import {waitForProfileId} from '@sagas/app.sagas';
import {competenceTreeSelector, getSelectedPersonV2Id, getSelectedPersonV2Username} from '@selectors/employees.selected-person.selectors';
import {findCompetenceId, findPersonId} from '@src/store/util/competence-identity';
import {getAllParentIds} from '@utils/misc.utils';
import {normalizeCompetence, normalizePersonCompetence} from '@utils/normalize.utils';
import {formatDateStr} from '@utils/time.utils';
import {fetchChildrenCompetencesAPI} from '../api/competences';
import {selectAPICompetences, selectAPIPersonCompetences} from '../selectors/api.selectors';

const getUpdatedChecklistData = (checklist, self_check, manager_check, is_check_manager_name) => {
  const updatedChecklist = {...checklist};

  if (self_check) {
    updatedChecklist.is_check_user = formatDateStr(self_check, 'DD.MM.YYYY HH:mm');
  }

  if (manager_check) {
    updatedChecklist.is_check_manager = formatDateStr(manager_check, 'DD.MM.YYYY HH:mm');
    if (is_check_manager_name) {
      updatedChecklist.is_check_manager_name = is_check_manager_name;
    }
  }

  return updatedChecklist;
};

const watchCompetenceActions = takeEvery([
  profileSelfSign.success,
  coursesSignCourse.success,
  employeesSaveVerification.success,
  profileCreateSelfSign.success,
  profileUpdateOneCompetence.success,
  profileUpdatePendingChecklists().type,
  competencesToggleSuccess().type,
  coursesCourseFinished().type,
  profileUpdatePassedCompetences().type,
  insertOrUpdateChildrenCompetences().type,
  // fetchTrackSuccess().type,
  // profileFetchPersonSummary.success,
], function* onCompetenceActions(action) {
  const {payload, type} = action;

  if (!payload) return;

  const profileId = yield call(waitForProfileId);
  const personId = findPersonId(payload) || profileId;

  if (type === competencesToggleSuccess().type) {
    const {responseData, data} = payload || {};
    const {
      passed,
      self_check,
      manager_check,
      is_check_manager_name,
      check_raw,
    } = responseData || data || {};

    const cid = findCompetenceId(payload);

    if (cid && passed && (self_check || manager_check)) {
      const selectedPersonId = yield select(getSelectedPersonV2Id);

      // update selected person competence
      if (personId === selectedPersonId) {
        const competences = yield select(competenceTreeSelector);
        // const personCompetences = yield select(_getSelectedPersonV2PersonCompetences);

        const prevCompetence = competences[cid] || {};
        const {checklist: prevChecklist} = prevCompetence || {};

        const updatedChecklist = getUpdatedChecklistData(prevChecklist, self_check, manager_check, is_check_manager_name);

        let passed = 100;

        if (prevChecklist?.check_raw === 'both' && !prevChecklist?.is_check_user) {
          passed = 50;
        }

        yield put(selectedPersonActions.fetchPersonCompetence.success({
          pid: personId,
          cid,
          data: freeze(normalizePersonCompetence({
            ...prevCompetence,
            competence_id: cid,
            passed,
            checklist: updatedChecklist,
          })),
        }));

        const parentIds = getAllParentIds(cid, competences);

        const selectedPersonUsername = yield select(getSelectedPersonV2Username);

        // refetch to get phce_ids
        for (const _cid of parentIds) {
          yield spawn(fetchChildrenCompetencesAPI, {
            payload: {
              pid: personId,
              username: selectedPersonUsername,
              cid: _cid,
              successAction: selectedPersonActions.fetchChildrenCompetences.success,
            },
          });
        }

        return;
      }

      // todo: always use data from selector:
      const personCompetences = yield select(selectAPIPersonCompetences);
      const prevPersonCompetence = personCompetences[personId]?.[cid]?.data || {};

      const {checklist} = prevPersonCompetence || {};

      if (checklist) {
        const updatedChecklist = getUpdatedChecklistData(checklist, self_check, manager_check);

        yield put(apiFetchPersonCompetence.success({
          pid: personId,
          cid,
          data: freeze(normalizePersonCompetence({
            ...prevPersonCompetence,
            checklist: updatedChecklist,
          })),
        }));

        return;
      }
    }
  }

  const personCompetence = normalizePersonCompetence(payload);
  const competence = normalizeCompetence(payload);

  const cid = personCompetence?.competence_id
    || competence?.competence_id
    || findCompetenceId(payload);

  if (!cid) return;

  const pid = personCompetence?.person_id || personId;

  if (competence) {
    yield put(apiFetchCompetence.success({
      pid,
      data: freeze(payload),
    }));
  } else if (personCompetence) {
    yield put(apiFetchPersonCompetence.success({
      pid,
      data: freeze(payload),
    }));
  }

  // const {parent_track_id} = parsePathname(window.location.pathname) || {};

  // if (parent_track_id) {
  //   const parentCompetence = yield select(createSingleCompetenceSelector(parent_track_id))[parent_track_id]?.data || {};

  //   if (parentCompetence?.layout === 'map') {
  //    yield put(fetchMapCourses({
  //       id: parent_track_id,
  //     }));
  //   }
  // }

  if (true || pid !== profileId) return;

  const competences = yield select(selectAPICompetences);

  const allParentIds = new Set();

  const getParentIdsRecursive = competenceId => {
    const competence = competences[competenceId]?.data || {};

    if (!competence) return;

    const {parent_ids = []} = competence;

    parent_ids.forEach(id => {
      allParentIds.add(id);

      getParentIdsRecursive(id);
    });
  };

  getParentIdsRecursive(cid);

  for (const id of allParentIds) {
    yield put(apiFetchPersonCompetence({
      cid: id,
      pid,
    }));
  }
});

const apiSagas = [
  watchCompetenceActions,
];

export default apiSagas;
