import React from 'react';
import {useSelector} from 'react-redux';
import {ThemeProvider as EmotionThemeProvider} from '@emotion/react';
import {selectCustomTheme} from '@selectors/global.selectors';
import {baseTheme} from '@styles/theme-objects/base';
import blue from '@styles/theme-objects/blue';
import './index.scss';

const defaultTheme = {
  ...baseTheme,
  ...blue,
};

const CustomThemeProvider = ({children}) => {
  const customTheme = useSelector(selectCustomTheme);

  return (
    <EmotionThemeProvider theme={customTheme}>
      {children}
    </EmotionThemeProvider>
  );
};

export const ThemeProvider = ({children}) => (
  <EmotionThemeProvider theme={defaultTheme}>
    <CustomThemeProvider>
      {children}
    </CustomThemeProvider>
  </EmotionThemeProvider>
);
