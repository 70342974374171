// messages
export const ROUTER_MESSAGES_CONTAINER_DID_MOUNT = 'router/messagesContainer/didMount';
export const routerMessagesContainerDidMount = payload => ({
  type: ROUTER_MESSAGES_CONTAINER_DID_MOUNT,
  payload,
});

// my-education (Min side)
export const ROUTER_MY_PAGE_DID_MOUNT = 'router/myPage/didMount';
export const routerMyPageDidMount = payload => ({
  type: ROUTER_MY_PAGE_DID_MOUNT,
  payload,
});

export const ROUTER_PROFILE_COMPETENCES_REPORT_DID_MOUNT = 'router/profileCompetencesReport/didMount';
export const routerProfileCompetencesReportDidMount = () => ({type: ROUTER_PROFILE_COMPETENCES_REPORT_DID_MOUNT});

export const ROUTER_ROLE_CONTAINER_DID_MOUNT = 'router/roleContainer/didMount';
export const routerRoleContainerDidMount = payload => ({
  type: ROUTER_ROLE_CONTAINER_DID_MOUNT,
  payload,
});

// employees
export const ROUTER_EMPLOYEES_CONTAINER_DID_MOUNT = 'router/employeesContainer/didMount';
export const routerEmployeesContainerDidMount = payload => ({
  type: ROUTER_EMPLOYEES_CONTAINER_DID_MOUNT,
  payload,
});
