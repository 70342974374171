import {
  dashboard,
  employees as employeesRoutes,
  help as _helpRoutes,
  learningPath,
  myEducation as myEducationRoutes,
  onboarding,
} from '@routes/routes.manifest';

export const employeesContainerRoutes = [...Object.values(employeesRoutes), dashboard.userPreview];
export const learningPathRoutes = [
  learningPath.resources.path,
  learningPath.track.path,
  onboarding.track.path,
  onboarding.main.path,
  learningPath.main.path,
];
export const helpRoutes = [_helpRoutes.search, _helpRoutes.item, _helpRoutes.startpage];
export const cvContainerRoutes = [myEducationRoutes.cvEdit.path, myEducationRoutes.cvAdd.path];
