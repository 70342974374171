import React from 'react';
import classNames from 'classnames';
import memoize from 'micro-memoize';
import {keyframes, useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {validIntOrNull} from '@utils/number.utils';
import {isStringWithLength} from '@utils/string.utils';

const keyframesSpin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const isIntegerValue = value => Number.isInteger(validIntOrNull(value));

const CSSParseSize = size => isIntegerValue(size)
  ? `${size}px`
  : size;

const CSSParseThemeColor = (color, theme = null) => {
  if (!theme) return color;
  if (isIntegerValue(color)) return theme?.scheme?.primary?.[`color${color}`];
  if (isStringWithLength(color) && color.startsWith('color')) return theme?.scheme?.primary?.[color];

  return color;
};

const getDynamicStyles = memoize(({
  size = 16,
  width = 3,
  color = 'rgb(220, 220, 220)',
  trackColor = 'transparent',
}) => {
  const sizeParsed = CSSParseSize(size);
  const widthParsed = CSSParseSize(width);

  return {
    height: sizeParsed,
    width: sizeParsed,
    borderColor: trackColor,
    borderTopColor: color,
    borderWidth: widthParsed,
  };
});

export const StyledLoadingSpinnerSmall = styled.div`
  border-radius: 50%;
  margin: 0 auto;
  border: 3px solid transparent;
  border-top-color: rgb(220, 220, 220);
  animation: ${keyframesSpin} 1s linear infinite;
`;

const Wrapper = styled.span`
  position: relative;

  .spinner-hidden-text {
    opacity: 0;
  }

  .spinner-inner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
`;

const LoadingSpinnerSmall = React.memo(({
  size,
  color,
  trackColor = 'transparent',
  width,
  hiddenText,
  themed,
  className,
  wrapperClassName,
}) => {
  const theme = useTheme();

  const spinner = React.useMemo(() => (
    <StyledLoadingSpinnerSmall
      className={classNames('loading-spinner-small', className)}
      key="loading-spinner"
      style={getDynamicStyles({
        size,
        color: themed
          ? CSSParseThemeColor(color ?? 400, theme)
          : color,
        trackColor: CSSParseThemeColor(trackColor, theme),
        width,
      })}
    />
  ), [size, color, width, className, theme, themed, trackColor]);

  if (!hiddenText) return spinner;

  return (
    <Wrapper className={classNames('loading-spinner-small-wrapper', wrapperClassName)}>
      <span className="spinner-hidden-text">
        {hiddenText}
      </span>
      <span className="spinner-inner-wrapper">
        {spinner}
      </span>
    </Wrapper>
  );
});

export default LoadingSpinnerSmall;
