import {isDark} from '@snapper/core';

/** based on https://awik.io/determine-color-bright-dark-using-javascript/ */
export const lightOrDark = color => {
  // Variables for red, green, blue values
  let r, g, b;

  // Check the format of the color, HEX or RGB?
  if (color.startsWith('rgb')) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 200) {
    return 'light';
  }

  return 'dark';
};

export const getIsDark = color => {
  if ([null, undefined, 'transparent'].includes(color)) return false;

  if (color.startsWith('rgba')) {
    const a = color.lastIndexOf(',');
    const alpha = Number.parseFloat(color.slice(a + 1, - 1));

    if (alpha < 0.5) return false;
  }

  return isDark(color);
};
