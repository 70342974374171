import Cookies from 'js-cookie';
import * as authActions from '@actions/auth.actions';
import {RESET_APP, RESET_STORE} from '@actions/global.actions';
import * as T from '@types/load.types';
import {emptyObj} from '@utils/constants';

const initialState = {
  user_name: undefined,
  authProfile: undefined,
  newPwd: undefined,
  stage: 'username',
  loginAttempts: 0,
  overtaken: null,
  transfered: {status: T.LoadStatuses.NOT_VERIFIED},
  secondLogin: false,
  secondData: undefined,
  sessionId: Cookies.get('tg-visit') || 'sd',
  authStatus: emptyObj,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
  case RESET_APP:
  case RESET_STORE:
  case authActions.AUTH_LOGOUT_REQUEST:
  case authActions.AUTH_LOGOUT_SUCCESS:
  case authActions.AUTH_UNAUTHORIZED: {
    return {
      ...initialState,
      authProfile: undefined,
      stage: 'username',
      sessionId: undefined,
      authStatus: emptyObj,
    };
  }
  case authActions.AUTH_LOGIN_REQUEST: {
    return {
      ...state,
      authStatus: {
        ...state.authStatus,
        isLoading: true,
      },
    };
  }
  case authActions.AUTH_LOGIN_SUCCESS: {
    return {
      ...state,
      authProfile: action.payload.authProfile,
      sessionId: action.payload.authProfile.session_id,
      secondLogin: false,
      startUrl: action?.payload?.start_url || state.startUrl,
    };
  }
  case authActions.AUTH_USER_IS_CONFIRMED_LOGGED_IN: {
    return {
      ...state,
      user_name: action?.payload?.authProfile?.user_name || state?.user_name || initialState.user_name,
      sessionId: action?.payload?.authProfile?.session_id || state.sessionId || initialState.sessionId,
      authProfile: action?.payload?.authProfile || state.authProfile || initialState.authProfile,
      authStatus: {
        ...state.authStatus,
        isLoading: false,
        isLoggedIn: true,
        statusCode: action?.payload?.statusCode,
        lastCheck: Date.now(),
      },
    };
  }
  case authActions.AUTH_USER_IS_CONFIRMED_NOT_LOGGED_IN: {
    return {
      ...initialState,
      user_name: initialState.user_name,
      authStatus: {
        isLoggedIn: false,
        lastCheck: Date.now(),
      },
    };
  }
  case authActions.AUTH_LOGIN_FAILURE: {
    return {
      ...state,
      authProfile: undefined,
      stage: 'username',
      sessionId: undefined,
      authStatus: {
        isLoggedIn: false,
        loginFailed: true,
        lastCheck: Date.now(),
        statusCode: action.payload?.statusCode,
      },
    };
  }
  case authActions.AUTH_CHECK_LOGIN_METHOD_FAILURE:
  case authActions.AUTH_CHECK_LOGIN_METHOD_REQUEST: {
    return {
      ...state,
      stage: 'username',
    };
  }
  case authActions.AUTH_LOGIN_ATTEMTPS_REQUEST: {
    return {
      ...state,
      loginAttempts: state.loginAttempts + 1,
    };
  }
  case authActions.AUTH_CHECK_LOGIN_METHOD_SUCCESS: {
    return {
      ...state,
      stage: action.payload.message,
    };
  }
  case authActions.AUTH_BART_USER_SUCCESS: {
    return {
      ...state,
      overtaken: action.payload.data,
    };
  }
  case authActions.AUTH_LOGIN_GOTO_ON: {
    return {
      ...state,
      secondLogin: false,
      secondData: undefined,
    };
  }

  case authActions.AUTH_LOGIN_SUCCESS_CHOICE: {
    return {
      ...state,
      authProfile: action.payload.authProfile,
      sessionId: action.payload.authProfile.session_id,
      secondLogin: true,
      secondData: {
        username: action.payload.username,
        password: action.payload.password,
      },
    };
  }
  case authActions.AUTH_LOGIN_NEW_PASSWORD_REQUEST: {
    return {
      ...state,
      newPwdStatus: -1,
      newPwd: null,
    };
  }
  case authActions.AUTH_LOGIN_NEW_PASSWORD_SUCCESS: {
    return {
      ...state,
      newPwdStatus: 2,
      newPwd: action.payload.message,
    };
  }
  case authActions.AUTH_LOGIN_NEW_PASSWORD_FAILURE: {
    return {
      ...state,
      newPwdStatus: 1,
      newPwd: action.payload.message,
    };
  }
  case authActions.AUTH_TRANSFER_USER: {
    return {
      ...state,
      transfered: {status: T.LoadStatuses.NOT_VERIFIED},
    };
  }
  case authActions.AUTH_TRANSFER_USER_SUCCESS: {
    return {
      ...state,
      transfered: {status: T.LoadStatuses.LOADED},
    };
  }
  case authActions.AUTH_CLOSE_RESET_PASSWORD_FORM: {
    return {
      ...state,
      newPwdStatus: -1,
    };
  }
  default: {
    return state;
  }
  }
};

export default auth;
