import {createSelector} from 'reselect';

export const selectAuth = ({auth} = {}) => auth;
export const selectAuthProfile = ({auth: {authProfile} = {}} = {}) => authProfile;
export const selectSecondData = ({auth: {secondData} = {}} = {}) => secondData;
export const selectAuthStartUrl = ({auth: {startUrl} = {}} = {}) => startUrl;
export const selectAuthStatus = ({auth: {authStatus} = {}} = {}) => authStatus;
export const selectSessionId = ({auth: {sessionId} = {}} = {}) => sessionId;

export const selectSecondLogin = ({auth: {secondLogin} = {}} = {}) => secondLogin;
export const selectProfileError = ({profile: {error} = {}} = {}) => error;

export const selectIsLoggedIn = ({auth: {authStatus: {isLoggedIn} = {}} = {}} = {}) => isLoggedIn;
export const selectAlert = ({alert}) => alert;

export const selectIsUnauthorized = createSelector(
  selectProfileError,
  selectSessionId,
  selectIsLoggedIn,
  selectSecondLogin,
  (profileError, sessionId, isLoggedIn, secondLogin) =>
    !!profileError || !sessionId || isLoggedIn === false
    || !!secondLogin || sessionId === 'sd',
);
