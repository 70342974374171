import {createReducerAndActions} from '@snapper/core';
import * as T from '@types/load.types';
import {emptyArr, emptyObj} from '@utils/constants';
import {filterNullishProps, isObjectWithKeys, mapDataToArray} from '@utils/misc.utils';
import {normalizeCompetence, normalizePersonCompetence} from '@utils/normalize.utils';
import {validIntOrNull} from '@utils/number.utils';
import {createDefaultFetchActionsAndReducers} from '@utils/redux-actions.utils';
import {findCompetenceId} from '../util/competence-identity';
import {upsertChildrenCompetences, upsertCompetences, upsertPersonCompetences} from '../util/competences.util';

const initialState = {
  // role data that is not related to a person (e.g. title, description, competence_ids, etc.)
  roles: {
    data: emptyObj, // {[role_id]: {data: {}, status: T.LoadStatuses.LOADED, last_update: 0}}
    status: T.LoadStatuses.NOT_LOADED,
    last_update: 0,
    org_role_ids: emptyObj, // {[org_id]: {data: role_ids[], status: T.LoadStatuses.LOADED, last_update: 0}}
  },

  // role data that is related to a person (e.g. passed, checklist, etc.)
  personRoles: emptyObj, // [person_id]: {data: role_ids[], status: T.LoadStatuses.LOADED, last_update: 0}

  roleCompetences: emptyObj, // [role_id]: {data: competence_ids[], status: T.LoadStatuses.LOADED, last_update: 0}

  // coursegroup data
  coursegroups: {
    data: emptyArr,
    status: T.LoadStatuses.NOT_LOADED,
    last_update: 0,
    orgId: null,
  },

  // competence data that is not related to a person (e.g. title, description, etc.)
  competences: emptyObj,

  // competence data that is related to a person (e.g. passed, checklist, etc.)
  personCompetences: emptyObj,

  // additional data for competences of type 'track' (e.g. layout etc.)
  tracks: emptyObj,

  // additional data for tracks with layout === 'map' (e.g. svgpath, styles, etc.)
  mapData: emptyObj,
};

const actions = {
  apiFetchRoles: createDefaultFetchActionsAndReducers(initialState, 'roles'),

  apiFetchCoursegroups: {
    request: (state, action) => {
      const {orgId} = action?.payload ?? {};

      if (!orgId) return;

      state.coursegroups = {
        ...state.coursegroups,
        status: T.LoadStatuses.IS_LOADING,
        orgId,
        data: orgId === state.coursegroups.orgId
          ? state.coursegroups.data
          : [],
      };
    },

    success: (state, action) => {
      const {orgId, data} = action?.payload ?? {};

      if (!orgId) return;

      state.coursegroups = {
        ...state.coursegroups,
        orgId,
        status: T.LoadStatuses.LOADED,
        last_update: Date.now(),
        data: data ?? state.coursegroups.data,
      };
    },
    failure: (state, action) => {
      const {orgId} = action?.payload ?? {};

      if (!orgId) return;

      state.coursegroups = {
        ...state.coursegroups,
        status: T.LoadStatuses.FAILED,
        orgId,
      };
    },
  },

  apiFetchPersonRoles: {
    request: (state, action) => {
      const {personId} = action?.payload ?? {};

      if (!personId) return;

      state.personRoles[personId] = {
        ...state.personRoles[personId],
        status: T.LoadStatuses.IS_LOADING,
      };
    },

    success: (state, action) => {
      const {personId, data} = action?.payload ?? {};

      if (!personId) return;

      state.personRoles[personId] = {
        ...state.personRoles[personId],
        role_ids: state.personRoles[personId]?.role_ids ?? emptyArr,
        status: T.LoadStatuses.LOADED,
        last_update: Date.now(),
      };

      const dataArr = mapDataToArray(data);

      if (!dataArr?.length) return;

      const roleIds = dataArr
        .map(r => validIntOrNull(r) || validIntOrNull(r?.role_id || r?.id))
        .filter(Boolean);

      state.personRoles[personId].role_ids = roleIds.length
        ? roleIds
        : emptyArr;
    },

    failure: (state, action) => {
      const {personId} = action?.payload ?? {};

      if (!personId) return;

      state.personRoles[personId] = {
        ...state.personRoles[personId],
        status: T.LoadStatuses.FAILED,
      };
    },
  },

  apiFetchRoleCompetences: {
    request: (state, action) => {
      const {roleId, personId} = action?.payload ?? {};

      if (roleId == null) return;

      state.roleCompetences[roleId] = {
        ...state.roleCompetences[roleId],
        personId,
        status: T.LoadStatuses.IS_LOADING,
      };
    },

    success: (state, action) => {
      const {roleId, personId, data} = action?.payload ?? {};

      if (roleId == null) return;

      state.roleCompetences[roleId] = {
        ...state.roleCompetences[roleId],
        personId,
        competence_ids: state.roleCompetences[roleId]?.competence_ids ?? emptyArr,
        data: state.roleCompetences[roleId]?.data ?? emptyObj,
        status: T.LoadStatuses.LOADED,
        last_update: Date.now(),
      };

      const dataArr = mapDataToArray(data);

      if (!dataArr?.length) return;

      const competenceIds = dataArr.map(findCompetenceId)
        .filter(Boolean);

      state.roleCompetences[roleId].competence_ids = competenceIds.length
        ? competenceIds
        : emptyArr;

      state.roleCompetences[roleId].data = dataArr;
      state.roleCompetences[roleId].last_update = Date.now();
    },
    failure: (state, action) => {
      const {roleId} = action?.payload ?? {};

      if (roleId == null) return;

      state.roleCompetences[roleId] = {
        ...state.roleCompetences[roleId],
        status: T.LoadStatuses.FAILED,
      };
    },
  },

  apiFetchRole: {
    request: (state, action) => {
      const {rid, pid, data} = action?.payload ?? {};

      console.log('apiFetchRole.success', {
        rid,
        pid,
        data,
      });
    },
    success: (state, action) => {
      const {rid, pid, data} = action?.payload ?? {};

      console.log('apiFetchRole.success', {
        rid,
        pid,
        data,
      });
    },
    failure: (state, action) => {
      const {rid, pid, data} = action?.payload ?? {};

      console.log('apiFetchRole.success', {
        rid,
        pid,
        data,
      });
    },
  },

  apiFetchTrack: {
    request: (state, action) => {
      const {trackId} = action?.payload ?? {};

      if (!trackId) throw new Error('trackId is required');

      state.tracks[trackId] = {
        ...state.tracks[trackId],
        status: T.LoadStatuses.IS_LOADING,
      };
    },
    success: (state, action) => {
      const {pid, data} = action?.payload ?? {};

      const dataArr = mapDataToArray(data);

      if (!dataArr?.length) return;

      for (const track of dataArr) {
        const trackId = findCompetenceId(track);

        if (!trackId) continue;

        if (!state.tracks.hasOwnProperty(trackId)) state.tracks[trackId] = {};

        state.tracks[trackId].status = T.LoadStatuses.LOADED;
        state.tracks[trackId].last_update = Date.now();

        const {
          track_image,
          layout,
          passed,
        } = track || {};

        const current = state.tracks[trackId]?.data ?? {};
        const competence = normalizeCompetence(track);

        if (!isObjectWithKeys(competence) && !isObjectWithKeys(current)) continue;

        state.tracks[trackId] = {
          ...state.tracks[trackId],
          data: {
            ...state.tracks[trackId]?.data,
            ...filterNullishProps(track),
            ...competence,
            competence_id: trackId,
            layout: layout ?? current.layout,
            // children_ids: children_ids ?? current.children_ids ?? state.competences[trackId]?.data?.children_ids,
            track_image: track_image ?? current.track_image,
          },
        };

        // update competence, since track is a competence
        if (!state.competences.hasOwnProperty(trackId)) state.competences[trackId] = {};

        if (!competence) continue;

        state.competences[trackId] = {
          ...state.competences[trackId],
          // status: newStatus,
          data: {
            ...state.competences[trackId]?.data,
            ...competence,
            competence_id: trackId,
            competence_type: 'track',
          },
        };

        if (passed == null || !pid) continue;

        const personCompetence = normalizePersonCompetence(track);

        if (!personCompetence) continue;

        // update person competence, since track can return "passed" value
        if (!state.personCompetences.hasOwnProperty(pid)) {
          state.personCompetences[pid] = {};
        }

        state.personCompetences[pid][trackId] = {
          ...state.personCompetences[pid][trackId],
          data: {
            ...state.personCompetences[pid][trackId]?.data,
            ...normalizePersonCompetence(track),
            person_id: pid,
            competence_id: trackId,
          },
        };
      }
    },
    failure: (state, action) => {
      const {trackId} = action?.payload ?? {};

      if (!trackId) return;

      state.tracks[trackId] = {
        ...state.tracks[trackId],
        status: T.LoadStatuses.FAILED,
      };
    },
  },
  apiFetchCompetence: {
    request: (state, action) => {
      const {cid} = action?.payload ?? {};

      if (!cid) return;

      state.competences[cid] = {
        ...state.competences[cid],
        status: T.LoadStatuses.IS_LOADING,
      };
    },
    success: upsertCompetences,
    failure: (state, action) => {
      const {cid} = action?.payload ?? {};

      if (!cid) return;

      state.competences[cid] = {
        ...state.competences[cid],
        status: T.LoadStatuses.FAILED,
      };
    },
  },
  apiFetchPersonCompetence: {
    request: (state, action) => {
      const {cid, pid} = action?.payload ?? {};

      if (!cid || !pid) return;

      if (!state.personCompetences.hasOwnProperty(pid)) {
        state.personCompetences[pid] = {};
      }

      state.personCompetences[pid][cid] = {
        ...state.personCompetences[pid][cid],
        status: T.LoadStatuses.IS_LOADING,
      };
    },
    success: upsertPersonCompetences,
    failure: (state, action) => {
      const {cid, pid} = action?.payload ?? {};

      if (!cid || !pid) return;

      if (!state.personCompetences.hasOwnProperty(pid)) {
        state.personCompetences[pid] = {};
      }

      state.personCompetences[pid][cid] = {
        ...state.personCompetences[pid][cid],
        status: T.LoadStatuses.FAILED,
      };
    },
  },

  apiFetchChildrenCompetences: {
    request: (state, action) => {
      const {cid: parentCid} = action?.payload ?? {};

      if (!parentCid) return;

      state.competences[parentCid] = {
        ...state.competences[parentCid],
        children_status: T.LoadStatuses.IS_LOADING,
      };
    },
    success: upsertChildrenCompetences,
    failure: (state, action) => {
      const {cid} = action?.payload ?? {};

      if (!cid) return;

      state.competences[cid] = {
        ...state.competences[cid],
        children_status: T.LoadStatuses.FAILED,
      };
    },
  },
  apiInsertOrUpdate: {
    oneCompetence: (state, action) => {},
    oneCompetenceChildren: (state, action) => {},
    onePersonCompetence: (state, action) => {},
    oneTrack: (state, action) => {},
    oneMap: (state, action) => {},

    manyCompetences: (state, action) => {},
    manyCompetenceChildren: (state, action) => {},
    manyPersonCompetences: (state, action) => {},
    manyTracks: (state, action) => {},
    manyMaps: (state, action) => {},
  },
};

export const {
  actions: apiActions,
  reducer: apiReducer,
} = createReducerAndActions({
  prefix: 'api',
  initialState,
  actions,
});

export const {
  apiFetchRoles, // request, success, failure
  apiFetchPersonRoles, // request, success, failure
  apiFetchRoleCompetences, // request, success, failure
  apiFetchCoursegroups, // request, success, failure
  apiFetchTrack, // request, success, failure
  apiFetchCompetence, // request, success, failure
  apiFetchPersonCompetence, // request, success, failure
  apiFetchChildrenCompetences, // request, success, failure

  apiInsertOrUpdate, // not implemented yet
} = apiActions;

export default apiReducer;
