import {getRoleSectionType} from '@selectors/employees.selected-person.selectors';
import {isObjectWithKeys} from '@utils/misc.utils';
import {getRoleId} from '@utils/roles.utils';
import {initialState} from '../initial-state/employees.initial-state';
import {upsertChildrenCompetences, upsertCompetences, upsertPersonCompetences} from '../util/competences.util';

const IS_DEV = process.env.NODE_ENV === 'development';

const actionDefinitions = {
  setInitial: (state, action) => {
    const {personId, username, orgId} = action?.payload || {};

    if (
      state.selectedPersonV2.username === username
      && state.selectedPersonV2.personId === personId
    ) {
      return;
    }

    if (
      username && state.selectedPersonV2.username && username !== state.selectedPersonV2.username
      || personId && state.selectedPersonV2.personId && personId !== state.selectedPersonV2.personId
    ) {
      Object.assign(state.selectedPersonV2, initialState.selectedPersonV2);
    }

    state.selectedPersonV2.username = username;
    state.selectedPersonV2.personId = personId;
    state.selectedPersonV2.orgId = orgId;

    state.selectedPersonV2.initialized = state.selectedPersonV2.username != null || state.selectedPersonV2.personId != null;
  },

  setAPIData: (state, action) => {
    if (!isObjectWithKeys(action?.payload)) return;

    const notUndefined = Object.keys(action.payload).reduce((acc, key) => {
      if (action.payload[key] === undefined) return acc;

      if (IS_DEV && !state.selectedPersonV2.apiData.hasOwnProperty(key)) {
        throw new Error('selectedPerson setAPIData:', key, 'does not exist in state');
      }

      acc[key] = action.payload[key];

      return acc;
    }, {});

    Object.assign(state.selectedPersonV2.apiData, notUndefined);
  },

  fetchRoleCompetences: {
    request: null,
    success: (state, action) => {
      const {
        roleId,
        data: competences,
        competence_ids,
        batch,
      } = action?.payload || {};

      const sectionType = action?.payload?.section || action?.payload?.type; // 'other' | 'requirement' | 'optional'

      const roles = Array.isArray(batch)
        ? batch
        : [{
          role_id: roleId,
          competences,
          competence_ids,
          section: sectionType,
        }];

      for (const role of roles) {
        let role_id = getRoleId(role);

        if (role_id == null) continue;

        if (role_id === -1) {
          role_id = 0;
        }

        if (!state.selectedPersonV2.roleCompetenceIds[role_id]) {
          state.selectedPersonV2.roleCompetenceIds[role_id] = {
            requirement: [],
            optional: [],
            other: [],
          };
        }

        const {
          data: role_data,
          competences: role_competences,
          competence_ids: role_competence_ids,
          section: role_section_type,
        } = role;

        if (isObjectWithKeys(role_competence_ids)) {
          const requirement = role_competence_ids.requirement || role_competence_ids.mandatory;

          if (requirement) {
            state.selectedPersonV2.roleCompetenceIds[role_id].requirement = requirement;
          }
          if (role_competence_ids.optional) {
            state.selectedPersonV2.roleCompetenceIds[role_id].optional = role_competence_ids.optional;
          }
          if (role_competence_ids.other != null) {
            state.selectedPersonV2.roleCompetenceIds[role_id].other = role_competence_ids.other;
          }

          continue;
        }

        const role_competences_data = role_competences || role_data;

        if (!role_competences_data?.length) {
          continue;
        }

        const sectionType = getRoleSectionType(role_section_type, 'requirement');

        if (!role_section_type || !state.selectedPersonV2.roleCompetenceIds[role_id][sectionType]) {
          if (IS_DEV) console.error('fetchRoleCompetences: missing or wrong type, got', {
            role_section_type,
            role,
          });

          continue;
        }

        state.selectedPersonV2.roleCompetenceIds[role_id][sectionType] = role_competences_data
          ?.map?.(c => c?.competence_id || c?.id)?.filter(Boolean);
      }
    },

    failure: null,
  },

  fetchCompetence: {
    request: null,
    success: (state, action) => upsertCompetences(state.selectedPersonV2, action),
    failure: null,
  },

  fetchChildrenCompetences: {
    request: null,
    success: (state, action) => upsertChildrenCompetences(state.selectedPersonV2, action),
    failure: null,
  },

  fetchPersonCompetence: {
    request: null,
    success: (state, action) => upsertPersonCompetences(state.selectedPersonV2, action),
    failure: null,
  },

  removePersonCompetence: {
    success: (state, action) => {
      const {phce_id, competence_id} = action?.payload || {};

      if (!competence_id) return;

      const pid = state.selectedPersonV2?.personId;

      if (!pid || !state.selectedPersonV2?.personCompetences?.[pid]?.[competence_id]) return;

      // todo: check if we need to use phce_id instead
      delete state.selectedPersonV2.personCompetences[pid][competence_id];
    },
  },

  // removeCompetence: {
  //   success: (state, action) => {
  //     const {
  //       section, // other/personal only?
  //       role_id,
  //       competence_id,
  //     } = action?.payload || {};

  //     const roleCompetences = state.selectedPersonV2.roleCompetenceIds[role_id];

  //     if (competence_id == null || role_id == null || !isObjectWithKeys(roleCompetences)) return;

  //     const sections = section
  //       ? [section]
  //       : Object.keys(roleCompetences);

  //     for (const s of sections) {
  //       if (!roleCompetences[s]?.length) {
  //         roleCompetences[s] = roleCompetences[s].filter(cid => cid !== competence_id);
  //       }
  //     }
  //   },
  // },
};

export default actionDefinitions;
