import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router';
import {selectReduxModuleLoadStatuses} from '@actions/app.actions';
import {LoadingLine} from '@components/loading/loading-line';
import {getIsSuperuser, selectIsManager, selectProfileId} from '@selectors/profile.selectors';
import {LoadStatuses} from '@types/load.types';
import {ReduxModuleIds} from '../constants';
import {ReduxModuleInjector} from './module-injector';

const WithAdminModule = ({children}) => {
  const profileId = useSelector(selectProfileId);
  const isManager = useSelector(selectIsManager);
  const isSuperUser = useSelector(getIsSuperuser);

  const {admin, employees} = useSelector(selectReduxModuleLoadStatuses);

  if (admin === LoadStatuses.LOADED && employees === LoadStatuses.LOADED) return children || null;

  const isLoading = !profileId
    || admin === LoadStatuses.IS_LOADING
    || employees === LoadStatuses.IS_LOADING;

  if (isLoading) return <LoadingLine />;
  if (profileId && !(isManager || isSuperUser)) return <Redirect to="/my-education" />;

  return (
    <ReduxModuleInjector moduleId={ReduxModuleIds.admin}>
      <ReduxModuleInjector moduleId={ReduxModuleIds.employees}>
        {children}
      </ReduxModuleInjector>
    </ReduxModuleInjector>
  );
};

export default WithAdminModule;
