import axios from 'axios';
import {freeze} from 'immer';
import {
  all,
  call,
  put,
  select,
  spawn,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {apiFetchChildrenCompetences} from '@actions/api.actions';
import {competencesToggleSuccess} from '@actions/competences.actions';
import {componentsSetMap, componentsSetMapAnimationTargetIndex} from '@actions/components.actions';
import {
  coursesCourseFinished,
  coursesFetchEventsForCompetence,
  coursesSignCourse,
  coursesStartCourse,
} from '@actions/courses.actions';
import {employeesSaveVerification} from '@actions/employees.actions';
import * as mapCoursesActions from '@actions/map.actions';
import {
  profileCreateSelfSign,
  profileFetchPersonCompetences,
  profileFetchPersonSummary,
  profileSelfSign,
  profileUpdatePassedCompetences,
} from '@actions/profile.actions';
import {fetchRole} from '@actions/roles.actions';
import {backendUrl, backendUrlV2} from '@config';
import {selectMapComponent} from '@selectors/components.selectors';
import {
  getCurrTrack,
  getPropertiesForCurrLangAndTrackBadge,
} from '@selectors/config.selectors';
import {
  createSingleCompetenceSelector,
  selectAllMergedCompetencesData,
} from '@selectors/joined-data.selectors';
import {
  completeExtraDataStatusByTrackIdSelector,
  getCourseTitleParams,
  getIsAllMapDotsCompleted,
  selectMapDottsWithoutVerificationAndOutro,
} from '@selectors/map.selectors.new';
import {
  getSelectedRoleId,
  selectProfileCompetences,
  selectProfileId,
  selectProfileSelfSign,
} from '@selectors/profile.selectors';
import {LoadStatuses} from '@types/load.types';
import {isNotLoaded} from '@utils/loadstatus.utils';
import {isObjectWithKeys} from '@utils/misc.utils';
import {validIntOrNull} from '@utils/number.utils';
import {retry} from '@utils/sagas.utils';
import {fetchCompetenceAPI} from '../api/competences';
import {
  fetchParentAndChildrenPersonCompetencesAPI,
  fetchPersonCompetenceAPI,
} from '../api/person-competences';
import {
  canHaveChildrenCompetences,
  findCompetenceId,
  isChecklistItemSelfSignOrSignatureCompetence,
  isGroupCompetence,
} from '../util/competence-identity';
import {waitForProfileId, waitForUsername} from './app.sagas';
import {getTrackDataSaga} from './courses.sagas';
import {getMapOrTrackIdFromCompetenceId} from './profile.sagas';

export const selectIndexOfFirstOpenMapDott = createSelector(
  selectMapDottsWithoutVerificationAndOutro,
  dotts => dotts?.findIndex?.(d => d?.status === 'OPEN') ?? -1,
);

const watchUnlockedNextDott = takeLatest(
  mapCoursesActions.MAP_UNLOCK_NEXT_DOTT,
  function* onUnlocked() {
    const idxOfFirstOpen = yield select(selectIndexOfFirstOpenMapDott);

    if (idxOfFirstOpen === -1) return;

    yield put(componentsSetMapAnimationTargetIndex(idxOfFirstOpen));
  },
);

const watchLearningPathAction = takeEvery(
  [
    `${profileCreateSelfSign.success}`,
    `${competencesToggleSuccess().type}`,
    `${coursesSignCourse.success}`,
    `${employeesSaveVerification.success}`,
    `${coursesCourseFinished().type}`,
  ],
  function* onAction(action) {
    try {
      const {payload = {}, type} = action || {};
      const competenceId = findCompetenceId(payload);

      if (!competenceId) return;

      const pathname = window.location.pathname;
      const isLocationRole = pathname.includes('/role/');

      if (isLocationRole) {
        yield put(profileFetchPersonSummary({refresh: true}));
        const selectedRoleId = yield select(getSelectedRoleId);

        yield put(fetchRole({
          roleId: selectedRoleId,
          refresh: true,
        }));
      }

      return;

      const trackId = payload?.trackId || payload?.mapId || (yield call(getMapOrTrackIdFromCompetenceId, {competenceId}));

      if (!trackId) return;

      yield put(mapCoursesActions.fetchTrackExtraData({
        trackId,
        cid: competenceId,
      }));
    } catch (error) {
      console.error(error);
    }
  },
);

export function* updateMapComponentState(action) {
  try {
    const {
      mapId,
      dotts = [],
      verification: verificationPayload,
      outro: outroPayload,
      firstOpenIndex,
      completedCount,
      skipAnimation,
    } = action?.payload || {};

    if (mapId == null) return;

    const outro = outroPayload || dotts.find(t => getCourseTitleParams(t?.title)?.includes?.('outro'));
    const verification = verificationPayload || dotts.find(t => getCourseTitleParams(t?.title)?.includes?.('verification'));

    const dottsWithoutVerificationAndOutro = dotts.filter(dot => dot.id !== verification?.id && dot.id !== outro?.id);
    const completedLength = completedCount == null
      ? dottsWithoutVerificationAndOutro.filter(dot => dot?.status === 'DONE').length
      : completedCount;

    const currentComponentState = yield select(selectMapComponent);

    const isInitialLoad = Number(currentComponentState.id) !== Number(mapId);

    const shouldSkipAnimation = skipAnimation == null
      ? isInitialLoad
      : skipAnimation;

    const animationIndex = shouldSkipAnimation
      ? completedLength - 1
      : firstOpenIndex == null
        ? currentComponentState.animationIndex
        : firstOpenIndex - 1;

    yield put(componentsSetMap({
      id: Number(mapId),
      animationIndex,
      animationTargetIndex: Math.min(animationIndex + 1, dottsWithoutVerificationAndOutro.length - 1),
      skipAnimation: shouldSkipAnimation,
    }));
  } catch (error) {
    console.error(error);
  }
}

function* watchFetchMapSuccess({payload}) {
  const {
    data = {},
    firstOpenIndex,
    completedCount,
    skipAnimation,
    skipComponentUpdate,
  } = payload || {};

  const {
    id: mapId,
    dotts = [],
    verification,
    outro,
  } = data?.tracks?.[0] || {};

  if (!mapId || !dotts?.length || skipComponentUpdate) return;

  try {
    yield call(updateMapComponentState, {
      payload: {
        mapId,
        dotts,
        verification,
        outro,
        firstOpenIndex,
        completedCount,
        skipAnimation,
      },
    });
  } catch (error) {
    console.error(error);
  }
}

export function* fetchMapCourses(action) {
  const {
    id: idPayload,
    refetch,
    selectFirstDot: shouldSelectFirstDot = false,
    skipAnimation,
    firstOpenIndex,
    skipComponentUpdate,
  } = action.payload;

  const mapId = idPayload || (yield select(getCurrTrack));

  if (!validIntOrNull(mapId)) return;

  const trackId = Number(mapId);

  yield put(mapCoursesActions.fetchMapCoursesRequest({
    refetch,
    id: trackId,
  }));

  const competences = yield select(selectAllMergedCompetencesData);

  try {
    const mapCourses = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrlV2}/tracks/${trackId}/view`,
      params: {id: trackId},
      withCredentials: true,
    })
      .then(res => res.data));

    const dottsData = mapCourses?.tracks?.[0]?.dotts || [];

    if (dottsData?.length) {
      const profileId = yield select(selectProfileId);

      yield put(apiFetchChildrenCompetences.success({
        cid: trackId,
        pid: profileId,
        data: freeze(dottsData),
      }));
    }

    const indexOfFirstOpenDot = firstOpenIndex ?? dottsData
      .findIndex(dott => {
        // check passed competences since there is a delay from backend to update the status
        if (competences?.[findCompetenceId(dott)]?.passed === 100) return false;

        return ['LOCKED', 'OPEN'].includes(dott?.status);
      });

    if (indexOfFirstOpenDot != null && indexOfFirstOpenDot > -1) {
      yield put(mapCoursesActions.fetchTrackExtraData({
        trackId,
        cid: findCompetenceId(dottsData[indexOfFirstOpenDot]),
      }));
    }

    /* FIND IF WE HAVE VERIFICATION OR NOT. */
    const verification = dottsData.find(t => getCourseTitleParams(t.title).includes('verification'));
    const outro = dottsData.find(t => getCourseTitleParams(t.title).includes('outro'));

    if (verification?.status === 'DONE') yield put(mapCoursesActions.setMapIsVerified({}));
    if (outro?.status === 'DONE') yield put(mapCoursesActions.setOutroIsCompleted({}));

    // lock all dots after the first open one
    const dotts = dottsData
      .map((dot, index) => {
        if (indexOfFirstOpenDot === -1) return {
          ...dot,
          status: 'DONE',
          real_status: 'DONE',
          index,
        };

        if (index < indexOfFirstOpenDot) return {
          ...dot,
          status: 'DONE',
          real_status: 'DONE',
          index,
        };

        if (index > indexOfFirstOpenDot) return {
          ...dot,
          status: 'LOCKED',
          real_status: dot.status,
          index,
        };

        return {
          ...dot,
          status: 'OPEN',
          real_status: 'OPEN',
          index,
        };
      });

    if (mapCourses?.tracks?.[0]?.dotts?.length) mapCourses.tracks[0].dotts = dotts;

    yield put(mapCoursesActions.fetchMapCoursesSucceeded({
      mapId: trackId,
      data: mapCourses,
      skipAnimation: skipAnimation == null ? undefined : skipAnimation,
      firstOpenIndex: indexOfFirstOpenDot,
      skipComponentUpdate,
    }));

    if (indexOfFirstOpenDot != null && indexOfFirstOpenDot > -1 && shouldSelectFirstDot) {
      const dot = dotts?.[indexOfFirstOpenDot];

      if (!dot?.id) return;

      yield put(mapCoursesActions.selectMapCourse(dot));

      if (dot.autostart) {
        console.log('autostart stuff.');
        yield put(coursesStartCourse({
          cid: dot.id,
          type: 'nano',
          mapId: trackId,
        }));
      }
    } else {
      const isAllDone = indexOfFirstOpenDot === -1 || (yield select(getIsAllMapDotsCompleted));

      if (isAllDone) {
        const badge = yield select(getPropertiesForCurrLangAndTrackBadge);

        if (badge) yield put(mapCoursesActions.selectMapCourse({id: 'badge'}));
      }
    }
  } catch (error) {
    yield put(mapCoursesActions.fetchMapCoursesFailed({
      id: trackId,
      error,
    }));
    console.error(error);
  }
}

// fetches additional data for children competences of active learning-path/map
export function* fetchTrackExtraDataSaga(action) {
  const {payload, type} = action || {};

  const {
    trackId,
    mapId,
    cid,
    parentCid,
    childrenIds: childrenIdsPayload,
    personId: personIdPayload,
    userName: userNamePayload,
  } = payload || {};

  const profileId = yield call(waitForProfileId);
  const profileUserName = yield call(waitForUsername);

  const userName = userNamePayload || profileUserName;

  const isEmployeeCompetence = !!personIdPayload && personIdPayload !== profileId
    || !!userNamePayload && userNamePayload !== profileUserName;

  const apiAction = competence_id => ({
    payload: {
      trackId,
      cid: competence_id,
      username: userName,
      personId: personIdPayload || profileId,
    },
  });

  if (cid && !isEmployeeCompetence) {
    try {
      const personCompetence = (yield call(fetchPersonCompetenceAPI, apiAction(cid)))?.[0];

      if (personCompetence?.passed === 100) {
        yield put(profileUpdatePassedCompetences({
          data: personCompetence,
          cid,
          mapId,
          isPassed: true,
        }));
      }
    } catch (error) {
      console.error(error);

      return {error};
    }
  }

  const competenceById = yield select(selectAllMergedCompetencesData);

  const parentCompetences = parentCid
    ? [competenceById?.[parentCid]]
    : cid != null && isObjectWithKeys(competenceById) && Object.values(competenceById)
      .filter(item => item?.children_ids?.some?.(id => id === cid)) || [];

  if (parentCompetences?.length) {
    const calls = [];

    // if parent, fetch children for parent and children
    for (const parent of parentCompetences) {
      const parentId = findCompetenceId(parent);

      if (parentId != null) {
        calls.push(call(function* fetchParentCompetence() {
          try {
            yield spawn(fetchParentAndChildrenPersonCompetencesAPI, apiAction(parentId));
          } catch (error) {
            console.error(error);
          }
        }));
      }
    }

    yield all(calls);
  }

  if (cid != null) return {error: null};

  // we are fetching data for all children competences of active learning-path/map
  try {
    if (!trackId) throw new Error('missing trackId');

    const status = yield select(completeExtraDataStatusByTrackIdSelector(trackId));

    if (status === LoadStatuses.IS_LOADING) return {error: null};

    const cidsPayload = [...new Set(childrenIdsPayload || [])].filter(Boolean);

    let cids = cidsPayload;

    yield put(mapCoursesActions.fetchTrackExtraDataRequest({
      trackId,
      all: !cids?.length,
      cids,
    }));

    const trackChildren = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrlV2}/tracks/${trackId}/children`,
      params: {
        fields: 'id,track_image,title,children_ids,layout,short_description,description',
        limit: 100,
        users_organisations_only: 1,
      },
      withCredentials: true,
    }).then(({data: competences}) => competences));

    if (trackChildren?.length) {
      yield put(apiFetchChildrenCompetences.success({
        cid: trackId,
        pid: personIdPayload || profileId,
        data: freeze(trackChildren),
      }));
    }

    const allChildrenIds = new Set(trackChildren
      .flatMap(item => {
        const competence_id = findCompetenceId(item);

        if (!childrenIdsPayload?.length) return [competence_id, ...item?.children_ids || []];

        if (childrenIdsPayload.includes(competence_id) || item?.children_ids?.some?.(id => childrenIdsPayload.includes(id))) {
          return [competence_id, ...item?.children_ids || []];
        }

        return [];
      }));

    if (!allChildrenIds?.size) {
      return {error: null};
    }

    if (!cids) {
      cids = [...allChildrenIds];

      yield put(mapCoursesActions.fetchTrackExtraDataRequest({
        trackId,
        all: !cidsPayload?.length,
        cids,
      }));
    }

    const filteredChildren = trackChildren
      .filter(item => allChildrenIds.has(findCompetenceId(item)) || item.children_ids?.some?.(id => allChildrenIds.has(id)));

    if (filteredChildren?.length) {
      const selfSignIds = filteredChildren
        .filter(isChecklistItemSelfSignOrSignatureCompetence).map(findCompetenceId)
        .filter(Boolean);
      const complexCompetenceIds = filteredChildren
        .filter(canHaveChildrenCompetences).map(findCompetenceId)
        .filter(Boolean);
      const groupCompetenceIds = filteredChildren
        .filter(isGroupCompetence).map(findCompetenceId)
        .filter(Boolean);

      const competencesData = yield select(selectAllMergedCompetencesData);

      const fetchCompetenceIds = selfSignIds.filter(id => competencesData?.[id]?.checked_by == null
          || competencesData?.[id]?.description == null);

      if (fetchCompetenceIds?.length) yield spawn(fetchCompetenceAPI, {payload: {batch: fetchCompetenceIds}});

      const fetchPersonCompetenceIds = selfSignIds.filter(id => competencesData?.[id]?.passed !== 100);

      if (fetchPersonCompetenceIds?.length) yield spawn(fetchPersonCompetenceAPI, {payload: {batch: fetchPersonCompetenceIds}});
      if (complexCompetenceIds?.length) yield spawn(fetchParentAndChildrenPersonCompetencesAPI, {payload: {batch: complexCompetenceIds}});

      for (const groupCid of groupCompetenceIds) {
        yield put(coursesFetchEventsForCompetence({courseId: groupCid}));
      }
    }

    return {error: null};
  } catch (error) {
    console.error('fetchTrackExtraData error', error);

    yield put(mapCoursesActions.fetchTrackExtraDataFailure({
      trackId,
      error,
    }));

    return {
      error,
      children: [],
      selfSignData: {},
      competences: [],
    };
  }
}

export function* fetchTrack(action) {
  const {id, parentId, fetchJsonData = false} = action.payload || {};

  const idNumber = !!id && Number(id);
  const parentIdNumber = !!parentId && Number(parentId);

  if (!idNumber && !parentIdNumber) return;

  const {status: selfSignStatus} = yield select(selectProfileSelfSign);
  const {status: profileCompetencesStatus} = yield select(selectProfileCompetences);

  const shouldFetchSelfSign = isNotLoaded(selfSignStatus);
  const shouldFetchProfileCompetences = isNotLoaded(profileCompetencesStatus);

  if (shouldFetchSelfSign) yield put(profileSelfSign());
  if (shouldFetchProfileCompetences) yield put(profileFetchPersonCompetences());

  try {
    const shouldFetchTrack = !!idNumber;
    const shouldFetchParentTrack = !!parentIdNumber && !(yield select(createSingleCompetenceSelector(parentIdNumber))?.competence_id);

    if (!shouldFetchTrack && !shouldFetchParentTrack) return;

    yield put(mapCoursesActions.fetchTrackRequest());

    if (shouldFetchTrack) yield spawn(getTrackDataSaga, {
      cid: idNumber,
      extraData: true,
    });
    if (shouldFetchParentTrack) yield spawn(getTrackDataSaga, {
      cid: parentIdNumber,
      extraData: true,
    });
    // if (fetchJsonData) yield spawn(fetchCompetenceAPI, {payload: {cid: idNumber}});
  } catch (error) {
    console.log('errore');
    console.error(error);
    yield put(mapCoursesActions.fetchTrackFailure({
      error,
      errorReason: error?.response?.status === 404 ? 'COMPETENCE_NOT_FOUND' : 'UNKNOWN',
    }));
  }
}

export function* fetchMapCompetence(action) {
  const {id} = action.payload || {};

  console.log('start up....', id);
  if (!id) return;

  yield put(mapCoursesActions.fetchMapCompetenceRequest({id}));
  try {
    const competence = yield retry(() => axios
      .request({
        method: 'GET',
        url: `${backendUrlV2}/competences/${id}`,
        params: {fields: 'title,files,children'},
        withCredentials: true,
      })
      .then(res => res.data));

    console.log('is done', competence);
    yield put(mapCoursesActions.fetchMapCompetenceSucceeded({data: competence}));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchMapVerificationCourse(action) {
  yield put(mapCoursesActions.fetchMapVerificationCompetenceRequest());
  const {cid} = action.payload || {};

  if (!cid) return;

  try {
    const course = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/competences/${cid}?fields=short_description,files`,
          withCredentials: true,
        })
        .then(res => res.data));

    yield put(mapCoursesActions.fetchMapVerificationCompetenceSucceeded({data: course.competences[0]}));
  } catch (error) {
    console.error(error);
  }
}

const exportObj = [
  watchLearningPathAction,
  takeEvery(mapCoursesActions.fetchTrackExtraData().type, fetchTrackExtraDataSaga),
  takeLatest(mapCoursesActions.fetchMapCourses().type, fetchMapCourses),
  takeEvery(mapCoursesActions.fetchTrack().type, fetchTrack),
  takeLatest(mapCoursesActions.fetchMapCompetence().type, fetchMapCompetence),
  takeLatest(
    mapCoursesActions.fetchMapVerificationCompetence().type,
    fetchMapVerificationCourse,
  ),
  takeLatest(mapCoursesActions.fetchMapCoursesSucceeded().type, watchFetchMapSuccess),
  watchUnlockedNextDott,
];

export default exportObj;
