import {all, cancel, fork, take} from 'redux-saga/effects';
import {RESET_APP, RESET_STORE} from '@actions/global.actions';
import authSagas from '@sagas/auth.sagas';
import cmsSagas from '@sagas/cms.sagas';
import competencesSagas from '@sagas/competences.sagas';
import competencesAPISagas from '@sagas/competences-api.sagas';
import componentsSagas from '@sagas/components.sagas';
import configSagas from '@sagas/config.sagas';
import coursesSagas from '@sagas/courses.sagas';
import helpSagas from '@sagas/help.sagas';
import manageSagas from '@sagas/manage.sagas';
import mapSagas from '@sagas/map.sagas.new';
import messagesSagas from '@sagas/messages.sagas';
import newsSagas from '@sagas/news.sagas';
import profileSagas from '@sagas/profile.sagas';
import rolesSagas from '@sagas/roles.sagas';
import themeSagas from '@sagas/theme.sagas';
import {isObjectWithKeys} from '@utils/misc.utils';
import apiSagas from '../store/api';
import {resetGlobalConfigObject} from '../store/util/config.util';

export const createRootSaga = ({
  injectedSagas,
  injectingCurrent,
}) => function*() {
  // ...authSagas,
  yield fork(function* auth() {
    yield all(authSagas);
  });
  // ...coursesSagas,
  yield fork(function* courses() {
    yield all(coursesSagas);
  });
  // ...profileSagas,
  yield fork(function* profile() {
    yield all(profileSagas);
  });
  // manageSagas
  yield fork(function* manage() {
    yield all(manageSagas);
  });
  // ...competencesSagas,
  yield fork(function* competences() {
    yield all(competencesSagas);
  });
  // ...messagesSagas,
  yield fork(function* messages() {
    yield all(messagesSagas);
  });
  // ...rolesSagas,
  yield fork(function* roles() {
    yield all(rolesSagas);
  });
  // ...themeSagas,
  yield fork(function* theme() {
    yield all(themeSagas);
  });
  // ...newsSagas,
  yield fork(function* news() {
    yield all(newsSagas);
  });
  // ...configSagas,
  yield fork(function* config() {
    yield all(configSagas);
  });
  // ...cmsSagas,
  yield fork(function* cms() {
    yield all(cmsSagas);
  });
  // ...mapSagas,
  yield fork(function* map() {
    yield all(mapSagas);
  });
  // ...helpSagas,
  yield fork(function* help() {
    yield all(helpSagas);
  });
  // ...componentsSagas,
  yield fork(function* components() {
    yield all(componentsSagas);
  });

  yield fork(function* api() {
    yield all(apiSagas);
  });

  // ...competencesAPI
  yield fork(function* competencesAPI() { // remove?
    yield all(competencesAPISagas);
  });

  // Listen for RESET_APP action (on logout/unauthorized).
  // Deletes global config object and removes all injected reducers and sagas
  yield fork(function* removeInjectedModulesOnAppReset() {
    while (true) {
      yield take([RESET_APP, RESET_STORE]);
      resetGlobalConfigObject();

      if (!isObjectWithKeys(injectedSagas)) continue;

      // Cancel all lazy loaded sagas
      for (const key of Object.keys(injectedSagas)) {
        yield cancel(injectedSagas[key].task);

        // wait for saga to be cancelled
        while (injectedSagas[key].task.isRunning()) {
          yield take('CANCELLED');
        }

        delete injectingCurrent[key];
        delete injectedSagas[key];
      }
    }
  });
};
