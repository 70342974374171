import * as React from 'react';
import {LayoutGroup} from 'framer-motion';
import {useSelector} from 'react-redux';
import {LoadingSpinnerSmall} from '@components/loading/components';
import {css} from '@emotion/react';
import {selectAuthStatus} from '@selectors/auth.selectors';
import {i18n} from '@src/i18n';
import {env} from '../../../../env';
import {Base, box, Button, ButtonLink, Input, Label, stack} from './styles';

const formCSS = css([
  box,
  stack,
  css`
    width: 100%;
    opacity: 1;
    pointer-events: auto;
    will-change: opacity, pointer-events;

    &.submitting {
      pointer-events: none;
      opacity: .5;
      transition: opacity 0.2s ease-in-out;
    }

    > div  {
      width: 100%;

      input {
        font-size: 16px;
      }
    }

    .invalid-user {
      color: #ee0c00;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      font-size: 14px;
      pointer-events: none;
    }
  `,
]);

const buttonCSS = css`
  margin-top: 0.7em;

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const LoginFormTwo = React.memo(({
  onSubmit,
  onForgotPassword,
}) => {
  const usernameRef = React.useRef(null);
  const passwordRef = React.useRef(null);

  const {
    isLoading,
    loginFailed,
    statusCode,
  } = useSelector(selectAuthStatus);

  const [touched, setTouched] = React.useState(false);
  const invalidUser = !touched && loginFailed && !isLoading && statusCode === 401;

  const handleSubmit = React.useCallback(e => {
    e.preventDefault();
    onSubmit(usernameRef.current.value, passwordRef.current.value);
    setTouched(false);
  }, [onSubmit]);

  const handleInput = React.useCallback(() => {
    setTouched(true);
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
      css={formCSS}
      className={isLoading && 'submitting'}
      onInput={handleInput}
    >
      <div>
        <LayoutGroup>
          <Label htmlFor="username-field">{i18n('login.username')}</Label>
          <Base sizeVariant="fullwidth">
            <Input
              id="username-field"
              autoComplete="username"
              name="username"
              type="text"
              placeholder={i18n('login.username-placeholder')}
              required
              ref={usernameRef}
            />
          </Base>

          <Label htmlFor="password-field">{i18n('login.password')}</Label>
          <Base sizeVariant="fullwidth">
            <Input
              id="password-field"
              autoComplete="password"
              placeholder={i18n('login.password-placeholder')}
              required
              name="password"
              type="password"
              ref={passwordRef}
            />
          </Base>

          {invalidUser && (
            <p
              role="alert"
              data-testid="invalid-credentials"
              className="invalid-user"
              aria-hidden={invalidUser && 'false' || 'true'}
            >
              {i18n('login.invalid-user')}
            </p>
          )}

          <Button
            sizeVariant="fullwidth"
            css={buttonCSS}
            type="submit"
            data-testid="submit-login"
            disabled={isLoading}
          >
            {isLoading
              ? <LoadingSpinnerSmall hiddenText={i18n('login.login')} />
              : i18n('login.login')}
          </Button>

          {!env.REACT_APP_LOGIN_DISABLE_CHANGE && (
            <ButtonLink
              type="button"
              sizeVariant="fullwidth"
              onClick={onForgotPassword}
              data-testid="submit-forgot"
              css={css`
                width: 100%;
                text-align: center;
                margin-top: 1.48em;
                margin-bottom: 3.2em;
                padding-bottom: 0.11em;
              `}
            >
              {i18n('login.forgot')}
            </ButtonLink>
          )}
        </LayoutGroup>
      </div>
    </form>
  );
});
