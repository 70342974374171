const isDev = process.env.NODE_ENV === 'development';

const frozenSet = iterable => {
  const s = new Set(iterable);

  if (isDev) {
    const throwErr = () => {
      throw new Error('Cannot modify frozen set');
    };

    s.add = throwErr;
    s.delete = throwErr;
    s.clear = throwErr;
  } else {
    s.add = undefined;
    s.delete = undefined;
    s.clear = undefined;
  }

  return s;
};

export const emptyArr = Object.freeze([]);
export const emptyObj = Object.freeze({});
export const emptySet = frozenSet(emptyArr);
