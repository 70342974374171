import React from 'react';
import classNames from 'classnames';
import memoize from 'micro-memoize';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import styled from '@emotion/styled';
import {getConfigObject} from '@selectors/config.selectors';
import styles from /* preval */ './styles.cjs';

const Wrapper = styled.div`${styles}`;

const selectClassName = memoize(className => createSelector(
  getConfigObject,
  configObject => classNames('app-wrapper', className, configObject.getProperty('params.menu.type') || 'menu-two'),
));

export const ResponsiveStyles = ({children, className}) => {
  const _className = useSelector(selectClassName(className));

  return (
    <Wrapper
      data-testid="main-app-wrapper"
      className={_className}
    >
      {children}
    </Wrapper>
  );
};
