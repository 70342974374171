import dayjs from 'dayjs';
import _isEqual from 'lodash/isEqual';
import _uniqWith from 'lodash/uniqWith';
import memoize from 'micro-memoize';
import {createSelector} from 'reselect';
import {EVENT_TYPES} from '@components/calendar-with-tooltip/util.jsx';
import {courseCatalog as courseCatalogRoutes} from '@routes/routes.manifest';
import {i18ndayjs} from '@src/i18n';
import {LoadStatuses} from '@types/load.types';
import {getByPath, isObjectWithKeys, objectKeysOrEmptyArray} from '@utils/misc.utils';
import {validIntOrNull} from '@utils/number.utils';
import {getRouteWithParams} from '@utils/routes.utils';
import {
  emptyArr,
  emptyObj,
  emptySet,
} from '../../common/utils/constants';
import {
  findCompetenceId,
  findCompetenceType,
} from '../util/competence-identity';
import {
  getCompentenceMetaData,
  getCompetenceCoverImage,
} from '../util/courses.util';
import {
  getConfigObject,
  getDefaultImage,
  getPropertiesForCurrLangAndTrack,
} from './config.selectors';
import {
  _selectEmployeeIdsByEventId,
  getNormalizedEmployeesEvents,
  selectEmployeeIds,
  selectEmployeesList,
} from './employees.selectors';
import {
  getNormalizedProfileEvents,
  selectIsManager,
  selectPassedCompetencesMap,
  selectPassedIdsSet,
  selectProfileId,
  selectProfilePersonData,
} from './profile.selectors';

export const getNormalizedCompetenceDetails = ({courses: {normalizedData: {competenceDetails}}}) => competenceDetails;
export const selectCourseCatalogNormalizedData = ({courses: {normalizedData: {competenceDetails}}}) => competenceDetails?.data || emptyObj;
export const getNormalizedCourses = ({courses: {normalizedData}}) => normalizedData;
export const getNormalizedCourseEvents = ({courses: {normalizedData: {courseEvents}}}) => courseEvents;
export const getNormalizedCompetencegroups = ({courses: {normalizedData: {competencegroups}}}) => competencegroups;
export const selectCompetenceGroupsBaseGroupId = ({courses: {normalizedData: {competencegroups: {baseGroupId}}}}) => baseGroupId;
export const getGroupedCompetenceIds = ({courses: {normalizedData: {competenceIdsByGroupId}}}) => competenceIdsByGroupId;
export const getNormalizedCompetences = ({courses: {normalizedData: {competences}}}) => competences;
export const getNormalizedSelectedCompetenceGroup = ({courses: {normalizedData: {selectedCompetencegroup}}}) => selectedCompetencegroup;
export const getNormalizedSelectedCompetenceGroups = ({courses: {normalizedData: {selectedCompetencegroups}}}) => selectedCompetencegroups;
export const getCompetencesSearchTerm = ({courses: {competences: {searchTerm}}}) => searchTerm;
export const getCompetencesSearchResults = ({courses: {normalizedData: {competencesSearchResults}}}) => competencesSearchResults;

export const getCompetencesSearchState = createSelector(
  getCompetencesSearchResults,
  getCompetencesSearchTerm,
  ({status, empty}, searchTerm) => ({
    isActive: !!searchTerm,
    isLoading: status === LoadStatuses.IS_LOADING,
    isEmpty: empty,
    status,
    searchTerm,
  }),
);

export const getEventsGroupedByYearMonth = createSelector(
  getNormalizedCourseEvents,
  ({eventIdsByYearMonth, eventById}) => {
    if (!isObjectWithKeys(eventIdsByYearMonth)) return emptyArr;

    const grouped = Object.entries(eventIdsByYearMonth)
      .sort((a, b) => Number(a[0]) - Number(b[0]))
      .map(([year, monthObj]) => {
        if (!isObjectWithKeys(monthObj)) return emptyArr;

        const months = Object.entries(monthObj)
          .sort((a, b) => Number(a[0]) - Number(b[0]))
          .map(([month, eventIds]) => ({
            monthName: i18ndayjs(`${year}-${month}`).format('MMMM'),
            month,
            events: eventIds.map(id => eventById[id]).filter(event => !!event && !event.__isChildEvent),
          }));

        return {
          year,
          months,
        };
      });

    return grouped;
  },
);

const getAutostartCourseTypesSet = createSelector(
  getConfigObject,
  configObject => {
    const types = configObject?.getProperty?.('routes.course-catalog.autostartCourseTypes');

    if (!types?.length) return emptySet;

    return new Set(types);
  },
);

export const getNormalizedSelectedCompetences = createSelector(
  getNormalizedSelectedCompetenceGroups,
  getCompetencesSearchTerm,
  getCompetencesSearchResults,
  getDefaultImage,
  getAutostartCourseTypesSet,
  selectPassedIdsSet,
  selectPassedCompetencesMap,
  (
    {competences, status: competencesLoadStatus},
    searchTerm,
    {data: searchResultsData, status: searchStatus},
    defaultImage,
    autoStartCoursetypesSet,
    passedIdsSet,
    passedCompetencesMap,
  ) => {
    const searchActive = !!searchTerm;

    let isFetching, competencesData;

    if (searchActive) {
      isFetching = searchStatus === LoadStatuses.IS_LOADING;
      competencesData = Object.values(searchResultsData);
    } else {
      isFetching = competencesLoadStatus === LoadStatuses.IS_LOADING;
      competencesData = competences;
    }

    const competencesWithProps = competencesData?.map?.(competence => {
      const competence_id = findCompetenceId(competence);
      const competence_type = findCompetenceType(competence);

      if (!competence_id) return null;

      const {durationText, points, passed} = getCompentenceMetaData({
        competence,
        passedIdsSet,
        passedCompetencesMap,
      }) || {};

      return {
        ...competence,
        durationText,
        points,
        passed,
        coverImage: getCompetenceCoverImage(competence, defaultImage),
        shouldAutostartCourse: !competence.url && autoStartCoursetypesSet.has(competence_type),
        competenceUrl: getRouteWithParams(courseCatalogRoutes.coursePreview.path, {cid: competence_id}),
      };
    }) || emptyArr;

    return {
      isFetching,
      data: competencesWithProps,
      empty: !isFetching && !competencesData.length,
    };
  },
);

const _selectCourseEvents = ({courses: {courseEvents}}) => courseEvents;

export const selectCourseEvents = createSelector(
  _selectCourseEvents,
  getNormalizedCourseEvents,
  (courseEvents, normalizedEvents) => {
    const {allEventIds = [], eventById = {}} = normalizedEvents || {};

    const data = [];

    for (const eventId of allEventIds) {
      const event = eventById[eventId];

      if (!event) continue;

      data.push(event);
    }

    return {
      ...courseEvents,
      ...normalizedEvents,
      data: data?.length ? data : emptyArr,
    };
  },
);

export const getCompetencegroups = ({courses: {competencegroups}}) => competencegroups;
export const getCourseCatalogNews = ({courses: {courseCatalogNews}}) => courseCatalogNews;
export const getCompetencetypes = ({courses: {competencetypes}}) => competencetypes;
export const getSelectedCatalogView = ({courses: {filters: {catalogView}}}) => catalogView;
export const getSelectedCourseKindTab = ({courses: {filters: {courseKind: {tab}}}}) => tab;
export const getSelectedCompetencegroupId = ({courses: {selectedCompetencegroupId}}) => selectedCompetencegroupId;
export const getSelectedSubcompetencegroupId = ({courses: {selectedSubcompetencegroupId}}) => selectedSubcompetencegroupId;
export const getSelectedSubSubcompetencegroupId = ({courses: {selectedSubSubcompetencegroupId}}) => selectedSubSubcompetencegroupId;
export const getSigningOnCourse = ({courses: {courseSignOn}}) => courseSignOn;
export const getSigningOffCourse = ({courses: {courseSignOff}}) => courseSignOff;
export const getCoursesSorting = ({courses: {sorting}}) => sorting;
export const getCourseFilters = ({courses}) => courses.filters;

export const getSelectedCompetencetypes = createSelector(
  ({courses: {filters: {selectedCompetencetypes}}}) => selectedCompetencetypes,
  selectedCompetencetypes =>
    Object.keys(selectedCompetencetypes).reduce(
      (ids, id) => selectedCompetencetypes[id] && [...ids, id] || ids,
      [],
    ),
);

export const getActiveCourse = ({courses: {activeCourse}}) => activeCourse;
export const selectActiveCourseMapId = ({courses: {activeCourseMapId}}) => activeCourseMapId;
export const getSignOnCourseResults = ({courses: {courseSignOn}}) => courseSignOn;

export const getFeaturedContentIds = createSelector(
  getPropertiesForCurrLangAndTrack,
  getConfigObject,
  (configForCurrLangAndMap, configObject) => {
    const featuredTiles = configObject.getProperty('routes.course-catalog.customToplevelSelector.featuredTiles');
    const featuredTilesForCurrMap = getByPath(configForCurrLangAndMap, 'courseCatalog.featuredTiles.contentIds');

    const ids = [];

    if (featuredTilesForCurrMap) {
      if (Array.isArray(featuredTilesForCurrMap)) {
        ids.push(featuredTilesForCurrMap);
      } else {
        ids.push([featuredTilesForCurrMap]);
      }
    }

    if (featuredTiles?.contentIds) {
      if (Array.isArray(featuredTiles.contentIds)) {
        ids.push(featuredTiles.contentIds);
      } else {
        ids.push([featuredTiles.contentIds]);
      }
    }

    return _uniqWith(ids, _isEqual);
  },
);

const emptyEventIds = Object.freeze({
  confirmed: emptySet,
  waitlist: emptySet,
  all: emptySet,
});

const _selectProfileEventIds = createSelector(
  getNormalizedProfileEvents,
  ({
    confirmedIds = emptyArr,
    waitlistIds = emptyArr,
  }) => ({
    confirmedIds,
    waitlistIds,
  }),
);

const selectEmployeeIdsWaitlistConfirmedByEventId = createSelector(
  getNormalizedEmployeesEvents,
  ({employeeIdsConfirmedByEventId, employeeIdsWaitlistByEventId}) => ({
    employeeIdsConfirmedByEventId,
    employeeIdsWaitlistByEventId,
  }),
);

const selectProfileEventIdsSets = createSelector(
  selectIsManager,
  selectProfileId,
  _selectProfileEventIds,
  selectEmployeeIdsWaitlistConfirmedByEventId,
  (isManager, profileId, profileEvents, employeesEvents) => {
    const {
      confirmedIds: profileConfirmedIds = [],
      waitlistIds: profileWaitlistIds = [],
    } = profileEvents || {};

    const {
      employeeIdsConfirmedByEventId = {},
      employeeIdsWaitlistByEventId = {},
    } = employeesEvents || {};

    if (!profileId) return emptyEventIds;

    const profileEmpty = !profileConfirmedIds?.length && !profileWaitlistIds?.length;

    const employeesEmpty = isManager
      ? !employeeIdsConfirmedByEventId?.[profileId]?.length && !employeeIdsWaitlistByEventId?.[profileId]?.length
      : true;

    if (profileEmpty && employeesEmpty) return emptyEventIds;

    if (employeesEmpty) {
      const eventIdsWaitlistSet = new Set(profileWaitlistIds);
      const eventIdsConfirmedSet = new Set(profileConfirmedIds.filter(id => !eventIdsWaitlistSet.has(id)));
      const eventIdsAllSet = new Set([...eventIdsConfirmedSet, ...eventIdsWaitlistSet]);

      return {
        confirmed: eventIdsConfirmedSet.size ? eventIdsConfirmedSet : emptySet,
        waitlist: eventIdsWaitlistSet.size ? eventIdsWaitlistSet : emptySet,
        all: eventIdsAllSet.size ? eventIdsAllSet : emptySet,
      };
    }

    const waitlist = new Set([
      ...profileWaitlistIds,
      ...employeeIdsWaitlistByEventId[profileId] || [],
    ].map(validIntOrNull).filter(Boolean));

    const confirmed = new Set([
      ...profileConfirmedIds,
      ...employeeIdsConfirmedByEventId[profileId] || [],
    ].map(validIntOrNull).filter(id => !!id && !waitlist.has(id)));

    const all = new Set([...confirmed, ...waitlist]);

    return {
      confirmed: confirmed.size ? confirmed : emptySet,
      waitlist: waitlist.size ? waitlist : emptySet,
      all: all.size ? all : emptySet,
    };
  },
);

const selectUniqueEventIdsSet = createSelector(
  selectIsManager,
  getNormalizedCourseEvents,
  selectProfileEventIdsSets,
  _selectEmployeeIdsByEventId,
  (isManager, courseEvents, profileEventIdsSets, employeeIdsByEventId) => {
    const {allEventIds = []} = courseEvents || {};
    const {all: profileEventIdsSet} = profileEventIdsSets || {};

    const someNotEmpty = allEventIds?.length
      || profileEventIdsSet?.size
      || isObjectWithKeys(employeeIdsByEventId);

    if (!someNotEmpty) return emptySet;

    const unique = new Set([
      ...allEventIds || [],
      ...profileEventIdsSet || [],
      ...isManager ? objectKeysOrEmptyArray(employeeIdsByEventId) : [],
    ].map(validIntOrNull).filter(Boolean));

    return unique.size
      ? unique
      : emptySet;
  },
);

const selectPersonIdsSetsByEventId = createSelector(
  selectIsManager,
  selectProfileId,
  selectProfileEventIdsSets,
  selectUniqueEventIdsSet,
  selectEmployeeIdsWaitlistConfirmedByEventId,
  (isManager, profileId, profileEventIdsSets, uniqueEventIdsSet, employeeIdsV2) => {
    if (!profileId || !uniqueEventIdsSet?.size) return emptyObj;

    const profileEmpty = !profileEventIdsSets?.all?.size;

    const {
      employeeIdsConfirmedByEventId = {},
      employeeIdsWaitlistByEventId = {},
    } = employeeIdsV2 || {};

    const employeesEmpty = isManager
      ? !isObjectWithKeys(employeeIdsConfirmedByEventId) && !isObjectWithKeys(employeeIdsWaitlistByEventId)
      : true;

    if (employeesEmpty) {
      if (profileEmpty) return emptyObj;

      const pidsByEventId = {};

      for (const eventId of uniqueEventIdsSet) {
        if (!eventId) continue;

        const set = new Set([profileId]);

        if (profileEventIdsSets?.waitlist?.has?.(eventId)) {
          pidsByEventId[eventId] = {
            confirmed: emptySet,
            waitlist: set,
            all: set,
            selfWaitlist: true,
          };
        } else if (profileEventIdsSets?.confirmed?.has?.(eventId)) {
          pidsByEventId[eventId] = {
            confirmed: set,
            waitlist: emptySet,
            all: set,
            selfConfirmed: true,
          };
        }
      }

      return pidsByEventId;
    }

    const pidsByEventId = {};

    for (const eventId of uniqueEventIdsSet) {
      const selfWaitlist = profileEventIdsSets?.waitlist?.has?.(eventId);
      const selfConfirmed = !selfWaitlist && profileEventIdsSets?.confirmed?.has?.(eventId);

      const waitlist = new Set([...selfWaitlist ? [profileId] : [], ...employeeIdsWaitlistByEventId[eventId] || []]);
      const confirmed = new Set([...selfConfirmed ? [profileId] : [], ...employeeIdsConfirmedByEventId[eventId] || []]);
      const all = new Set([...confirmed, ...waitlist]);

      pidsByEventId[eventId] = {
        selfWaitlist,
        selfConfirmed,
        waitlist,
        confirmed,
        all,
      };
    }

    return pidsByEventId;
  },
);

export const selectUserFilesByEventId = createSelector(
  getNormalizedProfileEvents,
  ({eventsConfirmed}) => {
    if (!eventsConfirmed?.length) return emptyObj;

    const userFilesByEventId = {};

    eventsConfirmed.forEach(({id, user_files, children}) => {
      if (!user_files?.length) return;

      userFilesByEventId[id] = user_files;

      if (!children?.length) return;

      children.forEach(({id: childId, user_files: childUserFiles}) => {
        if (!childUserFiles?.length) return;

        if (!userFilesByEventId[childId]) {
          userFilesByEventId[childId] = [];
        }

        userFilesByEventId[childId].push(...childUserFiles);
      });
    });

    return userFilesByEventId;
  },
);

export const createCourseEventsExtraDataSelector = memoize((courseId, eventId) => createSelector(
  [
    selectProfileId,
    selectProfilePersonData,
    getNormalizedCourseEvents,
    selectEmployeeIds,
    selectIsManager,
    selectPersonIdsSetsByEventId,
    selectUserFilesByEventId,
  ],
  (
    profileId,
    profileData,
    courseEvents = emptyObj,
    employeeIds = emptyArr,
    isManager = false,
    personIdsSets = emptyObj,
    userFilesByEventId = emptyObj,
  ) => {
    if (!profileId) return courseEvents;

    const {
      eventById = {},
      allEventIds = [],
      eventIdsByCourseId = {},
    } = courseEvents || {};

    const getEmployeeIdsNotSignedUp = (eventId, selfSignedUp) => {
      const employeeIdsSignedUp = personIdsSets[eventId]?.all;

      if (!employeeIdsSignedUp?.size) return employeeIds;

      const notSignedUp = employeeIds.filter(employeeId => {
        if (selfSignedUp && employeeId === profileId) return false;

        return !employeeIdsSignedUp.has(employeeId);
      });

      return notSignedUp.length
        ? notSignedUp
        : emptyArr;
    };

    const _eventIds = courseId
      ? eventIdsByCourseId[courseId] || []
      : allEventIds || [];
    const eventIds = eventId
      ? [eventId]
      : _eventIds;

    const eventByIdExtraData = eventIds.reduce((acc, eventId) => {
      const event = eventById[eventId];

      if (!event?.id) return acc;

      const isChildEvent = event?.__isChildEvent;

      let participantsData, selfSignedUp;

      if (!isChildEvent) {
        const selfWaitlist = personIdsSets[eventId]?.selfWaitlist;
        const selfConfirmed = !selfWaitlist && personIdsSets[eventId]?.selfConfirmed;

        selfSignedUp = selfWaitlist || selfConfirmed;

        const employeeIdsWaitlist = personIdsSets[eventId]?.waitlist?.size
          ? [...personIdsSets[eventId].waitlist]
          : emptyArr;
        const employeeIdsConfirmed = personIdsSets[eventId]?.confirmed?.size
          ? [...personIdsSets[eventId].confirmed]
          : emptyArr;

        participantsData = {
          selfWaitlist,
          selfConfirmed,
          selfSignedUp,
          employeeIdsNotSignedUp: emptyArr,
          employeeIdsWaitlist,
          employeeIdsConfirmed,
        };
      }

      const mappedChildren = (event.children_ids?.length
        ? event.children_ids.map(childId => {
          if (!childId || !eventById[childId]) return null;

          const child = eventById[childId];

          return {
            ...child,
            user_files: userFilesByEventId[childId] || child?.user_files || emptyArr,
            parent_id: event.id,
          };
        })
        : event.children?.length
          ? event.children.map(child => {
            if (!child?.id) return null;

            const eventObj = eventById[child.id];

            return {
              ...child,
              ...eventObj,
              user_files: userFilesByEventId[child.id] || child?.user_files || eventObj?.user_files || emptyArr,
            };
          })
          : []).filter(Boolean);

      acc[eventId] = {
        ...event,
        user_files: userFilesByEventId[eventId] || event.user_files || emptyArr,
        ...!isChildEvent && {participantsData},
        children: mappedChildren.length
          ? mappedChildren
          : event.children || emptyArr,
        loadedExtraData: true,
      };

      if (isManager && !isChildEvent) {
        acc[eventId].participantsData.employeeIdsNotSignedUp = getEmployeeIdsNotSignedUp(eventId, participantsData?.selfSignedUp);
      }

      return acc;
    }, {});

    return {
      ...courseEvents,
      loadedExtraData: true,
      eventById: eventByIdExtraData,
    };
  },
));

export const createEventWithParticipantsSelector = memoize(eventId => createSelector(
  [
    createCourseEventsExtraDataSelector(null, eventId),
    selectEmployeesList,
    getNormalizedEmployeesEvents,
    getNormalizedProfileEvents,
    selectProfileId,
    selectProfilePersonData,
  ],
  (courseEvents, employees, employeesEvents, profileEvents, profileId, profileData) => {
    const {eventById = {}} = courseEvents || {};

    const event = eventById?.[eventId];

    if (!event?.id) return emptyObj;

    const {byId: employeeById = {}} = employees || {};
    const {persons: eventPersons = {}} = employeesEvents || {};

    const profile = profileEvents?.eventsConfirmed?.[0]?.person
      ?? profileEvents?.eventsWaitlist?.[0]?.person
      ?? profileData;

    const getPerson = personId => {
      if (personId === profileId) return profile;

      const person = eventPersons?.[personId] ?? employeeById?.[personId];

      if (!person) return null;

      const {
        firstname,
        lastname,
        email,
        mobile,
        phone,
        person_id,
        user_name,
        profile_image,
        fullname,
      } = person;

      return {
        firstname,
        lastname,
        email,
        mobile,
        phone,
        person_id,
        user_name,
        profile_image,
        fullname,
      };
    };

    const {
      employeeIdsNotSignedUp,
      employeeIdsWaitlist,
      employeeIdsConfirmed,
      ...participantsData
    } = event?.participantsData || {};

    const filteredNotSignedUp = employeeIdsNotSignedUp?.map?.(getPerson)?.filter(Boolean);
    const filteredWaitlist = employeeIdsWaitlist?.map?.(getPerson)?.filter(Boolean);
    const filteredConfirmed = employeeIdsConfirmed?.map?.(getPerson)?.filter(Boolean);

    return {
      ...event,
      ...participantsData,
      employeesNotSignedUp: filteredNotSignedUp?.length ? filteredNotSignedUp : emptyArr,
      employeesWaitlist: filteredWaitlist?.length ? filteredWaitlist : emptyArr,
      employeesConfirmed: filteredConfirmed?.length ? filteredConfirmed : emptyArr,
    };
  },
));

export const getSelectedCompetenceTypes = createSelector(
  getNormalizedSelectedCompetenceGroups,
  competencegroups => {
    const {competences} = competencegroups || {};

    if (!competences?.length) return emptyArr;

    const types = [];
    const unique = new Set();

    competences.forEach(({
      competence_type,
      competence_type_id,
      competence_type_key,
    }) => {
      if (unique.has(competence_type_id)) return;

      unique.add(competence_type_id);

      types.push({
        competence_type,
        competence_type_id,
        competence_type_key,
      });
    });

    return types;
  },
);

export const getIsFetchingCompetenceGroups = createSelector(
  getNormalizedCompetencegroups,
  competencegroups => competencegroups.status === LoadStatuses.IS_LOADING,
);

export const getIsFetchingCourseEvents = createSelector(
  getNormalizedCourseEvents,
  courseEvents => courseEvents.status === LoadStatuses.IS_LOADING,
);

export const getAllEventsSortedByDate = createSelector(
  getNormalizedCourseEvents,
  events => {
    const {eventById, allEventIds: sortedEventIds} = events || {};

    if (!sortedEventIds?.length || !isObjectWithKeys(eventById)) return emptyArr;

    return sortedEventIds?.map?.(id => eventById[id]) || emptyArr;
  },
);

export const createCompetenceDetailsByIdSelector = memoize(cid => createSelector(
  getNormalizedCompetences,
  getNormalizedCompetenceDetails,
  createCourseEventsExtraDataSelector(cid),
  (competences = emptyObj, competenceDetails = emptyObj, events = emptyObj) => {
    if (!cid) return null;

    const data = competenceDetails?.data?.[cid] || competences?.data?.[cid];

    const isFetchingDetails = !competenceDetails?.data?.[cid];

    const {eventById = {}, eventIdsByCourseId = {}} = events;

    if (!eventIdsByCourseId?.[cid]?.length) return {
      ...data,
      competence_type: data?.competence_type_key || data?.competence_type,
      isFetching: isFetchingDetails,
      events: emptyArr,
      calendarItems: emptyObj,
    };

    const ret = {
      ...data,
      competence_type: data?.competence_type_key || data?.competence_type,
      isFetching: isFetchingDetails,
      events: [],
      calendarItems: {},
    };

    eventIdsByCourseId[cid].forEach(id => {
      const event = eventById[id];

      if (!event?.id || event.__isChildEvent) return;

      ret.events.push(event);

      const startDate = dayjs(event.startdate);

      const year = startDate.year();
      const month = startDate.month();
      const yearMonthString = `${year}-${month}`;
      const dayNumber = startDate.format('D');

      if (!ret.calendarItems[yearMonthString]) {
        ret.calendarItems[yearMonthString] = {};
      }

      if (!ret.calendarItems[yearMonthString][dayNumber]) {
        ret.calendarItems[yearMonthString][dayNumber] = [];
      }

      // Tooltip data
      ret.calendarItems[yearMonthString][dayNumber].push({
        title: event.title,
        dateStart: startDate,
        time: startDate.format('HH:mm'),
        duration: event.duration,
        id: event.id,
        competence_id: event.competence_id,
        persons: emptyArr,
        eventType: EVENT_TYPES.courseEvent,
        location: event.location,
      });
    });

    return ret;
  },
));

export const getCourseEventIdsByYearMonth = ({courses: {normalizedData: {courseEvents: {eventIdsByYearMonth}}}}) => eventIdsByYearMonth;

const _selectCourses = ({courses}) => courses;

export const getCourseSignStatus = createSelector(
  _selectCourses,
  ({courseSignOn, courseSignOff}) => ({
    isSigningOn: !!courseSignOn?.isFetching,
    isSigningOff: !!courseSignOff?.isFetching,
  }),
);

const getSigningOnIds = ({courses: {courseSignOn: {courseIds: signOnIds}}}) => signOnIds;
const getSigningOffIds = ({courses: {courseSignOff: {courseIds: signOffIds}}}) => signOffIds;

export const selectIsSigningOnOrOffCourseEventIds = createSelector(
  getSigningOnIds,
  getSigningOffIds,
  (signOnIds = [], signOffIds = []) => {
    const data = {
      on: {},
      off: {},
      all: {},
    };

    signOnIds.forEach(id => {
      data.on[id] = true;
      data.all[id] = true;
    });
    signOffIds.forEach(id => {
      data.off[id] = true;
      data.all[id] = true;
    });

    return data;
  },
);

export const getIsSigningOnOrOffCourseEvent = createSelector(
  getCourseSignStatus,
  ({isSigningOn, isSigningOff}) => isSigningOn || isSigningOff,
);

export const getCourseCatalogDefaultCompetencegroup = createSelector(
  getPropertiesForCurrLangAndTrack,
  data => data?.courseCatalog?.defaultSelectedCompetenceGroupId,
);

export const createEventByIdSelector = memoize(eventId => createSelector(
  createCourseEventsExtraDataSelector(null, eventId),
  events => events?.eventById?.[eventId] || emptyObj,
));

export const createEventExtraDataByIdSelector = memoize(eventId => createSelector(
  createEventWithParticipantsSelector(eventId),
  event => {
    if (!event?.id) return emptyObj;

    const {
      courseId = null,
      employeesNotSignedUp = emptyArr,
      employeesConfirmed = emptyArr,
      employeesWaitlist = emptyArr,
      selfConfirmed = false,
      selfWaitlist = false,
      deadlines = {},
    } = event || {};

    const {signOnPassed, signOffPassed} = deadlines || {};

    const isFull = event?.participants_count === event?.maxParticipants;

    const enableWaitlist = isFull && signOnPassed === false;

    const enableSignOn = event?.joinable ?? !signOnPassed;
    const enableSignOff = (selfConfirmed || selfWaitlist) && signOffPassed === false;

    const waitlist = isFull && !signOnPassed;

    return {
      event,
      courseId,
      isFull,
      selfConfirmed,
      selfWaitlist,
      enableSignOn,
      enableWaitlist,
      enableSignOff,
      waitlist,
      employeesNotSignedUp,
      signOnPassed,
      signOffPassed,
      employeesConfirmed,
      employeesWaitlist,
    };
  },
));
