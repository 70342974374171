import {isObjectWithKeys} from '@utils/misc.utils';

export const toDotPaths = (obj, prefix = '', res = {}) => {
  if (!isObjectWithKeys(obj)) {
    return res;
  }

  for (const k of Object.keys(obj)) {
    const pre = prefix.length ? prefix + '.' : '';

    if (obj?.[k] && typeof obj[k] === 'object') {
      toDotPaths(obj[k], pre + k, res);
    } else {
      res[pre + k] = obj[k];
    }
  }

  return res;
};
