import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {createSelector} from 'reselect';
import {
  atlas as AtlasRoutes,
  courseCatalog as courseCatalogRoutes,
} from '@routes/routes.manifest';
import {selectAuthStartUrl} from '@selectors/auth.selectors';
import {getConfigObject} from '@selectors/config.selectors';
import {selectKioskRedirectUrl} from '@selectors/joined-data.selectors';
import {getIsMapCompleted} from '@selectors/map.selectors.new';
import {selectIsKioskMode} from '@selectors/profile.selectors';

const selectDefaultRedirectRoute = createSelector(
  getConfigObject,
  selectAuthStartUrl,
  getIsMapCompleted,
  (configObject, startUrl, isMapCompleted) => {
    const defaultRoute = startUrl !== '/'
      && startUrl
      || configObject?.getProperty('params.default-route');

    if (configObject?.isMapActivated) {
      if (!isMapCompleted) return defaultRoute || AtlasRoutes.main.path;

      return configObject?.isModuleEnabled?.('course-catalog')
        ? courseCatalogRoutes.main.path
        : defaultRoute || AtlasRoutes.main.path;
    }

    return defaultRoute || courseCatalogRoutes.main.path;
  },
);

export const DefaultRedirectRoute = React.memo(() => {
  const isKiosk = useSelector(selectIsKioskMode);
  const kioskRedirectUrl = useSelector(selectKioskRedirectUrl);
  const defaultRedirectRoute = useSelector(selectDefaultRedirectRoute);
  const authStartUrl = useSelector(selectAuthStartUrl);

  if (window.location?.pathname?.includes?.('/set-atlas/')) return null;

  return (
    <Redirect
      to={isKiosk
        ? authStartUrl || kioskRedirectUrl
        : defaultRedirectRoute}
    />
  );
});
