import {backendUrlV2} from '@config';
import {axiosRetry} from '@utils/async.utils';

export async function fetchOrgCustomThemeAPI({orgId, throwOnError = false}) {
  try {
    const {data} = await axiosRetry({
      method: 'GET',
      url: `${backendUrlV2}/organisations/${orgId}/theme`,
      withCredentials: true,
    });

    return data;
  } catch (error) {
    console.error('error', error);

    if (throwOnError) {
      throw error;
    }

    return null;
  }
}

export async function postOrgCustomThemeAPI({orgId, data: orgData, throwOnError = false}) {
  try {
    const {data} = await axiosRetry({
      method: 'POST',
      url: `${backendUrlV2}/organisations/${orgId}/theme`,
      data: orgData,
      withCredentials: true,
    });

    return data;
  } catch (error) {
    console.error('error', error);

    if (throwOnError) {
      throw error;
    }

    return null;
  }
}
