import {
  findCompetenceCheckedBy,
  findCompetenceChecklist,
  findCompetenceChildrenIds,
  findCompetenceDescription,
  findCompetenceFiles,
  findCompetenceId,
  findCompetencePassed,
  findCompetencePassedDate,
  findCompetenceShortDescription,
  findCompetenceTitle,
  findCompetenceType,
  findCompetenceUserFiles,
  findPersonId,
  findPhceId,
  hasDescriptionField,
} from '@src/store/util/competence-identity';
import {filterNullishProps, isObjectWithKeys} from '@utils/misc.utils';
import {getCompetencePassed, getCompetenceStatus, getIsExpiredCompetence} from './competence.utils';

// functions for normalizing different API responses into common formats

export const normalizePersonCompetence = competence => {
  if (!isObjectWithKeys(competence)) return null;

  const data = {
    person_id: findPersonId(competence),
    competence_id: findCompetenceId(competence),
    passed: findCompetencePassed(competence),
    checklist: findCompetenceChecklist(competence),
    user_files: findCompetenceUserFiles(competence),
    phce_id: findPhceId(competence),
    verified: competence?.verified || competence?.competence?.verified || competence?.data?.verified,
    valid_until: competence?.valid_until || competence?.competence?.valid_until || competence?.data?.valid_until,
    joined: competence?.joined || competence?.competence?.joined || competence?.data?.joined,
    competence_type: findCompetenceType(competence),
  };

  const nonNullishData = filterNullishProps(data);

  const merged = {
    ...competence,
    ...nonNullishData,
  };

  if (merged.passed != null) {
    nonNullishData.passed = getCompetencePassed(merged);
    nonNullishData.status = getCompetenceStatus(merged);
    nonNullishData.expired = getIsExpiredCompetence(merged);
  }

  const passed_date = findCompetencePassedDate(merged);
  const checked_by = findCompetenceCheckedBy(merged);

  const phce_id = data.phce_id ?? (!!merged.id && merged.id !== data.competence_id
    ? merged.id
    : null);

  if (passed_date) nonNullishData.passed_date = passed_date;
  if (checked_by) nonNullishData.checked_by = checked_by;
  if (phce_id) {
    nonNullishData.phce_id = phce_id;
    nonNullishData.person_competence_id = phce_id;
    nonNullishData.phceId = phce_id;
  }

  return nonNullishData;
};

export const normalizeCompetence = data => {
  if (!isObjectWithKeys(data)) return null;

  const competence = {
    competence_type: findCompetenceType(data),
    competence_id: findCompetenceId(data),
    title: findCompetenceTitle(data),
    short_description: findCompetenceShortDescription(data),
    description: findCompetenceDescription(data),
    children_ids: findCompetenceChildrenIds(data),
    files: findCompetenceFiles(data),
    parent_ids: data?.parent_ids,
    checked_by: findCompetenceCheckedBy(data),
    cover: data?.cover || data?.competence?.cover || data?.data?.cover || data?.course?.cover,
  };

  if (competence.description && competence.description === competence.short_description) {
    delete competence.description;
  }

  competence.cover = competence?.cover || competence?.files?.find(({title}) => title === 'cover');
  competence.competence_type_key = competence.competence_type;
  competence.competence_title = competence.title;
  competence.competence_description = competence.description;
  competence.description = data?.description ?? competence.description;
  competence.abstract = competence.short_description;
  competence.id = competence.competence_id;
  competence.track_image = data.image_url ?? data.track_image;
  competence.layout = data.layout;
  competence.deactivated = data.deactivated;

  const competence_json_data = data?.competence_json_data
    || data?.data?.competence_json_data
    || data?.competence?.competence_json_data
    || data?.data
    || data?.competence?.data;

  if (competence_json_data && typeof competence_json_data === 'string') {
    try {
      const json = JSON.parse(competence_json_data);

      if (isObjectWithKeys(json?.data?.data)) competence.competence_json_data = json.data.data;
    } catch {
      // console.error(error);
    }
  } else if (isObjectWithKeys(competence_json_data)) {
    competence.competence_json_data = competence_json_data;
  }

  competence.__hasDescriptionField = !!(
    data?.__hasDescriptionField
    || data?.short_description !== undefined
    || data?.description !== undefined
    || hasDescriptionField(competence.competence_json_data))
    || hasDescriptionField(data);

  let nonNullish = filterNullishProps(competence, true);

  if (nonNullish.competence_json_data) {
    const normalizedJsonData = normalizeCompetence(nonNullish.competence_json_data);

    nonNullish = {
      ...normalizedJsonData,
      ...nonNullish,
    };
  }

  return nonNullish;
};
