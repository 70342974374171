import * as React from 'react';
import Cookies from 'js-cookie';
import {connect} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import {createSelector, createStructuredSelector} from 'reselect';
import CourseLoader from '@components/loading/courseLoader';
import KioskModeMenu from '@components/menu/kiosk-mode-menu';
import Menu from '@components/menu/menu';
import Notifications from '@components/notifications/notifications';
import {
  buildHash,
  nanoLearningLoginUrl,
  SENTRY_ENABLE,
  SENTRY_PROFILER,
  version,
} from '@config';
import {selectAuthStartUrl, selectIsUnauthorized} from '@selectors/auth.selectors';
import {getConfigObject, getIsConfigLoaded} from '@selectors/config.selectors';
import {getActiveCourse} from '@selectors/courses.selectors';
import {selectThemeOrgId} from '@selectors/global.selectors';
import {getLayoutClassname} from '@selectors/map.selectors.new';
import {
  getIsSuperuser,
  getOrganisationId,
  selectIsKioskMode,
  selectIsManager,
  selectProfileFirstname,
  selectProfileId,
  selectProfileUserName,
} from '@selectors/profile.selectors';
import * as Sentry from '@sentry/react';
import {getBartId} from '@utils/data.utils';
import {ModalsRenderer} from './features/modals/modals-renderer';
import {WithEmployeesModule} from './redux/lazy/components';
import {ResponsiveStyles} from './styles/responsive-styles';
import {
  AppLoading,
  BartPanel,
  CacheBuster,
  CoursePlayer,
  CvContainer,
  CyHistory,
  DebugPanel,
  LoginContainer,
  NanoLearningPlayerContainer,
} from './app.components';
import {cvContainerRoutes} from './app.routes';
import {AppSwitch} from './app.switch';
import {handleToggleParams, wrap} from './app.util';

const wrappers = SENTRY_ENABLE
  ? [
    ...SENTRY_PROFILER ? [Sentry.withProfiler] : [],
  ]
  : [];

class App extends React.PureComponent {
  constructor(props) {
    super(props);

    if (buildHash || version) {
      console.log(`${buildHash} v. ${version}\n${window.navigator.userAgent}`);
    }
  }

  render() {
    const {
      activeCourse,
      pageLayout,
      isManager,
      isSuperuser,
      profilePersonId,
      isUnauthorized,
      customThemeOrgId,
      profileFirstname,
      isKioskMode,
      isAppReady,
      configObject,
      isConfigLoaded,
    } = this.props;

    const loader = <CacheBuster><CourseLoader appload /></CacheBuster>;

    if (!Cookies.get('tg-visit') || isUnauthorized) {
      return (
        <div className={`app-wrapper menu-two ${pageLayout}`}>
          {CyHistory}
          <CacheBuster />
          {nanoLearningLoginUrl
            ? <LoginContainer to={nanoLearningLoginUrl} />
            : (
              <>
                <LoginContainer />
                {!window.location.pathname.includes('login') && !window.location.pathname.includes('logout')
                  ? (
                    <Redirect
                      from="*"
                      to={window.location.pathname !== 'login' && '/login?redirect=' + window.location.pathname}
                    />
                  )
                  : window.location.pathname.includes('logout') && (
                    <Redirect
                      from="*"
                      to="/login"
                    />
                  )}
              </>
            )}
          <Notifications />
        </div>
      );
    }

    if (isConfigLoaded) handleToggleParams(configObject);

    if (!isAppReady) return loader;

    const partiallyNotReady = customThemeOrgId == null
      || profileFirstname == null;

    return (
      <ResponsiveStyles>
        <CacheBuster />
        {CyHistory}
        {partiallyNotReady && loader}
        {(isManager || isSuperuser) && <DebugPanel />}
        <div
          key="portal-above"
          id="portal-above"
        >
          {!!activeCourse && activeCourse?.type === 24
            ? <div className="app-wrapper__content fullscreen"><NanoLearningPlayerContainer courseId={activeCourse.cid} /></div>
            : !!activeCourse?.cid && activeCourse.status !== 0 && <div className="app-wrapper__content fullscreen"><CoursePlayer /></div>}
          {(isManager || isSuperuser) && <WithEmployeesModule><ModalsRenderer accessLevel={3} /></WithEmployeesModule>}
          <ModalsRenderer accessLevel={0} />
        </div>
        <div
          key="side-modal"
          id="side-modal"
        />
        <div
          key="persons-container"
          id="persons-container"
        />
        {/* Routes shown above other content */}
        <Route
          path={cvContainerRoutes}
          component={CvContainer}
          breadcrumbs
        />
        <div className="app-wrapper__menu">
          {customThemeOrgId == null
            ? null
            : isKioskMode
              ? <KioskModeMenu />
              : <Menu />}
        </div>
        <div className="app-wrapper__content app-wrapper__content__main">
          <AppSwitch />
        </div>
        <Notifications />
        <AppLoading />
        {Boolean(getBartId() && profilePersonId === getBartId())
          && <BartPanel />}
      </ResponsiveStyles>
    );
  }
}

const selectIsAppReady = createSelector(
  selectIsUnauthorized,
  getIsConfigLoaded,
  selectProfileId,
  selectProfileUserName,
  getOrganisationId,
  selectIsKioskMode,
  selectAuthStartUrl,
  (isUnauthorized, loadedConfig, profilePersonId, profileUserName, activeOrgId, isKioskMode, authStartUrl) => !isUnauthorized
    && loadedConfig
    && profileUserName != null
    && profilePersonId != null
    && activeOrgId != null
    && !(isKioskMode && authStartUrl == null),
);

const mapStateToProps = createStructuredSelector({
  activeCourse: getActiveCourse,
  pageLayout: getLayoutClassname,
  isManager: selectIsManager,
  isSuperuser: getIsSuperuser,
  profilePersonId: selectProfileId,
  profileFirstname: selectProfileFirstname,
  isUnauthorized: selectIsUnauthorized,
  customThemeOrgId: selectThemeOrgId,
  isKioskMode: selectIsKioskMode,
  isAppReady: selectIsAppReady,
  configObject: getConfigObject,
  isConfigLoaded: getIsConfigLoaded,
});

export default wrap(wrappers, connect(mapStateToProps)(App));
