import * as React from 'react';
import Cookies from 'js-cookie';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Switch} from 'react-router-dom';
import {authLogoutRequest} from '@actions/auth.actions';
import LoadableAdminSwitch from '@routes/admin';
import {PrivateRoute} from '@routes/auth';
import LoadableCourseCatalogSwitch from '@routes/course-catalog';
import {Dummy} from '@routes/dummy/dummy';
import LoadableEmployeesContainer from '@routes/employees';
import LoadableLearningPathContainer from '@routes/learning-path';
import LoadableReportSwitch from '@routes/reports';
import {
  atlas as AtlasRoutes,
  courseCatalog as courseCatalogRoutes,
  logout as logoutRoute,
  myEducation as myEducationRoutes,
} from '@routes/routes.manifest';
import {selectIsUnauthorized} from '@selectors/auth.selectors';
import {useAppHeight} from './hooks/hooks';
import {
  Atlas,
  HelpContainer,
  LoginContainer,
  MyEducationSwitch,
} from './app.components';
import {Route} from './app.constants';
import {
  employeesContainerRoutes,
  helpRoutes,
  learningPathRoutes,
} from './app.routes';
import {DefaultRedirectRoute} from './default-redirect';
import '@routes/employees/styles/roles-list.scss';

const Logout = () => {
  const dispatch = useDispatch();

  const logout = React.useCallback(() => {
    dispatch(authLogoutRequest());
  }, [dispatch]);

  React.useEffect(() => {
    Cookies.remove('tg-visit');

    logout();
  }, [logout]);

  return <Redirect to="/login" />;
};

export const AppSwitch = React.memo(() => {
  useAppHeight();

  const isUnauthorized = useSelector(selectIsUnauthorized);

  if (isUnauthorized || !Cookies.get('tg-visit')) return <LoginContainer />;

  return (
    <Switch>
      <Route
        path={logoutRoute}
        component={Logout}
      />
      <PrivateRoute
        path={'/admin/*'}
        component={LoadableAdminSwitch}
      />
      <PrivateRoute
        path={'/dashboard/*'}
        component={LoadableReportSwitch}
      />
      <PrivateRoute
        path={courseCatalogRoutes.main.path}
        component={LoadableCourseCatalogSwitch}
      />
      <PrivateRoute
        path={myEducationRoutes.main.path}
        component={MyEducationSwitch}
      />
      <PrivateRoute
        path={employeesContainerRoutes}
        component={LoadableEmployeesContainer}
      />
      <PrivateRoute
        path={AtlasRoutes.main.path}
        component={Atlas}
      />
      <PrivateRoute
        path={learningPathRoutes}
        component={LoadableLearningPathContainer}
      />
      <PrivateRoute
        path={helpRoutes}
        component={HelpContainer}
      />
      <PrivateRoute
        path="/dummy"
        component={Dummy}
      />
      <DefaultRedirectRoute />
    </Switch>
  );
});
