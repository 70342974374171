import {RESET_APP, RESET_STORE} from '@actions/global.actions';
import * as themeActions from '@actions/theme.actions';
import {emptyObj} from '@utils/constants';
import {isObjectWithKeys} from '@utils/misc.utils';
import {defaultThemeKey, getThemeCSSVarsByThemeKey} from '@utils/themes.utils';

const initialState = {
  themeKey: defaultThemeKey,
  cssVars: getThemeCSSVarsByThemeKey(defaultThemeKey),
  customTheme: emptyObj,
  orgId: null,
};

const theme = (state = initialState, action) => {
  switch (action.type) {
  case RESET_APP:
  case RESET_STORE: {
    return initialState;
  }
  case themeActions.THEME_SET_CSS_VARS: {
    if (!isObjectWithKeys(action.payload?.cssVars)) return state;

    return {
      ...state,
      cssVars: {
        ...state.cssVars,
        ...action.payload.cssVars,
      },
      themeKey: action.payload.themeKey || state.themeKey,
    };
  }
  case themeActions.THEME_SET_CUSTOM_THEME: {
    if (!isObjectWithKeys(action.payload?.customTheme)) return state;

    return {
      ...state,
      customTheme: action.payload.customTheme || state.customTheme,
      orgId: action.payload.orgId || state.orgId,
      themeKey: action.payload.themeKey || state.themeKey,
    };
  }
  default: {
    return state;
  }
  }
};

export default theme;
