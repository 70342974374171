import axios from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import * as manageActions from '@actions/manage.actions';
import {backendUrl} from '@config';
import {selectManageCompetencesWithParams} from '@selectors/manage.selectors';
import {retry} from '@utils/sagas.utils';

const ManageAxios = axios.create({
  headers: {'X-Grape-Lang': localStorage.getItem('language')},
  withCredentials: true,
});

function* fetchRoles(action) {
  yield put(manageActions.fetchManageRolesRequest());

  try {
    const roles = yield call(fetchManageRolesApi, {});

    yield put(manageActions.fetchManageRolesSuccess({roles}));
  } catch (error) {
    console.error(error);
    yield put(manageActions.fetchManageRolesFailure({error}));
  }
}

function* fetchManageRolesApi(action) {
  return yield retry(() =>
    ManageAxios
      .request({
        method: 'GET',
        url: `${backendUrl}/roles/list_roles`,
        withCredentials: true,
      })
      .then(response => response.data));
}

function* fetchManageCompetencesApi(payload) {
  const {
    limit = 100,
    batch = 0,
    searchFor = '',
    courseTypes = null,
  } = payload || {};

  const courseTypeParam = courseTypes
    ? typeof courseTypes === 'string'
      ? `&course_type=${courseTypes}`
      : !!courseTypes?.length && courseTypes?.map?.(ct => `&course_type=${ct}`)?.join('') || ''
    : '';
  const searchForParam = searchFor
    ? `&search_for=${searchFor}`
    : '';

  return yield retry(() =>
    ManageAxios
      .request({
        method: 'GET',
        url: `${backendUrl}/courses/search_competences?ajax=1&json=1&batch=${batch}&limit=${limit}&sort_by=alfa${courseTypeParam}${searchForParam}`,
        withCredentials: true,
      })
      .then(response => response.data));
}

function* fetchCompetenceDetailsApi(action) {
  return yield retry(() =>
    ManageAxios
      .request({
        method: 'GET',
        url: `${backendUrl}/courses/search_competences?search_for=&ajax=1&json=1&batch=0&limit=100&sort_by=alfa`,
        withCredentials: true,
      })
      .then(response => response.data));
}

function* fetchCompetences(action) {
  const {
    limit = 100,
    fetchMore: payloadFetchMore = false,
    courseTypes = null,
    searchFor = null,
    refetch = false,
  } = action?.payload || {};

  const anyParamsActive = !!(searchFor?.trim?.()?.length || courseTypes?.length);

  const {
    data: currentData = [],
    count: currentCount = 0,
    hasMore: currentHasMore = false,
    filteredCount: currentFilteredCount = null,
    filteredHasMore: currentFilteredHasMore = null,
  } = yield select(state => selectManageCompetencesWithParams(state, {
    searchFor,
    courseTypes,
  }));

  const fetchMore = payloadFetchMore || currentHasMore && anyParamsActive;

  if (fetchMore && !currentHasMore && !refetch) return;

  yield put(manageActions.fetchManageCompetencesRequest({fetchMore}));

  const batch = fetchMore
    ? Math.floor(currentData.length / limit)
    : 0;

  try {
    const {
      result = [],
      count = 0,
    } = yield call(fetchManageCompetencesApi, {
      limit,
      batch,
      searchFor,
      courseTypes,
    });

    const {byId, allIds} = result.reduce((acc, competence) => {
      const {id} = competence;

      acc.allIds.push(id);
      acc.byId[id] = competence;

      return acc;
    }, {
      byId: {},
      allIds: [],
    });

    const hasMore = anyParamsActive
      ? currentHasMore
      : count > currentData.length + result.length;

    yield put(manageActions.fetchManageCompetencesSuccess({
      byId,
      allIds,
      count,
      hasMore,
      fetchMore,
      filteredCount: anyParamsActive ? count : null,
      filteredHasMore: anyParamsActive ? count > result.length : null,
    }));
  } catch (error) {
    console.error(error);
    yield put(manageActions.fetchManageCompetencesFailure({error}));
  }
}

function* fetchCompetenceDetailsSaga(action) {
  yield put(manageActions.fetchManageCompetenceDetailsRequest());

  try {
    const competence = yield call(fetchCompetenceDetailsApi, {});

    yield put(manageActions.fetchManageCompetenceDetailsSuccess({data: competence}));
  } catch (error) {
    console.error(error);
    yield put(manageActions.fetchManageCompetenceDetailsFailure({error}));
  }
}

const manageSagas = [
  takeLatest(manageActions.fetchManageRoles().type, fetchRoles),
  takeLatest(manageActions.fetchManageCompetences().type, fetchCompetences),
  takeLatest(manageActions.fetchManageCompetenceDetails().type, fetchCompetenceDetailsSaga),
];

export default manageSagas;
