import {i18n} from '@src/i18n';
import * as T from '@types/load.types';
import {emptyArr, emptyObj} from '@utils/constants';
import {getMilliseconds} from '@utils/time.utils';

export const initialState = {
  competences: {
    isFetching: false,
    isFetchingInitialCompetences: false,
    data: null,
    error: null,
    isExpanded: false,
    searchTerm: '',
  },
  featuredCompetences: {
    isFetching: false,
    data: null,
    error: null,
  },
  courseEvents: {
    isFetching: false,
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
    error: null,
    lastFetched: 0,
    refetchAfter: getMilliseconds({minutes: 5}),
  },
  selectedCompetencesIds: emptyArr,
  sorting: ['alpha', i18n('globals.sort-alpha')],
  competencetypes: {
    isFetching: false,
    data: null,
    error: null,
  },
  currentSignature: null,
  competencegroups: {
    isFetching: false,
    data: null,
    error: null,
  },
  filters: {selectedCompetencetypes: emptyArr},
  courseCatalogNews: {
    isFetching: false,
    data: null,
    error: null,
    status: T.LoadStatuses.NOT_LOADED,
  },
  competenceDetails: {
    isFetching: false,
    data: null,
    error: null,
  },
  activeCourse: null,
  activeCourseMapId: null,
  courseSignOn: {
    courseIds: emptyArr,
    isFetching: false,
    ceid: null,
    error: null,
    results: {
      errors: emptyArr,
      correct: emptyArr,
    },
  },
  courseSignOff: {
    courseIds: emptyArr,
    ceid: null,
    isFetching: false,
    status: '',
  },
  courseSignCourse: {
    isFetching: false,
    ceid: null,
    error: null,
    success: false,
    status: '',
  },
  courseDeleteFileForPerson: {
    isFetching: false,
    error: null,
    success: false,
    status: false,
  },
  normalizedData: {
    competencegroups: {
      baseGroupId: null,
      ids: emptyArr,
      data: emptyObj,
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
    competences: {
      ids: emptyArr,
      data: emptyObj,
      status: T.LoadStatuses.NOT_LOADED,
    },
    competenceIdsByGroupId: {
      data: emptyObj,
      status: T.LoadStatuses.NOT_LOADED,
    },
    subCategoriesByGroupId: {
      data: emptyObj,
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
    selectedCompetencegroups: {
      ids: null,
      subCategories: emptyArr,
      competences: emptyArr,
      data: emptyArr,
      status: T.LoadStatuses.NOT_LOADED,
    },
    courseEvents: {
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
      allEventIds: emptyArr,
      eventById: emptyObj,
      eventIdsByCourseId: emptyObj,
      eventIdsByYearMonth: emptyObj,
    },
    eventParticipantsByEventId: {
      data: emptyObj,
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
    selfSignedOnEventIds: {
      eventIds: emptyArr,
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
    competenceDetails: {
      ids: emptyArr,
      data: emptyObj,
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
    competencesSearchResults: {
      searchTerm: '',
      ids: emptyArr,
      data: emptyObj,
      empty: true,
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
  },
};
