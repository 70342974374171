import dayjs from 'dayjs';
import {produce} from 'immer';
import memoize from 'micro-memoize';
import {createSelector} from 'reselect';
import {faRoute} from '@fortawesome/pro-light-svg-icons/faRoute';
import {faTachometerAltFast} from '@fortawesome/pro-light-svg-icons/faTachometerAltFast';
import {faUserTag} from '@fortawesome/pro-light-svg-icons/faUserTag';
import {i18n} from '@src/i18n';
import {LoadStatuses} from '@types/load.types';
import {emptyArr, emptyObj} from '@utils/constants';
import {isObjectWithKeys} from '@utils/misc.utils';
import {createDeepEqualSelector} from '@utils/selectors.utils';
import {cleanPhoneNumber} from '@utils/string.utils';
import {initialState} from '../initial-state/employees.initial-state';
import {selectRolesNormalized} from './api.selectors';

const EmptyFunctions = Object.freeze({
  position: emptyArr,
  role: emptyArr,
});

const EmptyPendingItems = Object.freeze({
  eventsEnrolled: emptyArr,
  eventsWaitlist: emptyArr,
  expiring: emptyArr,
  selfSign: emptyArr,
});

export const selectEmployeesRoot = ({employees}) => employees || initialState;

export const selectOrgMap = createSelector(selectEmployeesRoot, ({orgMap}) => orgMap);

export const selectEmployeesEvents = createSelector(selectEmployeesRoot, ({events}) => events);
export const selectEmployeeModalState = createSelector(selectEmployeesRoot, ({employeeModal}) => employeeModal);

export const selectWorkList = createSelector(
  selectEmployeesRoot,
  ({worklist}) => worklist,
);

export const selectWorklistOrgIdByPersonId = createSelector(
  selectWorkList,
  ({ids}) => ids?.reduce?.((acc, [pid, orgId]) => {
    if (orgId == null) return acc;

    acc[pid] = orgId;

    return acc;
  }, {}) || emptyObj,
);

export const getIsSaving = createSelector(
  selectEmployeesRoot,
  ({saving}) => saving.isSaving,
);

export const getEmployeesEventsData = createSelector(
  selectEmployeesRoot,
  ({events}) => events?.data,
);

export const getEmployeesExpiring = createSelector(
  selectEmployeesRoot,
  ({expiring}) => expiring,
);

export const getSelectedOrganisation = createSelector(
  selectEmployeesRoot,
  ({organisation}) => organisation,
);

export const getSelectedOrganisationStatus = createSelector(
  selectEmployeesRoot,
  ({organisation: {status}}) => status,
);

export const getTree = createSelector(
  selectEmployeesRoot,
  ({tree}) => tree,
);

export const selectOrganisationProgressByOrgId = createSelector(
  selectEmployeesRoot,
  ({organisationProgress: {byId}}) => byId,
);

export const getNormalizedEmployeesEvents = createSelector(
  selectEmployeesRoot,
  ({normalizedData: {events}}) => events,
);

export const _selectEmployeeIdsByEventId = state => state?.employees?.normalizedData?.events?.employeeIdsByEventId || emptyObj;

export const selectEmployeesList = createSelector(
  selectEmployeesRoot,
  ({list}) => list,
);

export const selectEmployeeIds = createSelector(
  selectEmployeesList,
  ({allIds}) => allIds,
);

export const selectEmployeesChecklists = createSelector(
  selectEmployeesRoot,
  ({checklists}) => checklists,
);

export const getIsEmployeesLoaded = createSelector(
  selectEmployeesRoot,
  ({list: {status}}) => status === LoadStatuses.LOADED,
);

export const getEmployeesStatistics = createSelector(
  selectEmployeesRoot,
  ({statistics}) => statistics,
);

export const getFilteredEmployees = createSelector(
  selectEmployeesRoot,
  ({filteredList}) => filteredList,
);

export const getReport = createSelector(
  selectEmployeesRoot,
  ({report}) => report,
);

export const getIsFetchingFunctions = createSelector(
  selectEmployeesRoot,
  ({functions: {isFetching} = {}}) => isFetching,
);

export const selectEmployeesPendingSelfSignById = createSelector(
  selectEmployeesChecklists,
  checklists => {
    const {data: tasksData} = checklists || {};

    if (!tasksData?.length) return emptyObj;

    const data = tasksData.reduce((acc, task) => {
      if (!task.person_id) return acc;

      if (!acc[task.person_id]) acc[task.person_id] = [];

      acc[task.person_id].push(task);

      return acc;
    }, {});

    return data;
  },
);

export const selectEmployeesEventsStatus = createSelector(
  selectEmployeesEvents,
  events => {
    if (!events) return {status: LoadStatuses.NOT_LOADED};

    const {status, lastFetched, refetchAfter} = events || {};

    return {
      status,
      lastFetched,
      refetchAfter,
    };
  },
);

export const selectEmployeesEventsById = createSelector(
  getEmployeesEventsData,
  events => {
    if (!events?.length) return emptyObj;

    const data = events.reduce((acc, event) => {
      if (
        !event?.person?.person_id
        || !event?.startdate
        || dayjs().isAfter(dayjs(event?.startdate))
      ) return acc;

      if (!acc[event.person.person_id]) acc[event.person.person_id] = {
        enrolled: [],
        waitlist: [],
      };

      if (event.waitlist) {
        acc[event.person.person_id].waitlist.push(event);
      } else {
        acc[event.person.person_id].enrolled.push(event);
      }

      return acc;
    }, {});

    return data;
  },
);

export const selectEmployeesExpiringCompetencesById = createSelector(
  getEmployeesExpiring,
  expiring => {
    if (!expiring?.data?.length) return emptyObj;

    const data = expiring.data.reduce((acc, item) => {
      if (!item?.person?.person_id) return acc;

      if (!acc[item.person.person_id]) acc[item.person.person_id] = [];

      acc[item.person.person_id].push(item);

      return acc;
    }, {});

    return data;
  },
);

export const createEmployeePendingItemsByIdSelector = memoize(personId =>
  personId
    ? createDeepEqualSelector(
      selectEmployeesEventsById,
      selectEmployeesPendingSelfSignById,
      selectEmployeesExpiringCompetencesById,
      (events, selfSign, expiring) => ({
        eventsEnrolled: events?.[personId]?.enrolled || EmptyPendingItems.eventsEnrolled,
        eventsWaitlist: events?.[personId]?.waitlist || EmptyPendingItems.eventsWaitlist,
        expiring: expiring?.[personId] || EmptyPendingItems.expiring,
        selfSign: selfSign?.[personId] || EmptyPendingItems.selfSign,
      }),
    )
    : () => EmptyPendingItems);

const selectPersonRolesByPersonId = createDeepEqualSelector(
  selectRolesNormalized,
  selectEmployeesList,
  (roles, employees) => {
    if (!employees?.data?.length || !isObjectWithKeys(roles?.data)) return emptyObj;

    const data = employees.data.reduce((acc, employee) => {
      if (!employee?.person_id) return acc;

      const activeRoles = employee?.active_role_ids?.map?.(roleId => roles?.data?.[roleId]).filter(Boolean) || emptyArr;

      acc[employee.person_id] = activeRoles;

      return acc;
    }, {});

    return data;
  },
);

export const getEmployees = createSelector(
  selectEmployeesList,
  selectPersonRolesByPersonId,
  selectEmployeesEventsById,
  selectEmployeesPendingSelfSignById,
  selectEmployeesExpiringCompetencesById,
  (employees, roles, events, checklists, expiring) => {
    const personIdByUserName = {};

    const updated = produce(employees, draft => {
      const {data, byId} = draft;

      data?.forEach?.(employee => {
        if (!employee?.person_id) return;

        const {person_id, user_name, mobile} = employee;

        if (user_name) personIdByUserName[user_name] = person_id;

        Object.assign(employee, {
          ...mobile && {mobile: cleanPhoneNumber(mobile)},
          pending_checklists: checklists?.[person_id]?.length,
          eventsEnrolled: events?.[person_id]?.enrolled || emptyArr,
          eventsWaitlist: events?.[person_id]?.waitlist || emptyArr,
          expiringCompetences: expiring?.[person_id] || emptyArr,
          pendingSelfSign: checklists?.[person_id] || emptyArr,
          active_personroles: roles?.[person_id] || emptyArr,
        });

        if (!byId[person_id]) byId[person_id] = employee;
      });
    });

    return {
      ...updated,
      personIdByUserName,
    };
  },
);

export const getStatisticsKindsForView = createSelector(
  getEmployeesStatistics,
  // getIsFetchingFunctions,
  (statistics /* isFetching*/) => {
    if (!statistics?.data/* || isFetching*/) {
      return emptyArr;
    }

    const stats = [
      {
        id: 'dashboard',
        data: {},
        icon: faTachometerAltFast,
        name: i18n('employees.report-search'),
        gotoName: '/dashboard/search',
      },
    ];

    stats.push({
      id: 'totalt',
      data: statistics.data.progress,
      name: i18n('employees.total'),
      gotoName: i18n('globals.goto-x', {functionArgs: {x: i18n('employees.total')}}),
    });

    statistics.data.functions.forEach(item => {
      stats.push({
        id: item.role_id,
        data: item.progress,
        name: item.title,
        gotoName: i18n('globals.goto-x', {functionArgs: {x: item.title}}),
      });
    });

    stats.push(
      {
        id: 'role',
        data: {},
        name: i18n('employees.report-roles'),
        gotoName: i18n('globals.goto-x', {functionArgs: {x: i18n('employees.report-roles')}}),
        icon: faUserTag,
      },
      {
        id: 'competence',
        data: {},
        name: i18n('employees.report-competences'),
        gotoName: i18n('globals.goto-x', {functionArgs: {x: i18n('employees.report-competences')}}),
        icon: faRoute,
      },
    );

    return stats;
  },
);

export const getFunctions = createSelector(
  selectEmployeesRoot,
  ({functions}) => functions?.data?.length
    ? functions.data.reduce(
      (acc, cur) => {
        const {rolemetatype} = cur;

        const types = ['position', 'role'];

        types.forEach(type => {
          if (rolemetatype === type) {
            acc[type].push(cur);
          }
        });

        return acc;
      },
      {
        position: [],
        role: [],
      },
    )
    : EmptyFunctions,
);
