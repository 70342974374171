import {LoadStatuses} from '@types/load.types';
import {isObjectWithKeys} from '@utils/misc.utils';
import {validIntOrNull} from '@utils/number.utils';

const {
  FAILED,
  NOT_LOADED,
  LOADED,
  IS_LOADING,
  LOADED_PARTIAL,
  IS_LOADING_MORE,
  NEED_RELOAD,
} = LoadStatuses;

export const getLoadStatus = stateOrStatus => typeof stateOrStatus === 'string'
  ? stateOrStatus
  : stateOrStatus?.status;

export const isFailed = stateOrStatus => getLoadStatus(stateOrStatus) === FAILED;
export const isNotLoaded = stateOrStatus => getLoadStatus(stateOrStatus) === NOT_LOADED;
export const isLoaded = stateOrStatus => getLoadStatus(stateOrStatus) === LOADED;
export const isLoading = stateOrStatus => getLoadStatus(stateOrStatus) === IS_LOADING;
export const isLoadedPartial = stateOrStatus => getLoadStatus(stateOrStatus) === LOADED_PARTIAL;
export const isLoadingMore = stateOrStatus => getLoadStatus(stateOrStatus) === IS_LOADING_MORE;
export const isNeedReload = stateOrStatus => getLoadStatus(stateOrStatus) === NEED_RELOAD;

const isOtherOrgId = (state, payload) => payload?.orgId != null
  && state?.orgId != null
  && validIntOrNull(state.orgId) !== validIntOrNull(payload.orgId);

export const isFailedOrNotLoaded = (state, payload = {}) =>
  isNotLoaded(state) || isFailed(state) || isOtherOrgId(state, payload);

export const isLoadedOrLoading = state =>
  isLoaded(state) || isLoading(state) || isLoadingMore(state);

export const isLoadingOrNotLoaded = state => isLoading(state) || isNotLoaded(state);

export const getShouldFetchFull = (state, payload) => {
  if (isLoadedPartial(state) || isFailedOrNotLoaded(state, payload)) return true;

  return !isLoadedOrLoading(state);
};

export const getShouldRefetch = state => {
  const {lastFetched = null, refetchAfter = null} = state || {};

  if (isNeedReload(state)) return true;
  if (isLoading(state) || !lastFetched || !refetchAfter) return false;

  return (lastFetched || 0) + (refetchAfter || 0) - Date.now() < 0;
};

export const getShouldFetch = (state, payload) => {
  const fullFetch = !!payload?.forceFull || getShouldFetchFull(state, payload);

  const refresh = isObjectWithKeys(payload)
    ? !!payload.forceRefresh || !!payload.refetch || !!payload.refresh
    : !fullFetch && getShouldRefetch(state);

  if (!fullFetch && !refresh) return null;

  return {
    full: fullFetch,
    refetch: refresh,
    refresh,
  };
};

export const updateFetchStateRequest = (draftState, payload) => {
  if (payload?.refresh) return; // todo: change to LoadStatuses.IS_RELOADING
  draftState.status = IS_LOADING;

  if (draftState.error) draftState.error = null;
  if (draftState.isFetching) draftState.isFetching = true;
  if (draftState.lastFetched !== undefined) draftState.lastFetched = Date.now();

  if (payload?.orgId !== undefined) draftState.orgId = payload.orgId;
};

export const updateFetchStateSuccess = (draftState, payload) => {
  draftState.status = LOADED;

  if (draftState.lastFetched !== undefined) draftState.lastFetched = Date.now();

  if (draftState.isFetching) draftState.isFetching = false;
  if (draftState.error) draftState.error = null;

  if (!isObjectWithKeys(payload)) return;

  if (payload.data !== undefined) draftState.data = payload.data;
  if (payload.byId !== undefined) draftState.byId = payload.byId;
  if (payload.allIds !== undefined) draftState.allIds = payload.allIds;

  if (payload.orgId !== undefined) draftState.orgId = payload.orgId;
  if (payload.hasMore !== undefined) draftState.hasMore = payload.hasMore;
};

export const updateFetchStateFailure = (draftState, payload) => {
  draftState.status = FAILED;
  if (draftState.isFetching) draftState.isFetching = false;
  if (draftState.lastFetched !== undefined) draftState.lastFetched = Date.now();

  if (!isObjectWithKeys(payload)) return;

  if (payload.error) draftState.error = payload.error;
};
