import {freeze} from 'immer';
import {createReducerAndActions} from '@snapper/core';
import * as T from '@types/load.types';
import {emptyArr} from '@utils/constants';
import {updateFetchStateFailure, updateFetchStateRequest, updateFetchStateSuccess} from '@utils/loadstatus.utils';
import {last} from '@utils/misc.utils';
import {validIntOrNull} from '@utils/number.utils';
import {initialState} from '../initial-state/employees.initial-state';
import {searchItems} from '../util/employees.util';
import selectedPersonActionDefinitions from './employees.selected-person.actions';

const employees = {
  selectedPersonActions: selectedPersonActionDefinitions,

  setOrgMap: (state, action) => {
    state.orgMap = action.payload;
  },
  getEmployees: {
    request: (state, action) => {
      const orgId = validIntOrNull(action?.payload?.orgId);
      const isOtherOrg = orgId != null && orgId !== state.list.orgId;

      // reset if orgId changed
      if (isOtherOrg) {
        Object.assign(state.list, initialState.list);
        state.list.orgId = orgId;
      }

      state.list.isFetching = true;
      state.list.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      state.list.isFetching = false;
      state.list.status = T.LoadStatuses.LOADED;
      state.list.hasMore = action.payload.hasMore;
      state.list.hasChildren = action.payload.hasChildren;

      const {data, offset} = action?.payload || {};

      if (!data?.length) return;

      if (!offset || !state.list.data) {
        state.list.data = [];
        state.list.allIds = [];
      }

      for (const employee of data) {
        const id = employee?.person_id;

        if (id == null) continue;

        state.list.byId[id] = employee;
        state.list.allIds.push(id);
        state.list.data.push(employee);
      }
    },
    failure: (state, action) => {
      state.list.isFetching = false;
      state.list.status = T.LoadStatuses.FAILED;
      state.normalizedData.employees.status = T.LoadStatuses.FAILED;
    },
  },
  getWorklist: {
    request: (state, action) => {
      // state.worklist.data = null;
      state.worklist.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      state.worklist.data = freeze(action.payload.data);
      state.worklist.ids = action.payload.ids;
      state.worklist.status = T.LoadStatuses.LOADED;
    },
    failure: (state, action) => {
      state.worklist.data = null;
      state.worklist.status = T.LoadStatuses.FAILED;
    },
  },

  fetchFunctions: {
    request: (state, action) => {
      state.functions.isFetching = true;
    },
    success: (state, action) => {
      state.functions.data = action.payload.functions;
      state.functions.isFetching = false;
    },
    failure: (state, action) => {
      state.functions.error = action.payload.error;
      state.functions.isFetching = false;
    },
  },

  fetchSelectedPersonReport: {
    request: (state, action) => {
      const {userName} = action?.payload || {};

      state.selectedPersonV2.apiData.report = {
        status: T.LoadStatuses.IS_LOADING,
        data: userName && userName === state.selectedPersonV2.apiData.report?.userName
          ? state?.selectedPersonV2.apiData.report?.data ?? null
          : null,
        userName,
      };
    },
    success: (state, action) => {
      const {userName, data} = action?.payload || {};

      state.selectedPersonV2.apiData.report = {
        status: T.LoadStatuses.LOADED,
        data,
        userName,
      };
    },
    failure: (state, action) => {
      state.selectedPersonV2.apiData.report = {
        isFetching: false,
        status: T.LoadStatuses.FAILED,
        data: null,
        userName: null,
      };
    },
  },
  fetchSelectedPersonChecklists: {
    request: null,
    success: (state, action) => {
      state.selectedPersonV2.apiData.checklists = action.payload.data;
    },
    failure: null,
  },
  fetchSelectedPersonEvents: {
    request: null,
    success: (state, action) => {
      state.selectedPersonV2.apiData.events = action.payload.data;
    },
    failure: null,
  },
  fetchSelectedPersonExpiring: {
    request: null,
    success: (state, action) => {
      state.selectedPersonV2.apiData.expiring = action.payload.data;
    },
    failure: null,
  },
  fetchSelectedPerson: {
    request: null,
    success: null,
    failure: null,
  },
  fetchSelectedPersonRoleCompetences: {
    request: null,
    success: null,
    failure: null,
  },
  updateSelectedPersonEmployment: {
    request: null,
    success: null,
    failure: null,
  },
  fetchSelectedPersonCompetencesChildren: {
    request: null,
    success: null,
    failure: null,
  },
  editSelectedPerson: {
    request: null,
    success: null,
    failure: null,
  },

  approveRejectSelfSign: {
    request: (state, action) => {
      state.checklists.saving.status = T.LoadStatuses.SAVING;
    },
    success: (state, action) => {
      state.checklists.saving.status = T.LoadStatuses.SAVED;
    },
    failure: (state, action) => {
      state.checklists.saving.status = T.LoadStatuses.FAILED;
    },
  },

  fetchOrganisation: {
    request: (state, action) => {
      const {orgId: payloadOrgId, isParent} = action?.payload || {};

      if (isParent) return;

      const orgId = validIntOrNull(payloadOrgId);

      if (orgId != null) {
        if (state.organisation.orgId === orgId) return;

        state.organisation.orgId = orgId;
      }

      state.organisation.status = T.LoadStatuses.IS_LOADING;
      state.organisation.data = null;
    },
    success: (state, action) => {
      const {isParent} = action?.payload || {};

      if (isParent) return;

      state.organisation.status = T.LoadStatuses.LOADED;
      state.organisation.data = action.payload.data;
    },
    failure: (state, action) => {
      const {isParent} = action?.payload || {};

      if (isParent) return;

      state.organisation.status = T.LoadStatuses.FAILED;
      state.organisation.data = null;
    },
  },
  fetchTree: {
    request: (state, action) => {
      const {
        orgId: payloadOrgId,
        fetchMore,
      } = action?.payload || {};

      const orgId = validIntOrNull(payloadOrgId);

      if (fetchMore) {
        const child = !!state.tree?.data && searchItems(state.tree.data, orgId);

        if (child) {
          child.status = T.LoadStatuses.IS_LOADING;
        }
      } else {
        state.tree.status = T.LoadStatuses.IS_LOADING;
        state.tree.data = null;
      }
    },
    success: (state, action) => {
      const {
        fetchMore,
        orgId: payloadOrgId,
        data,
      } = action?.payload || {};

      const orgId = validIntOrNull(payloadOrgId);

      if (fetchMore) {
        const child = !!state.tree?.data && searchItems(state.tree.data, orgId);

        if (child) {
          child.status = T.LoadStatuses.LOADED;
          child.children = data?.children;
        }

        state.tree.status = T.LoadStatuses.LOADED;
      } else {
        state.tree.status = T.LoadStatuses.LOADED;
        state.tree.data = data;
      }
    },
    failure: (state, action) => {
      state.tree.status = T.LoadStatuses.FAILED;
      state.tree.data = null;
    },
  },
  fetchTreeProgress: {
    request: null,
    success: (state, action) => {
      const {orgId: payloadOrgId, data = null} = action?.payload || {};
      const orgId = validIntOrNull(payloadOrgId);

      if (orgId) {
        state.organisationProgress.byId[orgId] = data;
      }

      if (!state.tree?.data || data == null) return;

      const child = searchItems(state.tree.data, orgId);

      if (child) {
        child.progress_status = T.LoadStatuses.LOADED;
        child.progress = data;
      }
    },
    failure: null,
  },

  fetchStatistics: {
    request: (state, action) => {
      if (action?.payload?.orgId != null && state?.statistics?.orgId !== action.payload.orgId) {
        state.statistics.data = initialState.statistics.data;
      }
      updateFetchStateRequest(state.statistics, {
        refresh: action?.payload?.refresh,
        orgId: action?.payload?.orgId,
      });
    },
    success: (state, action) => {
      updateFetchStateSuccess(state.statistics, {data: action.payload.statistics});
    },
    failure: (state, action) => {
      updateFetchStateFailure(state.statistics, {error: action.payload.error});
    },
  },
  fetchChecklists: {
    request: (state, action) => {
      const {orgId: payloadOrgId, refresh} = action?.payload || {};
      const orgId = validIntOrNull(payloadOrgId);

      if (orgId != null && state?.checklists?.orgId !== orgId) {
        state.checklists.data = initialState.checklists.data;
      }

      updateFetchStateRequest(state.checklists, {
        refresh,
        orgId,
      });
    },
    success: (state, action) => {
      updateFetchStateSuccess(state.checklists, {data: action.payload.competences});
    },
    failure: (state, action) => {
      updateFetchStateFailure(state.checklists, {error: action.payload.error});
    },
  },
  fetchExtraData: {
    request: (state, action) => {
      state.extraData.isFetching = true;
    },
    success: (state, action) => {
      state.extraData = {
        data: action.payload.data,
        isFetching: false,
      };
    },
    failure: (state, action) => {
      state.extraData.isFetching = false;
      state.extraData.error = action.payload.error;
    },
  },
  fetchActivities: {
    request: (state, action) => {
      state.activities.isFetching = true;
      if (action?.payload?.orgId) state.activities.orgId = action.payload.orgId;
    },
    success: (state, action) => {
      state.activities.isFetching = false;
      state.activities.data = action.payload.activities;
    },
    failure: (state, action) => {
      state.activities.isFetching = false;
      state.activities.error = action.payload.error;
    },
  },
  fetchExpiringCompetences: {
    request: (state, action) => {
      state.expiring.isFetching = true;
      state.expiring.status = T.LoadStatuses.IS_LOADING;

      if (action.payload.orgId) {
        if (state.expiring.orgId !== Number(action.payload.orgId)) {
          state.expiring.data = initialState.expiring.data;
        }
        state.expiring.orgId = Number(action.payload.orgId);
      }
    },
    success: (state, action) => {
      state.expiring.isFetching = false;
      state.expiring.status = T.LoadStatuses.LOADED;
      state.expiring.data = action.payload.expiring;
    },
    failure: (state, action) => {
      state.expiring.isFetching = false;
      state.expiring.error = action.payload.error;
    },
  },
  viewReport: {
    request: (state, action) => {
      state.report = {
        data: null,
        id: action.payload.reportId,
        status: T.LoadStatuses.IS_LOADING,
      };
    },
    success: (state, action) => {
      state.report = {
        data: action.payload.data,
        status: T.LoadStatuses.LOADED,
      };
    },
    failure: (state, action) => {
      state.report = {
        data: null,
        status: T.LoadStatuses.FAILED,
        error: action.payload.error,
      };
    },
  },
  saveVerification: {
    request: (state, action) => {
      state.saving.isSaving = true;
    },
    success: (state, action) => {
      state.saving.isSaving = false;
      state.saving.error = null;
    },
    failure: (state, action) => {
      state.saving.isSaving = false;
      state.saving.error = action.payload.error;
    },
  },
  saveRequirements: {
    request: (state, action) => {
      state.saving.isSaving = true;
    },
    success: (state, action) => {
      state.saving.isSaving = false;
      state.saving.error = null;
    },
    failure: (state, action) => {
      state.saving.isSaving = false;
      state.saving.error = action.payload.error;
    },
  },
  saveRole: {
    request: (state, action) => {
      state.saving.isSaving = true;
    },
    success: (state, action) => {
      state.saving.isSaving = false;
      state.saving.error = null;
    },
    failure: (state, action) => {
      state.saving.isSaving = false;

      state.saving.error = action.payload.error;
    },
  },
  fetchEvents: {
    request: (state, action) => {
      const {orgId: payloadOrgId, refresh} = action?.payload || {};

      const orgId = validIntOrNull(payloadOrgId);

      if (orgId) {
        if (state.events.orgId !== orgId) {
          state.events.data = initialState.events.data;

          state.normalizedData.events = {
            ...initialState.normalizedData.events,
            orgId,
            status: T.LoadStatuses.IS_LOADING,
          };
        }
        state.events.orgId = orgId;
        state.events.status = T.LoadStatuses.IS_LOADING;
      }

      state.events.error = null;

      if (refresh) return;

      state.events.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      const {orgId: payloadOrgId, events} = action?.payload || {};
      const orgId = validIntOrNull(payloadOrgId);

      if (orgId) {
        state.events.orgId = orgId;
        state.normalizedData.events.orgId = orgId;
      }

      if (events) state.events.data = events;

      state.events.status = T.LoadStatuses.LOADED;
      state.events.lastFetched = Date.now();
    },
    failure: (state, action) => {
      state.events.isFetching = false;
      state.events.error = action.payload.error;
    },
  },
  fetchEventsWaitlist: {
    request: (state, action) => {
      state.eventsWaitlist.isFetching = true;
      state.eventsWaitlist.error = null;
    },
    success: (state, action) => {
      state.eventsWaitlist.isFetching = false;
      state.eventsWaitlist.data = action.payload.eventsWaitlist;
    },
    failure: (state, action) => {
      state.eventsWaitlist.isFetching = false;
      state.eventsWaitlist.error = action.payload.error;
    },
  },
  updateEvents: (state, action) => {
    if (action.payload.empty) {
      if (state.normalizedData.events.eventsSortedByDate.length) {
        state.normalizedData.events = {
          ...initialState.normalizedData.events,
          status: T.LoadStatuses.LOADED,
        };
      } else {
        state.normalizedData.events.status = T.LoadStatuses.LOADED;
      }

      state.events.data = emptyArr;

      updateFetchStateSuccess(state.events, null);

      return;
    }

    state.normalizedData.events.status = T.LoadStatuses.LOADED;
    state.normalizedData.events.persons = action.payload.persons;
    state.normalizedData.events.eventsSortedByDate = action.payload.eventsSortedByDate;

    state.normalizedData.events.employeeIdsByEventId = action.payload.employeeIdsByEventId;
    state.normalizedData.events.employeeIdsWaitlistByEventId = action.payload.employeeIdsWaitlistByEventId;
    state.normalizedData.events.employeeIdsConfirmedByEventId = action.payload.employeeIdsConfirmedByEventId;

    updateFetchStateSuccess(state.events, null);
    state.events.data = action.payload.apiData;
  },
  putEmployeeModal: (state, action) => {
    const {
      personId,
      userName,
      orgId,
      isOpen,
      activeTab = initialState.employeeModal.activeTab,
    } = action?.payload || {};

    if (!isOpen) {
      state.employeeModal = initialState.employeeModal;

      return;
    }

    state.employeeModal = {
      isOpen,
      orgId,
      userName,
      personId,
      activeTab,
      tabHistory: [activeTab],
    };
  },
  putEmployeeModalTab: {
    popTab: (state, action) => {
      state.employeeModal.tabHistory.pop();
      state.employeeModal.activeTab = last(state.employeeModal.tabHistory) || initialState.employeeModal.activeTab;
    },
    pushTab: (state, action) => {
      const activeTab = action?.payload || initialState.employeeModal.activeTab;

      if (state.employeeModal.activeTab !== activeTab) {
        state.employeeModal.activeTab = activeTab;
        state.employeeModal.tabHistory.push(activeTab);
      }
    },
    resetTabs: (state, action) => {
      state.employeeModal.activeTab = initialState.employeeModal.activeTab;
      state.employeeModal.tabHistory = initialState.employeeModal.tabHistory;
    },
  },
  removeRequirements: {
    request: (state, action) => {
      state.saving.isSaving = true;
    },
    success: (state, action) => {
      state.saving.isSaving = false;
      state.saving.error = null;
    },
    failure: (state, action) => {
      state.saving.isSaving = false;
      state.saving.error = action.payload.error;
    },
  },
  // actions without reducers
  openEmployeeModal: null,
  closeEmployeeModal: null,
  addPerson: {
    request: null,
    success: null,
    failure: null,
  },
  editPassword: {
    request: null,
    success: null,
    failure: null,
  },
  employeeMove: {
    request: null,
    success: null,
    failure: null,
  },
  worklistAddRoles: {
    request: null,
    success: null,
    failure: null,
  },
  worklistSendMail: {
    request: null,
    success: null,
    failure: null,
  },
  resetPassword: {
    request: null,
    success: null,
    failure: null,
  },
  editViewInit: null,
  fetchExtradata: null,
  worklistAdd: null,
  worklistClear: null,
  worklistRemove: null,
  addViewInit: null,
  reportViewInit: null,
};

const {actions, reducer} = createReducerAndActions({
  prefix: 'employees',
  initialState,
  actions: employees,
});

export {reducer as employeesReducerImmer};

export default reducer;

export const {
  selectedPersonActions,

  editSelectedPerson: employeesEditSelectedPerson,
  fetchSelectedPerson: employeesFetchSelectedPerson,
  fetchSelectedPersonChecklists: employeesFetchSelectedPersonChecklists,
  fetchSelectedPersonRoleCompetences: employeesFetchSelectedPersonRoleCompetences,
  fetchSelectedPersonCompetencesChildren: employeesFetchSelectedPersonCompetencesChildren,
  fetchSelectedPersonEvents: employeesFetchSelectedPersonEvents,
  fetchSelectedPersonExpiring: employeesFetchSelectedPersonExpiring,
  fetchSelectedPersonReport: employeesFetchSelectedPersonReport,
  updateSelectedPersonEmployment: employeesUpdateSelectedPersonEmployment,

  setOrgMap: employeesSetOrgMap,
  openEmployeeModal: employeesOpenEmployeeModal,
  closeEmployeeModal: employeesCloseEmployeeModal,
  putEmployeeModal: employeesPutEmployeeModal,
  putEmployeeModalTab: employeesPutEmployeeModalTab,
  addPerson: employeesAddPerson,
  addViewInit: employeesInitAddView,
  approveRejectSelfSign: employeesApproveRejectSelfSign,
  editPassword: employeesEditPassword,
  editViewInit: employeesInitEditView,
  employeeMove: employeesMoveEmployee,
  fetchActivities: employeesFetchActivities,
  fetchChecklists: employeesFetchChecklists,
  fetchEvents: employeesFetchEvents,
  fetchEventsWaitlist: employeesFetchEventsWaitlist,
  fetchExpiringCompetences: employeesFetchExpiringCompetences,
  fetchExtradata: employeesFetchExtradata,
  fetchFunctions: employeesFetchFunctions,
  fetchOrganisation: employeesFetchOrganisation,
  fetchStatistics: employeesFetchStatistics,
  fetchTree: employeesFetchTree,
  fetchTreeProgress: employeesFetchTreeProgress,
  getEmployees: employeesGetEmployees,
  removeRequirements: employeesRemoveRequirements,
  viewReport: employeesViewReport,
  reportViewInit: employeesReportViewInit,
  resetPassword: employeesResetPassword,
  saveRequirements: employeesSaveRequirements,
  saveRole: employeesSaveRole,
  saveVerification: employeesSaveVerification,
  updateEvents: employeesUpdateEvents,
  getWorklist: employeesGetWorklist,
  worklistAdd: employeesWorklistAdd,
  worklistAddRoles: employeesWorklistAddRoles,
  worklistClear: employeesWorklistClear,
  worklistRemove: employeesWorklistRemove,
  worklistSendMail: employeesWorklistSendMail,
} = actions;
