import * as T from '@types/load.types';
import {emptyArr, emptyObj} from '@utils/constants';
import {getMilliseconds} from '@utils/time.utils';
import {selectedPersonV2InitialState} from './employees.selected-person.initial-state';

export const initialState = {
  orgMap: emptyObj,

  selectedPersonV2: selectedPersonV2InitialState,

  employeeModal: {
    isOpen: false,
    personId: null,
    userName: null,
    orgId: null,
    activeTab: 'overview',
    tabHistory: emptyArr,
  },

  list: {
    isFetching: false,
    hasMore: false,
    status: T.LoadStatuses.NOT_LOADED,
    hasChildren: false,
    data: null,
    byId: emptyObj,
    allIds: emptyArr,
    orgId: null,
  },
  tree: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
  },
  organisation: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
    orgId: null,
  },
  organisationProgress: {byId: emptyObj},
  organisations: {
    status: T.LoadStatuses.NOT_LOADED,
    hasMore: false,
    data: null,
  },
  filteredList: {
    isFetching: false,
    hasMore: false,
    data: null,
  },
  statistics: {
    isFetching: false,
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
    error: null,
    orgId: null,
  },
  checklists: {
    saving: {
      status: T.LoadStatuses.NOT_LOADED,
      error: null,
    },
    isFetching: false,
    data: emptyArr,
    error: null,
    status: T.LoadStatuses.NOT_LOADED,
    lastFetched: 0,
    refetchAfter: getMilliseconds({minutes: 5}),
    orgId: null,
  },
  expiring: {
    status: T.LoadStatuses.NOT_LOADED,
    isFetching: false,
    error: null,
    data: null,
    orgId: null,
  },
  extraData: {
    isFetching: false,
    error: null,
    data: null,
  },
  activities: {
    isFetching: false,
    data: null,
    error: null,
    orgId: null,
  },
  report: {
    status: T.LoadStatuses.NOT_LOADED,
    id: '',
    data: null,
    error: null,
  },
  worklist: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
    ids: emptyArr,
    error: null,
  },
  functions: {
    isFetching: false,
    data: emptyArr,
    error: null,
    orgId: null,
  },
  saving: {
    isSaving: false,
    error: null,
  },
  events: {
    status: T.LoadStatuses.NOT_LOADED,
    isFetching: false,
    data: null,
    error: null,
    lastFetched: 0,
    refetchAfter: getMilliseconds({minutes: 5}),
    orgId: null,
  },
  eventsWaitlist: {
    isFetching: false,
    data: null,
    error: null,
  },
  searchPhraze: '',
  normalizedData: {
    employees: {
      orgId: null,
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
      ids: emptyArr,
      data: emptyObj,
    },
    events: {
      orgId: null,
      error: null,
      status: T.LoadStatuses.NOT_LOADED,

      employeeIdsByEventId: emptyObj,
      employeeIdsWaitlistByEventId: emptyObj,
      employeeIdsConfirmedByEventId: emptyObj,

      eventsSortedByDate: emptyArr,
      eventIdsByOrgId: emptyObj,
    },
  },
};
